import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import axios, { Axios } from 'axios';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css'],
})
export class CustomersComponent implements OnInit {
  moment: any = moment;
  showTable: boolean = true;
  searchInput = '';
  tableSpinner: boolean = false;

  orderColumn: any = [
    // {
    //   title: 'S.No',
    //   width: '30px',
    // },
    {
      title: 'Name',
      width: '50px',
    },
    {
      title: 'Mobile',
      width: '60px',
    },
    {
      title: 'Email',
      width: '110px',
    },
    {
      title: 'Address',
      width: '80px',
    },
    {
      title: 'Total Orders Count',
      width: '75px',
    },
    {
      title: 'Total Orders Price',
      width: '75px',
    },
  ];

  customerList: any = [];

  // customer products section
  customerOrders: any = [];
  productPreview: boolean = false;
  liveUrl = environment.url;
  drawerSpinner: boolean = false;
  drawerWidth: any;

  // pagination

  pageSize = 10;
  pageIndex = 1;
  totalCount: number = 0;

  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    if (window.innerWidth > 768) {
      this.drawerWidth = '50%';
    } else {
      this.drawerWidth = '95%';
    }
    this.fetchData();
  }

  onPageNumberChange(id: any, event: any) {
    if (event.pageIndex != this.pageIndex) {
      this.pageIndex = event.pageIndex;
      this.fetchData();
    }
  }

  fetchData() {
    this.tableSpinner = true;
    this.spinner.show();
    axios
      .post(
        environment.url +
          'orders/customer-orders?page=' +
          this.pageIndex +
          '&search=' +
          this.searchInput,
        {},
        {
          headers: {
            Authorization:
              'Bearer ' + localStorage.getItem('admintokenoasisdata'),
          },
        }
      )
      .then((response) => {
        this.customerList = response?.data?.data;
        console.log(response.data);
        this.totalCount = response.data?.pagination?.totalCount;
        this.pageSize = response?.data?.pagination?.limit;
        document.getElementById('customersTable').scrollIntoView();
        this.spinner.hide();
        this.tableSpinner = false;
      })
      .catch((err) => {
        console.log(err.response);
        this.tableSpinner = false;
        this.spinner.hide();
      });
  }

  showOrderDetails(item: any) {
    // console.log(item);
    if (item?.orderCount) {
      this.drawerSpinner = true;
      this.spinner.show();
      this.productPreview = true;

      axios
        .post(
          environment.url + 'orders/get-orders-by-userId',
          { userId: item?._id },
          {
            headers: {
              Authorization:
                'Bearer ' + localStorage.getItem('admintokenoasisdata'),
            },
          }
        )
        .then((response) => {
          this.customerOrders = response?.data?.data?.allOrders;
          console.log(this.customerOrders);
          this.drawerSpinner = false;
          this.spinner.hide();
        })
        .catch((error) => {
          console.log(error.response);
          if (error.response.data.data == undefined) {
            this.toastr.error('', error.response.data.message, {
              timeOut: 2500,
            });
          } else {
            this.toastr.error('', error.response.data.data[0].msg, {
              timeOut: 2500,
            });
          }
          this.drawerSpinner = false;
          this.spinner.hide();
        });
    }
  }

  previewClose() {
    this.productPreview = false;
    this.customerOrders = [];
  }

  searchCustomers() {
    this.pageIndex = 1;
    this.fetchData();
  }
}
