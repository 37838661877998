// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  url: "https://now7.getdesignworks.com/",
  // url: "http://192.168.1.15:4020/",

  firebase: {
    apiKey: "AIzaSyAeTD3HBYuY08iQSYC8db2Y7vrBKNEgUNk",
    authDomain: "muttonstall-adminpanel.firebaseapp.com",
    projectId: "muttonstall-adminpanel",
    storageBucket: "muttonstall-adminpanel.appspot.com",
    messagingSenderId: "917478658173",
    appId: "1:917478658173:web:d9c4092303cee3bc486114",
    measurementId: "G-M584LJN0VC",
    vapidKey:
      "BELU_Ore4PCpCqsZuNE-nuKGgsufoJchMI9Rqf0rnmLUZ9uirbJu4PsIDO5ajMbDFgzvc31mADd_Mg5ualIzCno",
  },
};

// LIVE URL : https://now5.getdesignworks.com/

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
