<div class="container-fluid p-h-0 p-v-20 h-100 bg" style="background-image: url('assets/images/others/login-3.png')">
    <div class="d-flex flex-column justify-content-center h-100">
        <div class="d-none d-md-block"></div>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-7 col-lg-5 m-h-auto">
                    <nz-card class="m-b-100 shadow-lg">
                        <div class="d-flex align-items-center justify-content-between m-b-30">
                            <img class="img-fluid logo" alt="" src="../../../assets/images/logo/Shamimah_logo.svg">
                            <a class="close-icon" routerLink="/"><i nz-icon nzType="close" nzTheme="outline"></i></a>
                        </div>
                        <div class="text-center mb-5">
                            <p class="mobile-text">Please Contact Your Administrator.</p>
                            <!-- <b class="text-danger">dummy@gmail.com</b> -->
                        </div>
                        <!-- <form nz-form nzLayout="vertical " class="login-form ">
                            <div class="text-center mb-5">
                                <ng-otp-input (onInputChange)="onOtpChange($event)" *ngIf="showOtpComponent"
                                    [config]="config"></ng-otp-input>
                            </div> -->
                        <!-- <nz-form-item>
                                <nz-form-control nzErrorTip="Please input your username! ">
                                    <nz-input-group [nzPrefix]="prefixUser ">
                                        <input type="text" class="form-control" autofocus="">
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item> -->
                        <!-- <nz-form-item class="relative ">
                                <nz-form-label nzRequired nzFor="password ">Password</nz-form-label>
                                <a class="float-right font-size-13 text-muted " style="position:absolute; right: 0 " routerLink="/forgot-password ">Forget Password?</a>
                                <nz-form-control nzErrorTip="Please input your Password! ">
                                    <nz-input-group [nzPrefix]="prefixLock ">
                                        <input type="password " nz-input placeholder="Password ">
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item> -->
                        <!-- <nz-form-item>
                                <nz-form-control>
                                    <div class="mb-3 text-center">
                                        <span class="font-size-13 text-muted ">Don't receive the code?</span>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-center ">
                                        <button nz-button class="login-form-button " [nzType]=" 'primary' ">Resend
                                            OTP</button>
                                    </div>
                                </nz-form-control>
                            </nz-form-item>
                        </form> -->
                        <ng-template #prefixUser><i nz-icon nzType="user "></i></ng-template>
                        <ng-template #prefixLock><i nz-icon nzType="lock "></i></ng-template>
                    </nz-card>
                </div>
            </div>
        </div>
        <!-- <div class="d-none d-md-flex p-h-40 justify-content-between ">
            <span class=" ">© 2019 ThemeNate</span>
            <ul class="list-inline ">
                <li class="list-inline-item ">
                    <a class="text-dark text-link " href=" ">Legal</a>
                </li>
                <li class="list-inline-item ">
                    <a class="text-dark text-link " href=" ">Privacy</a>
                </li>
            </ul>
        </div> -->
    </div>
</div>

<!-- [formGroup]="loginForm "  goes to line 12 -->
<!-- (ngSubmit)="submitForm() "  goes to line 12-->
<!-- formControlName="password " formControlName="name " goes to line 26 and goes to line 16 -->