import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import axios, { Axios } from 'axios';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-build-pc',
  templateUrl: './build-pc.component.html',
  styleUrls: ['./build-pc.component.css'],
})
export class BuildPcComponent implements OnInit {
  orderColumn = [
    // {
    //   title: 'S.No',
    //   width: '50px',
    // },
    {
      title: 'Customer Name',
      width: '100px',
    },
    {
      title: 'Mobile Number',
      width: '100px',
    },
    {
      title: 'Email',
      width: '100px',
    },
    {
      title: 'Budget',
      width: '80px',
    },
    {
      title: 'System Requirements',
      width: '150px',
    },
  ];

  enquiryList: any = [];

  constructor(private spinner: NgxSpinnerService) {}

  ngOnInit(): void {
    this.spinner.show();
    this.fetchData();
  }

  fetchData() {
    axios
      .get(environment.url + 'buildYourPc/get', {
        headers: {
          Authorization:
            'Bearer ' + localStorage.getItem('admintokenoasisdata'),
        },
      })
      .then((response) => {
        this.enquiryList = response.data.data;
        console.log(this.enquiryList);
        this.spinner.hide();
      })
      .catch((error) => {
        console.log(error.response);
        this.spinner.hide();
      });
  }
}
