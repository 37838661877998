<div class="common-layout {{ 'is-' + selectedHeaderColor }}" [ngClass]="{
    'is-folded': isFolded,
    'is-side-nav-dark' : isSideNavDark,
    'is-expand' : isExpand
}">

    <app-header *ngIf="hideNavbarSidnav"></app-header>
    <app-sidenav *ngIf="hideNavbarSidnav"></app-sidenav>
    <div [ngClass]="{'page-container' : hideNavbarSidnav}">
        <div class="main-content" [ngClass]="{'heightProperty' : !hideNavbarSidnav}">
            <!-- <div class="main-content-header" *ngIf="contentHeaderDisplay !== 'none'">
                <h4 class="page-title" *ngIf="breadcrumbs$ | async; let breadcrumbs">{{(breadcrumbs[breadcrumbs.length - 1].label)}}</h4>
                <nz-breadcrumb nzSeparator=">">
                    <i class="m-r-5 text-gray" nz-icon nzType="home"></i>
                    <nz-breadcrumb-item *ngFor="let breadcrumb of breadcrumbs$ | async">
                        <a [routerLink]="breadcrumb.url">
                            {{ breadcrumb.label }}
                        </a>
                    </nz-breadcrumb-item>
                </nz-breadcrumb>
            </div> -->
            <router-outlet></router-outlet>

        </div>
        <!-- <app-footer *ngIf="hideNavbarSidnav"></app-footer> -->
    </div>
</div>

<app-color-switcher *ngIf="hideNavbarSidnav"></app-color-switcher>