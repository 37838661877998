<nz-card class="fixed_height_with_heading" *ngIf="showTable">
    <div class="row">
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
            <div class="d-md-flex">
                <div class="m-b-10 m-r-20">
                    <nz-input-group [nzPrefix]="prefixTemplate">
                        <input (keyup)="searchSpecGroup()" autocomplete="off" type="text" nz-input
                            placeholder="Enter Group Name" [(ngModel)]="searchInput">
                    </nz-input-group>
                    <ng-template #prefixTemplate>
                        <i nz-icon nzType="search" class="opacity-05"></i>
                    </ng-template>
                </div>
            </div>
        </div>
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 text-right ">
            <a (click)="showFormDetails()" nz-button class="save-button">
                <i nz-icon nzType="plus-circle" theme="outline"></i>
                <span>Add Spec Group</span>
            </a>
        </div>
    </div>
    <nz-table
        [nzFooter]="totalCount == 0 ? '' : 'Showing ' +  (((pageSize * pageIndex) - pageSize) + 1) + ' to ' +  (pageSize * pageIndex > totalCount ? totalCount : pageSize * pageIndex) +  ' of ' +  totalCount"
        #productsListTable [nzData]="productsList" [nzScroll]="{ y: '50vh' , x : '300px'}" [nzFrontPagination]="false"
        [nzTotal]="totalCount" [nzPageSize]="pageSize" [nzPageIndex]="pageIndex"
        (nzQueryParams)="onPageNumberChange('specGroupTable' , $event)">
        <thead>
            <tr>
                <th *ngFor="let column of orderColumn" [nzWidth]="column.width">{{ column.title }}</th>
            </tr>
        </thead>
        <tbody>
            <tr id="specGroupTable" *ngFor="let item of productsListTable.data ;  let i=index">
                <!-- <td>{{i+1}}</td> -->
                <td>
                    <div (click)="editSpecGroup(item)" class="d-flex align-items-center spec-name">
                        {{item.name}}
                    </div>
                </td>
                <td>
                    <nz-switch [nzLoading]="specLoading" [(ngModel)]="item.active"
                        (ngModelChange)="directUpdate(item , $event)"></nz-switch>
                </td>
            </tr>
        </tbody>

        <!-- LOADER -->
        <ngx-spinner *ngIf="showSpinner" bdColor="rgb(255 255 255 / 88%)" size="medium" color="#00f226"
            type="ball-spin-clockwise" [fullScreen]="false">
        </ngx-spinner>
    </nz-table>
</nz-card>

<!-- (ngModelChange)="search()" Goes to line 7 in top -->

<!-- ADD SPECIFICATION GROUP FORM DESIGN STARTS  -->

<nz-card class="fixed_height_with_heading" *ngIf="showForm">
    <div class="row">
        <div class="col-12 mb-3 title-text text-left">
            <div class="">
                <h4>
                    <span class="back_icon" (click)="showFormDetails()" nz-icon nzType="left" nzTheme="outline">
                    </span>
                    {{isEdit? 'Edit': 'Add New'}} Specification Group
                </h4>
            </div>
        </div>
        <!-- <div class="col-5 close">
            <div class="close-icon ">
                <i (click)="resetForm()" nz-icon nzType="close" nzTheme="outline" title="close"></i>
            </div>
        </div> -->

        <div class="col-lg-12">
            <form nz-form>
                <nz-form-item>
                    <nz-form-label class="large_label" [nzSm]="24" nzRequired nzFor="GroupName">Name</nz-form-label>
                    <nz-form-control nzErrorTip="Please input your username!">
                        <nz-input-group>
                            <input autocomplete="off" type="text" [(ngModel)]="specName"
                                [ngModelOptions]="{standalone: true}" nz-input id="GroupName"
                                placeholder="Enter Spec Group Name">
                        </nz-input-group>
                        <p class="error-message" *ngIf="requiredData == 'name'">*Please Enter Spec Group Name</p>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label nzRequired nzFor="GroupActive">Active</nz-form-label>
                    <nz-form-control>
                        <!-- <nz-input-group> -->
                        <nz-switch id="GroupActive" [(ngModel)]="isActive" [ngModelOptions]="{standalone: true}"
                            [nzCheckedChildren]="checkedTemplate" [nzUnCheckedChildren]="unCheckedTemplate">
                        </nz-switch>
                        <ng-template #checkedTemplate><i nz-icon nzType="check"></i></ng-template>
                        <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i></ng-template>
                        <!-- </nz-input-group> -->
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item class="text-center">
                    <nz-form-control>
                        <button (click)="resetForm()" type="button" class="mr-3 cancel-button" nz-button
                            nzType="default">Reset</button>
                        <a><button [nzLoading]="specLoading" class="save-button" type="button" (click)="doChanges()"
                                nz-button nzType="primary">{{isEdit? 'Update': 'Create'}}</button></a>
                    </nz-form-control>
                </nz-form-item>

            </form>

        </div>
    </div>
</nz-card>

<!-- ADD SPECIFICATION GROUP FORM DESIGN ENDS  -->