import { Injectable, EventEmitter } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import axios, { Axios } from 'axios';

@Injectable({
  providedIn: 'root',
})
export class NavService {
  navChange: EventEmitter<any> = new EventEmitter();
  public formRefreshAnnouncedSource: BehaviorSubject<any> =
    new BehaviorSubject<any>('');
  public formRefreshAnnouncedSource1: BehaviorSubject<any> =
    new BehaviorSubject<any>('');
  public brandList: BehaviorSubject<any> = new BehaviorSubject<any>('');

  public productStatus: BehaviorSubject<any> = new BehaviorSubject<any>('');

  public sharedUserData: BehaviorSubject<any> = new BehaviorSubject<any>('');

  temp: any = '';

  constructor() {}

  publishFormRefresh(value) {
    this.formRefreshAnnouncedSource.next(value);
  }
  parentToChild(value: any) {
    this.formRefreshAnnouncedSource1.next(value);
  }
  sendBrandList(values: any) {
    this.brandList.next(values);
  }

  checkProductStatus(value: any) {
    this.productStatus.next(value);
  }

  getUserDetails() {
    if (localStorage.getItem('admintokenoasisdata')) {
      axios
        .post(
          environment.url + 'auth/get-detail-fromToken',
          {},
          {
            headers: {
              Authorization:
                'Bearer ' + localStorage.getItem('admintokenoasisdata'),
            },
          }
        )
        .then((response) => {
          // console.log(response.data);
          this.sharedUserData.next(response.data);
          // localStorage.setItem(
          //   'kgahjegfygwfhjvbajsndfgjhjagjkhfteygfhygakjhfkghjfgasdkayetyfjhgd',
          //   response?.data?.user?.role
          // );
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  }
}
