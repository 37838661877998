import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";

import axios, { Axios } from "axios";
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { NzModalService } from "ng-zorro-antd/modal";

@Component({
  selector: "app-tags",
  templateUrl: "./tags.component.html",
  styleUrls: ["./tags.component.css"],
})
export class TagsComponent implements OnInit {
  showTable: boolean = true;
  showForm: boolean = false;

  addingTag: boolean = false;

  allTags: any = [];

  // pagination

  pageIndex = 1;
  totalCount: number = 0;
  pageSize: number = 0;

  searchInput: any = "";

  // form section

  tagsForm!: FormGroup;
  isEdit: boolean = false;

  selectedTag: any;

  orderColumn = [
    {
      title: "Tag Name",
      width: "100px",
    },
    {
      title: "Status",
      width: "60px",
    },
  ];

  directMode: boolean = false;

  constructor(
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private modalService: NzModalService
  ) {}

  ngOnInit(): void {
    if (window.innerWidth > 992) {
      this.showForm = true;
    }

    this.tagsForm = this.fb.group({
      tagName: [null, [Validators.required]],
      isActive: [true],
    });

    this.fetchData();
  }

  fetchData() {
    this.spinner.show();
    axios
      .get(
        environment.url +
          "tags?page=" +
          this.pageIndex +
          "&search=" +
          this.searchInput,
        {
          headers: {
            Authorization:
              "Bearer " + localStorage.getItem("admintokenoasisdata"),
          },
        }
      )
      .then((response) => {
        console.log(response.data.data);
        this.allTags = response.data.data;
        this.totalCount = response?.data?.pagination?.totalCount;
        this.pageSize = response?.data?.pagination?.limit;
        this.spinner.hide();
      })
      .catch((error) => {
        this.spinner.hide();
        console.log(error);
      });
  }

  onPageNumberChange(id: any, event: any) {
    if (event.pageIndex != this.pageIndex) {
      this.spinner.show();
      this.pageIndex = event.pageIndex;
      this.fetchData();
      document.getElementById(id).scrollIntoView();
      window.scroll(0, 0);
    }
  }

  searchTags() {
    this.pageIndex = 1;
    this.fetchData();
  }

  editTag(selected: any) {
    this.directMode = false;
    this.tagsForm.reset();
    this.isEdit = true;
    this.selectedTag = selected;
    if (selected?.isMandatory) {
      this.tagsForm.get("tagName").disable();
    } else {
      this.tagsForm.get("tagName").enable();
    }

    this.tagsForm.get("tagName").setValue(selected.name);
    this.tagsForm.get("isActive").setValue(selected.isActive);
  }

  addUpdateTag() {
    this.addingTag = true;
    var url: any = "";

    var reqObj: any = {
      name: this.tagsForm.value.tagName,
      isActive: this.tagsForm.value.isActive,
    };

    if (this.isEdit) {
      url = "tags/update";
      reqObj.id = this.selectedTag._id;
    } else {
      url = "tags/create";
    }

    axios
      .post(environment.url + url, reqObj, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("admintokenoasisdata"),
        },
      })
      .then((response) => {
        // console.log(response);
        if (response.data.status == "success") {
          this.addingTag = false;
          this.selectedTag = null;
          this.isEdit = false;
          this.tagsForm.reset();
          this.tagsForm.get("isActive").setValue(true);
          this.toastr.success(response.data.message, "", {
            timeOut: 2500,
          });
          if (!this.directMode) {
            this.fetchData();
          }
        }
      })
      .catch((error) => {
        this.fetchData();
        this.addingTag = false;
        this.toastr.error(error.response.data.message, "", {
          timeOut: 2500,
        });
        console.log(error?.response);
      });
  }

  resetForm(): void {
    // this.validateForm.reset();
    this.modalService.confirm({
      nzTitle: "Confirm",
      nzContent: "Sure You Want to Reset ?",
      nzOkText: "Yes",
      nzCancelText: "No",
      nzOnOk: () => {
        this.tagsForm.reset();
        this.tagsForm.get("isActive").setValue(true);
        this.isEdit = false;
        if (window.innerWidth > 992) {
          this.isEdit = false;
        } else {
          this.showFormDetails();
        }
      },
    });
  }

  showFormDetails() {
    this.directMode = false;
    this.tagsForm.reset();
    this.tagsForm.get("isActive").setValue(true);
    if (window.innerWidth < 992) {
      this.showTable = !this.showTable;
      this.showForm = !this.showForm;
    }
  }

  directUpdate(selected: any, event: any) {
    this.directMode = true;
    this.addingTag = true;
    this.selectedTag = selected;
    this.isEdit = true;
    this.tagsForm.get("tagName").setValue(selected.name);
    this.tagsForm.get("isActive").setValue(event);
    this.addUpdateTag();
  }
}
