<h3 class="category-heading-outside">Payments</h3>


<nz-card>
    <div class="row justify-content-between">
        <div class="col-5 col-lg-3">
            <div class="">
                <nz-form-item>
                    <nz-form-label class="large_label">Payment Status
                    </nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <nz-select nzShowSearch [(ngModel)]="selectedPaymentStatus" (ngModelChange)="applyFilter()"
                                class="w-100" nzPlaceHolder="select payment status">
                                <nz-option *ngFor="let status of paymentStatus" [nzValue]="status.value"
                                    [nzLabel]="status.label"></nz-option>
                            </nz-select>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>

        <div class="col-5 col-lg-4">
            <div class="">
                <nz-form-item>
                    <nz-form-label class="large_label payment_mode"> Payment Mode
                    </nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <nz-select nzShowSearch [(ngModel)]="selectedPaymentMode" (ngModelChange)="applyFilter()"
                                class="w-100" nzPlaceHolder="select order status">
                                <nz-option *ngFor="let mode of paymentMode" [nzValue]="mode.value"
                                    [nzLabel]="mode.label"></nz-option>
                            </nz-select>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>

        <div class="col-12 col-lg-5">
            <nz-input-group nzCompact class=" mb-3">
                <nz-input-group [nzSuffix]="suffixIconSearch">
                    <input [(ngModel)]="searchInput" (keyup)="searchPayments()" type="text" nz-input
                        placeholder="Enter order id or ref number.." />
                </nz-input-group>
                <ng-template #suffixIconSearch>
                    <span class="search_icon" (click)="searchPayments()" nz-icon nzType="search"></span>
                </ng-template>

            </nz-input-group>
        </div>
    </div>

    <nz-table
        [nzFooter]="totalCount == 0 ? '' : 'Showing ' +  (((pageSize * pageIndex) - pageSize) + 1) + ' to ' +  (pageSize * pageIndex > totalCount ? totalCount : pageSize * pageIndex) +  ' of ' +  totalCount"
        #paymentsListTable [nzData]="paymentsList" [nzScroll]="{ x: '1100px', y: '50vh'}" [nzFrontPagination]="false"
        [nzTotal]="totalCount" [nzPageSize]="pageSize" [nzPageIndex]="pageIndex"
        (nzQueryParams)="onPageNumberChange($event)">
        <thead>
            <tr>
                <ng-container *ngFor="let column of orderColumn">
                    <th [nzWidth]="column.width">{{ column.title }}
                    </th>
                </ng-container>
            </tr>
        </thead>
        <tbody>
            <tr id="paymentsTable" *ngFor="let item of paymentsList;  let i=index">

                <td> {{item?.orderId }} </td>
                <td>{{item?.userId?.name }}
                </td>
                <!-- <td> {{item.shipping.contact.firstName + ' '+ item.shipping.contact.lastName}} </td> -->
                <td> {{item?.shipping?.contact?.contactPhone }} </td>
                <td> {{'$ ' + item?.orderTotal }} </td>
                <td> {{item?.paymentType }} </td>
                <td>
                    <div [ngClass]="{'un-published' : item?.shipping?.tracking?.paymentStatus == 'unpaid'}"
                        class=" published ">
                        {{item?.shipping?.tracking?.paymentStatus}}
                    </div>
                </td>
                <td>{{item?.transactionId ? item?.transactionId : '-'}}</td>
                <!-- <td> {{item.gpayData ? item.gpayData : '-' }} </td> -->
            </tr>
        </tbody>
        <!-- LOADER -->

        <ngx-spinner bdColor="rgb(255 255 255 / 88%)" size="medium" color="#00f226" type="ball-spin-clockwise"
            [fullScreen]="false">
        </ngx-spinner>
    </nz-table>
</nz-card>