import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavService } from 'src/app/services/nav.service';
import { environment } from 'src/environments/environment';
import axios, { Axios } from 'axios';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-to-offer-page',
  templateUrl: './add-to-offer-page.component.html',
  styleUrls: ['./add-to-offer-page.component.css'],
})
export class AddToOfferPageComponent implements OnInit {
  moment: any = moment;
  selectedType = '';
  MediaVisible = false;

  dateFormat = 'dd-MM-yyyy';
  isOkLoading = false;

  gotoURL: any;
  addOfferForm: FormGroup;

  bannerImage: any;
  liveUrl = environment.url;

  @Input() offerDetails: any = {};
  @Output() offerUpdated = new EventEmitter<any>();

  constructor(
    private navService: NavService,
    private fb: FormBuilder,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    // this.subscription = this.route.params.subscribe((data) => {
    //   this.gotoURL = JSON.parse(data['state']);
    // });

    if (this.offerDetails) {
      console.log(this.offerDetails);
    }

    this.addOfferForm = this.fb.group({
      discountType: [null, [Validators.required]],
      percentage: [null],
      price: [null],
      fromDate: [null, [Validators.required]],
      toDate: [null, [Validators.required]],
    });
  }

  sendMedia() {
    this.navService.formRefreshAnnouncedSource.subscribe((item) => {
      this.bannerImage = item;
      console.log(this.bannerImage);
      this.handleCancel();
    });
  }

  showMedia() {
    this.MediaVisible = true;
  }
  handleCancel(): void {
    this.MediaVisible = false;
  }

  addOffer() {
    if (this.addOfferForm.valid) {
      console.log(this.addOfferForm.value);

      var offerObj: any = {
        startDate: moment(this.addOfferForm.value.fromDate).format(),
        endDate: moment(this.addOfferForm.value.toDate).format(),
        discountType: this.addOfferForm.value.discountType,
        offerType: this.offerDetails.offerType,
        action: this.offerDetails.action,
        bannerImageId: this.bannerImage[0]?._id,
      };

      if (this.addOfferForm.value.discountType == 'price-discount') {
        offerObj.priceDiscount = this.addOfferForm.value.price;
      } else {
        offerObj.percentageDiscount = this.addOfferForm.value.percentage;
      }

      if (this.offerDetails.offerType == 'single-product') {
        offerObj.productId = this.offerDetails.id;
      } else if (this.offerDetails.offerType == 'category') {
        offerObj.categoryIds = [this.offerDetails.id];
      }

      console.log(offerObj);
      axios
        .post(environment.url + 'offers/create-offer', offerObj, {
          headers: {
            Authorization:
              'Bearer ' + localStorage.getItem('admintokenoasisdata'),
          },
        })
        .then((response: any) => {
          console.log(response);
          this.toastr.success(response.data.message, '', {
            timeOut: 2500,
          });
          this.offerUpdated.emit();
          this.bannerImage = [];
        })
        .catch((error: any) => {
          this.toastr.error(error.response.message, '', {
            timeOut: 2500,
          });
          console.log(error);
        });
    } else {
      Object.values(this.addOfferForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }
}
