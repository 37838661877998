import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { scrollToTop } from '../../shared/dummy';
import axios, { Axios } from 'axios';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';

interface DataItem {
  id: string;
  name: string;
  brand: any;
  address1: any;
  address2: any;
  state: any;
  city: any;
  pincode: number;
  map: any;
  phone: any;
  landline: any;
  description: any;
  website: any;
  active: any;
}

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.css'],
})
export class SettingComponent implements OnInit {
  selectedCategory: string;
  selectedStatus: string;
  searchInput: any;
  displayData = [];
  brands = [];

  centerName = '';
  brandList = [];
  address1 = '';
  address2 = '';
  city = '';
  state = '';
  pincode: any = '';
  mapUrl = '';
  mobNo = '';
  landlineNo = '';
  description = '';
  website = '';
  isActive = false;
  storeId = '';
  defaultMode = 'Add';
  requiredData = '';

  showTable: boolean = true;
  showForm: boolean = false;

  validateForm: FormGroup;

  okLoading: boolean;
  directMode: boolean;

  fetchBrand() {
    axios
      .get(environment.url + 'brands?isForProductPage=true', {
        headers: {
          Authorization:
            'Bearer ' + localStorage.getItem('admintokenoasisdata'),
        },
      })
      .then((response) => {
        console.log(response.data.data);
        this.brands = response.data.data;
        this.spinner.hide();
        //console.log(response.data.status);
      })
      .catch((error) => {
        console.log(error);
        //alert(error.response.data.message);
      });
  }

  fetchData() {
    this.spinner.show();
    axios
      .post(environment.url + 'service-center/getAll-for-admin', {
        headers: {
          Authorization:
            'Bearer ' + localStorage.getItem('admintokenoasisdata'),
        },
      })
      .then((response) => {
        console.log(response.data.data);
        this.productsList = response.data.data.serviceCenters.map(
          (data: any, index: number) => {
            return {
              id: data._id,
              name: data.name,
              brand: data.brands,
              address1: data.serviceLocation.addressLine1,
              address2: data.serviceLocation.addressLine12,
              state: data.serviceLocation.state,
              city: data.serviceLocation.city,
              pincode: data.serviceLocation.pincode,
              map: data.map,
              phone: data.phone,
              landline: data.landline,
              description: data.description,
              website: data.website,
              active: data.isActive,
            };
          }
        );

        this.fetchBrand();
        //console.log(response.data.status);
      })
      .catch((error) => {
        console.log(error);
        this.spinner.hide();
        //alert(error.response.data.message);
      });
  }

  orderColumn = [
    // {
    //   title: 'S.NO',
    //   width: '50px',
    // },
    {
      title: 'Service Center Name',
      width: '150px',
      compare: (a: DataItem, b: DataItem) => a.name.localeCompare(b.name),
    },
    {
      title: 'Brand',
      width: '80px',
      compare: (a: DataItem, b: DataItem) => a.brand - b.brand,
    },
    {
      title: 'Address 1',
      width: '100px',
      compare: (a: DataItem, b: DataItem) => a.address1 - b.address1,
    },

    {
      title: 'Address 2',
      width: '80px',
      compare: (a: DataItem, b: DataItem) => a.address2 - b.address2,
    },
    {
      title: 'State',
      width: '100px',
      compare: (a: DataItem, b: DataItem) => a.state - b.state,
    },
    {
      title: 'City',
      width: '100px',
      compare: (a: DataItem, b: DataItem) => a.city - b.city,
    },
    {
      title: 'Pincode',
      width: '60px',
      compare: (a: DataItem, b: DataItem) => a.pincode - b.pincode,
    },
    {
      title: 'Map',
      width: '150px',
      compare: (a: DataItem, b: DataItem) => a.map - b.map,
    },
    {
      title: 'Phone',
      width: '100px',
      compare: (a: DataItem, b: DataItem) => a.phone - b.phone,
    },
    {
      title: 'Landline',
      width: '100px',
      compare: (a: DataItem, b: DataItem) => a.landline - b.landline,
    },
    {
      title: 'Description',
      width: '150px',
      compare: (a: DataItem, b: DataItem) => a.description - b.description,
    },
    {
      title: 'Website',
      width: '150px',
      compare: (a: DataItem, b: DataItem) => a.website - b.website,
    },
    {
      title: 'Status',
      width: '80px',
      compare: (a: DataItem, b: DataItem) => a.active - b.active,
    },
    // {
    //   title: 'Action',
    // },
  ];
  productsList: DataItem[] = [];
  // productsList = [
  //   {
  //       name: 'SKY PRO',
  //       brand: 'HP',
  //       address1: 'NO 11 , west street',
  //       address2: 'no12 , main road',
  //       state: 'tamil nadu',
  //       city: 'nagapattinam',
  //       pincode: '611105',
  //       map: 'sdfghksdifdgbdg',
  //       phone: '1213123121',
  //       landline: '14366859878',
  //       description: 'helo',
  //       website: 'www.skypro.com',
  //       active: 'active',
  //     },

  // ];

  // constructor(private tableSvc : TableService) {
  //     this.displayData = this.productsList
  // }

  // search(): void {
  //     const data = this.productsList
  //     this.displayData = this.tableSvc.search(this.searchInput, data )
  // }
  constructor(
    private fb: FormBuilder,
    private modalService: NzModalService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {}

  categoryChange(value: string): void {
    const data = this.productsList;
    // value !== 'All'
    //   ? (this.displayData = data.filter((elm) => elm.name === value))
    this.displayData = data;
  }

  statusChange(value: string): void {
    const data = this.productsList;
    // value !== 'All'
    //   ? (this.displayData = data.filter((elm) => elm.feature === value))
    //   : (this.displayData = data);
    this.displayData = data;
  }

  ngOnInit(): void {
    scrollToTop();
    this.fetchData();
    this.validateForm = this.fb.group({});
  }

  showFormDetails() {
    this.clearFields();
    this.directMode = false;
    this.defaultMode = 'Add';
    this.showTable = !this.showTable;
    this.showForm = !this.showForm;
  }

  // FORM RESET SECTION

  resetForm(): void {
    // this.validateForm.reset();
    this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Sure You Want to Reset ?',
      nzOkText: 'Yes',
      nzCancelText: 'No',
      nzOnOk: () => this.clearFields(),
    });
  }
  doChanges() {
    console.log(this.brandList);
    var self = this;
    if (self.centerName == '') {
      self.requiredData = 'centerName';
    } else if (self.brandList.length == 0) {
      self.requiredData = 'brandList';
    } else if (self.address1 == '') {
      self.requiredData = 'address1';
    } else if (self.city == '') {
      self.requiredData = 'city';
    } else if (self.state == '') {
      self.requiredData = 'state';
    } else if (self.pincode == '' || self.pincode.toString().includes('-')) {
      self.requiredData = 'pincode';
    } else {
      self.requiredData = '';

      if (self.defaultMode == 'Add') {
        var serviceCenterDetails: any = {
          name: self.centerName,
          brands: self.brandList,
          serviceLocation: {
            addressLine1: self.address1,
            addressLine2: self.address2,
            city: self.city,
            state: self.state,
            pincode: self.pincode,
          },
          phone: self.mobNo,
          description: self.description,
          website: self.website,
          isActive: self.isActive,
          landline: self?.landlineNo?.toString(),
        };
        self.okLoading = true;
        if (self.landlineNo == '' || self.landlineNo == null) {
          delete serviceCenterDetails['landline'];
        }
        console.log(serviceCenterDetails);
        axios
          .post(
            environment.url + 'service-center/create',
            serviceCenterDetails,
            {
              headers: {
                Authorization:
                  'Bearer ' + localStorage.getItem('admintokenoasisdata'),
              },
            }
          )
          .then((response) => {
            if (response.data.status == 'success') {
              self.toastr.success('', response.data.message, {
                timeOut: 2500,
              });
              console.log(response.data);
              if (!self.directMode) {
                self.fetchData();
                self.clearFields();
                self.showTable = !self.showTable;
                self.showForm = !self.showForm;
              }
              self.directMode = false;
              self.okLoading = false;
              //  localStorage.setItem('admintokenoasisdata',response.data.data);
              //  self.router.navigate(['dashboard'])
            }
          })
          .catch((error) => {
            self.okLoading = false;
            if (self.directMode) {
              self.fetchData();
            }
            console.log(error.response);
            if (error.response.data.data == undefined) {
              self.toastr.error('', error.response.data.message, {
                timeOut: 2500,
              });
            } else {
              self.toastr.error('', error.response.data.data[0].msg, {
                timeOut: 2500,
              });
            }
          });
      } else if (self.defaultMode == 'Edit') {
        var reqObj = {
          id: self.storeId,
          name: self.centerName,
          brands: self.brandList,
          serviceLocation: {
            addressLine1: self.address1,
            addressLine2: self.address2,
            city: self.city,
            state: self.state,
            pincode: self.pincode,
          },
          phone: self.mobNo,
          description: self.description,
          website: self.website,
          isActive: self.isActive,
          landline: self.landlineNo.toString(),
        };
        if (self.landlineNo == '' || self.landlineNo == null) {
          delete reqObj['landline'];
        }
        self.okLoading = true;
        console.log(reqObj);
        axios
          .post(environment.url + 'service-center/update', reqObj, {
            headers: {
              Authorization:
                'Bearer ' + localStorage.getItem('admintokenoasisdata'),
            },
          })
          .then((response) => {
            if (response.data.status == 'success') {
              console.log(response.data);
              self.toastr.success('', response.data.message, {
                timeOut: 2500,
              });
              if (!self.directMode) {
                self.fetchData();
                self.clearFields();
                self.showTable = !self.showTable;
                self.showForm = !self.showForm;
              }
              self.okLoading = false;

              //  localStorage.setItem('admintokenoasisdata',response.data.data);
              //  self.router.navigate(['dashboard'])
            }
          })
          .catch((error) => {
            self.okLoading = false;
            if (self.directMode) {
              self.fetchData();
            }
            console.log(error.response);
            if (error.response.data.data == undefined) {
              self.toastr.error('', error.response.data.message, {
                timeOut: 2500,
              });
            } else {
              self.toastr.error('', error.response.data.data[0].msg, {
                timeOut: 2500,
              });
            }
          });
      }
    }
  }
  editChanges(value) {
    this.clearFields();
    this.directMode = false;
    this.storeId = value.id;
    this.centerName = value.name;
    this.brandList = value.brand.map((k: any) => k._id);
    this.address1 = value.address1;
    this.address2 = value.address2;
    this.city = value.city;
    this.state = value.state;
    this.pincode = value.pincode;
    this.mapUrl = value.map;
    this.mobNo = value.phone;
    this.landlineNo = value.landline;
    this.description = value.description;
    this.website = value.website;
    this.isActive = value.active;
    this.defaultMode = 'Edit';
    this.showTable = !this.showTable;
    this.showForm = !this.showForm;
    //console.log(value);
  }
  clearFields() {
    this.displayData = [];
    // this.brands = [];

    this.centerName = '';
    this.brandList = [];
    this.address1 = '';
    this.address2 = '';
    this.city = '';
    this.state = '';
    this.pincode = '';
    this.mapUrl = '';
    this.mobNo = '';
    this.landlineNo = '';
    this.description = '';
    this.website = '';
    this.isActive = false;
    this.storeId = '';
  }

  directUpdate(selected: any, event: any) {
    this.clearFields();
    this.storeId = selected.id;
    this.centerName = selected.name;
    this.brandList = selected.brand.map((k: any) => k._id);
    this.address1 = selected.address1;
    this.address2 = selected.address2;
    this.city = selected.city;
    this.state = selected.state;
    this.pincode = selected.pincode;
    this.mapUrl = selected.map;
    this.mobNo = selected.phone;
    this.landlineNo = selected.landline;
    this.description = selected.description;
    this.website = selected.website;
    this.isActive = selected.active;
    this.defaultMode = 'Edit';

    this.directMode = true;
    this.doChanges();
  }

  onPageNumberChange(event: any) {
    document.getElementById(event).scrollIntoView();
    window.scroll(0, 0);
  }
}
