<h3 *ngIf="showTable" class="category-heading-outside">Users</h3>

<nz-card *ngIf="showTable">
    <div class="row">
        <div class="col-lg-8">
            <div class="d-md-flex">
                <div class="m-b-10 m-r-20">
                    <nz-input-group [nzPrefix]="prefixTemplate">
                        <input autocomplete="off" type="text" nz-input placeholder="Enter Name" [(ngModel)]="searchInput" />
                    </nz-input-group>
                    <ng-template #prefixTemplate>
                        <i nz-icon nzType="search" class="opacity-05"></i>
                    </ng-template>
                </div>
            </div>
        </div>
        <div class="col-lg-4 text-right add-button-section">
            <a (click)="showForm()" nz-button class="save-button">
                <i nz-icon nzType="plus-circle" theme="outline"></i>
                <span>Add User</span>
            </a>
        </div>
    </div>
    <nz-table #userListTable [nzData]="userList" [nzScroll]="{ y: '50vh' , x : '600px' }" (nzQueryParams)="onPageNumberChange('usersTable')">
        <thead>
            <tr>
                <th *ngFor="let column of orderColumn" [nzWidth]="column.width">
                    {{ column.title }}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr id="usersTable" *ngFor="let item of userListTable.data | filter: searchInput;">
                <td>
                    <div (click)="editUser(item)" class="product-name">
                        {{item?.name}}
                    </div>
                </td>
                <td>{{item.phoneNo}}</td>
                <td>{{item.email}}</td>
                <td>{{item.role}}</td>
                <td>
                    <nz-switch [nzLoading]="specLoading" [(ngModel)]="item.isActive" (ngModelChange)="directUpdate(item , $event)"></nz-switch>
                </td>
            </tr>

        </tbody>
        <ngx-spinner bdColor="rgb(255 255 255 / 88%)" size="medium" color="#00f226" type="ball-spin-clockwise" [fullScreen]="false"></ngx-spinner>
    </nz-table>
</nz-card>

<nz-card *ngIf="!showTable" class="container">
    <div class="row justify-content-center">
        <div class="col-12 mb-3 title-text">
            <div class="">
                <h4>
                    <span class="back_icon" (click)="showTableData()" nz-icon nzType="left" nzTheme="outline">
                    </span> {{editOption ? 'Update User' : 'Add New User'}}
                </h4>
            </div>
        </div>

        <form [formGroup]="addUserForm" (ngSubmit)="addUser()" nz-form>
            <div class="row justify-content-center">
                <div class="col-12 left_column">
                    <div nz-row [nzGutter]="24">
                        <div nz-col [nzSm]="13" [nzXs]="24" [nzSpan]="12">
                            <nz-form-item>
                                <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired nzFor="coupon-code">Name
                                </nz-form-label>
                                <nz-form-control nzErrorTip="Please Enter name!">
                                    <nz-input-group>
                                        <input autocomplete="off" formControlName="name" type="text" nz-input id="coupon-code" placeholder="Enter name" />
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                        <div nz-col [nzSm]="13" [nzXs]="24" [nzSpan]="12">
                            <nz-form-item>
                                <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired nzFor="coupon-code">Email
                                </nz-form-label>
                                <nz-form-control nzErrorTip="Please Enter email!">
                                    <nz-input-group>
                                        <input autocomplete="off" formControlName="email" type="email" nz-input id="coupon-code" placeholder="Enter email" />
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                        <div *ngIf="!editOption" nz-col [nzSm]="13" [nzXs]="24" [nzSpan]="12">
                            <nz-form-item>
                                <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired nzFor="coupon-code">Password
                                </nz-form-label>
                                <nz-form-control nzErrorTip="Please Enter password!">
                                    <nz-input-group>
                                        <input autocomplete="off" formControlName="password" type="text" nz-input id="coupon-code" placeholder="Enter password" />
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                        <div nz-col [nzSm]="13" [nzXs]="24" [nzSpan]="12">
                            <nz-form-item>
                                <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired nzFor="coupon-code">Phone Number
                                </nz-form-label>
                                <nz-form-control nzErrorTip="Please Enter Phone Number!">
                                    <nz-input-group>
                                        <input autocomplete="off" formControlName="phoneNumber" type="number" nz-input id="coupon-code" placeholder="Enter Phone Number" maxlength="10" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                        />
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                        <div nz-col [nzSm]="13" [nzXs]="24" [nzSpan]="12">
                            <nz-form-item>
                                <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired nzFor="coupen-type">Select Role
                                </nz-form-label>
                                <nz-form-control nzErrorTip="Please Select Role!">
                                    <nz-input-group>
                                        <nz-select formControlName="role" id="coupen-type" nzShowSearch class="w-100" nzPlaceHolder="Enter Coupen Type">
                                            <nz-option nzValue="Admin" nzLabel="Admin">
                                            </nz-option>
                                            <nz-option nzValue="deliveryBoy" nzLabel="Delivery Boy">
                                            </nz-option>
                                        </nz-select>
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                        <div nz-col [nzSm]="13" [nzXs]="24" [nzSpan]="12">
                            <nz-form-item>
                                <nz-form-label [nzLg]="4" [nzSm]="6" nzRequired nzFor="attributeActive">
                                    Active
                                </nz-form-label>
                                <nz-form-control>
                                    <nz-switch formControlName="isActive" id="attributeActive" [nzCheckedChildren]="checkedTemplate" [nzUnCheckedChildren]="unCheckedTemplate">
                                    </nz-switch>
                                    <ng-template #checkedTemplate><i nz-icon nzType="check"></i>
                                    </ng-template>
                                    <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i>
                                    </ng-template>
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                        <div nz-col [nzSm]="13" [nzXs]="24" [nzSpan]="12">
                            <nz-form-item class="text-center">
                                <nz-form-control>
                                    <button (click)="showTableData()" type="button" class="mr-5" nz-button class="mr-3 cancel-button">
                                        Back
                                    </button>
                                    <a><button type="Submit" [nzLoading]="isOkLoading" nz-button class="save-button">
                                            {{editOption ? 'Update' : 'Create'}}
                                        </button></a>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</nz-card>