import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import axios, { Axios } from 'axios';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css'],
})
export class UserManagementComponent implements OnInit {
  showTable: boolean = true;
  searchInput = '';
  addUserForm: FormGroup;

  editOption: boolean;

  orderColumn: any = [
    {
      title: 'Name',
      width: '100px',
    },
    {
      title: 'Phone Number',
      width: '100px',
    },
    {
      title: 'Email',
      width: '100px',
    },
    {
      title: 'Role',
      width: '50px',
    },
    {
      title: 'Status',
      width: '50px',
    },
  ];

  userList: any = [];
  directMode = false;

  constructor(
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.addUserForm = this.fb.group({
      name: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required]],
      role: [null, [Validators.required]],
      phoneNumber: [null, [Validators.required]],
      isActive: [true, [Validators.required]],
    });
    this.fetchData();
  }

  fetchData() {
    this.spinner.show();
    axios
      .post(
        environment.url + 'auth/get-all-users',
        {},
        {
          headers: {
            Authorization:
              'Bearer ' + localStorage.getItem('admintokenoasisdata'),
          },
        }
      )
      .then((response) => {
        this.userList = response?.data?.data?.allUsers;
        this.spinner.hide();
        console.log(response.data);
      })
      .catch((err) => {
        console.log(err.response);
        this.spinner.hide();
      });
  }

  onPageNumberChange(event) {
    document.getElementById(event).scrollIntoView();
    window.scroll(0, 0);
  }

  showForm() {
    this.showTable = false;
    this.directMode = false;
    this.addUserForm.reset();
    this.addUserForm.get('isActive').setValue(true);
  }

  showTableData() {
    this.editOption = false;
    this.showTable = true;
  }

  addUser() {
    if (this.addUserForm.valid) {
      console.log(this.addUserForm.value);
      var userObj: any = {};
      var url = '';
      if (this.editOption) {
        url = 'auth/update-admin-users';
        userObj = {
          email: this.addUserForm.value.email,
          name: this.addUserForm.value.name,
          phoneNo: this.addUserForm.value.phoneNumber,
          isActive: this.addUserForm.value.isActive,
          role: this.addUserForm.value.role,
          adminId: this.adminId,
        };
      } else {
        url = 'auth/create-admin-users';
        userObj = {
          email: this.addUserForm.value.email,
          password: this.addUserForm.value.password,
          name: this.addUserForm.value.name,
          phoneNo: this.addUserForm.value.phoneNumber,
          isActive: this.addUserForm.value.isActive,
          role: this.addUserForm.value.role,
        };
      }

      axios
        .post(environment.url + url, userObj, {
          headers: {
            Authorization:
              'Bearer ' + localStorage.getItem('admintokenoasisdata'),
          },
        })
        .then((response) => {
          this.spinner.hide();
          this.toastr.success(response.data.message, '', {
            timeOut: 2500,
          });

          if (!this.directMode) {
            this.fetchData();
            this.showTable = true;
          }
          this.directMode = false;

          console.log(response.data);
        })
        .catch((err) => {
          if (this.directMode) {
            this.fetchData();
          }
          console.log(err.response);
          this.spinner.hide();
          this.toastr.error(err.response.data.message, '', {
            timeOut: 2500,
          });
        });
    } else {
      Object.values(this.addUserForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  adminId: any = '';

  editUser(selected: any) {
    this.showTable = false;
    this.editOption = true;
    this.directMode = false;
    this.addUserForm.get('name').setValue(selected.name);
    this.addUserForm.get('email').setValue(selected.email);
    this.addUserForm.get('role').setValue(selected.role);
    this.addUserForm.get('isActive').setValue(selected.isActive);
    this.addUserForm.get('phoneNumber').setValue(selected.phoneNo);
    this.addUserForm.get('password').setValue('123');

    this.adminId = selected._id;
    console.log(selected);
  }

  directUpdate(selected: any, event: any) {
    this.editOption = true;
    this.directMode = true;
    this.addUserForm.get('name').setValue(selected.name);
    this.addUserForm.get('email').setValue(selected.email);
    this.addUserForm.get('role').setValue(selected.role);
    this.addUserForm.get('isActive').setValue(selected.isActive);
    this.addUserForm.get('phoneNumber').setValue(selected.phoneNo);
    this.addUserForm.get('password').setValue('123');

    this.adminId = selected._id;
    console.log(this.addUserForm.value);
    this.addUser();
  }
}
