import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { scrollToTop } from "../../shared/dummy";
import axios, { Axios } from "axios";
import { NavService } from "../../services/nav.service";
import { ActivatedRoute, Router } from "@angular/router";
import { NzModalService } from "ng-zorro-antd/modal";
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from "src/environments/environment";
import { ToastrService } from "ngx-toastr";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { ExportExcelService } from "src/app/services/export-excel.service";
import * as moment from "moment";

import { HttpClient } from "@angular/common/http";

import * as fileSaver from "file-saver";

interface DataItem {
  id: any;
  Skuld: number;
  name: string;
  type: any;
  image: any;
  bestseller: any;
  featured: any;
  active: any;
  brand: number;
  modelNumber: any;
  regularPrice: any;
  salePrice: any;
  tags: any;
  category: any;
  specification: any;
  imageData: any;
  shortDescription: string;
  longDescription: string;
  isSelected: any;
  outOfStock: any;
  isInOffer: any;
  quantity: any;
  minimumQuantityAlert: any;
  slug: any;
}

@Component({
  selector: "app-products",
  templateUrl: "./products.component.html",
  styleUrls: ["./products.component.css"],
})
export class ProductsComponent implements OnInit {
  moment: any = moment;
  // text editor config
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "15rem",
    minHeight: "5rem",
    placeholder: "Enter description here",
    translate: "no",
    defaultParagraphSeparator: "p",
    defaultFontName: "",
    toolbarHiddenButtons: [["list", ""]],
    sanitize: false,
    fonts: [
      { class: "Inter-Bold", name: "Inter Bold" },
      { class: "times-new-roman", name: "Times New Roman" },
      { class: "calibri", name: "Calibri" },
      { class: "comic-sans-ms", name: "Comic Sans MS" },
    ],
  };

  tags: any = [];

  productAdd: boolean;

  selectedCategory: string;
  selectedStatus: string;
  searchInput: any = "";
  searchInputKey: any = "";
  displayData = [];

  showTable: boolean = true;
  showForm: boolean = false;

  MediaVisible = false;
  isVisible = false;
  isOkLoading = false;

  currentUrl = "";

  validateForm: FormGroup;

  productType = "simple";
  productName: any = "";
  slugName = "";
  productSkuID = "";
  productImages = [];
  productTags = [];
  categoryList = [];
  selectedBrand = "";
  modelNo = "";
  regularPrice: any = "";
  sellPrice: any = "";
  isBestSeller = false;
  isFeatured = false;
  isActive = true;
  manageStock = false;
  minimumQuantityAlert: any;
  quantity: any;
  productShortDescription: any = "";
  productLongDescription: any = "";
  allowedAttributes = [];
  variations = [];
  brandList = [];
  listOfCategory = [];
  listOfBrands = [];
  selectedAttribute = [];
  reqSpecArray = [];
  reqSpecValue = [];
  specResult: any = [];
  specForcreateApi: any = [];
  defaultMode = "Add";
  reqImageArray = [];
  productId = "";
  liveUrl = environment.url;
  pageSize = 10;
  pageIndex = 1;
  totalCount: number = 0;

  publishing = false;

  directMode: boolean;
  productsLoading: boolean;

  selectedData: any = {};
  productSpinner = false;

  // stock status update

  Checked = false;
  checkboxActive = false;
  selectedOption = "Out of Stock";
  checkedList: any = [];
  okLoading: boolean;

  offerLoading: boolean;

  isDraft: boolean;

  // table filter

  tableFilterArr: any = [];
  applyFilterForm: FormGroup;
  productsForFilter: any = [];

  orderColumn = [
    // {
    //   title: 'S.NO',
    //   width: '50px',
    // },
    {
      title: "Image",
      width: "60px",
      compare: (a: DataItem, b: DataItem) => a.image - b.image,
    },
    {
      title: "Name",
      width: "100px",
      compare: (a: DataItem, b: DataItem) => a.name.localeCompare(b.name),
    },
    // {
    //   title: "SKU ID",
    //   width: "60px",
    //   compare: (a: DataItem, b: DataItem) => a.Skuld - b.Skuld,
    // },
    // {
    //   title: "SKU ID",
    //   width: "60px",
    //   compare: (a: DataItem, b: DataItem) => a.Skuld - b.Skuld,
    // },
    {
      title: "Stock",
      width: "90px",
    },
    // {
    //   title: "Brand",
    //   width: "80px",
    //   compare: (a: DataItem, b: DataItem) => a.brand - b.brand,
    // },
    // {
    //   title: "Brand",
    //   width: "80px",
    //   compare: (a: DataItem, b: DataItem) => a.brand - b.brand,
    // },
    {
      title: "Category",
      width: "100px",
      compare: (a: DataItem, b: DataItem) => a.category - b.category,
    },
    {
      title: "Regular Price",
      width: "100px",
      compare: (a: DataItem, b: DataItem) => a.regularPrice - b.regularPrice,
    },
    {
      title: "Sale Price",
      width: "80px",
      compare: (a: DataItem, b: DataItem) => a.salePrice - b.salePrice,
    },

    // {
    //   title: "Best Seller",
    //   width: "80px",
    //   compare: (a: DataItem, b: DataItem) => a.bestseller - b.bestseller,
    // },
    // {
    //   title: "Featured",
    //   width: "80px",
    //   compare: (a: DataItem, b: DataItem) => a.featured - b.featured,
    // },
    {
      title: "Status",
      width: "60px",
      compare: (a: DataItem, b: DataItem) => a.active - b.active,
    },
    // {
    //   title: 'Tags',
    //   width: '100px',
    //   compare: (a: DataItem, b: DataItem) => a.tags - b.tags,
    // },
    // {
    //   title: 'Type',
    //   width: '80px',
    //   compare: (a: DataItem, b: DataItem) => a.type - b.type,
    // },

    // {
    //   title: 'Model Number',
    //   width: '100px',
    //   compare: (a: DataItem, b: DataItem) =>
    //     a.modelNumber.localeCompare(b.modelNumber),
    // },
    // {
    //   title: 'Action',
    //   width: '120px',
    // },
  ];

  productsList: DataItem[] = [];
  attributeList = [];
  categoriesLists = [];
  brandsList = [];

  requiredData = "";
  filterApplied: boolean;

  // FORM DETAILS STARTS

  VariationHeading = [
    { heading: "Variation" },
    { heading: "Regular Price" },
    { heading: "SKU" },
    { heading: "Sale Price" },
    { heading: "Photo" },
  ];

  VariationData = [
    {
      Variation: "AMD",
      RegularPrice: "41500",
      SKU: "54795",
      SalePrice: "35200",
      Photo: "",
    },
    {
      Variation: "AMD",
      RegularPrice: "41500",
      SKU: "54795",
      SalePrice: "35200",
      Photo: "",
    },
  ];

  specificationGroup = [
    {
      specGroupName: "Processor Specification",
    },
    {
      specGroupName: "Keyboard Specification",
    },
    {
      specGroupName: "Monitor Specification",
    },
    {
      specGroupName: "Board Specification",
    },
  ];

  endUrl: any = "products";

  priceAlert = false;
  notSellPrice = false;
  notRegularPrice = false;

  constructor(
    private fb: FormBuilder,
    public navService: NavService,
    private router: Router,
    private modalService: NzModalService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private modal: NzModalService,
    private route: ActivatedRoute,
    public ete: ExportExcelService,
    public http: HttpClient
  ) {
    this.navService.productStatus.subscribe((status) => {
      console.log(status);
      if (status) {
        this.endUrl = status;
      }
    });
  }

  fetchAttributes() {
    //axios.get(environment.url+'attributes', {
    axios
      .get(environment.url + "attributes", {})
      .then((response) => {
        // console.log(response.data.data);
        this.attributeList = response.data.data;
        this.spinner.hide();
        this.productSpinner = false;
      })
      .catch((error) => {
        console.log(error.response);
        this.spinner.hide();
        this.productSpinner = false;
        //alert(error.response.data.message);
      });
  }

  fetchBrands(key: any) {
    //axios.get(environment.url+'brands', {
    axios
      .get(environment.url + "brands?isForProductPage=true", {})
      .then((response) => {
        console.log(response.data.data);
        this.brandsList = response.data.data;
        if (key == "new") {
          this.selectedBrand = this.brandsList[0]._id;
        }
      })
      .catch((error) => {
        console.log(error.response);
        this.spinner.hide();
        //alert(error.response.data.message);
      });
  }

  fetchCategories(key) {
    // axios.get(environment.url+'categories', {
    axios
      .get(environment.url + "categories?isForProductPage=true", {})
      .then((response) => {
        this.categoriesLists = response.data.data;
        console.log(this.categoriesLists);
        if (key == "new") {
          this.categoryList.push(
            this.categoriesLists[this.categoriesLists.length - 1]._id
          );
        }
      })
      .catch((error) => {
        console.log(error);
        //alert(error.response.data.message);
      });
  }

  fetchData(pageNo: any) {
    this.productSpinner = true;
    this.filterApplied = false;
    this.filterObj = {};
    this.spinner.show();
    axios
      .get(
        environment.url +
          "products?page=" +
          pageNo +
          "&search=" +
          this.searchInputKey,
        {}
      )
      .then((response) => {
        console.log(response.data.data);
        var initialResult = response?.data?.data;
        this.listOfCategory = initialResult?.categoriesForDropdown;
        this.listOfBrands = initialResult?.brandsForFilter;
        this.totalCount = response.data?.data?.pagination?.totalCount;
        this.pageSize = response?.data?.data?.pagination?.limit;
        this.productsList = initialResult?.products.map(
          (data: any, index: number) => {
            return {
              id: data?._id,
              Skuld: data?.skuId,
              name: data?.name,
              type: data?.type,
              image: data?.images[0]?.image?.url,
              bestseller: data?.isBestSeller,
              featured: data?.isFeaturedProduct,
              active: data?.isActive,
              brand: data?.brand,
              modelNumber: data?.modelNo,
              regularPrice: data?.regularPrice,
              salePrice: data?.salePrice,
              tags: data?.tags,
              category: data?.category,
              specification: data?.specifications, //data?.specifications[0]?.specGroup?.name
              imageData: data?.images,
              shortDescription: data?.shortDescription,
              longDescription: data?.longDescription
                ? data?.longDescription
                : "",
              isSelected: false,
              outOfStock: data?.outOfStock,
              isInOffer: data?.isInOffer,
              isDraft: data.isDraft,
              isManageStock: data.isManageStock,
              quantity: data.quantity,
              minimumQuantityAlert: data.minimumQuantityAlert,
              slug: data.slug,
            };
          }
        );
        // console.log(response.data.data);

        this.fetchAttributes();
        document.getElementById("productTable").scrollIntoView();
        window.scroll(0, 0);

        //console.log(response.data.status);
      })
      .catch((error) => {
        console.log(error.response);
        this.spinner.hide();
        this.productSpinner = false;
      });
  }

  searchProducts() {
    this.pageIndex = 1;
    this.fetchData(this.pageIndex);
  }

  categoryChange(value: string): void {
    const data = this.productsList;
    // value !== 'All'
    //   ? (this.displayData = data.filter((elm) => elm.name === value))
    this.displayData = data;
  }

  statusChange(value: string): void {
    const data = this.productsList;
    // value !== 'All'
    //   ? (this.displayData = data.filter((elm) => elm.feature === value))
    //   : (this.displayData = data);
    this.displayData = data;
  }

  ngOnInit(): void {
    this.applyFilterForm = this.fb.group({
      productName: [null],
      category: [null],
      brand: [null],
      stockStatus: [null],
      productStatus: [null],
      isFeatured: [null],
      isBestSeller: [null],
      SkuID: [null],
      andItsChildren: [false],
      tags: [null],
    });

    this.tableFilterArr = this.orderColumn.map((e) => e.title);
    this.route.params.subscribe((data) => {
      console.log(data);
      if (data["showForm"] && !data["destroy"]) {
        this.showForm = true;
        this.showTable = false;
        this.productAdd = true;
      } else if (data["showForm"] && data["destroy"]) {
        this.showForm = true;
        this.showTable = false;
        this.productAdd = false;
      } else {
        this.productAdd = false;
        this.showTable = true;
        this.showForm = false;
      }
    });
    scrollToTop();
    this.fetchProductsForFilter();
    this.pageIndex = 1;
    this.fetchData(1);
    this.fetchCategories("all");
    this.fetchBrands("all");
    this.getAllSpecGroups("all");
    this.getAllSpecValues("all");
    this.selectedTab = "Processor Specification";
    this.currentUrl = this.router.url;

    this.validateForm = this.fb.group({});
    this.getAllTags("all");
  }

  AddOffer() {
    this.router.navigate([
      "/add-offer",
      { state: JSON.stringify(this.currentUrl) },
    ]);
  }

  showFormDetails() {
    // this.navService.checkProductStatus(true);
    this.isActive = true;
    this.productAdd = true;
    this.requiredData = "";
    this.clearFields();
    this.defaultMode = "Add";
    this.router.navigate(["/products", { showForm: true }]);
    // this.showTable = !this.showTable;
    // this.showForm = !this.showForm;
  }

  // POPOVER CLOSE BUTTON
  showModal(): void {
    if (this.categoryList.length == 0) {
      this.toastr.error("Please select a category!", "", {
        timeOut: 2500,
      });
    } else {
      this.isVisible = true;
    }
  }
  showMedia() {
    this.MediaVisible = true;
    // console.log(this.reqImageArray);
  }

  handleCancel(): void {
    this.modal.confirm({
      nzTitle: "Sure you Want to Cancel?",
      nzContent: "<b>Changes you made were cleared.</b>",
      nzOkText: "Yes",
      nzCancelText: "No",
      nzOnOk: () => {
        this.isVisible = false;
        this.MediaVisible = false;
      },
    });
  }

  selectedTab: string = "";

  ShowSpecValues(specSelectedValue: any, index: any) {
    if (index !== 0) {
      this.addProductSpec(this.reqSpecValue);
    }
    this.selectedTab = specSelectedValue.groupName.name;
    this.reqSpecValue = specSelectedValue.specValues;
  }

  // FORM RESET SECTION

  resetForm(): void {
    this.modalService.confirm({
      nzTitle: "Confirm",
      nzContent: "Sure You Want to Cancel ?",
      nzOkText: "Yes",
      nzCancelText: "No",
      nzOnOk: () => {
        this.clearFields();
      },
    });
  }

  sendMedia() {
    this.reqImageArray = [];
    this.navService.formRefreshAnnouncedSource.subscribe((item) => {
      this.productImages = item;
      // console.log(this.productImages);
      for (var i = 0; i < this.productImages?.length; i++) {
        if (this.reqImageArray.indexOf(this.productImages[i]?._id) == -1) {
          this.reqImageArray.push(this?.productImages[i]?._id);
        }
      }
    });
    console.log(this.reqImageArray);
    this.isVisible = false;
    this.MediaVisible = false;
  }

  getSpec(category: any) {
    //get specifications for selected category
    axios
      .post(
        environment.url + "categories/get-specs",
        { id: this.categoryList },
        {
          headers: {
            Authorization:
              "Bearer " + localStorage.getItem("admintokenoasisdata"),
          },
        }
      )
      .then((response) => {
        this.specResult = response.data.data.specifications;

        if (this.defaultMode == "Edit") {
          for (var i = 0; i < this.specResult.length; i++) {
            for (var j = 0; j < this.specForcreateApi.length; j++) {
              if (
                this.specResult[i].groupName.name ==
                this.specForcreateApi[j].specGroup.name
              ) {
                for (var k = 0; k < this.specResult[i].specValues.length; k++) {
                  for (
                    var l = 0;
                    l <
                    this.specForcreateApi[j].specGroup.specNamesAndValues
                      .length;
                    l++
                  ) {
                    if (
                      this.specResult[i].specValues[k].name ==
                      this.specForcreateApi[j].specGroup.specNamesAndValues[l]
                        .name
                    ) {
                      this.specResult[i].specValues[k].value =
                        this.specForcreateApi[j].specGroup.specNamesAndValues[
                          l
                        ].value;
                      this.specResult[i].specValues[k].isHighlighted =
                        this.specForcreateApi[j].specGroup.specNamesAndValues[
                          l
                        ].isHighlighted;
                    }
                  }
                }
              }
            }
          }
        }
        this.ShowSpecValues(this.specResult[0], 0);
      })
      .catch((error) => {
        console.log(error.response);
      });
  }

  requiredToastr(data: any) {
    this.toastr.error("Please Fill" + " " + data, "", {
      timeOut: 2500,
    });
  }

  requiredNewToastr() {
    this.toastr.error(" " + " ", "sell price must be less than regular price", {
      timeOut: 2500,
    });
  }

  doChanges() {
    var self = this;
    self.isDraft = false;
    if (!self.directMode) {
      self.isActive = true;
    }

    if (self.productName == "") {
      self.requiredData = "productName";
      self.requiredToastr("product Name");
    } else if (self.productImages.length == 0) {
      self.requiredData = "productImages";
      self.requiredToastr("product Images");
    } else if (self.productTags.length == 0) {
      self.requiredData = "productTags";
      self.requiredToastr("product Tags");
    } else if (self.categoryList.length == 0) {
      self.requiredData = "categoryList";
      self.requiredToastr("category List");
    } else if (
      self.regularPrice == "" ||
      self.regularPrice == 0 ||
      self.regularPrice == null ||
      self.regularPrice == undefined
    ) {
      console.log(self.regularPrice);

      self.requiredData = "regularPrice";
      self.notRegularPrice = true;
      self.requiredToastr("regular Price");
    } else if (
      self.sellPrice == "" ||
      self.sellPrice == 0 ||
      self.sellPrice == null ||
      self.sellPrice == undefined
    ) {
      self.requiredData = "sellPrice";
      self.requiredToastr("sell Price");
      self.notSellPrice = true;
      if (self.sellPrice > 0) {
        self.notSellPrice = false;
      } else if (self.sellPrice > self.regularPrice) {
        self.priceAlert = true;
        self.requiredNewToastr();
      } else {
        self.priceAlert = false;
      }
    } else if (
      self.regularPrice.toString().includes("-") ||
      self.sellPrice.toString().includes("-")
    ) {
      self.priceAlert = false;
      self.toastr.error("Price does not be Negative Value", "", {
        timeOut: 2500,
      });
    } else if (self.manageStock) {
      if (!self.minimumQuantityAlert) {
        self.requiredData = "minimumQuantityAlert";
        self.requiredToastr("Minimum Quantity Alert");
      } else if (!self.quantity) {
        self.requiredData = "Quantity";
        self.requiredToastr("Quantity");
      } else {
        self.publishing = true;
        self.productsLoading = true;
        self.addUpdateProduct();
      }
    } else {
      self.publishing = true;
      self.productsLoading = true;
      self.addUpdateProduct();
    }
  }

  addUpdateProduct() {
    var self = this;
    this.productLongDescription = this.productLongDescription;
    // .replaceAll('<b>', '<strong>')
    // .replaceAll('</b>', '</strong>');
    this.productShortDescription = this.productShortDescription;
    // .replaceAll('<b>', '<strong>')
    // .replaceAll('</b>', '</strong>');
    if (self.defaultMode == "Add") {
      var productData: any = {
        // skuId: self.productSkuID,
        name: self.productName,
        images: self.reqImageArray,
        type: self.productType,
        // allowedAttributes: [],
        variations: [],
        regularPrice: self.regularPrice,
        salePrice: self.sellPrice,
        tags: self.productTags,
        category: self.categoryList,
        // isBestSeller: self.isBestSeller == null ? false : self.isBestSeller,
        // isFeaturedProduct: self.isFeatured == null ? false : self.isFeatured,
        // brand: self.selectedBrand,
        shortDescription: self.productShortDescription,
        longDescription: self.productLongDescription,
        isActive: self.isActive == null ? false : self.isActive,
        // modelNo: self.modelNo,
        // specifications: self.specificationData,
        isDraft: self.isDraft == null ? false : self.isDraft,
        manageStock: self.manageStock,
        minimumQuantityAlert: self.minimumQuantityAlert,
        quantity: self.quantity,
      };
      if (self.slugName) {
        productData.slug = self.slugName;
      }
      self.okLoading = true;

      console.log(productData);

      axios
        .post(
          //environment.url+'products/create',
          environment.url + "products/create",
          productData,
          {
            headers: {
              Authorization:
                "Bearer " + localStorage.getItem("admintokenoasisdata"),
            },
          }
        )
        .then((response) => {
          if (response.data.status == "success") {
            self.okLoading = false;
            console.log(response.data.data);
            self.toastr.success("Product Added!", "", {
              timeOut: 2500,
            });
            self.productsLoading = false;
            self.clearFields();
            self.publishing = false;
            if (!self.directMode) {
              // changed for show filtered values even after add and update.
              if (self.filterApplied) {
                self.applyProductFilter();
              } else {
                this.pageIndex = 1;
                self.fetchData(1);
              }

              // self.showTable = !self.showTable;
              // self.showForm = !self.showForm;
              self.productAdd = false;
              self.router.navigate(["/products"]);
            }
            self.directMode = false;
            //  localStorage.setItem('admintokenoasisdata',response.data.data);
            //  self.router.navigate(['dashboard'])
          }
        })
        .catch((error) => {
          console.log(error);
          self.publishing = false;
          self.productsLoading = false;
          self.okLoading = false;
          if (self.directMode) {
            this.pageIndex = 1;
            self.fetchData(1);
          }
          if (error.response.data.data == undefined) {
            self.toastr.error("", error.response.data.message, {
              timeOut: 2500,
            });
          } else {
            self.toastr.error("", error.response.data.data[0].msg, {
              timeOut: 2500,
            });
          }
        });
    } else if (self.defaultMode == "Edit") {
      var reqObj: any = {
        productId: self.productId,
        // skuId: self.productSkuID,
        name: self.productName,
        images: self.reqImageArray,
        type: self.productType,
        // allowedAttributes: [],
        variations: [],
        regularPrice: self.regularPrice,
        salePrice: self.sellPrice,
        tags: self.productTags,
        category: self.categoryList,
        // isBestSeller: self.isBestSeller == null ? false : self.isBestSeller,
        // isFeaturedProduct: self.isFeatured == null ? false : self.isFeatured,
        // brand: self.selectedBrand,
        shortDescription: self.productShortDescription,
        longDescription: self.productLongDescription,
        isActive: self.isActive == null ? false : self.isActive,
        // modelNo: self.modelNo,
        // specifications: self.specificationData,
        isDraft: self.isDraft,
        manageStock: self.manageStock,
        minimumQuantityAlert: self.minimumQuantityAlert,
        quantity: self.quantity,
      };
      if (self.slugName) {
        reqObj.slug = self.slugName;
      }
      self.okLoading = true;

      if (reqObj.salePrice > reqObj.regularPrice) {
        self.priceAlert = true;
        self.requiredNewToastr();

        if ((self.priceAlert = true)) {
          self.okLoading = false;
          self.publishing = false;
          self.productsLoading = false;
        }
      } else {
        self.priceAlert = false;

        axios
          .put(
            //environment.url+'products/create',
            environment.url + "products/update",
            reqObj,
            {
              headers: {
                Authorization:
                  "Bearer " + localStorage.getItem("admintokenoasisdata"),
              },
            }
          )
          .then((response) => {
            if (response.data.status == "success") {
              self.okLoading = false;
              // console.log(response.data.data);
              self.toastr.success("Product Updated!", "", {
                timeOut: 2500,
              });
              self.publishing = false;
              self.productsLoading = false;
              if (!self.directMode) {
                // changed for show filtered values even after add and update.
                if (self.filterApplied) {
                  self.applyProductFilter();
                } else {
                  this.pageIndex = 1;
                  self.fetchData(1);
                }
                self.clearFields();
                // self.showTable = !self.showTable;
                // self.showForm = !self.showForm;
                this.productAdd = false;
                this.router.navigate(["/products"]);
              }
              self.directMode = false;
              //  localStorage.setItem('admintokenoasisdata',response.data.data);
              //  self.router.navigate(['dashboard'])
            }
          })
          .catch((error) => {
            console.log(error);
            self.publishing = false;
            self.productsLoading = false;
            self.okLoading = false;
            if (self.directMode) {
              this.pageIndex = 1;
              self.fetchData(1);
            }
            if (error.response.data.data == undefined) {
              self.toastr.error("", error.response.data.message, {
                timeOut: 2500,
              });
            } else {
              self.toastr.error("", error.response.data.data[0].msg, {
                timeOut: 2500,
              });
            }
          });
      }
    }
  }

  getSelectedSpecs() {
    this.addProductSpec(this.reqSpecValue);

    if (this.specForcreateApi.length == 0) {
      // alert('Please Select Spec Values');
    } else {
      for (var i = 0; i < this.specForcreateApi.length; i++) {
        var tempArray = [];
        for (
          var j = 0;
          j < this.specForcreateApi[i].specGroup.specNamesAndValues.length;
          j++
        ) {
          if (
            this.specForcreateApi[i].specGroup.specNamesAndValues[j].value !=
            undefined
          ) {
            if (
              this.specForcreateApi[i].specGroup.specNamesAndValues[j]
                .isHighlighted == undefined
            ) {
              this.specForcreateApi[i].specGroup.specNamesAndValues[
                j
              ].isHighlighted = false;
            }
            tempArray.push(
              this.specForcreateApi[i].specGroup.specNamesAndValues[j]
            );
            //this.specForcreateApi[i].specGroup.specNamesAndValues[j] = tempArray;
            //this.specForcreateApi.removeAt(j);
            //  this.specForcreateApi[i].specGroup.specNamesAndValues.splice(j,1);
            //  tempArray.push(this.specForcreateApi)
          }
        }
        this.specForcreateApi[i].specGroup.specNamesAndValues = tempArray;
      }
      //  console.log('tempArray is');
      //  console.log(tempArray);
      // console.log(this.specForcreateApi);
      this.isVisible = false;
      this.MediaVisible = false;
    }
  }
  addProductSpec(specArray: any) {
    // console.log('edit check');
    // console.log(specArray);
    var self = this;
    var count = 0;
    // if(specArray.length == 0){
    //   alert('Please Select Spec Value');
    // }
    for (var i = 0; i < specArray.length; i++) {
      if (specArray[i].value == undefined) {
        count++;
      }
    }
    if (count == specArray.length) {
      // alert('Please Select Spec Value');
    } else {
      // var specArray = reqArray;
      // var tempArray : any[] = [];
      // console.log(specArray);
      //  for(var i = 0; i < specArray.length; i++) {
      //    delete specArray[i]._id;
      //    delete specArray[i].acceptedValues;
      //    if(specArray[i].value != undefined) {
      //      tempArray.push(specArray[i]);
      //    }
      //  for(var j = 0; j < specArray[i].val.length; j++) {
      //    if(specArray[i].acceptedValues[j].value == undefined){
      //      specArray[i].acceptedValues.removeAt(j);
      //    }
      //  }
      //}
      // console.log(self.selectedTab);
      var reqObj = {
        specGroup: {
          name: this.selectedTab,
          specNamesAndValues: specArray,
        },
      };
      // console.log(reqObj);
      for (var i = 0; i < self.specForcreateApi.length; i++) {
        if (self.specForcreateApi[i].specGroup.name == reqObj.specGroup.name) {
          self.specForcreateApi.splice(i, 1);
        }
      }
      self.specForcreateApi.push(reqObj);
      // console.log('specArray');
      // console.log(self.specForcreateApi);
    }
  }
  editProduct(product: any) {
    var self = this;
    self.productAdd = true;
    self.clearFields();
    console.log(product.specification);
    if (product.specification) {
      self.specificationData = product?.specification;
      for (let k = 0; k < product.specification.length; k++) {
        for (
          let l = 0;
          l < product?.specification[k]?.specGroup?.specNamesAndValues?.length;
          l++
        ) {
          var targetIndex = self.allSpecValues?.findIndex((e: any) => {
            return (
              e.name ==
              product.specification[k].specGroup.specNamesAndValues[l].name
            );
          });
          var temp: any = {
            specGroup: {
              name: product?.specification[k]?.specGroup?.name,
            },
            specName:
              targetIndex > -1
                ? self?.allSpecValues[targetIndex]
                : { isHighlighted: false },

            specValue:
              product?.specification[k]?.specGroup?.specNamesAndValues[l]
                ?.value,
          };
          console.log(targetIndex);
          temp.specName.isHighlighted =
            product?.specification[k]?.specGroup?.specNamesAndValues[
              l
            ]?.isHighlighted;
          self?.selectedSpecsTableData?.push(temp);
        }
      }
      console.log(this.selectedSpecsTableData);
    }

    self.requiredData = "";
    self.productId = product.id;
    self.productName = product.name;
    self.slugName = product.slug;
    self.productSkuID = product.Skuld;
    self.productImages = product.imageData;
    for (var i = 0; i < self.productImages.length; i++) {
      if (self.reqImageArray.indexOf(self.productImages[i]?._id) == -1) {
        self.reqImageArray.push(self.productImages[i]._id);
      }
    }
    self.productTags = product.tags;
    if (product.category[0]) {
      for (let s = 0; s < product.category.length; s++) {
        self.categoryList.push(product.category[s]._id);
      }
    }

    // self.getSpec(self.categoryList);
    self.selectedBrand = product?.brand?._id;
    self.modelNo = product.modelNumber;
    self.regularPrice = product.regularPrice;

    self.sellPrice = product.salePrice;

    // self.isBestSeller = product.bestseller;
    // self.isFeatured = product.featured;
    self.isActive = product.active;
    self.specForcreateApi = product.specification;
    self.productShortDescription = product.shortDescription;
    self.productLongDescription = product.longDescription;
    self.manageStock = product.isManageStock;
    self.defaultMode = "Edit";
    self.quantity = product.quantity;
    self.minimumQuantityAlert = product.minimumQuantityAlert;
    self.router.navigate(["/products", { showForm: true }]);
  }

  clearFields() {
    this.productType = "simple";
    this.productName = "";
    this.slugName = "";
    this.productSkuID = "";
    this.productImages = [];
    this.productTags = [];
    this.categoryList = [];
    this.selectedBrand = "";
    this.modelNo = "";
    this.regularPrice = "";
    this.sellPrice = "";
    this.isBestSeller = false;
    this.isFeatured = false;
    this.isActive = true;
    this.manageStock = false;
    this.minimumQuantityAlert = "";
    this.quantity = "";
    this.productShortDescription = "";
    this.productLongDescription = "";
    this.allowedAttributes = [];
    this.variations = [];
    this.brandList = [];
    this.listOfCategory = [];
    this.listOfBrands = [];
    this.selectedAttribute = [];
    this.reqSpecArray = [];
    this.reqSpecValue = [];
    this.specResult = [];
    this.specForcreateApi = [];

    this.reqImageArray = [];
    // new spec data
    this.specificationData = [];
    this.selectedSpecsTableData = [];
    this.acceptedValues = [];
    this.selectedAcceptedValues = "";
    this.selectedSpecValue = "";
    this.selectedSpecGroup = "";
  }

  onPageNumberChange(event) {
    // this.spinner.show();
    if (event.pageIndex != this.pageIndex) {
      this.pageIndex = event.pageIndex;
      if (this.filterApplied) {
        this.applyProductFilter();
      } else {
        this.fetchData(event.pageIndex);
      }
    }
  }

  directUpdate(selected: any, event: any, type: string) {
    var self = this;
    console.log(selected);
    if (!selected.isDraft) {
      self.clearFields();
      self.requiredData = "";
      self.productId = selected.id;
      self.productName = selected.name;
      self.slugName = selected.slug;
      self.productSkuID = selected.Skuld;
      self.productImages = selected.imageData;
      for (var i = 0; i < self.productImages.length; i++) {
        if (self.reqImageArray.indexOf(self.productImages[i]?._id) == -1) {
          self.reqImageArray.push(self.productImages[i]?._id);
        }
      }
      self.productTags = selected.tags;

      for (let s = 0; s < selected.category.length; s++) {
        self.categoryList.push(selected.category[s]?._id);
      }
      // self.getSpec(self.categoryList);
      self.selectedBrand = selected?.brand?._id;
      self.modelNo = selected.modelNumber;
      self.regularPrice = selected.regularPrice;
      self.sellPrice = selected.salePrice;
      // self.isBestSeller = selected.bestseller;
      // self.isFeatured = selected.featured;
      self.isActive = selected.active;
      // self.specForcreateApi = selected.specification;
      self.specificationData = selected?.specification;
      self.productShortDescription = selected?.shortDescription;
      self.productLongDescription = selected?.longDescription;
      self.defaultMode = "Edit";
      self.directMode = true;
      self.doChanges();
    } else {
      var targetIndex = this.productsList?.findIndex((e: any) => {
        return e.id === selected.id;
      });
      console.log(this.productsList[targetIndex]);
      if (type == "active") {
        this.productsList[targetIndex].active = !selected.active;
      } else if (type == "featured") {
        this.productsList[targetIndex].featured = !selected.featured;
      } else if (type == "bestSeller") {
        this.productsList[targetIndex].bestseller = !selected.bestseller;
      }
      this.toastr.error("Product is in draft Please Update Product", "", {
        timeOut: 2500,
      });
    }
    // self.productsLoading = true;
  }

  // stock status update

  SelectAll() {
    for (let i = 0; i < this.productsList.length; i++) {
      if (this.Checked == false) {
        this.productsList[i].isSelected = true;
        this.checkedList.push(this.productsList[i].id);
      } else if (this.Checked == true) {
        this.productsList[i].isSelected = false;
        this.checkedList = [];
      }
    }
    // console.log(this.checkedList);

    if (this.checkedList.length >= 1) {
      this.checkboxActive = true;
    } else if (this.checkedList.length == 0) {
      this.checkboxActive = false;
    }
  }

  checkSelected(item: any) {
    item.isSelected = !item.isSelected;
    // console.log(item.isSelected);
    if (item.isSelected == true) {
      this.checkedList.push(item.id);
      // console.log(this.checkedList);
    } else if (item.isSelected == false) {
      this.checkedList.splice(this.checkedList.indexOf(item.id), 1);
      // console.log(this.checkedList);
    }
    // console.log(this.checkedList);

    if (this.checkedList.length >= 1) {
      this.checkboxActive = true;
    } else if (this.checkedList.length == 0) {
      this.checkboxActive = false;
    }
  }

  updateStockStatus() {
    // console.log(this.selectedOption);
    var outOfStockStatus: any;
    this.okLoading = true;
    if (this.selectedOption == "In Stock") {
      outOfStockStatus = false;
    } else if (this.selectedOption == "Out of Stock") {
      outOfStockStatus = true;
    }
    axios
      .post(
        environment.url + "products/update-stock-status",
        {
          productIds: this.checkedList,
          outOfStock: outOfStockStatus,
        },
        {
          headers: {
            Authorization:
              "Bearer " + localStorage.getItem("admintokenoasisdata"),
          },
        }
      )
      .then((response) => {
        // console.log(response);
        if (response.data.status == "success") {
          this.okLoading = false;
          this.toastr.success(response.data.message, "", {
            timeOut: 2500,
          });
          this.checkedList = [];
          this.checkboxActive = false;
          this.pageIndex = 1;
          this.fetchData(1);
        }
      })
      .catch((error) => {
        console.log(error);
        this.okLoading = false;
        if (error.response.data.data == undefined) {
          this.toastr.error("", error.response.data.message, {
            timeOut: 2500,
          });
        } else {
          this.toastr.error("", error.response.data.data[0].msg, {
            timeOut: 2500,
          });
        }
      });
  }

  addOffer(offerData, type) {
    if (!offerData.isInOffer) {
      this.selectedData = {
        id: offerData.id,
        offerType: type,
        action: offerData.isInOffer ? "update" : "create",
      };
      // console.log(offerData);
      this.showTable = false;
    } else if (offerData.isInOffer) {
      this.removeOffer(offerData);
    }
  }

  removeOffer(offerData: any) {
    this.offerLoading = true;
    var offerObj: any = {
      productId: offerData.id,
      type: "product",
    };

    axios
      .post(environment.url + "offers/remove-offer", offerObj, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("admintokenoasisdata"),
        },
      })
      .then((response: any) => {
        // console.log(response);
        this.offerLoading = false;
        this.pageIndex = 1;
        this.fetchData(1);
        this.toastr.success(response.data.message, "", {
          timeOut: 2500,
        });
      })
      .catch((error: any) => {
        this.offerLoading = false;
        this.toastr.error(error.response.message, "", {
          timeOut: 2500,
        });
        console.log(error);
      });
  }

  showTableData() {
    this.showTable = true;
    this.pageIndex = 1;
    this.fetchData(1);
  }

  // Get specifications data for new design

  sideMenuCategories: any = ["General", "Inventory", "Specification"];
  sideMenu = "General";

  sideMenuChange(menu: string) {
    this.sideMenu = menu;
  }

  allSpecGroups: any = [];
  allSpecValues: any = [];
  acceptedValues: any = [];
  specValueIndex: any;

  selectedSpecGroup: any = "";
  selectedSpecValue: any = "";
  selectedAcceptedValues: any = "";
  addOption: boolean;

  filteredData: any = [];
  searchValue: any = "";

  // spec value section
  specValueModal: boolean = false;
  specValueName: any = "";
  specType: any = "";

  // spec display
  selectedSpecsTableData: any = [];
  specTableHeading = [
    {
      name: "Group name",
      width: "100px",
    },
    {
      name: "Specification name",
      width: "150px",
    },
    {
      name: "Specification Value",
      width: "100px",
    },
    {
      name: "HighLighted",
      width: "80px",
    },
    {
      name: "",
      width: "60px",
    },
  ];

  specificationData: any = [];
  editSpecData: any;
  editSpecIndex: any;
  isEditSpec: boolean = false;
  specHighlighted: boolean = false;

  errorField: any = [];

  getAllSpecValues(key: string) {
    axios
      .get(environment.url + "specValues/get?isForProductPage=true", {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("admintokenoasisdata"),
        },
      })
      .then((response) => {
        console.log(response);
        this.allSpecValues = response.data.data;
        for (let n = 0; n < this.allSpecValues.length; n++) {
          this.allSpecValues[n].isHighlighted = false;
        }
        if (key == "new") {
          this.acceptedValues = [];
          this.selectedSpecValue = this.allSpecValues[0];
        } else if (key == "valueUpdate") {
          var targetIndex = this.allSpecValues?.findIndex((e: any) => {
            return e.name == this.selectedSpecValue.name;
          });
          this.acceptedValues = this.allSpecValues[targetIndex].acceptedValues;
          this.selectedAcceptedValues =
            this.acceptedValues[this.acceptedValues.length - 1];
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getAllSpecGroups(key: any) {
    axios
      .get(environment.url + "specGroup/get?isForProductPage=true", {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("admintokenoasisdata"),
        },
      })
      .then((response) => {
        console.log(response.data.data);
        this.allSpecGroups = response.data.data;
        //console.log(response.data.status);
        if (key == "new") {
          this.selectedSpecGroup = this.allSpecGroups[0].name;
        }
        this.spinner.hide();
      })
      .catch((error) => {
        console.log(error);
        this.spinner.hide();
      });
  }

  // spec values change functions

  specGroupValueChange(type: string) {
    this.addOption = false;
    console.log(this.selectedSpecValue);
    if (type == "group") {
      if (this.selectedSpecGroup == "addGroup") {
        this.addNewSpecGroup();
      }
      if (this.errorField.indexOf("spec group") != -1) {
        this.errorField.splice(this.errorField.indexOf("spec group"), 1);
      }
    } else if (type == "value") {
      if (this.selectedSpecValue == "addValue") {
        // this.addNewSpecValue();
        this.specValueModal = true;
        this.specValueName = this.searchValue;
      } else {
        this.acceptedValues = [];
        this.selectedAcceptedValues = "";
        var targetIndex = this.allSpecValues?.findIndex((e: any) => {
          return e.name == this.selectedSpecValue.name;
        });
        this.acceptedValues = this.allSpecValues[targetIndex].acceptedValues;
      }
      if (this.errorField.indexOf("spec name") != -1) {
        this.errorField.splice(this.errorField.indexOf("spec name"), 1);
      }
      // console.log(this.allSpecValues);
    } else if (type == "accepted value") {
      if (this.selectedAcceptedValues == "addAcceptedValue") {
        this.addNewAcceptedValue();
      }
      if (this.errorField.indexOf("spec value") != -1) {
        this.errorField.splice(this.errorField.indexOf("spec value"), 1);
      }
    }
  }

  specGroupSearch(event: any, type: string) {
    this.addOption = true;
    if (type == "group") {
      this.filteredData = this.allSpecGroups.filter((filtered: any) =>
        filtered.name
          .toString()
          .toLowerCase()
          .includes(event.searchValue.toString().toLowerCase())
      );
    } else if (type == "value") {
      this.filteredData = this.allSpecValues.filter((filtered: any) =>
        filtered.name
          .toString()
          .toLowerCase()
          .includes(event.searchValue.toString().toLowerCase())
      );
    } else if (type == "accepted value") {
      this.filteredData = this.acceptedValues.filter((filtered: any) =>
        filtered
          .toString()
          .toLowerCase()
          .includes(event.searchValue.toString().toLowerCase())
      );
    }
    this.searchValue = event.searchValue;
    // console.log(this.filteredData);
  }

  onFocus(event: any) {
    if (event.searchValue == "") {
      this.addOption = false;
    }
  }
  // Adding new specs

  addNewSpecGroup() {
    this.spinner.show();
    var specGroupDetails = {
      name: this.searchValue,
      isActive: true,
    };
    axios
      .post(environment.url + "specGroup/create", specGroupDetails, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("admintokenoasisdata"),
        },
      })
      .then((response) => {
        if (response.data.status == "success") {
          console.log(response);
          this.addOption = false;
          this.getAllSpecGroups("new");
        }
      })
      .catch((error) => {
        console.log(error);
        this.spinner.hide();
      });
  }

  closeSpecValueModal() {
    this.specValueModal = false;
  }

  addNewSpecValue() {
    var specValueDetails = {
      name: this.specValueName,
      type: this.specType,
      isShowOnFilter: true,
    };

    axios
      .post(environment.url + "specValues/create", specValueDetails, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("admintokenoasisdata"),
        },
      })
      .then((response) => {
        if (response.data.status == "success") {
          console.log(response);
          this.addOption = false;
          this.getAllSpecValues("new");
          this.specValueModal = false;
          this.specType = "";
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  addNewAcceptedValue() {
    var targetIndex = this.allSpecValues?.findIndex((e: any) => {
      return e.name == this.selectedSpecValue.name;
    });
    var specValueData = this.allSpecValues[targetIndex];
    specValueData.acceptedValues.push(this.searchValue);
    // console.log(specValueData);
    var reqObj: any = {
      id: specValueData._id,
      name: specValueData.name,
      type: "Value",
      acceptedValues: specValueData.acceptedValues,
      isShowOnFilter: true,
    };

    console.log(reqObj);

    axios
      .post(environment.url + "specValues/update", reqObj, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("admintokenoasisdata"),
        },
      })
      .then((response) => {
        console.log(response);
        this.getAllSpecValues("valueUpdate");
      })
      .catch((error) => {
        console.log(error.response);
      });
  }

  // add specification section

  addSpecification() {
    this.errorField = [];
    if (!this.selectedSpecGroup) {
      this.errorField.push("spec group");
    } else if (!this.selectedSpecValue) {
      this.errorField.push("spec name");
    } else if (!this.selectedAcceptedValues) {
      this.errorField.push("spec value");
    } else {
      if (this.isEditSpec) {
        this.deleteSpec(this.editSpecData, this.editSpecIndex);
        this.isEditSpec = false;
      }

      var specObj: any = {
        specValue: this?.selectedAcceptedValues,
      };
      var targetIndex = this?.allSpecValues?.findIndex((e: any) => {
        return e.name == this?.selectedSpecValue.name;
      });
      specObj.specName = this?.allSpecValues[targetIndex];
      specObj.specName.isHighlighted = this.specHighlighted;
      var valueIndex = this?.allSpecGroups?.findIndex((e: any) => {
        return e.name == this?.selectedSpecGroup;
      });
      specObj.specGroup = this?.allSpecGroups[valueIndex];
      this.selectedSpecsTableData =
        this?.selectedSpecsTableData.concat(specObj);

      this.selectedAcceptedValues = "";
      this.selectedSpecValue = "";
      this.selectedSpecGroup = "";

      console.log(specObj);

      var temp: any = {};
      var specGroup: any = {};
      var namesAndValues = {
        name: specObj?.specName?.name,
        value: specObj?.specValue,
        type: specObj?.specName?.type,
        isHighlighted: this?.specHighlighted,
      };

      var specIndex = this?.specificationData?.findIndex((e: any) => {
        return e.specGroup?.name == specObj?.specGroup?.name;
      });

      if (specIndex == -1) {
        specGroup.name = specObj?.specGroup?.name;
        specGroup.specNamesAndValues = [namesAndValues];
        temp.specGroup = specGroup;
        this?.specificationData.push(temp);
      } else {
        this?.specificationData[specIndex]?.specGroup?.specNamesAndValues.push(
          namesAndValues
        );
      }
      this.errorField = [];
      this.specHighlighted = false;
      console.log(this.specificationData);
    }
  }

  // delete and edit specification

  deleteSpec(data: any, index: any) {
    console.log(this.specificationData);
    this.selectedSpecsTableData.splice(index, 1);
    for (let s = 0; s < this.specificationData.length; s++) {
      if (this.specificationData[s]?.specGroup?.name == data.specGroup.name) {
        var specIndex = this?.specificationData[
          s
        ]?.specGroup?.specNamesAndValues.findIndex((e: any) => {
          return e.name == data.specName.name;
        });

        this.specificationData[s].specGroup.specNamesAndValues.splice(
          specIndex,
          1
        );
        break;
      }
    }
    console.log(this.specificationData);
  }

  editSpec(data: any, index: any) {
    console.log(data?.specName);
    this.isEditSpec = true;
    this.editSpecData = data;
    this.editSpecIndex = index;
    this.selectedSpecValue = data?.specName;
    this.selectedSpecGroup = data.specGroup.name;
    this.specHighlighted = data?.specName?.isHighlighted;
    var targetIndex = this?.allSpecValues?.findIndex((e: any) => {
      return e.name == this?.selectedSpecValue?.name;
    });
    this.acceptedValues = this?.allSpecValues[targetIndex]?.acceptedValues;
    this.selectedAcceptedValues = data?.specValue;
    console.log(this.selectedSpecValue);
  }

  // check add new category

  filteredCategories: any = [];
  addCategoryOption: boolean;
  categoryForm: boolean;

  parentId: any = "";
  categoryName: any = "";

  categoryFilter(event: any) {
    this.categoryForm = false;
    this.addCategoryOption = true;
    this.filteredCategories = this.categoriesLists.filter((filtered: any) =>
      filtered.name
        .toString()
        .toLowerCase()
        .includes(event.searchValue.toString().toLowerCase())
    );
    this.searchValue = event.searchValue;
    console.log(this.filteredCategories);
  }

  onCategoryFocus(event: any) {
    if (event.searchValue == "") {
      this.categoryForm = false;
      this.addCategoryOption = false;
    }
  }

  categoryValueChange() {
    this.addCategoryOption = false;
    console.log(this.categoryList);
    if (this.categoryList[this.categoryList.length - 1] == "addCategory") {
      this.categoryList.splice(this.categoryList.indexOf("addCategory"), 1);
      this.categoryName = this.searchValue;
      this.categoryForm = true;
    } else {
      this.categoryForm = false;
    }
  }

  addNewCategory() {
    var categoryData = {
      name: this.categoryName,
      isMainParent: this.parentId.length > 0 ? false : true,
      parentId: this.parentId,
      featured: false,
      isActive: true,
    };

    axios
      .post(environment.url + "categories/create", categoryData, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("admintokenoasisdata"),
        },
      })
      .then((response) => {
        if (response.data.status == "success") {
          this.categoryForm = false;
          this.parentId = "";
          this.fetchCategories("new");
          console.log(response);
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
  }

  // filter tags to create new tag if its not available

  searchTag: any = "";
  filteredTags: any = [];
  addTagOption: boolean = false;

  tagFilter(event: any) {
    this.addTagOption = true;
    this.filteredTags = this.tags.filter((filtered: any) =>
      filtered.name
        .toString()
        .toLowerCase()
        .includes(event.searchValue.toString().toLowerCase())
    );
    this.searchTag = event.searchValue;
    // console.log(this.filteredTags);
  }

  onTagFocus(event: any) {
    if (event.searchValue == "") {
      this.addTagOption = false;
    }
  }

  tagValueChange() {
    this.addTagOption = false;
    // console.log(this.productTags);
    if (this.productTags.indexOf("addNewTagDirect") > -1) {
      this.productTags.splice(this.productTags.indexOf("addNewTagDirect"), 1);
      this.createTag();
    }
  }

  createTag() {
    var reqObj: any = {
      name: this.searchTag,
      isActive: true,
    };
    axios
      .post(environment.url + "tags/create", reqObj, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("admintokenoasisdata"),
        },
      })
      .then((response) => {
        if (response.data.status == "success") {
          this.getAllTags("add");
        }
      })
      .catch((error) => {
        console.log(error);
        this.toastr.error(error.response.data.message, "", {
          timeOut: 2500,
        });
      });
  }

  // check and add new brand

  filteredBrands: any = [];
  addBrandsOption: boolean;
  brandsForm: boolean;

  brandFilter(event: any) {
    this.brandsForm = false;
    this.addBrandsOption = true;
    this.filteredBrands = this.brandsList.filter((filtered: any) =>
      filtered.name
        .toString()
        .toLowerCase()
        .includes(event.searchValue.toString().toLowerCase())
    );
    this.searchValue = event.searchValue;
    console.log(this.filteredBrands);
  }

  onBrandFocus(event: any) {
    if (event.searchValue == "") {
      this.brandsForm = false;
      this.addBrandsOption = false;
    }
  }
  brandValueChange() {
    this.addCategoryOption = false;
    console.log(this.brandsList);
    if (this.selectedBrand == "addBrand") {
      this.addNewBrand();
    }
  }

  addNewBrand() {
    var brandData = {
      name: this.searchValue,
      isPopular: false,
      isActive: true,
    };

    axios
      .post(environment.url + "brands/create", brandData, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("admintokenoasisdata"),
        },
      })
      .then((response) => {
        if (response.data.status == "success") {
          console.log(response.data.data);
          this.selectedBrand = "";
          this.fetchBrands("new");
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
  }

  // save draft section

  draftAlert() {
    this.modal.confirm({
      nzTitle: "Do you Want save this item as Draft?",
      // nzContent: '<b>Some descriptions</b>',
      nzOkText: "Yes",
      nzCancelText: "No",
      nzOnOk: () => this.saveAsDraft(),
      nzOnCancel: () => {
        console.log(this.endUrl);
        this.productAdd = false;
        this.requiredData = "";
        this.clearFields();
        this.defaultMode = "Add";
        // this.showTable = !this.showTable;
        // this.showForm = !this.showForm;
        this.router.navigate([this.endUrl]);
        // this.navService.checkProductStatus(false);
      },
    });
  }

  saveAsDraft() {
    if (
      this.productName ||
      this.slugName ||
      this.productSkuID ||
      this.productImages.length > 0 ||
      this.productTags.length > 0 ||
      this.categoryList.length > 0 ||
      this.selectedBrand ||
      this.modelNo ||
      this.regularPrice ||
      this.sellPrice
    ) {
      this.isDraft = true;
      this.isActive = false;
      this.addUpdateProduct();
    } else {
      this.toastr.error("Please fill AtLeast 1 Field", "", {
        timeOut: 2500,
      });
    }
  }

  ngOnDestroy() {
    if (this.productAdd && this.endUrl != "/") {
      this.router.navigate(["/products", { showForm: true, destroy: true }]);
      this.draftAlert();
    }
  }

  // excel file upload

  uploadExcelFile(ev: any) {
    this.spinner.show();
    this.productSpinner = true;
    console.log(ev.target.files);
    var formData = new FormData();
    formData.append("file", ev.target.files[0]);
    axios
      .post(environment.url + "products/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization:
            "Bearer " + localStorage.getItem("admintokenoasisdata"),
        },
      })
      .then((response) => {
        console.log(response.data);
        this.toastr.success("products excel updated!..", "", {
          timeOut: 3500,
        });
        // if (response.data.message == 'media uploaded') {
        this.pageIndex = 1;
        this.fetchData(1);
        this.downloadExcel(response.data.data);
        this.productSpinner = false;
        this.spinner.hide();
        // }
      })
      .catch((error) => {
        console.log(error);
        this.toastr.error(error.response.data.message, "", {
          timeOut: 3500,
        });
        this.productSpinner = false;
        this.spinner.hide();
      });

    // ev.target.value = '';
  }

  downloadExcel(data: any) {
    var categoryData: any = [];
    var brandData: any = [];
    var duplicateData: any = [];

    let orderData = [];
    if (data?.categories?.length > 0) {
      for (let i = 0; i < data?.categories?.length; i++) {
        var temp = [
          i + 1,
          data?.categories[i][0]?.indexNo,
          data?.categories[i][0]?.categoryName,
        ];
        categoryData.push(temp);
        temp = [];
      }
      orderData.push({
        title: ["Mismatch Categories"],
        data: categoryData,
        headers: ["S.No", "Line Number", "Category Name"],
      });
    }

    if (data?.brands?.length > 0) {
      for (let i = 0; i < data?.brands?.length; i++) {
        var temp = [
          i + 1,
          data?.brands[i][0]?.indexNo,
          data?.brands[i][0]?.brandName,
        ];
        brandData.push(temp);
        temp = [];
      }
      orderData.push({
        title: ["Mismatch Brands"],
        data: brandData,
        headers: ["S.No", "Line Number", "Brand Name"],
      });
    }

    if (data?.duplicateModels?.length > 0) {
      for (let i = 0; i < data?.duplicateModels?.length; i++) {
        var temp = [i + 1, data?.duplicateModels[i]];
        duplicateData.push(temp);
        temp = [];
      }

      orderData.push({
        title: ["Duplicate Models"],
        data: duplicateData,
        headers: ["S.No", "Model Number"],
      });
    }

    if (orderData.length > 0) {
      this.ete.exportExcel(orderData, "Error_Report");
    }
  }

  tableFilterChange(heading: any) {
    if (this.tableFilterArr.indexOf(heading) >= 0) {
      this.tableFilterArr.splice(this.tableFilterArr.indexOf(heading), 1);
    } else {
      this.tableFilterArr.push(heading);
    }
    console.log(this.tableFilterArr);
  }

  // product filter section

  filterVisible: boolean = false;

  openTableFilter() {
    this.filterVisible = true;
  }

  filterClose() {
    this.filterVisible = false;
  }

  resetFilters() {
    this.applyFilterForm.reset();
    this.applyFilterForm.get("andItsChildren").setValue(false);
    this.filterObj = {};
    this.filterClose();
    this.pageIndex = 1;
    this.fetchData(1);
  }

  fetchProductsForFilter() {
    axios
      .get(environment.url + "products?isForProductPage=true", {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("admintokenoasisdata"),
        },
      })
      .then((response) => {
        this.productsForFilter = response.data.data;
        console.log(this.productsForFilter);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  filterObj: any = {};

  applyFilterButton() {
    this.pageIndex = 1;
    this.applyProductFilter();
  }

  applyProductFilter() {
    this.filterObj = {};
    // console.log(this.applyFilterForm.value);
    if (this.applyFilterForm.value.productName) {
      this.filterObj.productIds = this.applyFilterForm.value.productName;
    }
    if (this.applyFilterForm.value.category) {
      this.filterObj.categoryIds = this.applyFilterForm.value.category;
    }
    if (this.applyFilterForm.value.brand) {
      this.filterObj.brandIds = this.applyFilterForm.value.brand;
    }
    if (this.applyFilterForm.value.stockStatus) {
      this.filterObj["stockStatus-mutt"] =
        this.applyFilterForm.value.stockStatus;
    }
    if (this.applyFilterForm.value.productStatus) {
      this.filterObj.productStatus = this.applyFilterForm.value.productStatus;
    }
    if (this.applyFilterForm.value.SkuID) {
      this.filterObj.skuId = this.applyFilterForm.value.SkuID;
    }
    if (this.applyFilterForm.value.isFeatured) {
      this.filterObj.isFeatured = this.applyFilterForm.value.isFeatured;
    }
    if (this.applyFilterForm.value.tags) {
      this.filterObj.tags = this.applyFilterForm.value.tags;
    }
    // if (this.applyFilterForm.value.isBestSeller) {
    //   this.filterObj.isBestSeller = this.applyFilterForm.value.isBestSeller;
    // }
    this.filterObj.andItsChildren = this.applyFilterForm.value.andItsChildren;
    // console.log(Object.keys(this.filterObj).length > 0);
    if (this.filterObj) {
      this.filterApplied = true;
      this.filterClose();
      this.productSpinner = true;
      this.spinner.show();

      axios
        .post(
          environment.url +
            "products/productsByFilterForAdminPanel?page=" +
            this.pageIndex,
          this.filterObj,
          {
            headers: {
              Authorization:
                "Bearer " + localStorage.getItem("admintokenoasisdata"),
            },
          }
        )
        .then((response) => {
          console.log(response);
          var initialResult = response?.data?.data;
          this.totalCount = response.data?.data?.pagination?.totalCount;
          this.pageSize = response?.data?.data?.pagination?.limit;
          this.productsList = initialResult?.product.map(
            (data: any, index: number) => {
              return {
                id: data?._id,
                Skuld: data?.skuId,
                name: data?.name,
                type: data?.type,
                image: data?.images[0]?.image?.url,
                bestseller: data?.isBestSeller,
                featured: data?.isFeaturedProduct,
                active: data?.isActive,
                brand: data?.brand,
                modelNumber: data?.modelNo,
                regularPrice: data?.regularPrice,
                salePrice: data?.salePrice,
                tags: data?.tags,
                category: data?.category,
                specification: data?.specifications, //data?.specifications[0]?.specGroup?.name
                imageData: data?.images,
                shortDescription: data?.shortDescription,
                longDescription: data?.longDescription
                  ? data?.longDescription
                  : "",
                isSelected: false,
                outOfStock: data?.outOfStock,
                isInOffer: data?.isInOffer,
                isDraft: data.isDraft,
                isManageStock: data.isManageStock,
                quantity: data.quantity,
                minimumQuantityAlert: data.minimumQuantityAlert,
              };
            }
          );
          this.productSpinner = false;
          this.spinner.hide();
        })
        .catch((error) => {
          console.log(error.response);
          this.productSpinner = false;
          this.spinner.hide();
        });
    } else {
      this.toastr.error("At least select 1 filter..", "", {
        timeOut: 3500,
      });
    }
  }

  // slug name section

  addSlugName() {
    this.slugName = this.productName.toLowerCase().replaceAll(" ", "-");
  }

  // download file section

  downloadFile() {
    // axios({
    //   method: 'post',
    //   url: environment.url + 'products/exportAsExcel',
    //   responseType: 'arraybuffer',
    // })
    //   .then((mediaResponse: any) => {
    //     console.log(mediaResponse);
    //     // mediaResponse.data.pipe(
    //     //   fs.createWriteStream('./sample_csv_format-v2bbbbbb.xlsx')
    //     // );
    //     const blob = new Blob([mediaResponse.body], {
    //       type: mediaResponse.headers.get('content-type'),
    //     });
    //     // fileName = fileName || response.headers.get('content-disposition').split(';')[0];
    //     const file = new File([blob], 'test', {
    //       type: mediaResponse.headers.get('content-type'),
    //     });
    //     fileSaver.saveAs(file);
    //   })
    //   .catch((error) => {
    //     console.log(error.response);
    //   });
    // axios
    //   .post(
    //     environment.url + 'products/exportAsExcel',
    //     {},
    //     {
    //       headers: {
    //         Authorization:
    //           'Bearer ' + localStorage.getItem('admintokenoasisdata'),
    //       },
    //     }
    //   )
    //   .then((response: any) => {
    //     console.log(response);
    //     let blob: any = new Blob([response.data.blob()], {
    //       type: 'application/octet-stream',
    //     });
    //     fileSaver.saveAs(blob, 'employees.xlsx');
    //   })
    //   .catch((error) => {
    //     console.log(error.response);
    //   });
    // this.http
    //   .post<any>(
    //     environment.url + 'products/exportAsExcel',
    //     { responseType: 'blob' },
    //     {
    //       headers: {
    //         Authorization:
    //           'Bearer ' + localStorage.getItem('admintokenoasisdata'),
    //       },
    //     }
    //   )
    //   .subscribe((data) => {
    //     console.log(data);
    //   });
  }

  downloadingFile: boolean = false;

  downloadProductsAsFile() {
    this.downloadingFile = true;
    axios({
      url: environment.url + "products/exportAsExcel", //your url
      method: "POST",
      responseType: "blob", // important
      headers: {
        Authorization: "Bearer " + localStorage.getItem("admintokenoasisdata"),
      },
    })
      .then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        let date = moment(new Date()).format("DD-MM-YYYY");
        link.setAttribute("download", "products_dated_" + date + ".csv"); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        this.downloadingFile = false;
      })
      .catch((error) => {
        this.downloadingFile = false;
        this.toastr.error("Please Try Again!", "", {
          timeOut: 2500,
        });
      });
  }

  sellPriceChange() {
    if (this.sellPrice > this.regularPrice) {
      this.priceAlert = true;
    } else {
      this.priceAlert = false;
    }
  }

  regularPriceChange() {
    if (this.regularPrice > 0) {
      this.notRegularPrice = false;
    } else {
      this.notRegularPrice = true;
    }
  }

  // getAllTags() {
  //   axios({
  //     url: environment.url + "products/get-all-tags",
  //     method: "GET",
  //     // responseType: "blob",
  //     // headers: {
  //     //   Authorization: "Bearer " + localStorage.getItem("admintokenoasisdata"),
  //     // },
  //   })
  //     .then((response: any) => {
  //       console.log(response);
  //       this.tags=response?.data?.data
  //     })
  //     .catch((error: any) => {
  //       console.log(error);

  //     });
  // }

  // get all tags for product creation

  getAllTags(action: any) {
    axios
      .get(environment.url + "tags/tags-for-create", {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("admintokenoasisdata"),
        },
      })
      .then((response) => {
        console.log(response.data.data);
        this.tags = response?.data?.data;
        if (action == "add") {
          this.productTags.push(this.tags?.[0]?._id);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
