import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { scrollToTop } from "../../shared/dummy";
import axios, { Axios } from "axios";
import { NavService } from "../../services/nav.service";
import { NzModalService } from "ng-zorro-antd/modal";
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from "src/environments/environment";
import { ToastrService } from "ngx-toastr";

interface DataItem {
  type: any;
  typeId: any;
  banner: any;
  bannerId: any;
  liink: string;
  active: any;
  location: any;
  createdBy: any;
  createdAt: any;
  lastUpdateBy: any;
  lastUpdateAt: any;
  imageObg: any;
}

@Component({
  selector: "app-banners",
  templateUrl: "./banners.component.html",
  styleUrls: ["./banners.component.css"],
})
export class BannersComponent implements OnInit {
  selectedCategory: string;
  selectedStatus: string;
  searchInput: any;
  displayData = [];
  liveUrl = environment.url;
  imageSize = "";

  selectionType = "";
  bannerType: any;
  categoryType: any;
  categoryId: string;
  productId: string;
  brandId: string;

  categoryList: any[] = [];
  brandList: any[] = [];
  productList: any[] = [];
  location= "hero-section";
  bannerImage: any;
  isActive = true;
  isEdit = false;
  id = "";

  showTable: boolean = true;
  showForm: boolean = false;

  MediaVisible = false;
  isOkLoading = false;

  validateForm: FormGroup;
  requiredData = "";
  bannerLoading: boolean;

  orderColumn = [
    // {
    //   title: 'S.NO',
    //   width: '50px',
    // },
    {
      title: "Banner Type",
      width: "70px",
      compare: (a: DataItem, b: DataItem) => a.type - b.type,
    },
    // {
    //   title: "Type Id",
    //   width: "100px",
    //   compare: (a: DataItem, b: DataItem) => a.typeId - b.typeId,
    // },
    {
      title: "Banner",
      width: "50px",
      compare: (a: DataItem, b: DataItem) => a.banner - b.banner,
    },
    {
      title: "Status",
      width: "70px",
      compare: (a: DataItem, b: DataItem) => a.active - b.active,
    },
    {
      title: "Location",
      width: "80px",
      compare: (a: DataItem, b: DataItem) => a.location - b.location,
    },
  ];

  productsList: DataItem[] = [];

  // search(): void {
  //     const data = this.productsList
  //     this.displayData = this.tableSvc.search(this.searchInput, data )
  // }

  constructor(
    private fb: FormBuilder,
    private navService: NavService,
    private modalService: NzModalService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {}

  categoryChange(value: string): void {
    const data = this.productsList;
    // value !== 'All'
    //   ? (this.displayData = data.filter((elm) => elm.name === value))
    this.displayData = data;
  }

  statusChange(value: string): void {
    const data = this.productsList;
    // value !== 'All'
    //   ? (this.displayData = data.filter((elm) => elm.feature === value))
    //   : (this.displayData = data);
    this.displayData = data;
  }

  fetchCategories() {
    axios
      .get(environment.url + "categories?isForProductPage=true", {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("admintokenoasisdata"),
        },
      })
      .then((response) => {
        // console.log(response.data.data);
        this.categoryList = response.data.data;
        this.spinner.hide();
        // this.count = response.data.data.count;

        //console.log(response.data.status);
      })
      .catch((error) => {
        console.log(error.response);
        this.spinner.hide();
        //alert(error.response.data.message);
      });
  }

  fetchProducts() {
    axios
      .get(environment.url + "products?isForProductPage=true", {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("admintokenoasisdata"),
        },
      })
      .then((response) => {
        //console.log(response.data.data.products[0].name);
        this.productList = response.data.data;

        // this.count = response.data.data.count;

        console.log(response);
      })
      .catch((error) => {
        console.log(error);
        //alert(error.response.data.message);
      });
  }

  fetchBrands() {
    axios
      .get(environment.url + "brands?isForProductPage=true", {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("admintokenoasisdata"),
        },
      })
      .then((response) => {
        console.log(response.data.data[0].name);
        this.brandList = response.data.data;

        // this.count = response.data.data.count;

        //console.log(response.data.status);
      })
      .catch((error) => {
        console.log(error);
        //alert(error.response.data.message);
      });
  }

  fetchData() {
    axios
      .get(environment.url + "banners", {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("admintokenoasisdata"),
        },
      })
      .then((response) => {
        console.log(response?.data?.data?.banners);
        this.productsList = response?.data?.data?.banners?.map(
          (data: any, index: number) => {
            return {
              type: data?.bType,
              typeId: data?._id,
              banner: data?.image?.image.url,
              bannerId: data?.image?._id,
              link: data?.link,
              active: data?.isActive,
              location: data?.location,
              createdBy: data?.createdBy,
              createdAt: data?.createdAt,
              lastUpdateBy: data?.lastUpdatedBy,
              lastUpdateAt: data?.lastUpdatedAt,
              imageObg: data?.image,
            };
          }
        );
        // console.log(this.productsList);
        this.fetchCategories();
        // this.count = response.data.data.count;

        //console.log(response.data.status);
      })
      .catch((error) => {
        console.log(error);
        //alert(error.response.data.message);
      });
  }

  ngOnInit(): void {
    this.spinner.show();
    scrollToTop();
    this.fetchData();
    this.validateForm = this.fb.group({});

    if (window.innerWidth > 992) {
      this.showForm = true;
    }
  }

  showFormDetails() {
    this.requiredData = "";
    this.isEdit = false;
    this.clearFields();
    this.showTable = !this.showTable;
    this.showForm = !this.showForm;
  }

  // MODEL SECTION
  showMedia() {
    this.MediaVisible = true;
  }
  handleCancel(): void {
    this.MediaVisible = false;
  }

  // FORM RESET SECTION

  resetForm(): void {
    this.imageSize = "";
    this.modalService.confirm({
      nzTitle: "Confirm",
      nzContent: "Sure You Want to Reset ?",
      nzOkText: "Yes",
      nzCancelText: "No",
      nzOnOk: () => {
        // if (window.innerWidth > 992) {
        this.clearFields();
        this.isEdit = false;
        // } else {
        //   this.showFormDetails();
        // }
      },
    });
    // this.showTable = !this.showTable;
    // this.showForm = !this.showForm;
    // this.validateForm.reset();
  }
  editBanner(banner: any) {
    // console.log(banner);
    this.bannerType = banner?.type;
    this.selectionType = banner?.type;
    this.getBannerType(this.selectionType);
    if (this.bannerType == "category") {
      this.categoryId = banner?.link;
    } else if (this.bannerType == "product") {
      this.productId = banner?.link;
    } else if (this.bannerType == "brand") {
      this.brandId = banner?.link;
    }
    this.location = banner?.location;
    this.isActive = banner?.active;
    this.bannerImage = [banner?.imageObg];
    this.id = banner?.typeId;
    //this.bannerImage = [banner.banner];
    this.isEdit = true;
    if (window.innerWidth < 992) {
      this.showTable = !this.showTable;
      this.showForm = !this.showForm;
    }
  }

  getBannerType(value: any) {
    this.selectionType = value;
    if (value == "category") {
      this.fetchCategories();
    } else if (value == "product") {
      this.fetchProducts();
    } else if (value == "brand") {
      this.fetchBrands();
    }
  }

  sendMedia() {
    this.navService.formRefreshAnnouncedSource.subscribe((item) => {
      this.bannerImage = item;
    });
    this.handleCancel();
  }

  clearFields() {
    this.isActive = true;
    this.isEdit = false;
    this.selectionType = "";
    this.bannerType = undefined;
    this.categoryType = undefined;
    this.categoryId = undefined;
    this.productId = undefined;
    this.brandId = undefined;
    this.location = "hero-section";
    this.bannerImage = [];
    this.id = "";
  }

  doChanges() {
    var self = this;
    if (self.bannerType == undefined) {
      alert("Select Banner Type");
    } else if (self.bannerType == "category" && self.categoryId == undefined) {
      alert("Select Category");
    } else if (self.bannerType == "product" && self.productId == undefined) {
      alert("Select Product");
    } else if (self.bannerType == "brand" && self.brandId == undefined) {
      alert("Select Brand");
    } else if (self.bannerImage == undefined || self.bannerImage.length == 0) {
      alert("select Banner Image");
    } else if (
      self.location == undefined &&
      self.bannerType != "login-banner"
    ) {
      alert("Select Banner Location");
    } else {
      var link;
      if (self.bannerType == "category") link = self.categoryId;
      else if (self.bannerType == "product") link = self.productId;
      else if (self.bannerType == "brand") link = self.brandId;
      var url;
      if (self.isEdit == false) {
        var reqObr: any = {
          bType: self.bannerType,
          link: link,
          image: self.bannerImage[self.bannerImage.length - 1]._id,
          isActive: self.isActive,
          location:self.location,
        };
        if (self.bannerType == "login-banner") {
          reqObr.location = "login-banner";
        }
        self.bannerLoading = true;
        axios
          .post(environment.url + "banners/create", reqObr, {
            headers: {
              Authorization:
                "Bearer " + localStorage.getItem("admintokenoasisdata"),
            },
          })
          .then((response) => {
            if (response.data.status == "success") {
              // console.log(response.data.data);
              self.toastr.success("Banner Added!", "", {
                timeOut: 2500,
              });
              self.fetchData();
              self.clearFields();
              self.bannerLoading = false;
              if (window.innerWidth < 992) {
                self.showTable = !self.showTable;
                self.showForm = !self.showForm;
              }
              //  localStorage.setItem('admintokenoasisdata',response.data.data);
              //  self.router.navigate(['dashboard'])
            }
          })
          .catch((error) => {
            self.bannerLoading = false;
            if (error.response.data.data == undefined) {
              self.toastr.error("", error.response.data.message, {
                timeOut: 2500,
              });
            } else {
              self.toastr.error("", error.response.data.data[0].msg, {
                timeOut: 2500,
              });
            }
          });
      } else if (self.isEdit == true) {
        var reqObj: any = {
          id: self.id,
          bType: self.bannerType,
          link: link,
          image: self.bannerImage[self.bannerImage.length - 1]._id,
          isActive: self.isActive,
          location: this.location,
        };
        if (self.bannerType == "login-banner") {
          reqObj.location = "login-banner";
        }
        self.bannerLoading = true;
        axios
          .put(environment.url + "banners/update", reqObj, {
            headers: {
              Authorization:
                "Bearer " + localStorage.getItem("admintokenoasisdata"),
            },
          })
          .then((response) => {
            if (response.data.status == "success") {
              // console.log(response.data.data);
              self.toastr.success("Banner Updated!", "", {
                timeOut: 2500,
              });
              self.fetchData();
              self.clearFields();
              self.bannerLoading = false;
              if (window.innerWidth < 992) {
                self.showTable = !self.showTable;
                self.showForm = !self.showForm;
              }
              //  localStorage.setItem('admintokenoasisdata',response.data.data);
              //  self.router.navigate(['dashboard'])
            }
          })
          .catch((error) => {
            self.bannerLoading = false;
            if (error.response.data.data == undefined) {
              self.toastr.error("", error.response.data.message, {
                timeOut: 2500,
              });
            } else {
              self.toastr.error("", error.response.data.data[0].msg, {
                timeOut: 2500,
              });
            }
          });
      }
    }
  }

  onLocationChange(locationSelected: any) {
    // console.log(locationSelected);
    switch (locationSelected) {
      case "offer-banner":
        this.imageSize = "370px X 260px";
        break;
      case "hero-section":
        this.imageSize = "1200px X 420px";
        break;
      case "sub-banner":
        this.imageSize = "1200px X 350px";
        break;
      case "mobile-sub-banner-large":
        this.imageSize = "350px X 120px";
        break;
      case "mobile-sub-banner-small":
        this.imageSize = "160px X 120px";
        break;
      case "login-banner":
        this.imageSize = "180px X 220px";
        break;

      default:
        break;
    }
  }

  onPageNumberChange(event) {
    document.getElementById(event).scrollIntoView();
    window.scroll(0, 0);
  }
}
