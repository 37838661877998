<div class="row justify-content-center">
    <div class="col-lg-8">
        <nz-card>
            <div class="text-center">
                <h3 class="category-heading-outside">Setting</h3>
            </div>

            <form nz-form>
                <div nz-row [nzGutter]="24">
                    <div nz-col [nzXs]="24" [nzSpan]="12">
                        <nz-form-item>
                            <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired nzFor="">Minimum Order
                                Value
                            </nz-form-label>
                            <nz-form-control nzErrorTip="Please Enter Valid Value!">
                                <nz-input-group>
                                    <input type="number" autocomplete="off" [(ngModel)]="minimumOrderValue"
                                        (ngModelChange)="inputValueChange('MOV')" [ngModelOptions]="{standalone: true}"
                                        nz-input placeholder="Enter Minimum Order Value">
                                </nz-input-group>
                                <p class="error-message" *ngIf="errorArr.indexOf('MOV') > -1">
                                    *Please Enter Valid Value
                                </p>
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div nz-col [nzXs]="24" [nzSpan]="12">
                        <nz-form-item>
                            <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired nzFor="">delivery
                                Charge
                            </nz-form-label>
                            <nz-form-control nzErrorTip="Please Enter Valid Value!">
                                <nz-input-group>
                                    <input type="number" autocomplete="off" [(ngModel)]="deliveryCharges"
                                        (ngModelChange)="inputValueChange('DC')" [ngModelOptions]="{standalone: true}"
                                        nz-input placeholder="Enter Delivery Charge">
                                </nz-input-group>
                                <p class="error-message" *ngIf="errorArr.indexOf('DC') > -1">
                                    *Please Enter Valid Value </p>
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div nz-col [nzXs]="24" [nzSpan]="12">
                        <nz-form-item>
                            <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired nzFor="">Free Delivery
                                Charge Over
                            </nz-form-label>
                            <nz-form-control nzErrorTip="Please Enter Valid Value!">
                                <nz-input-group>
                                    <input type="number" autocomplete="off" [(ngModel)]="freeDeliveryChargesOver"
                                        (ngModelChange)="inputValueChange('FDC')" [ngModelOptions]="{standalone: true}"
                                        nz-input placeholder="Enter Free Delivery Charge Over">
                                </nz-input-group>
                                <p class="error-message" *ngIf="errorArr.indexOf('FDC') > -1">
                                    *Please Enter Valid Value </p>
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div *ngIf="isUpiCodAvailable" nz-col [nzXs]="24" [nzSpan]="12">
                        <nz-form-item>
                            <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired nzFor="">
                                UPI ID
                            </nz-form-label>
                            <nz-form-control nzErrorTip="Please Enter Valid Value!">
                                <nz-input-group>
                                    <input type="text" autocomplete="off" [(ngModel)]="upiId"
                                        (ngModelChange)="inputValueChange('upiId')"
                                        [ngModelOptions]="{standalone: true}" nz-input placeholder="Enter UPI ID">
                                </nz-input-group>
                                <p class="error-message" *ngIf="errorArr.indexOf('upiId') > -1">
                                    *Please Enter Valid Value </p>
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <!-- <div nz-col [nzXs]="24" [nzSpan]="12">
                        <nz-form-item>
                            <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired nzFor="">Free Delivery
                                Charge Range(Kms)
                            </nz-form-label>
                            <nz-form-control nzErrorTip="Please Enter Valid Value!">
                                <nz-input-group>
                                    <input type="number" autocomplete="off" [(ngModel)]="freeDeliveryChargesRange"
                                        [ngModelOptions]="{standalone: true}" nz-input
                                        placeholder="Enter Free Delivery Charge Range">
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>
                    </div> -->

                    <!-- <div nz-col [nzXs]="24" [nzSpan]="12">
                        <nz-form-item>
                            <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired nzFor="">Category Of
                                Products For HomePage
                            </nz-form-label>
                            <nz-form-control nzErrorTip="Please Select Categories!">
                                <nz-select #categorySelect nzMode="multiple" [(ngModel)]="categoryList"
                                    [ngModelOptions]="{standalone: true}" id="BrandCategory" nzShowSearch class="w-100"
                                    nzPlaceHolder="None">
                                    <nz-option *ngFor="let column of categoriesLists" nzValue="{{column._id}}"
                                        nzLabel="{{column.name}}">
                                    </nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div> -->

                    <!-- <div nz-col [nzXs]="24" [nzSpan]="12">
                        <nz-form-item>
                            <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired nzFor="">Category Of
                                Products For Navbar
                            </nz-form-label>
                            <nz-form-control nzErrorTip="Please Select Categories!">
                                <nz-select #categorySelect nzMode="multiple" [(ngModel)]="navbarCategoryList"
                                    [ngModelOptions]="{standalone: true}" id="BrandCategory" nzShowSearch class="w-100"
                                    nzPlaceHolder="None">
                                    <nz-option *ngFor="let column of categoriesLists" nzValue="{{column._id}}"
                                        nzLabel="{{column.name}}">
                                    </nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div> -->

                    <div nz-col [nzXs]="24" [nzLg]="12" [nzSpan]="12">
                        <nz-form-item>
                            <nz-form-label class="large_label" nzRequired nzFor="">Location
                            </nz-form-label>
                            <nz-form-control nzErrorTip="Please Select Categories!">
                                <nz-switch [(ngModel)]="locationActive" [ngModelOptions]="{standalone: true}">
                                </nz-switch>
                                <!-- <p class="error-message" *ngIf="requiredData == 'freeDeliveryChargesRange'">*Please Enter Valid Value </p> -->
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div nz-col [nzXs]="24" [nzLg]="12" [nzSpan]="12">
                        <nz-form-item>
                            <nz-form-label class="large_label" nzRequired nzFor="">Cod Available
                            </nz-form-label>
                            <nz-form-control nzErrorTip="Please Select Categories!">
                                <nz-switch [(ngModel)]="isCodAvailable" [ngModelOptions]="{standalone: true}">
                                </nz-switch>
                                <!-- <p class="error-message" *ngIf="requiredData == 'freeDeliveryChargesRange'">*Please Enter Valid Value </p> -->
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div nz-col [nzXs]="24" [nzLg]="12" [nzSpan]="12">
                        <nz-form-item>
                            <nz-form-label class="large_label" nzRequired nzFor="">Net Banking
                            </nz-form-label>
                            <nz-form-control nzErrorTip="Please Select Categories!">
                                <nz-switch [(ngModel)]="isDirectBankTransferAvailable"
                                    [ngModelOptions]="{standalone: true}">
                                </nz-switch>
                                <!-- <p class="error-message" *ngIf="requiredData == 'freeDeliveryChargesRange'">*Please Enter Valid Value </p> -->
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div nz-col [nzXs]="24" [nzLg]="12" [nzSpan]="12">
                        <nz-form-item>
                            <nz-form-label class="large_label" nzRequired nzFor="">
                                Upi Available
                            </nz-form-label>
                            <nz-form-control nzErrorTip="Please Select Categories!">
                                <nz-switch [(ngModel)]="isUpiCodAvailable" [ngModelOptions]="{standalone: true}">
                                </nz-switch>
                                <!-- <p class="error-message" *ngIf="requiredData == 'freeDeliveryChargesRange'">*Please Enter Valid Value </p> -->
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <!-- <div nz-col [nzXs]="24" [nzLg]="12" [nzSpan]="12">
                        <nz-form-item>
                            <nz-form-label class="large_label" nzRequired nzFor="">
                                Store Is Active
                            </nz-form-label>
                            <nz-form-control nzErrorTip="Please Select Categories!">
                                <nz-switch [(ngModel)]="storeIsActive" [ngModelOptions]="{standalone: true}">
                                </nz-switch> -->
                    <!-- <p class="error-message" *ngIf="requiredData == 'freeDeliveryChargesRange'">*Please Enter Valid Value </p> -->
                    <!-- </nz-form-control>
                        </nz-form-item>
                    </div> -->

                    <!-- <div nz-col [nzXs]="24" [nzLg]="12" [nzSpan]="12">
                        <nz-form-item>
                            <nz-form-label class="large_label" nzRequired nzFor="">
                                Review Enabled
                            </nz-form-label>
                            <nz-form-control nzErrorTip="Please Select Categories!">
                                <nz-switch [(ngModel)]="reviewIsAvailable" [ngModelOptions]="{standalone: true}">
                                </nz-switch>
                            </nz-form-control>
                        </nz-form-item>
                    </div> -->

                    <div nz-col [nzXs]="24" [nzSpan]="12">
                        <div class="text-center">
                            <button style="background-color: green; color: white" type=" button"
                                (click)="updateSettings()" class="add-button" nz-button
                                nzType="default ">Update</button>
                        </div>
                    </div>


                </div>


            </form>
        </nz-card>
    </div>
</div>

<ngx-spinner bdColor="rgba(0,0,0,0.69)" size="medium" color="#00f226" type="ball-spin-clockwise" [fullScreen]="true">
</ngx-spinner>