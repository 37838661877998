import { Component, OnInit } from "@angular/core";
import { ThemeConstantService } from "src/app/shared/services/theme-constant.service";

@Component({
  selector: "app-color-switcher",
  templateUrl: "./color-switcher.component.html",
  styleUrls: ["./color-switcher.component.css"],
})
export class ColorSwitcherComponent implements OnInit {
  toggled: boolean;
  themeType = "light";
  themeLoading: boolean = false;

  allColors: any = [
    {
      sideNavColors: [
        {
          color: "#d960b8",
        },
        {
          color: "#5abdf0",
        },
        {
          color: "#f24845",
        },
        {
          color: "#7b70d9",
        },
        {
          color: "#00b4db",
        },
      ],
      headerColors: [
        {
          color: "#0727d7",
        },
        {
          color: "#23282c",
        },
        {
          color: "#e20920",
        },
        {
          color: "#157d4d",
        },
        {
          color: "#673bb7",
        },
        {
          color: "#795548",
        },
        {
          color: "#d3084e",
        },
        {
          color: "#ff9901",
        },
      ],
    },
  ];

  constructor(private themeService: ThemeConstantService) {}

  ngOnInit(): void {}

  toggleWrapper() {
    this.toggled = !this.toggled;
  }

  changeColor(selected: any, key: any, optional: string) {
    if (optional == "sideNav") {
      this.toggleSideNav();
    }
    document.documentElement.style.setProperty(key, selected.color);
  }

  toggleSideNav() {
    this.themeService.toggleExpand(true);
    this.themeService.toggleFold(false);
  }

  themeChange() {
    var styles: any = [];
    if (this.themeType == "light") {
      styles = [
        { name: "--theme_white", value: "#fff" },
        { name: "--theme_black", value: "#000" },
        { name: "--side_nav_background", value: "#fff" },
        { name: "--primary_color", value: "#03500a" },
        { name: "--header_background", value: "#fff" },
        { name: "--content_background", value: "#f9fbfd" },
        { name: "--input_background", value: "#fff" },
        { name: "--table_border_color", value: "#edf2f9" },
        { name: "--input_border", value: "#c9c9ca" },
        { name: "--table_link_color", value: "#1227e9" },
        { name: "--table_header", value: "#f8f9fa" },
        { name: "--table_body", value: "#fff" },
        { name: "--tag_background", value: "#f5f5f5" },
        { name: "--order_heading_background", value: "#e3e3e3" },
        { name: "--input_label", value: "#53535f" },
        { name: "--image_invert", value: "invert(1)" },
        {
          name: "--card_box_shadow",
          value: "0 2px 6px #dadafda6, 0 2px 6px #ceceee8a",
        },
      ];
      styles.forEach((data: any) => {
        document.documentElement.style.setProperty(`${data.name}`, data.value);
      });
      document.documentElement.style.setProperty("", "#fff");
    } else {
      styles = [
        { name: "--theme_white", value: "#000" },
        { name: "--theme_black", value: "#fff" },
        { name: "--side_nav_background", value: "#000" },
        { name: "--primary_color", value: "#8181817d" },
        { name: "--header_background", value: "#000" },
        { name: "--content_background", value: "#1a1c23" },
        { name: "--input_background", value: "#1a1c23" },
        { name: "--table_border_color", value: "#3d3d3d" },
        { name: "--input_border", value: "#3d3d3d" },
        { name: "--table_link_color", value: "#3ec0f7" },
        { name: "--table_header", value: "#1c1c1c" },
        { name: "--table_body", value: "#1a1c23" },
        { name: "--tag_background", value: "#444" },
        { name: "--order_heading_background", value: "#000" },
        { name: "--input_label", value: "#fff" },
        { name: "--image_invert", value: "invert(0)" },
        { name: "--card_box_shadow", value: "none" },
      ];
      styles.forEach((data: any) => {
        document.documentElement.style.setProperty(`${data.name}`, data.value);
      });
    }
  }

  resetTheme() {
    this.themeType = "light";
    this.themeChange();
  }

  applyTheme() {}
}
