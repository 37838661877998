<!-- <div class="row main-row">
    <div class="col-lg-10">
        <div class="row">
            <div class="col-xl-3 col-lg-6 col-md-6">
                <nz-card routerLink="/brands" class="card-design1 card-design">
                    <div class="details-section">
                        <div class="user-icon">
                            <img src="../../../assets/images/Dashboard/brands.png" alt="">
                        </div>
                        <div class="user-details">
                            <p class="m-b-0 text-muted">Brands</p>
                            <h2 class="m-b-0">{{count?.brand}}</h2>
                        </div>
                    </div>
                </nz-card>
            </div>
            <div class="col-xl-3 col-lg-6 col-md-6">
                <nz-card routerLink="/category" class="card-design2 card-design">
                    <div class="details-section">
                        <div class="user-icon">
                            <img src="../../../assets/images/Dashboard/category.png" alt="">
                        </div>
                        <div>
                            <p class="m-b-0 text-muted">Category</p>
                            <h2 class="m-b-0">{{count?.category}}</h2>
                        </div>
                    </div>
                </nz-card>
            </div>
            <div class="col-xl-3 col-lg-6 col-md-6">
                <nz-card routerLink="/products" class="card-design3 card-design">
                    <div class="details-section">
                        <div class="user-icon">
                            <img src="../../../assets/images/Dashboard/products.png" alt="">
                        </div>
                        <div>
                            <p class="m-b-0 text-muted">Products</p>
                            <h2 class="m-b-0">{{count?.product}}</h2>
                        </div>
                    </div>
                </nz-card>
            </div>
            <div class="col-xl-3 col-lg-6 col-md-6">
                <nz-card routerLink="/store-location" class="card-design4 card-design">
                    <div class="details-section">
                        <div class="user-icon">
                            <img src="../../../assets/images/Dashboard/location.png" alt="">
                        </div>
                        <div>
                            <p class="m-b-0 text-muted">Service Centers</p>
                            <h2 class="m-b-0">{{count?.serviceCenters}}</h2>
                        </div>
                    </div>
                </nz-card>
            </div>
        </div>
    </div>

    <div class="col-lg-10 mt-5">
        <div class="row">
            <div class="col-lg-8 dashboard-table-data">
                <nz-table #productsListTable nzShowPagination="true" [nzPageSize]="5" [nzData]="productsList">
                    <thead>
                        <tr>
                            <th *ngFor="let column of orderColumn">{{ column.title }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="dashboard-table" *ngFor="let item of productsListTable.data;  let i=index">
                            <td>{{i+1}}</td>
                            <td>
                                <nz-avatar nzShape="square" [nzSize]="50" nzIcon="picture" [nzSrc]="liveUrl+item.images[0].image.url"></nz-avatar>
                            </td>
                            <td>
                                <div class="d-flex align-items-center">
                                    {{item?.name}}
                                </div>
                            </td>
                            <td>{{item?.viewedCount}}</td>
                        </tr>
                    </tbody>
                </nz-table>
            </div>
        </div>
    </div>
</div> -->

<div class="row date-picker-row mb-3">
    <div class="col-12 date-picker-column">
        <nz-range-picker [(ngModel)]="date" (ngModelChange)="onDateChange($event)" [nzAllowClear]="false">
        </nz-range-picker>
        <button (click)="resetDate()" class="reset-button" nz-button>Reset</button>
    </div>
</div>


<div class="row justify-content-around">
    <div class="col-lg-6 col-xl-6 col-xxl-6">
        <div class="row">
            <div class="col-xl-6 col-lg-12 col-md-6">
                <nz-card class="card-design5 card-design">
                    <div class="card-column">
                        <div class="user-details">
                            <h2 class="m-b-0">${{count?.overAllSales}}</h2>
                            <!-- <img class="icon_image" src="../../../assets/images/Dashboard/total-sales.png" alt=""> -->
                            <span class="dashboard_icons" nz-icon nzType="line-chart" nzTheme="outline"></span>
                        </div>
                        <div class="user-icon">
                            <p class="m-b-0 text-muted">Total Sales</p>
                        </div>
                    </div>
                </nz-card>
            </div>
            <div class="col-xl-6 col-lg-12 col-md-6">
                <nz-card class="card-design6 card-design">
                    <div class=" card-column">
                        <div class="user-details">
                            <h2 class="m-b-0">${{count?.overAllTodaySales}}</h2>
                            <!-- <img class="icon_image" src="../../../assets/images/Dashboard/today.png" alt=""> -->
                            <span class="dashboard_icons" nz-icon nzType="schedule" nzTheme="outline"></span>
                        </div>
                        <div class="user-icon">
                            <p class="m-b-0 text-muted">Today Sales</p>
                        </div>
                    </div>
                </nz-card>
            </div>

            <div class="col-xl-6 col-lg-12 col-md-6">
                <nz-card (click)="toTotalOrders()" class="card-design7 card-design">
                    <div class=" card-column">
                        <div class="user-details">
                            <h2 class="m-b-0">{{count?.count?.totalOrders}}</h2>
                            <!-- <img class="icon_image" src="../../../assets/images/Dashboard/total-orde$png" alt=""> -->
                            <span class="dashboard_icons" nz-icon nzType="shopping-cart" nzTheme="outline"></span>
                        </div>
                        <div class="user-icon">
                            <p class="m-b-0 text-muted">Total Orders</p>
                        </div>
                    </div>
                </nz-card>
            </div>
            <div class="col-xl-6 col-lg-12 col-md-6">
                <nz-card (click)="toTodayOrders()" class="card-design2 card-design">
                    <div class=" card-column">
                        <div class="user-details">
                            <h2 class="m-b-0">{{count?.count?.todayOrders}}</h2>
                            <!-- <img class="icon_image" src="../../../assets/images/Dashboard/purchase.png" alt=""> -->
                            <span class="dashboard_icons" nz-icon nzType="file-done" nzTheme="outline"></span>
                        </div>
                        <div class="user-icon">
                            <p class="m-b-0 text-muted">Today Orders</p>
                        </div>
                    </div>
                </nz-card>
            </div>
        </div>
    </div>

    <div class="col-lg-6 col-xl-6 col-xxl-6">
        <div class="row">
            <!-- <div class="col-xl-6 col-lg-12 col-md-6">
                <nz-card routerLink="/brands" class="card-design1 card-design">
                    <div class=" card-column">
                        <div class="user-details">
                            <h2 class="m-b-0">{{count?.count?.brand}}</h2>
                            <span class="dashboard_icons" nz-icon nzType="tag" nzTheme="outline"></span>
                        </div>
                        <div class="user-icon">
                            <p class="m-b-0 text-muted">Brands</p>
                        </div>
                    </div>
                </nz-card>
            </div> -->
            <div class="col-xl-6 col-lg-12 col-md-6">
                <nz-card routerLink="/category" class="card-design4 card-design">
                    <div class=" card-column">
                        <div class="user-details">
                            <h2 class="m-b-0">{{count?.count?.category}}</h2>
                            <!-- <img class="icon_image" src="../../../assets/images/Dashboard/category.png" alt=""> -->
                            <span class="dashboard_icons" nz-icon nzType="unordered-list" nzTheme="outline"></span>
                        </div>
                        <div class="user-icon">
                            <p class="m-b-0 text-muted">Category</p>
                        </div>
                    </div>
                </nz-card>
            </div>

            <div class="col-xl-6 col-lg-12 col-md-6">
                <nz-card routerLink="/products" class="card-design3 card-design">
                    <div class=" card-column">
                        <div class="user-details">
                            <h2 class="m-b-0">{{count?.count?.product}}</h2>
                            <!-- <img class="icon_image" src="../../../assets/images/Dashboard/products.png" alt=""> -->
                            <span class="dashboard_icons" nz-icon nzType="group" nzTheme="outline"></span>
                        </div>
                        <div class="user-icon">
                            <p class="m-b-0 text-muted">Products</p>
                        </div>
                    </div>
                </nz-card>
            </div>
            <!-- <div class="col-xl-6 col-lg-12 col-md-6">
                <nz-card routerLink="/store-location" class="card-design8 card-design">
                    <div class=" card-column">
                        <div class="user-details">
                            <h2 class="m-b-0">{{count?.count?.serviceCenters}}</h2>
                            <span class="dashboard_icons" nz-icon nzType="shop" nzTheme="outline"></span>
                        </div>
                        <div class="user-icon">
                            <p class="m-b-0 text-muted">Service Centers</p>
                        </div>
                    </div>
                </nz-card>
            </div> -->
        </div>
    </div>

    <ngx-spinner *ngIf="dashboardSpinner" bdColor="rgb(255 255 255 / 88%)" size="medium" color="#00f226" type="ball-spin-clockwise" [fullScreen]="true">
    </ngx-spinner>
</div>

<div class="row">

    <div class="col-lg-7">
        <nz-card>
            <div class="d-flex justify-content-between align-items-center">
                <h5>Sales Statistics</h5>
            </div>
            <div class="m-t-50">
                <canvas id="salesChartData" ngChartjs style="height: 385px">
                </canvas>
            </div>
        </nz-card>
    </div>

    <div class="col-lg-5">
        <nz-card style="height: 500px">
            <h5>Carted Products by Area</h5>
            <div class="text-center" style="height: 300px">
                <canvas *ngIf="showChart" ngChartjs [data]="cartedByDistrictChartData" [labels]="cartedByDistrictChartLabels" [chartType]="cartedByDistrictChartType" [options]="cartedByDistrictChartOptions" [legend]="false" [colors]="chartBackgroundcolors"></canvas>
            </div>
            <div class="row p-t-25 list_row">
                <div *ngFor="let item of CaretedByDistrictData" class="col-md-11 m-h-auto">
                    <div class="d-flex justify-content-between align-items-center m-b-20">
                        <p class="m-b-0 d-flex align-items-center">
                            <nz-badge nzStatus="default" [nzColor]="item.colors"></nz-badge>
                            <span>{{item?.district[0]}}</span>
                        </p>
                        <h5 class="m-b-0">{{item.totalItems}}</h5>
                    </div>
                </div>
            </div>
        </nz-card>
    </div>

</div>

<div class="row district_charts">
    <div class="col-lg-5">
        <nz-card style="height: 535px">
            <h5>Ordered Products by Area</h5>
            <div class="text-center" style="height: 300px">
                <canvas *ngIf="showChart" ngChartjs [data]="ordersByDistrictChartData" [labels]="ordersByDistrictChartLabels" [chartType]="ordersByDistrictChartType" [options]="ordersByDistrictChartOptions" [legend]="false" [colors]="chartBackgroundcolors"></canvas>
            </div>
            <div class="row p-t-25 list_row">
                <div *ngFor="let item of ordersByDistrictData" class="col-md-11 m-h-auto">
                    <div class="d-flex justify-content-between align-items-center m-b-20">
                        <p class="m-b-0 d-flex align-items-center">
                            <nz-badge nzStatus="default" [nzColor]="item.colors"></nz-badge>
                            <span>{{item.district[0]}}</span>
                        </p>
                        <h5 class="m-b-0">{{item.totalOrders}}</h5>
                    </div>
                </div>
            </div>
        </nz-card>
    </div>
    <div class="col-lg-7">
        <app-order-details [ordersList]="recentOrders"></app-order-details>
    </div>
</div>





<div class="row">
    <div class="col-lg-6">
        <nz-card style="height: 560px;">
            <div class="d-flex justify-content-between align-items-center">
                <h5>Most Viewed Product Name</h5>
            </div>
            <div class="m-t-30">
                <nz-table #mostViewedTable [nzData]="mostViewedList" [nzShowPagination]="false" class="no-border-last" [nzScroll]="{   y: '400px' }">
                    <thead>
                        <tr>
                            <th *ngFor="let column of mostViewedColumn" [nzWidth]="column.width">{{column?.title}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of mostViewedTable.data ; let i = index">
                            <!-- <td>{{i+1}}</td> -->
                            <td>
                                <div class="" nzWidth="80px">
                                    {{item.name}}
                                </div>
                            </td>
                            <td>
                                <!-- <nz-avatar nzShape="square" [nzSize]="50" nzIcon="picture"
                                    [nzSrc]="liveUrl+item?.images[0]?.image?.url"></nz-avatar> -->
                                <img class="table_image" nz-image [nzSrc]="liveUrl+item?.images[0]?.image?.url" alt="">
                            </td>
                            <td>{{item.viewedCount}}</td>
                        </tr>
                    </tbody>
                </nz-table>
            </div>
        </nz-card>
    </div>

    <div class="col-lg-6">
        <nz-card style="height: 560px;">
            <div class="d-flex justify-content-between align-items-center">
                <h5>Most Carted Product Name</h5>
            </div>
            <div class="m-t-30">
                <nz-table #mostCartedTable [nzData]="mostCartedList" [nzShowPagination]="false" class="no-border-last" [nzScroll]="{ y: '400px'}">
                    <thead>
                        <tr>
                            <th *ngFor="let column of mostCartedColumn" [nzWidth]="column.width">{{column.title}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of mostCartedTable.data ; let i = index">
                            <!-- <td>{{i+1}}</td> -->
                            <td>{{item.name}}</td>
                            <td>
                                <!-- <nz-avatar nzShape="square" [nzSize]="50" nzIcon="picture"
                                    [nzSrc]="liveUrl+item?.images?.image?.url"></nz-avatar> -->
                                <img class="table_image" nz-image [nzSrc]="liveUrl+item?.images?.image?.url" alt="">
                            </td>
                            <td>{{item.totalCarted}}</td>
                        </tr>
                    </tbody>
                </nz-table>
            </div>
        </nz-card>
    </div>
</div>

<div class="row">
    <!-- <div class="col-lg-6">
        <nz-card style="height: 560px;">
            <div class="d-flex justify-content-between align-items-center">
                <h5>Most Wishlisted Products</h5>
            </div>
            <div class="m-t-30">
                <nz-table #mostWishlistTable [nzData]="mostWishlistList" [nzShowPagination]="false"
                    class="no-border-last" [nzScroll]="{x: '400px' , y: '400px'}">
                    <thead>
                        <tr>
                            <th *ngFor="let column of mostWishlistColumn" [nzWidth]="column.width">{{column.title}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of mostWishlistTable.data ; let i = index">
                           /////// <td>{{i+1}}</td> //////
                            <td>{{item.name}}</td>
                            <td>
                                /////// <nz-avatar nzShape="square" [nzSize]="50" nzIcon="picture"
                                    [nzSrc]="liveUrl+item.images.image.url"></nz-avatar> //////
                                <img class="table_image" nz-image [nzSrc]="liveUrl+item?.images?.image?.url" alt="">
                            </td>
                            <td>{{item.totalWishlisted}}</td>
                        </tr>
                    </tbody>
                </nz-table>
            </div>
        </nz-card>
    </div> -->

    <div class="col-lg-6">
        <nz-card style="height: 560px;">
            <div class="d-flex justify-content-between align-items-center">
                <h5>Abandoned carts</h5>
            </div>
            <div class="m-t-30">
                <nz-table #abandonedCartsTable [nzData]="abandonedCarts" [nzShowPagination]="false" class="no-border-last" [nzScroll]="{x: '800px' , y: '400px'}">
                    <thead>
                        <tr>
                            <th *ngFor="let column of abandonedCartsColumn" [nzWidth]="column.width">{{column.title}}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of abandonedCartsTable?.data ; let i = index">
                            <!-- <td>{{i+1}}</td> -->
                            <td>
                                <div (click)="showProductDetails(item)" class="product-name">
                                    <span>{{item?.items[0]?.item?.name}}</span>
                                </div>
                            </td>
                            <td>
                                <!-- <nz-avatar nzShape="square" [nzSize]="50" nzIcon="picture"
                                    [nzSrc]="liveUrl+item?.images?.image?.url"></nz-avatar> -->
                                <img class="table_image" nz-image [nzSrc]="liveUrl+item?.items[0]?.item?.images[0]?.image?.url" alt="">
                            </td>

                            <td>{{item?.userId?.name}}</td>
                            <td>{{item?.userId?.phoneNo}}</td>
                            <td>{{item?.userId?.email}}</td>
                        </tr>
                    </tbody>
                </nz-table>
            </div>
        </nz-card>
    </div>

    <div class="col-lg-6">
        <nz-card style="height: 560px;">
            <div class="d-flex justify-content-between align-items-center">
                <h5>Low Quantity Products</h5>
            </div>
            <div class="m-t-30">
                <nz-table #lowQuantityProductsTable [nzData]="dashboardData.lowQuantityProducts" [nzShowPagination]="false" class="no-border-last" [nzScroll]="{x: '400px' , y: '400px'}">
                    <thead>
                        <tr>
                            <th *ngFor="let column of lowQuantityColumn" [nzWidth]="column.width">{{column.title}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of lowQuantityProductsTable.data ; let i = index">
                            <!-- <td>{{i+1}}</td> -->
                            <td>{{item?.name}}</td>
                            <!-- <td>
                                <nz-avatar nzShape="square" [nzSize]="50" nzIcon="picture"
                                    [nzSrc]="liveUrl+item.images.image.url"></nz-avatar>
                            </td> -->
                            <td>{{item?.quantity}}</td>
                        </tr>
                    </tbody>
                </nz-table>
            </div>
        </nz-card>
    </div>

</div>


<!-- country map -->
<!-- <nz-card>
    <highcharts-chart id="container" [Highcharts]="Highcharts" [constructorType]="chartConstructor"
        [options]="chartOptions" style="width: 50%; height: 400px; display: block;">
    </highcharts-chart>
</nz-card> -->




<nz-modal [(nzVisible)]="abandonedCartDetails" nzCentered nzTitle="Carted Items" (nzOnCancel)="handleCancel()">

    <div class="container-fluid abandonedCart-container" *nzModalContent>

        <div *ngFor="let cart of selectedCart" class="cart_items_main_div row">
            <div class="col-3">
                <img class="img-fluid preview_image_section" nz-image [nzSrc]="liveUrl + cart?.item?.images[0]?.image?.url" alt="">
            </div>
            <div class="col-9">
                <span>{{cart?.item?.name}}</span>
                <p>{{'Quantity : ' + cart?.quantity}}</p>
            </div>
        </div>

    </div>
    <ng-template class="modal_footer" *nzModalFooter>

    </ng-template>
</nz-modal>