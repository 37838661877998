<h3 *ngIf="showTable" class="category-heading-outside">Coupon Codes</h3>

<nz-card *ngIf="showTable">
    <div class="row">
        <div class="col-lg-8">
            <div class="d-md-flex">
                <div class="m-b-10 m-r-20">
                    <nz-input-group [nzPrefix]="prefixTemplate">
                        <input autocomplete="off" type="text" nz-input placeholder="Enter Coupon"
                            [(ngModel)]="searchInput" />
                    </nz-input-group>
                    <ng-template #prefixTemplate>
                        <i nz-icon nzType="search" class="opacity-05"></i>
                    </ng-template>
                </div>
            </div>
        </div>
        <div class="col-lg-4 text-right add-button-section">
            <a (click)="showForm()" nz-button class="save-button">
                <i nz-icon nzType="plus-circle" theme="outline"></i>
                <span>Add Coupen</span>
            </a>
        </div>
    </div>
    <nz-table #coupenListTable [nzData]="coupenList" [nzScroll]="{ y: '50vh' , x : '1350px' }"
        (nzQueryParams)="onPageNumberChange('couponTable')">
        <thead>
            <tr>
                <th *ngFor="let column of orderColumn" [nzWidth]="column.width">
                    {{ column.title }}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr id="couponTable" *ngFor="
          let item of coupenListTable.data | filter: searchInput;
          let i = index
        ">
                <!-- <td>{{ i + 1 }}</td> -->
                <td class="">
                    {{ item.name }}
                </td>
                <!-- <td>
                    <div class="">
                        <span>{{ item.type }}</span>
                    </div>
                </td> -->
                <td>
                    <!-- <nz-avatar nzShape="square" [nzSize]="50" nzIcon="picture"
              [nzSrc]="liveUrl+item?.images[0]?.image?.url"></nz-avatar> -->
                    <img class="table_image" nz-image [nzSrc]="liveUrl+item?.banner?.imageId?.image?.url" alt="">
                    <!-- {{liveUrl+item?.banner?.imageId?.image?.url}} -->
                </td>
                <td>
                    <div *ngIf="item.type == 'percentage-discount'" class="">
                        {{ item.value + "%" }}
                    </div>
                    <div *ngIf="item.type == 'price-discount'" class="">
                        {{'$ ' + item.value }}
                    </div>
                </td>
                <td>
                    <div class="">
                        <span>{{ item.type }}</span>
                    </div>
                </td>
                <td>
                    {{ moment(item.startDate).format("DD-MM-YYYY hh:mm a") }}
                </td>
                <td>
                    {{ moment(item.endDate).format("DD-MM-YYYY hh:mm a") }}
                </td>
                <td>
                    <span (click)="updateCoupen(item)" [ngClass]="{ 'un-published': !item.isActive }"
                        class="text-capitalize published">{{ item.isActive ? "Active" : "Inactive" }}</span>
                </td>
                <td>
                    {{ item.hasMaxOrderAmountValue}}
                </td>
                <td>
                    {{ item.maximumAllowedTimes}}
                </td>
            </tr>
        </tbody>
        <ngx-spinner *ngIf="!MediaVisible" bdColor="rgb(255 255 255 / 88%)" size="medium" color="#00f226"
            type="ball-spin-clockwise" [fullScreen]="false"></ngx-spinner>
    </nz-table>
</nz-card>

<nz-card *ngIf="!showTable" class="container">
    <div class="row justify-content-center">
        <div class="col-12 mb-3 title-text">
            <div class="">
                <h4>
                    <span class="back_icon" (click)="showForm()" nz-icon nzType="left" nzTheme="outline">
                    </span> Add New Coupen Code
                </h4>
            </div>
        </div>

        <form [formGroup]="addCoupenForm" (ngSubmit)="addCoupon()" nz-form>
            <div class="row justify-content-center">
                <div class="col-lg-7 left_column">
                    <nz-card class="form_fixed_height">
                        <div nz-row [nzGutter]="24">
                            <div nz-col [nzXs]="24" [nzSpan]="12">
                                <nz-form-item>
                                    <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired
                                        nzFor="coupon-code">Coupen Code
                                    </nz-form-label>
                                    <nz-form-control nzErrorTip="Please Enter Coupen Code!">
                                        <nz-input-group>
                                            <input autocomplete="off" formControlName="couponCode" type="text" nz-input
                                                id="coupon-code" placeholder="Enter Coupen Code" />
                                        </nz-input-group>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>

                            <div nz-col [nzXs]="24" [nzSpan]="12">
                                <nz-form-item>
                                    <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired
                                        nzFor="coupen-type">Select Type
                                    </nz-form-label>
                                    <nz-form-control nzErrorTip="Please Select Type!">
                                        <nz-input-group>
                                            <nz-select formControlName="coupenType" id="coupen-type" nzShowSearch
                                                class="w-100" nzPlaceHolder="Enter Coupen Type">
                                                <nz-option nzValue="price-discount"
                                                    nzLabel="Price Discount"></nz-option>
                                                <nz-option nzValue="percentage-discount"
                                                    nzLabel="Percentage Discount"></nz-option>
                                            </nz-select>
                                        </nz-input-group>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>

                            <div nz-col [nzXs]="24" [nzSpan]="12">
                                <nz-form-item>
                                    <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired
                                        nzFor="value">Value
                                    </nz-form-label>
                                    <nz-form-control nzErrorTip="Please Enter Valid Value!">
                                        <nz-input-group>
                                            <input autocomplete="off" min="1" formControlName="value" type="number"
                                                nz-input id="value" placeholder="Enter Value" />
                                        </nz-input-group>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>

                            <div nz-col [nzXs]="24" [nzSpan]="12">
                                <nz-form-item>
                                    <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired
                                        nzFor="BannerImage">Banner Image
                                    </nz-form-label>
                                    <nz-form-control nzErrorTip="Please input your username!">
                                        <nz-input-group>
                                            <button type="button" nz-button class="cancel-button" (click)="showMedia()">
                                                <span>Select from media</span>
                                            </button>
                                            <img *ngFor="let img of bannerImage" style="width: 70px; padding-left: 10px"
                                                src="{{ liveUrl + img.image.url }}" />
                                        </nz-input-group>
                                        <p class="image-size">Image Size: 1300px X 580px</p>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>
                    </nz-card>
                </div>

                <div class="col-lg-5 right_column">
                    <nz-card class="form_fixed_height">
                        <div nz-row [nzGutter]="24">
                            <div nz-col [nzXs]="12" [nzSpan]="12">
                                <nz-form-item>
                                    <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired
                                        nzFor="from-date">From
                                    </nz-form-label>
                                    <nz-form-control nzErrorTip="Please Select date!">
                                        <nz-input-group>
                                            <nz-date-picker nzShowTime nzFormat="dd-MM-yyyy h:mm:ss a"
                                                formControlName="fromDate" class="w-100" [nzRenderExtraFooter]=""
                                                nzPlaceHolder="Start Date"></nz-date-picker>
                                        </nz-input-group>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>

                            <div nz-col [nzXs]="12" [nzSpan]="12">
                                <nz-form-item>
                                    <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired
                                        nzFor="to-date">To
                                    </nz-form-label>
                                    <nz-form-control nzErrorTip="Please Select date!">
                                        <nz-input-group>
                                            <nz-date-picker nzShowTime nzFormat="dd-MM-yyyy h:mm:ss a"
                                                formControlName="toDate" class="w-100" [nzRenderExtraFooter]=""
                                                nzPlaceHolder="End Date"></nz-date-picker>
                                        </nz-input-group>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>

                            <div nz-col [nzXs]="24" [nzSpan]="12">
                                <nz-form-item>
                                    <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired
                                        nzFor="max-times">Maximum Allowed Times
                                    </nz-form-label>
                                    <nz-form-control nzErrorTip="Please Enter Value!">
                                        <nz-input-group>
                                            <input autocomplete="off" formControlName="maxAllowedTimes" type="number"
                                                nz-input id="max-times" placeholder="Enter Maximum Allowed Times" />
                                        </nz-input-group>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>

                            <div nz-col [nzXs]="24" [nzSpan]="12">
                                <nz-form-item>
                                    <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired
                                        nzFor="max-order">Maximum Order Value
                                    </nz-form-label>
                                    <nz-form-control nzErrorTip="Please Enter Value!">
                                        <nz-input-group>
                                            <input autocomplete="off" formControlName="maxOrderValue" type="number"
                                                nz-input id="max-order" placeholder="Enter Maximum Order Value" />
                                        </nz-input-group>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>

                            <div nz-col [nzXs]="24" [nzSpan]="12">
                                <nz-form-item>
                                    <nz-form-label nzRequired nzFor="coupon-active">Active</nz-form-label>
                                    <nz-form-control>
                                        <!-- <nz-input-group> -->
                                        <nz-switch formControlName="coupenActive" id="coupon-active"
                                            [nzCheckedChildren]="checkedTemplate"
                                            [nzUnCheckedChildren]="unCheckedTemplate"></nz-switch>
                                        <ng-template #checkedTemplate><i nz-icon nzType="check"></i></ng-template>
                                        <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i></ng-template>
                                        <!-- </nz-input-group> -->
                                    </nz-form-control>
                                </nz-form-item>
                            </div>

                            <div nz-col [nzXs]="24" [nzSpan]="12">
                                <nz-form-item class="text-center">
                                    <nz-form-control>
                                        <button (click)="showTableData()" type="button" class="mr-5" nz-button
                                            class="mr-3 cancel-button">
                                            Reset
                                        </button>
                                        <a><button type="Submit" [nzLoading]="isOkLoading" nz-button
                                                class="save-button">
                                                Create
                                            </button></a>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>
                    </nz-card>
                </div>
            </div>
        </form>
    </div>
</nz-card>

<!-- MEDIA PAGE -->

<nz-modal [nzStyle]="{ top: '10px' }" nzWidth="90%" [(nzVisible)]="MediaVisible" nzTitle="Banner Image" nzCentered
    (nzOnCancel)="handleCancel()" (nzOnOk)="sendMedia()" nzOkText="Set Banner Image">
    <div class="container-fluid" *nzModalContent>
        <app-media></app-media>
    </div>
</nz-modal>