import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { scrollToTop } from '../../shared/dummy';
import axios, { Axios } from 'axios';
import { NavService } from '../../services/nav.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.css'],
})
export class MediaComponent implements OnInit {
  @Output() imageSelected = new EventEmitter<any>();
  setBorder = false;
  imageurls = [];
  liveUrl = environment.url;

  gallery = [];
  searchInput: any = '';

  @Input('reqImages') reqImages = [];
  @Input('MaxSelect') MaxSelect = [];
  @Input() productID = '';
  pageIndex = 1;

  initialPage = true;

  addMedia = true;

  imageCopied: boolean = false;

  constructor(
    private router: Router,
    public navService: NavService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {}

  // selectedImage(a: any) {
  //   this.imageurls.splice(a, 1);
  //   console.log(a);
  // }
  fetchData() {
    console.log(this.productID);
    var productPageId: any = '';
    if (this.productID) {
      productPageId = '&isForProductId=' + this.productID;
    } else {
      productPageId = '';
    }
    axios
      .get(
        environment.url +
          'media?page=' +
          this.pageIndex +
          '&search=' +
          this.searchInput +
          productPageId,
        {}
      )
      .then((response) => {
        console.log(response.data.data);
        if (this.initialPage) {
          this.gallery = response.data.data;
        } else if (!this.initialPage) {
          this.gallery = this.gallery.concat(response.data.data);
        }

        // this.gallery = initialResult.map((data: any, index: number) => {
        //   return {
        //     name : data.name,
        //     logo : data.image.image.url,
        //     website : data.website

        //   }});
        //   console.log(this.productsList[0].logo);

        //console.log(response.data.status);
        this.spinner.hide();
      })
      .catch((error) => {
        console.log(error.response);
        this.spinner.hide();
      });
  }

  tempId: any = '';

  checkChange(event: any, imageId: any) {
    console.log(event.checked);
    if (event.checked) {
    }
    let currentUrl = this.router.url;
    if (currentUrl == '/media') {
      imageId.checked = false;
      return null;
    } else if (currentUrl.includes('/products')) {
      console.log(this.MaxSelect.indexOf(imageId));
      if (this.MaxSelect.length >= 4 && this.MaxSelect.indexOf(imageId) == -1) {
        alert('You Can Only Select 4 Images Maximum');
        // event.checked = false;
      } else if (this.MaxSelect.indexOf(imageId) == -1) {
        if (this.MaxSelect.length < 4) {
          this.MaxSelect.push(imageId);
        }
      } else {
        this.MaxSelect.splice(this.MaxSelect.indexOf(imageId), 1);
      }
      console.log(this.MaxSelect);
    } else if (
      currentUrl == '/brands' ||
      currentUrl == '/category' ||
      currentUrl == '/offer-page' ||
      currentUrl == '/banners' ||
      currentUrl == '/coupon-page'
    ) {
      if (this.MaxSelect.length >= 1 && this.MaxSelect.indexOf(imageId) == -1) {
        alert('You Can Only Select 1 Images Maximum');
        // event.checked = false;
        return false;
      } else if (this.MaxSelect.indexOf(imageId) == -1) {
        if (this.MaxSelect.length < 1) {
          this.MaxSelect.push(imageId);
        }
      } else {
        this.MaxSelect.splice(this.MaxSelect.indexOf(imageId), 1);
      }
    }

    // console.log(this.gallery);
    // console.log(this.reqImages);

    // for (var i = 0; i < this.gallery.length; i++) {
    //   for (var j = 0; j < this.MaxSelect.length; j++) {
    //     if (this.gallery[i]._id == this.MaxSelect[j]) {
    //       for (var k = 0; k < this.reqImages.length; k++) {
    //         if (this.gallery[i]._id == this.reqImages[k]._id) {
    //           this.reqImages.splice(k, 1);
    //         }
    //       }
    //       this.reqImages.push(this.gallery[i]);
    //     }
    //   }
    // }

    for (let s = 0; s < this.MaxSelect.length; s++) {
      var targetIndex = this.gallery.findIndex((e) => {
        return e._id === this.MaxSelect[s];
      });

      var temp = this.reqImages.findIndex((e) => {
        return e._id === this.gallery[targetIndex]._id;
      });

      if (temp === -1) {
        this.reqImages.push(this.gallery[targetIndex]);
      } else {
        this.reqImages.splice(temp, 1);
      }
    }

    console.log(this.reqImages);

    if (
      currentUrl == '/banners' ||
      currentUrl == '/category' ||
      currentUrl.includes('/products') ||
      currentUrl == '/brands' ||
      currentUrl == '/coupon-page' ||
      currentUrl == '/offer-page'
    ) {
      this.navService.publishFormRefresh(this.reqImages);
    }
    // } else if ()
    //   this.navService.publishFormRefresh(this.reqImages);
    // else if ()
    //   this.navService.publishFormRefresh(this.reqImages);
    // else if ()
    //   this.navService.publishFormRefresh(this.reqImages);
    // else if ()
    //   this.navService.publishFormRefresh(this.reqImages);
    // else if ()
    //   this.navService.publishFormRefresh(this.reqImages);
    // console.log('after', this.MaxSelect.length);
    // console.log(currentUrl);
  }

  newCheckChange(e: any, event: any, imageId: any) {
    let currentUrl = this.router.url;
    if (currentUrl == '/media') {
      e.preventDefault();
      e.stopPropagation();
    }
    console.log(event.checked);
    if (event.checked) {
      if (
        currentUrl == '/banners' ||
        currentUrl == '/category' ||
        currentUrl.includes('/products') ||
        currentUrl == '/brands' ||
        currentUrl == '/coupon-page' ||
        currentUrl == '/offer-page'
      ) {
        if (
          (this.MaxSelect.length < 4 && currentUrl.includes('/products')) ||
          (this.MaxSelect.length < 1 && currentUrl == '/banners') ||
          (this.MaxSelect.length < 1 && currentUrl == '/category') ||
          (this.MaxSelect.length < 1 && currentUrl == '/brands') ||
          (this.MaxSelect.length < 1 && currentUrl == '/coupon-page') ||
          (this.MaxSelect.length < 1 && currentUrl == '/offer-page')
        ) {
          if (this.MaxSelect.indexOf(imageId) === -1) {
            this.MaxSelect.push(imageId);
          }

          for (let s = 0; s < this.MaxSelect?.length; s++) {
            var targetIndex = this.gallery.findIndex((e) => {
              return e?._id === this.MaxSelect[s];
            });

            if (this.reqImages?.length == 0) {
              this.reqImages.push(this.gallery[targetIndex]);
            } else {
              var temp = this.reqImages?.findIndex((e) => {
                return e?._id === this?.gallery[targetIndex]?._id;
              });

              if (temp === -1 && targetIndex > -1) {
                this.reqImages?.push(this?.gallery[targetIndex]);
              }
            }
          }
        } else {
          if (
            this.MaxSelect?.length >= 4 &&
            currentUrl?.includes('/products')
          ) {
            alert('You Can Only Select 4 Images Maximum');
            e.preventDefault();
            e.stopPropagation();
          }
          if (
            (this.MaxSelect?.length >= 1 && currentUrl == '/banners') ||
            (this.MaxSelect?.length >= 1 && currentUrl == '/category') ||
            (this.MaxSelect?.length >= 1 && currentUrl == '/brands') ||
            (this.MaxSelect?.length >= 1 && currentUrl == '/coupon-page') ||
            (this.MaxSelect?.length >= 1 && currentUrl == '/offer-page')
          ) {
            alert('You Can Only Select 1 Images Maximum');
            e.preventDefault();
            e.stopPropagation();
          }

          // event.preventDefault();
        }
      }
    } else {
      if (this.MaxSelect.indexOf(imageId) > -1) {
        this.MaxSelect?.splice(this.MaxSelect?.indexOf(imageId), 1);
      }

      var temp = this.reqImages?.findIndex((e) => {
        return e._id === imageId;
      });
      if (temp > -1) {
        this.reqImages?.splice(temp, 1);
      }
    }

    console.log(this.MaxSelect);
    console.log(this.reqImages);

    if (
      currentUrl == '/banners' ||
      currentUrl == '/category' ||
      currentUrl.includes('/products') ||
      currentUrl == '/brands' ||
      currentUrl == '/coupon-page' ||
      currentUrl == '/offer-page'
    ) {
      this.navService.publishFormRefresh(this.reqImages);
    }
  }

  mediaPage = true;

  ngOnInit(): void {
    this.initialPage = true;
    this.pageIndex = 1;
    if (this.router.url.includes('/media')) {
      this.mediaPage = true;
    } else {
      this.mediaPage = false;
      this.addMedia = false;
    }
    scrollToTop();
    this.spinner.show();
    this.fetchData();
  }

  // UPLOAD FILE

  filesDropped(files: any): void {
    console.log(files);
    this.uploadImage(files, 'drag&drop');
  }

  uploadFile(ev) {
    console.log(ev.target.files);
    this.uploadImage(ev.target.files, 'direct');
    ev.target.value = '';
  }

  uploadImage(event, type) {
    console.log(event);
    // alert('entered');
    var formData = new FormData();
    console.log(event);
    for (var i = 0; i < event.length; i++) {
      if (type == 'drag&drop') {
        formData.append('images', event[i].file);
      } else if (type == 'direct') {
        formData.append('images', event[i]);
      }
    }
    this.spinner.show();
    axios
      .post(environment.url + 'media/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization:
            'Bearer ' + localStorage.getItem('admintokenoasisdata'),
        },
      })
      .then((response) => {
        console.log(response.data);

        if (response.data.message == 'media uploaded') {
          this.fetchData();
        }
        //this.gallery = response.data.data;
        // this.gallery = initialResult.map((data: any, index: number) => {
        //   return {
        //     name : data.name,
        //     logo : data.image.image.url,
        //     website : data.website

        //   }});
        //   console.log(this.productsList[0].logo);

        //console.log(response.data.status);
      })
      .catch((error) => {
        console.log(error.response);
        this.toastr.error(error.response.data.message, '', {
          timeOut: 3500,
        });
        this.spinner.hide();
      });
  }

  onScroll() {
    // alert('page end');
    this.initialPage = false;
    this.pageIndex = this.pageIndex + 1;
    this.fetchData();
  }

  searchImage() {
    this.initialPage = true;
    this.pageIndex = 1;
    this.fetchData();
  }

  copyUrl(event: any, imageData: any, id: any) {
    event.preventDefault();
    navigator.clipboard.writeText(id).then(
      () => {
        this.tempId = imageData;
        this.imageCopied = true;
        console.log(this.tempId);
        setTimeout(() => {
          this.imageCopied = false;
          this.tempId = '';
        }, 1000);
        // this.toastr.success('image id copied to clipboard', '', {
        //   timeOut: 2500,
        // });
      },
      (error) => {
        this.toastr.error('Browser do not support Clipboard API', '', {
          timeOut: 2500,
        });
      }
    );
  }
}
