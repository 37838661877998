import { SideNavInterface } from "../../interfaces/side-nav.type";
export const ROUTES: SideNavInterface[] = [
  {
    // Role Admin

    path: "dashboard",
    role: "Admin",
    title: "Dashboard",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "dashboard",
    submenu: [],
  },
  // {
  //   path: '/attribute',
  //   role: 'Admin',
  //   title: 'Attributes',
  //   iconType: 'nzIcon',
  //   iconTheme: 'outline',
  //   icon: 'solution',
  //   submenu: [],
  // },
  {
    path: "/recent-orders",
    role: "Admin",
    title: "Order List",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "unordered-list",
    submenu: [],
  },
  {
    path: "/payments",
    role: "Admin",
    title: "Payments",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "setting",
    submenu: [],
  },
  // {
  //   path: '/specification',
  //   role: 'Admin',
  //   title: 'Specification',
  //   iconType: 'nzIcon',
  //   iconTheme: 'outline',
  //   icon: 'folder',
  //   submenu: [
  //     // {
  //     //   path: '/specGroup',
  //     // role: 'Admin',
  //     //   title: 'Manage Group',
  //     //   iconType: 'nzIcon',
  //     //   iconTheme: 'outline',
  //     //   icon: 'group',
  //     //   submenu: [],
  //     // },
  //     // {
  //     //   path: '/specValue',
  //     // role: 'Admin',
  //     //   title: 'Manage Value',
  //     //   iconType: 'nzIcon',
  //     //   iconTheme: 'outline',
  //     //   icon: 'reconciliation',
  //     //   submenu: [],
  //     // },
  //   ],
  // },
  // {
  //   path: '/brands',
  //   role: 'Admin',
  //   title: 'Brands',
  //   iconType: 'nzIcon',
  //   iconTheme: 'outline',
  //   icon: 'tags',
  //   submenu: [],
  // },
  {
    path: "/category",
    role: "Admin",
    title: "Category",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "database",
    submenu: [],
  },
  {
    path: "/media",
    role: "Admin",
    title: "Media",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "file-image",
    submenu: [],
  },
  {
    path: "/products",
    role: "Admin",
    title: "Products",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "book",
    submenu: [],
  },
  {
    path: "/banners",
    role: "Admin",
    title: "Banners",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "picture",
    submenu: [],
  },
  {
    path: "/tags",
    role: "Admin",
    title: "Tags",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "tags",
    submenu: [],
  },
  {
    path: "/offer-page",
    role: "Admin",
    title: "Offer",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "percentage",
    submenu: [],
  },
  {
    path: "/coupon-page",
    role: "Admin",
    title: "Coupon Code",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "qrcode",
    submenu: [],
  },
  // {
  //   path: "/store-location",
  //   role: "Admin",
  //   title: "Service Center",
  //   iconType: "nzIcon",
  //   iconTheme: "outline",
  //   icon: "environment",
  //   submenu: [],
  // },
  // {
  //   path: "/build-pc-enquiry",
  //   role: "Admin",
  //   title: "Build PC Enquiry",
  //   iconType: "nzIcon",
  //   iconTheme: "outline",
  //   icon: "question-circle",
  //   submenu: [],
  // },

  {
    path: "/user-management",
    role: "Admin",
    title: "Users",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "user",
    submenu: [],
  },
  {
    path: "/customers",
    role: "Admin",
    title: "Customers",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "usergroup-add",
    submenu: [],
  },
  {
    path: "/policies",
    role: "Admin",
    title: "Policy",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "container",
    submenu: [],
  },
  {
    path: "/setting",
    role: "Admin",
    title: "Setting",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "setting",
    submenu: [],
  },

  // Role Delivery boy

  {
    path: "/recent-orders",
    role: "deliveryBoy",
    title: "Order List",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "unordered-list",
    submenu: [],
  },
];
