<h3 *ngIf="showTable" class="category-heading-outside outside_heading">Policies</h3>



<div class="row">
    <!-- form column -->
    <div class="col-lg-5">
        <nz-card class="fixed_height" *ngIf="showForm">
            <div class="row justify-content-center">
                <div class="col-12 form-details">
                    <div class="row justify-content-center">
                        <div class="col-12 title-text">
                            <div class="mb-3">
                                <h4>
                                    <span class="back_icon" (click)="showFormDetails()" nz-icon nzType="left"
                                        nzTheme="outline">
                                    </span>
                                    {{isEdit? 'Edit': 'Add New'}} Policy
                                </h4>
                            </div>
                        </div>

                        <div class="col-lg-12 form_main_div">
                            <form nz-form [formGroup]="addPolicyForm" (ngSubmit)="addUpdatePolicy()">
                                <div nz-row [nzGutter]="24">
                                    <div nz-col [nzXs]="24" [nzSpan]="12">
                                        <nz-form-item>
                                            <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired>
                                                Policy Name
                                            </nz-form-label>
                                            <nz-form-control nzErrorTip="Please Enter Policy Name!">
                                                <nz-input-group>
                                                    <input [disabled]="isEdit" [readOnly]="isEdit" formControlName="policyName"
                                                        autocomplete="off" type="text" nz-input id="CategoryName"
                                                        placeholder="Enter Category Name">
                                                </nz-input-group>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>

                                    <div class="policy_page_editor" nz-col [nzXs]="24" [nzSpan]="12">
                                        <!-- <nz-form-item> -->
                                        <nz-form-label class="large_label editor_column" [nzLg]="24" [nzSm]="6" nzRequired>
                                            Page Description
                                        </nz-form-label>
                                        <nz-form-control class="editor_column" nzErrorTip="Please Enter Page Description!">
                                            <!-- <nz-input-group> -->
                                            <angular-editor formControlName="description" [config]="config">
                                            </angular-editor>
                                            <!-- </nz-input-group> -->
                                        </nz-form-control>
                                        <!-- </nz-form-item> -->
                                    </div>

                                    <div nz-col [nzXs]="24" [nzSpan]="12" class="mt-3">
                                        <nz-form-item>
                                            <nz-form-label nzRequired>
                                                Active
                                            </nz-form-label>
                                            <nz-form-control>
                                                <nz-switch formControlName="isActive"
                                                    [nzCheckedChildren]="checkedTemplate"
                                                    [nzUnCheckedChildren]="unCheckedTemplate">
                                                </nz-switch>
                                                <ng-template #checkedTemplate><i nz-icon nzType="check"></i>
                                                </ng-template>
                                                <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i>
                                                </ng-template>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>

                                    <div nz-col [nzXs]="24" [nzSpan]="12">
                                        <nz-form-item class="text-center">
                                            <nz-form-control>
                                                <button (click)="resetForm()" type="button" class="mr-3 cancel-button"
                                                    nz-button nzType="default">Reset</button>
                                                <a>
                                                    <button [nzLoading]="addingPolicy" type="submit" nz-button
                                                        class="save-button">
                                                        {{!isEdit ? 'Create' : 'Update'}}
                                                    </button>
                                                </a>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </nz-card>
    </div>
    <div class="col-lg-7">
        <nz-card class="fixed_height" *ngIf="showTable">
            <div class="row">
                <div class="col-lg-4 text-left web-heading">
                    <h4>All Policies</h4>
                </div>
                <div class="col-lg-8 table_search">
                    <div class="d-md-flex">
                        <div class="m-b-10">
                            <nz-input-group [nzPrefix]="prefixTemplate">
                                <input autocomplete="off" type="text" nz-input placeholder="Enter Policy name"
                                    [(ngModel)]="searchInput">
                            </nz-input-group>
                            <ng-template #prefixTemplate>
                                <i nz-icon nzType="search" class="opacity-05"></i>
                            </ng-template>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 text-right add-button-section">
                    <a (click)="showFormDetails()" nz-button class="save-button">
                        <i nz-icon nzType="plus-circle" theme="outline"></i>
                        <span>Add Banners</span>
                    </a>
                </div>
            </div>
            <nz-table #allPoliciesTable [nzData]="allPolicies" [nzScroll]="{ y: '58vh', x : '500px'}">
                <thead>
                    <tr>
                        <th *ngFor="let column of orderColumn" [nzWidth]="column.width">{{ column.title }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr id="policyTable" *ngFor="let item of allPolicies | filter : searchInput;  let i=index">
                        <td>
                            <div (click)="editPolicy(item)" class="d-flex align-items-center brand-name">
                                {{item.name}}
                            </div>

                        </td>
                        <td class="">
                            <div class="description_section">
                                <div [innerHTML]="(item?.description?.length > 15 ? 
                                    (item?.description).slice(0,15) + '...' : item?.description)" class="description_html_div">
                                </div>
                                <a (click)="showDetailModal(item.description)">view detail..</a>
                            </div>

                        </td>
                        <td>
                            <span class="text-capitalize">
                                <nz-switch [nzLoading]="addingPolicy" [(ngModel)]="item.isActive"
                                    (ngModelChange)="directUpdate(item , $event)"></nz-switch>
                            </span>
                        </td>
                    </tr>
                </tbody>
                <!-- LOADER -->

                <ngx-spinner bdColor="rgb(255 255 255 / 88%)" size="medium" color="#00f226" type="ball-spin-clockwise"
                    [fullScreen]="false"></ngx-spinner>
            </nz-table>
        </nz-card>
    </div>
</div>


<nz-modal [(nzVisible)]="detailsModal" nzCentered nzTitle="Description" (nzOnCancel)="handleCancel()">
    <div class="description-container" *nzModalContent>
        <div [innerHTML]="singleDescription" class=""> </div>
    </div>
    <ng-template class="modal_footer" *nzModalFooter> </ng-template>
</nz-modal>