import { Component, OnInit } from "@angular/core";
import { TableService } from "../../shared/services/table.service";
import { scrollToTop } from "../../shared/dummy";
import { FormBuilder, FormGroup, NgForm } from "@angular/forms";
import axios, { Axios } from "axios";
import { NzModalService } from "ng-zorro-antd/modal";
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from "src/environments/environment";
import { ToastrService } from "ngx-toastr";

interface DataItem {
  name: string;
  value: any;
  feature: string;
  active: any;
}

@Component({
  selector: "app-attributes",
  templateUrl: "./attributes.component.html",
  styleUrls: ["./attributes.component.css"],
})
export class AttributesComponent {
  selectedCategory: string;
  selectedStatus: string;
  searchInput: any;
  displayData = [];
  formDetails: any;
  isEdit = false;
  requiredData = "";

  showTable: boolean = true;
  showForm: boolean = false;
  name: any;
  attValues = [];
  isFeatured = false;
  isActive = false;
  id = "";

  attrLoading: boolean;

  validateForm: FormGroup;

  orderColumn = [
    // {
    //   title: 'S.NO',
    //   width: '50px',
    // },
    {
      title: "Attribute Name",
      width: "80px",
      compare: (a: DataItem, b: DataItem) => a.name.localeCompare(b.name),
    },
    {
      title: "Value",
      width: "100px",
      compare: (a: DataItem, b: DataItem) => a.value - b.value,
    },
    {
      title: "Feature",
      width: "50px",
      compare: (a: DataItem, b: DataItem) => a.feature.localeCompare(b.feature),
    },
    {
      title: "Status",
      width: "70px",
      compare: (a: DataItem, b: DataItem) => a.active - b.active,
    },
  ];

  productsList: DataItem[] = [];

  constructor(
    private fb: FormBuilder,
    private modalService: NzModalService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {}

  fetchData() {
    this.spinner.show();
    axios
      .get(environment.url + "attributes", {})
      .then((response) => {
        console.log(response.data.data);
        this.productsList = response.data.data;
        this.spinner.hide();
        //console.log(response.data.status);
      })
      .catch((error) => {
        console.log(error.response);
        this.spinner.hide();
        //alert(error.response.data.message);
      });
  }

  categoryChange(value: string): void {
    const data = this.productsList;
    // value !== 'All'
    //   ? (this.displayData = data.filter((elm) => elm.name === value))
    this.displayData = data;
  }

  statusChange(value: string): void {
    const data = this.productsList;
    // value !== 'All'
    //   ? (this.displayData = data.filter((elm) => elm.feature === value))
    //   : (this.displayData = data);
    this.displayData = data;
  }

  ngOnInit(): void {
    // window.scroll(0, 0);
    this.formDetails = this.fb.group({
      name: [""],
      values: [""],
      isFeature: [""],
      isActive: [""],
    });
    scrollToTop();
    this.fetchData();

    if (window.innerWidth > 992) {
      this.showForm = true;
    }

    //this.validateForm = this.fb.group({});
  }

  showFormDetails() {
    let self = this;
    if (self.name == undefined || self.name == "") {
      // alert('Enter Attribute Name');
      self.requiredData = "name";
    } else if (self.attValues.length == 0) {
      // alert('Enter Attribute Values');
      self.requiredData = "value";
    } else {
      if (!self.isEdit) {
        self.attrLoading = true;
        var attributeData = {
          name: self.name,
          values: self.attValues,
          isFeature: self.isFeatured,
          isActive: self.isActive,
        };
        axios
          .post(environment.url + "attributes/create", attributeData, {
            headers: {
              Authorization:
                "Bearer " + localStorage.getItem("admintokenoasisdata"),
            },
          })
          .then((response) => {
            if (response.data.status == "success") {
              // console.log(response.data.data);
              self.toastr.success("Attribute Created!", "", {
                timeOut: 2500,
              });
              self.fetchData();
              self.clearForms();
              self.requiredData = "";
              self.attrLoading = false;
              if (window.innerWidth < 992) {
                self.showTable = !self.showTable;
                self.showForm = !self.showForm;
              }
              //  localStorage.setItem('admintokenoasisdata',response.data.data);
              //  self.router.navigate(['dashboard'])
            }
          })
          .catch((error) => {
            self.requiredData = "";
            self.attrLoading = false;
            let errorData = error.response.data.data;
            let errorString = "Please Check Below Error \n\n";
            if (error.response.data.data == undefined) {
              self.toastr.error("", error.response.data.message, {
                timeOut: 2500,
              });
            } else {
              self.toastr.error("", error.response.data.data[0].msg, {
                timeOut: 2500,
              });
            }
            // if (errorData) {
            //   for (let i = 0; i < errorData.length; i++) {
            //     errorString +=
            //       errorData[i].param + ' - ' + errorData[i].msg + '\n';
            //   }
            //   console.log(errorString);
            //   self.toastr.error(errorString, '', {
            //     timeOut: 2500,
            //   });
            // }
          });
      } else {
        self.attrLoading = true;
        var updateData = {
          id: self.id,
          name: self.name,
          values: self.attValues,
          isFeature: self.isFeatured,
          isActive: self.isActive,
        };
        axios
          .put(environment.url + "attributes/update", updateData, {
            headers: {
              Authorization:
                "Bearer " + localStorage.getItem("admintokenoasisdata"),
            },
          })
          .then((response) => {
            if (response.data.status == "success") {
              // console.log(response.data.data);
              self.attrLoading = false;
              self.toastr.success("Attribute Updated!", "", {
                timeOut: 2500,
              });
              self.fetchData();
              self.clearForms();
              self.requiredData = "";
              if (window.innerWidth < 992) {
                self.showTable = !self.showTable;
                self.showForm = !self.showForm;
              }
              //  localStorage.setItem('admintokenoasisdata',response.data.data);
              //  self.router.navigate(['dashboard'])
            }
          })
          .catch((error) => {
            console.log(error);
            self.requiredData = "";
            self.attrLoading = false;
            if (error.response.data.data == undefined) {
              self.toastr.error("", error.response.data.message, {
                timeOut: 2500,
              });
            } else {
              self.toastr.error("", error.response.data.data[0].msg, {
                timeOut: 2500,
              });
            }
            // let errorData = error.response.data.data;
            // let errorString = 'Please Check Below Error \n\n';
            // if (errorData) {
            //   for (let i = 0; i < errorData.length; i++) {
            //     errorString += errorData[i].param + ' - ' + errorData[i].msg + '\n';
            //   }
            //   alert(errorString);
            // }
          });
      }
    }
  }

  showTableDesign() {
    this.requiredData = "";
    this.isEdit = false;
    // this.clearForms();
    this.showTable = !this.showTable;
    this.showForm = !this.showForm;
  }

  editAttribute(data) {
    if (window.innerWidth < 992) {
      this.showTable = !this.showTable;
      this.showForm = !this.showForm;
    }
    this.isEdit = true;
    // this.clearForms();
    this.id = data._id;
    this.name = data.name;
    this.attValues = data.values;
    this.isFeatured = data.isFeature;
    this.isActive = data.isActive;
  }

  resetForm(): void {
    // this.validateForm.reset();
    this.modalService.confirm({
      nzTitle: "Confirm",
      nzContent: "Sure You Want to Reset ?",
      nzOkText: "Yes",
      nzCancelText: "No",
      nzOnOk: () => {
        // if (window.innerWidth > 992) {
        this.clearForms();
        this.isEdit = false;
        // } else {
        //   this.showTableDesign();
        // }
      },
    });
  }

  resetting() {
    this.showTable = !this.showTable;
    this.showForm = !this.showForm;
  }

  clearForms() {
    this.isEdit = false;
    this.name = "";
    this.attValues = [];
    this.isFeatured = false;
    this.isActive = false;
    this.id = "";
  }

  onPageNumberChange(event: any) {
    document.getElementById(event).scrollIntoView();
    window.scroll(0, 0);
  }
}
