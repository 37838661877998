<div class="switcher-wrapper" [ngClass]="{'opened-wrapper' : toggled}">
    <div (click)="toggleWrapper()" class="switcher-btn">
        <span nz-icon nzType="setting" nzTheme="outline"></span>
    </div>

    <div class="switcher-body">
        <div class="d-flex align-items-center justify-content-between mb-3">
            <h5 class="mb-0 text-uppercase">Theme Customizer</h5>
            <span (click)="toggleWrapper()" nz-icon nzType="close" nzTheme="outline"></span>
        </div>

        <h6 class="mb-3">Theme Style</h6>
        <div class="header-colors-indigators mb-3">
            <nz-radio-group [(ngModel)]="themeType" (ngModelChange)="themeChange()">
                <label nz-radio nzValue="light">Light</label>
                <label nz-radio nzValue="dark">Dark</label>
            </nz-radio-group>
        </div>

        <h6 class="mb-3">Header Background</h6>
        <div class="header-colors-indigators mb-3">
            <div class="row row-cols-auto g-3">
                <div *ngFor="let nav of allColors[0].headerColors" class="col-3">
                    <div (click)="changeColor(nav , '--header_background' ,'')" [style.backgroundColor]="nav.color"
                        class="indigator"></div>
                </div>
            </div>
        </div>

        <h6 class="mb-3">Side Nav Selected</h6>
        <div class="header-colors-indigators mb-3">
            <div class="row row-cols-auto g-3">
                <div *ngFor="let nav of allColors[0].sideNavColors" class="col-3">
                    <div (click)="changeColor(nav , '--primary_color' , 'sideNav')" [style.backgroundColor]="nav.color"
                        class="indigator"></div>
                </div>
            </div>
        </div>

        <h6 class="mb-3">Side Nav Background</h6>
        <div class="header-colors-indigators mb-3">
            <div class="row row-cols-auto g-3">
                <div *ngFor="let nav of allColors[0].sideNavColors" class="col-3">
                    <div (click)="changeColor(nav , '--side_nav_background' ,'sideNav')"
                        [style.backgroundColor]="nav.color" class="indigator"></div>
                </div>
            </div>
        </div>

        <div class="mt-3 text-center">
            <button (click)="resetTheme()" type="button" class="mr-3 cancel-button" nz-button
                nzType="default">Reset</button>
            <a>
                <button [nzLoading]="themeLoading" (click)="applyTheme()" type="button" nz-button
                    class="save-button">Apply
                </button>
            </a>
        </div>
    </div>
</div>