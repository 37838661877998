import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import axios, { Axios } from "axios";
import { ToastrService } from "ngx-toastr";
import { NavService } from "src/app/services/nav.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  passwordVisible = false;
  logging = false;

  // submitForm(): void {
  //     for (const i in this.loginForm.controls) {
  //         this.loginForm.controls[ i ].markAsDirty();
  //         this.loginForm.controls[ i ].updateValueAndValidity();
  //     }
  // }

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private navService: NavService
  ) {}

  ngOnInit(): void {
    // this.loginForm = this.fb.group({
    //     userName: [ null, [ Validators.required ] ],
    //     password: [ null, [ Validators.required ] ]
    // });
    localStorage.removeItem("admintokenoasisdata");
  }
  loggedIn() {
    if ((<HTMLInputElement>document.getElementById("username")).value == "") {
      this.toastr.error("", "Please Enter Email Id", {
        timeOut: 2500,
      });
    } else if (
      (<HTMLInputElement>document.getElementById("password")).value == ""
    ) {
      this.toastr.error("", "Please Enter Password", {
        timeOut: 2500,
      });
    } else {
      this.logging = true;
      var userDetails = {
        email: (<HTMLInputElement>document.getElementById("username")).value,
        password: (<HTMLInputElement>document.getElementById("password")).value,
        firebaseRegistrationToken: localStorage.getItem(
          "sgdfgdgqirbanmsdfgfjhsdfgkkfdhstafydiuscdagsyuker"
        ),
        platform: "web",
      };

      let self = this;

      axios
        .post(environment.url + "auth/login", userDetails)
        .then((response) => {
          // console.log(response);
          if (response.data.status == "success") {
            localStorage.setItem("admintokenoasisdata", response.data.data);
            localStorage.setItem(
              "kgahjegfygwfhjvbajsndfgjhjagjkhfteygfhygakjhfkghjfgasdkayetyfjhgd",
              response.data.role
            );
            if (response.data.role == "deliveryBoy") {
              self.router.navigate(["/recent-orders"]);
            } else {
              self.router.navigate(["/dashboard"]);
            }
            // self.router.navigate(['/dashboard']);
            self.logging = false;
          }
        })
        .catch((error) => {
          self.logging = false;
          console.log(error.response);
          if (error?.response?.data?.data == undefined) {
            self.toastr.error("", error?.response?.data?.message, {
              timeOut: 2500,
            });
          } else {
            self.toastr.error("", error.response.data.data[0].msg, {
              timeOut: 2500,
            });
          }
        });
      // console.log(userDetails);
    }
    //this.router.navigate(['dashboard'])
  }
}
