import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ThemeConstantService } from "src/app/shared/services/theme-constant.service";
import { scrollToTop } from "../../shared/dummy";
import axios, { Axios } from "axios";
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from "src/environments/environment";
import { Chart } from "chart.js";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import * as Highcharts from "highcharts/highmaps";
import * as worldMap from "@highcharts/map-collection/custom/world.geo.json";
import indiaMap from "../../../assets/indiamap";
import * as stateMap from "../../../assets/statemap.geo.json";
import { Router } from "@angular/router";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit {
  themeColors = this.colorConfig.get().colors;
  blue = this.themeColors.blue;
  blueLight = this.themeColors.blueLight;
  cyan = this.themeColors.cyan;
  cyanLight = this.themeColors.cyanLight;
  gold = this.themeColors.gold;
  purple = this.themeColors.purple;
  purpleLight = this.themeColors.purpleLight;
  red = this.themeColors.red;
  liveUrl = environment.url;
  date = null;
  moment: any = moment;
  // CUSTOM
  monthlySales = [];
  chartReady = false;
  showChart = false;
  @ViewChild("myChart") myChart: ElementRef;

  selectedCart: any;

  dashboardSpinner = false;

  dashboardData: any = {};
  // MAP DATA

  constructor(
    private colorConfig: ThemeConstantService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router
  ) {}

  // MAP DATA

  Highcharts: typeof Highcharts = Highcharts;
  chartConstructor = "mapChart";
  chartData = [
    { code3: "ABW", z: 105 },
    { code3: "AFG", z: 35530 },
  ];

  chartOptions: Highcharts.Options = {
    chart: {
      map: indiaMap,
    },
    title: {
      text: "Sales Data",
    },
    // subtitle: {
    //   text: 'Source map: <a href="http://code.highcharts.com/mapdata/custom/world.js">World, Miller projection, medium resolution</a>',
    // },
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        alignTo: "spacingBox",
      },
    },
    legend: {
      enabled: true,
    },
    colorAxis: {
      min: 0,
    },
    series: [
      {
        type: "map",
        name: "Random data",
        states: {
          hover: {
            color: "rgb(3 80 10 / 90%)",
          },
        },
        dataLabels: {
          enabled: true,
          format: "{point.name}",
        },
        allAreas: false,
        data: [
          ["in-py", 0],
          ["in-ld", 1],
          ["in-wb", 2],
          ["in-or", 3],
          ["in-br", 4],
          ["in-sk", 5],
          ["in-ct", 6],
          ["in-tn", 7],
          ["in-mp", 8],
          ["in-2984", 9],
          ["in-ga", 10],
          ["in-nl", 11],
          ["in-mn", 12],
          ["in-ar", 13],
          ["in-mz", 14],
          ["in-tr", 15],
          ["in-3464", 16],
          ["in-dl", 17],
          ["in-hr", 18],
          ["in-ch", 19],
          ["in-hp", 20],
          ["in-jk", 21],
          ["in-kl", 22],
          ["in-ka", 23],
          ["in-dn", 24],
          ["in-mh", 25],
          ["in-as", 26],
          ["in-ap", 27],
          ["in-ml", 28],
          ["in-pb", 29],
          ["in-rj", 30],
          ["in-up", 31],
          ["in-ut", 32],
          ["in-jh", 33],
        ],
      },
    ],
  };

  salesChartOptions: any = {
    scaleShowVerticalLines: false,
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          display: true,
          scaleLabel: {
            display: false,
            labelString: "Month",
          },
          gridLines: false,
          ticks: {
            display: true,
            beginAtZero: true,
            fontSize: 13,
            padding: 10,
          },
        },
      ],
      yAxes: [
        {
          display: true,
          scaleLabel: {
            display: false,
            labelString: "Value",
          },
          gridLines: {
            drawBorder: false,
            offsetGridLines: false,
            drawTicks: false,
            borderDash: [3, 4],
            zeroLineWidth: 1,
            zeroLineBorderDash: [3, 4],
          },
          ticks: {
            max: 200000,
            stepSize: 40000,
            display: true,
            beginAtZero: true,
            fontSize: 13,
            padding: 10,
          },
        },
      ],
    },
  };
  salesChartLabels: string[] = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  salesChartType = "bar";
  salesChartColors: Array<any> = [
    {
      backgroundColor: this.themeColors.blue,
      borderWidth: 0,
    },
  ];

  count: any;
  mostViewedProducts: any[] = [];
  mostCartedList: any[] = [];
  mostWishlistList: any[] = [];
  mostViewedList: any = [];

  productsList: any[] = [];
  recentOrders: any = [];

  chartBackgroundcolors: any = [
    {
      backgroundColor: [
        "#3D8FEE",
        "#616eb2",
        "#4ebef7",
        "#4dccde",
        "#b45dc3",
        "#ee5688",
        "#ffb251",
        "#48afa4",
        "#4EBEF7",
        "#616EB2",
        "#4DCCDE",
        "#B45DC3",
        "#FFB251",
        "#48AFA4",
        "#00D3F4",
        "#00E5DD",
        "#5DF1B7",
        "#ADF88E",
        "#F9F871",
        "#A62E70",
        "#7B8BDD",
        "#F360A9",
        "#FF7788",
        "#FF9F6B",
        "#FFCC5E",
        "#007FDB",
        "#00827A",
        "#D1A6D6",
        "#AC855B",
        "#005248",
        "#76D4C1",
        "#EB2C73",
        "#FF8983",
        "#CCCCCC",
        "#FF64C8",
        "#F36262",
        "#DA7B4C",
        "#9F5B61",
        "#3CCFCF",
        "#BFA5A6",
        "#A2697D",
      ],
      pointBackgroundColor: [
        "#3D8FEE",
        "#616eb2",
        "#4ebef7",
        "#4dccde",
        "#b45dc3",
        "#ee5688",
        "#ffb251",
        "#48afa4",
        "#4EBEF7",
        "#616EB2",
        "#4DCCDE",
        "#B45DC3",
        "#FFB251",
        "#48AFA4",
        "#00D3F4",
        "#00E5DD",
        "#5DF1B7",
        "#ADF88E",
        "#F9F871",
        "#A62E70",
        "#7B8BDD",
        "#F360A9",
        "#FF7788",
        "#FF9F6B",
        "#FFCC5E",
        "#007FDB",
        "#00827A",
        "#D1A6D6",
        "#AC855B",
        "#005248",
        "#76D4C1",
        "#EB2C73",
        "#FF8983",
        "#CCCCCC",
        "#FF64C8",
        "#F36262",
        "#DA7B4C",
        "#9F5B61",
        "#3CCFCF",
        "#BFA5A6",
        "#A2697D",
      ],
    },
  ];

  // MOST CARTED PRODUCTS CHART

  public cartedByDistrictChartLabels: string[] = [];
  public cartedByDistrictChartData: number[] = [];
  public cartedByDistrictChartType: Chart.ChartType = "doughnut";
  public cartedByDistrictChartOptions: any = {
    cutoutPercentage: 60,
    maintainAspectRatio: false,
  };

  CaretedByDistrictData: any = [];

  // ORDERED PRODUCTS CHART

  public ordersByDistrictChartLabels: string[] = [];
  public ordersByDistrictChartData: number[] = [];
  public ordersByDistrictChartType: Chart.ChartType = "doughnut";
  public ordersByDistrictChartOptions: any = {
    cutoutPercentage: 60,
    maintainAspectRatio: false,
  };

  ordersByDistrictData: any = [];
  abandonedCarts: any = [];

  // TABLE DETAILS

  mostViewedColumn: any = [
    {
      title: "Name",
      width: "80px",
    },
    {
      title: "Image",
      width: "50px",
    },
    {
      title: "View Count",
      width: "60px",
    },
  ];

  mostCartedColumn: any = [
    {
      title: "Name",
      width: "80px",
    },
    {
      title: "Image",
      width: "50px",
    },
    {
      title: "Count",
      width: "50px",
    },
  ];

  mostWishlistColumn: any = [
    {
      title: "Name",
      width: "80px",
    },
    {
      title: "Image",
      width: "50px",
    },
    {
      title: "Count",
      width: "50px",
    },
  ];

  abandonedCartsColumn: any = [
    // {
    //   title: 'S.no',
    //   width: '40px',
    // },
    {
      title: "Products",
      width: "100px",
    },
    {
      title: "Name",
      width: "80px",
    },

    {
      title: "image",
      width: "80px",
    },
    {
      title: "Mobile Number",
      width: "80px",
    },
    {
      title: "Email",
      width: "120px",
    },
  ];

  lowQuantityColumn: any = [
    {
      title: "Product Name",
      width: "150px",
    },
    {
      title: "Quantity",
      width: "80px",
    },
  ];

  salesChartData;

  abandonedCartDetails: boolean;

  ngOnInit(): void {
    // console.log('OnInit');
    // scrollToTop();
    window.scroll(0, 0);
    this.fetchData();
  }

  fetchData() {
    var self = this;
    self.dashboardSpinner = true;
    self.spinner.show();
    axios
      .get(environment.url + "dashboard", {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("admintokenoasisdata"),
        },
      })
      .then((response) => {
        self.dashboardData = response.data.data;
        self.recentOrders = response.data.data.recentOrders.map(
          (data: any, index: number) => {
            return {
              id: data._id,
              orderId: data.orderId,
              name: data?.userId?.name,
              date: data.orderedDate,
              amount: data.orderTotal,
              status: data.shipping.tracking.status,
              address: data?.shipping?.contact?.address,
              paymentMode: data.paymentType,
              contact: data?.shipping?.contact?.contactPhone,
              products: data.products,
              orderDate: data?.orderedDate,
              shipping: data?.shipping,
              paymentType: data?.paymentType,
            };
          }
        );
        console.log(response.data.data);

        // CARTED BY DISTRICT CHART DATA
        this.CaretedByDistrictData = response?.data?.data?.cartedByTown;
        for (let i = 0; i < this.CaretedByDistrictData?.length; i++) {
          this.cartedByDistrictChartLabels.push(
            this.CaretedByDistrictData[i]?.district[0]?.replaceAll(
              "District",
              ""
            )
          );
          this.cartedByDistrictChartData.push(
            this.CaretedByDistrictData[i]?.totalItems
          );
          this.CaretedByDistrictData[i].colors =
            this.chartBackgroundcolors[0]?.backgroundColor[i];
        }
        // WISHLISTED BY DISTRICT CHART DATA
        this.ordersByDistrictData = response?.data?.data?.ordersByTown;
        for (let i = 0; i < this.ordersByDistrictData?.length; i++) {
          this.ordersByDistrictChartLabels.push(
            this.ordersByDistrictData[i]?.district[0]?.replaceAll(
              "District",
              ""
            )
          );
          this.ordersByDistrictChartData.push(
            this.ordersByDistrictData[i]?.totalOrders
          );
          this.ordersByDistrictData[i].colors =
            this.chartBackgroundcolors[0].backgroundColor[i];
        }
        this.showChart = true;
        // console.log(this.CaretedByDistrictData);
        self.mostViewedList = response.data?.data?.mostViewedProducts;
        self.mostCartedList = response.data?.data?.productsWithCarted;
        self.mostWishlistList = response?.data?.data?.productsWithWishlisted;
        self.monthlySales = response.data?.data?.overAllMonthSales;
        self.abandonedCarts = response.data.data.abandonedCart;

        self.count = response.data.data;
        self.productsList = response.data?.data?.count?.mostViewedProducts;
        // self.spinner.hide();
        // console.log(self.monthlySales);
        self.salesChartData = new Chart("salesChartData", {
          type: "bar",
          data: {
            labels: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
            datasets: [
              {
                label: "Sales",
                categoryPercentage: 0.35,
                backgroundColor: this.themeColors.blue,
                barPercentage: 1,
                data: [],
              },
            ],
          },
          options: {
            scaleShowVerticalLines: false,
            maintainAspectRatio: false,
            responsive: true,
            legend: false,
            scales: {
              xAxes: [
                {
                  display: true,
                  scaleLabel: {
                    display: false,
                    labelString: "Month",
                  },
                  gridLines: false,
                  ticks: {
                    display: true,
                    beginAtZero: true,
                    fontSize: 13,
                    padding: 10,
                  },
                },
              ],
              yAxes: [
                {
                  display: true,
                  scaleLabel: {
                    display: false,
                    labelString: "Value",
                  },
                  gridLines: {
                    drawBorder: false,
                    offsetGridLines: false,
                    drawTicks: false,
                    borderDash: [3, 4],
                    zeroLineWidth: 1,
                    zeroLineBorderDash: [3, 4],
                  },
                  ticks: {
                    max: 10000,
                    stepSize: 500,
                    display: true,
                    beginAtZero: true,
                    fontSize: 13,
                    padding: 10,
                  },
                },
              ],
            },
          },
        });

        self.salesChartData.data.datasets[0].data = this.monthlySales;
        self.salesChartData.update();
        // self.spinner.hide();
        // self.dashboardSpinner = false;
      })
      .catch((error) => {
        console.log(error.response);
        // self.spinner.hide();
        // self.dashboardSpinner = false;
      });
  }

  onDateChange(dateSelected: Date) {
    console.log(
      moment(dateSelected[0]).format() +
        "  to  " +
        moment(dateSelected[1]).format()
    );
    axios
      .post(
        environment.url + "dashboard/date-filter",
        {
          fromDate: moment(dateSelected[0]).format(),
          toDate: moment(dateSelected[1]).format(),
        },
        {
          headers: {
            Authorization:
              "Bearer " + localStorage.getItem("admintokenoasisdata"),
          },
        }
      )
      .then((response) => {
        // console.log(response.data);
        this.count.overAllSales = response.data.data.overAllSales;
        this.count.count.totalOrders = response.data.data.count.orders;
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response.data.data == undefined) {
          this.toastr.error("", error.response.data.message, {
            timeOut: 2500,
          });
        } else {
          this.toastr.error("", error.response.data.data[0].msg, {
            timeOut: 2500,
          });
        }
      });
  }

  showProductDetails(cartData: any) {
    this.selectedCart = cartData.items;
    this.abandonedCartDetails = true;
  }

  handleCancel() {
    this.abandonedCartDetails = false;
    this.selectedCart = [];
  }

  resetDate() {
    this.date = null;
    window.location.reload();
  }

  toTotalOrders() {
    this.router.navigate(["/recent-orders"]);
  }
  toTodayOrders() {
    this.router.navigate(["/recent-orders", "today"]);
  }
}
