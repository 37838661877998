import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { scrollToTop } from '../../shared/dummy';
import axios, { Axios } from 'axios';
import { NzModalService } from 'ng-zorro-antd/modal';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

interface DataItem {
  id: any;
  name: string;
  active: any;
  createdBy: any;
  createdAt: any;
  lastUpdateBy: any;
  lastUpdateAt: any;
}

@Component({
  selector: 'app-spec-group',
  templateUrl: './spec-group.component.html',
  styleUrls: ['./spec-group.component.css'],
})
export class SpecGroupComponent implements OnInit {
  selectedCategory: string;
  selectedStatus: string;
  searchInput: any = '';
  displayData = [];

  showTable: boolean = true;
  showForm: boolean = false;

  validateForm: FormGroup;
  showSpinner = false;

  specName = '';
  isActive = false;
  id = '';
  isEdit = false;
  requiredData = '';

  specLoading: boolean;

  pageIndex = 1;
  totalCount: number = 0;
  pageSize: number = 0;

  directMode: boolean;

  fetchData() {
    this.showSpinner = true;
    this.spinner.show();
    axios
      .get(
        environment.url +
          'specGroup/get?page=' +
          this.pageIndex +
          '&search=' +
          this.searchInput,
        {
          headers: {
            Authorization:
              'Bearer ' + localStorage.getItem('admintokenoasisdata'),
          },
        }
      )
      .then((response) => {
        console.log(response.data.data);
        this.totalCount = response?.data?.pagination?.totalCount;
        this.pageSize = response?.data?.pagination?.limit;
        this.productsList = response.data.data.map(
          (data: any, index: number) => {
            return {
              id: data._id,
              name: data.name,
              active: data.isActive,
              createdBy: data.createdBy,
              createdAt: data.createdAt,
              lastUpdateBy: data.lastUpdatedBy,
              lastUpdateAt: data.lastUpdatedAt,
            };
          }
        );
        this.showSpinner = false;
        this.spinner.hide();
        //console.log(response.data.status);
      })
      .catch((error) => {
        console.log(error);
        this.showSpinner = false;
        this.spinner.hide();
        //alert(error.response.data.message);
      });
  }

  orderColumn = [
    // {
    //   title: 'S.NO',
    //   width: '40px',
    // },
    {
      title: 'Group Name',
      width: '80px',
      compare: (a: DataItem, b: DataItem) => a.name.localeCompare(b.name),
    },
    {
      title: 'Status',
      width: '80px',
      compare: (a: DataItem, b: DataItem) => a.active - b.active,
    },
    // {
    //   title: 'Created By',
    //   compare: (a: DataItem, b: DataItem) => a.createdBy - b.createdBy,
    // },
    // {
    //   title: 'Created At',
    //   compare: (a: DataItem, b: DataItem) => a.createdAt - b.createdAt,
    // },
    // {
    //   title: 'Last Updated By',
    //   compare: (a: DataItem, b: DataItem) => a.lastUpdateBy - b.lastUpdateBy,
    // },
    // {
    //   title: 'Last Updated At',
    //   compare: (a: DataItem, b: DataItem) => a.lastUpdateAt - b.lastUpdateAt,
    // },
    // {
    //   title: 'Action',
    // },
  ];
  productsList: DataItem[] = [];

  // constructor(private tableSvc : TableService) {
  //     this.displayData = this.productsList
  // }

  // search(): void {
  //     const data = this.productsList
  //     this.displayData = this.tableSvc.search(this.searchInput, data )
  // }
  constructor(
    private fb: FormBuilder,
    private modalService: NzModalService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {}

  categoryChange(value: string): void {
    const data = this.productsList;
    // value !== 'All'
    //   ? (this.displayData = data.filter((elm) => elm.name === value))
    this.displayData = data;
  }

  statusChange(value: string): void {
    const data = this.productsList;
    // value !== 'All'
    //   ? (this.displayData = data.filter((elm) => elm.feature === value))
    //   : (this.displayData = data);
    this.displayData = data;
  }

  ngOnInit(): void {
    scrollToTop();
    this.fetchData();
    this.validateForm = this.fb.group({});
  }

  showFormDetails() {
    this.requiredData = '';
    this.isEdit = false;
    this.directMode = false;
    this.clearFields();
    this.showTable = !this.showTable;
    this.showForm = !this.showForm;
  }

  resetForm(): void {
    // this.validateForm.reset();
    this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Sure You Want to Cancel ?',
      nzOkText: 'Yes',
      nzCancelText: 'No',
      nzOnOk: () => this.clearFields(),
    });
  }
  doChanges() {
    var self = this;
    //alert(self.isActive);
    if (self.specName == '') {
      // alert('Enter Spec Name');
      self.requiredData = 'name';
    } else {
      if (!self.isEdit) {
        var specGroupDetails = {
          name: self.specName,
          isActive: self.isActive,
        };
        self.specLoading = true;
        axios
          .post(environment.url + 'specGroup/create', specGroupDetails, {
            headers: {
              Authorization:
                'Bearer ' + localStorage.getItem('admintokenoasisdata'),
            },
          })
          .then((response) => {
            if (response.data.status == 'success') {
              console.log(response.data.data);
              self.toastr.success('Specification Group Added!', '', {
                timeOut: 2500,
              });
              self.requiredData = '';
              if (!self.directMode) {
                self.fetchData();
                self.showTable = !self.showTable;
                self.showForm = !self.showForm;
              }
              self.directMode = false;

              self.specName = '';
              self.isActive = false;
              self.specLoading = false;
              //  localStorage.setItem('admintokenoasisdata',response.data.data);
              //  self.router.navigate(['dashboard'])
            }
          })
          .catch((error) => {
            self.specLoading = false;
            if (self.directMode) {
              self.fetchData();
            }
            if (error.response.data.data == undefined) {
              self.toastr.error('', error.response.data.message, {
                timeOut: 2500,
              });
            } else {
              self.toastr.error('', error.response.data.data[0].msg, {
                timeOut: 2500,
              });
            }
          });
      } else {
        var specGroupDetail = {
          id: self.id,
          name: self.specName,
          isActive: self.isActive,
        };
        self.specLoading = true;
        axios
          .post(environment.url + 'specGroup/update', specGroupDetail, {
            headers: {
              Authorization:
                'Bearer ' + localStorage.getItem('admintokenoasisdata'),
            },
          })
          .then((response) => {
            if (response.data.status == 'success') {
              console.log(response.data.data);
              self.isEdit = false;
              self.toastr.success('Specification Group Updated!', '', {
                timeOut: 2500,
              });
              self.requiredData = '';
              if (!self.directMode) {
                self.fetchData();
                self.showTable = !self.showTable;
                self.showForm = !self.showForm;
              }
              self.directMode = false;

              self.specName = '';
              self.isActive = false;
              self.specLoading = false;
              //  localStorage.setItem('admintokenoasisdata',response.data.data);
              //  self.router.navigate(['dashboard'])
            }
          })
          .catch((error) => {
            if (self.directMode) {
              self.fetchData();
            }
            self.directMode = false;
            self.specLoading = false;
            if (error.response.data.data == undefined) {
              self.toastr.error('', error.response.data.message, {
                timeOut: 2500,
              });
            } else {
              self.toastr.error('', error.response.data.data[0].msg, {
                timeOut: 2500,
              });
            }
          });
      }
    }
  }
  editSpecGroup(item) {
    var self = this;
    self.directMode = false;
    self.clearFields();
    self.specName = item.name;
    self.id = item.id;
    self.isActive = item.active;
    self.isEdit = true;
    self.showTable = !self.showTable;
    self.showForm = !self.showForm;
  }
  clearFields() {
    var self = this;
    self.specName = '';
    self.isActive = false;
  }

  directUpdate(selected: any, event: any) {
    var self = this;
    self.clearFields();
    self.specName = selected.name;
    self.id = selected.id;
    self.isActive = selected.active;
    self.isEdit = true;

    self.directMode = true;
    self.doChanges();
  }

  onPageNumberChange(id: any, event: any) {
    if (event.pageIndex != this.pageIndex) {
    this.pageIndex = event.pageIndex;
    this.fetchData();
    document.getElementById(id).scrollIntoView();
    window.scroll(0, 0);
    }
  }

  searchSpecGroup() {
    this.pageIndex = 1;
    this.fetchData();
  }
}
