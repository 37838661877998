<nz-card class="fixed_height_with_heading" *ngIf="showTable">
    <div class="row justify-content-between">
        <div class="col-lg-6 col-md-6">
            <div class="d-md-flex">
                <div class="m-b-10 m-r-20">
                    <nz-input-group [nzPrefix]="prefixTemplate">
                        <input (keyup)="searchSpecValue()" autocomplete="off" type="text" nz-input
                            placeholder="Enter Value Name" [(ngModel)]="searchInput">
                    </nz-input-group>
                    <ng-template #prefixTemplate>
                        <i nz-icon nzType="search" class="opacity-05"></i>
                    </ng-template>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 text-right ">
            <a (click)="showFormDetails()" nz-button class="save-button">
                <i nz-icon nzType="plus-circle" theme="outline"></i>
                <span>Add Spec Value</span>
            </a>
        </div>
    </div>
    <nz-table
        [nzFooter]="totalCount == 0 ? '' : 'Showing ' +  (((pageSize * pageIndex) - pageSize) + 1) + ' to ' +  (pageSize * pageIndex > totalCount ? totalCount : pageSize * pageIndex) +  ' of ' +  totalCount"
        #productsListTable [nzData]="productsList" [nzScroll]="{ y: '50vh', x:'450px' }" [nzFrontPagination]="false"
        [nzTotal]="totalCount" [nzPageSize]="pageSize" [nzPageIndex]="pageIndex"
        (nzQueryParams)="onPageNumberChange('specValueTable',$event)">
        <thead>
            <tr>
                <th *ngFor="let column of orderColumn" [nzWidth]="column.width">{{ column.title }}</th>
            </tr>
        </thead>
        <tbody>
            <tr id="specValueTable" *ngFor="let item of productsListTable.data;  let i=index">
                <!-- <td>{{i+1}}</td> -->
                <td>
                    <div (click)="editSpecValues(item)" class="spec-value">
                        {{item.name}}
                    </div>
                </td>
                <td>{{item.type}}</td>
                <td>
                    <nz-switch [nzLoading]="specLoading" [(ngModel)]="item.showOnFilter"
                        (ngModelChange)="directUpdate(item , $event)"></nz-switch>
                </td>
            </tr>
        </tbody>

        <ngx-spinner *ngIf="specSpinner" bdColor="rgb(255 255 255 / 88%)" size="medium" color="#00f226"
            type="ball-spin-clockwise" [fullScreen]="false">
        </ngx-spinner>
    </nz-table>
</nz-card>

<!-- (ngModelChange)="search()" Goes to line 7 in top -->

<!-- ADD SPECIFICATION VALUE FORM DESIGN STARTS  -->

<nz-card class="fixed_height_with_heading" *ngIf="showForm">
    <div class="row">
        <div class="col-12 mb-3 title-text text-left">
            <div class="">
                <h4>
                    <span class="back_icon" (click)="showFormDetails()" nz-icon nzType="left" nzTheme="outline">
                    </span>
                    {{defaultMode == 'Add' ? 'Add New' : 'Edit'}} Specification Value
                </h4>
                <!-- <h4 *ngIf="defaultMode == 'Edit'"> Specification Value</h4> -->
            </div>
        </div>

        <div class="col-lg-12">
            <form nz-form>
                <nz-form-item>
                    <nz-form-label class="large_label" [nzSm]="24" nzRequired nzFor="ValueName">Name</nz-form-label>
                    <nz-form-control nzErrorTip="Please input your username!">
                        <nz-input-group>
                            <input autocomplete="off" type="text" [(ngModel)]="specValueName"
                                [ngModelOptions]="{standalone: true}" nz-input id="ValueName"
                                placeholder="Enter Spec Value Name">
                        </nz-input-group>
                        <p class="error-message" *ngIf="requiredData == 'name'">*Please Enter Spec Value Name</p>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label class="large_label" [nzSm]="24" nzRequired nzFor="ValueType">Type</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <nz-select id="ValueType" [(ngModel)]="specType" [ngModelOptions]="{standalone: true}"
                                nzShowSearch class="w-100" nzPlaceHolder="None">
                                <nz-option nzValue="Value" nzLabel="Value"></nz-option>
                                <nz-option nzValue="Description" nzLabel="Description"></nz-option>
                            </nz-select>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item *ngIf="specType == 'Value'">
                    <nz-form-label class="large_label" [nzSm]="24" nzRequired nzFor="ValueAccepted">Accepted Values
                    </nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <nz-select nzMode="tags" [(ngModel)]="acceptedValues" [ngModelOptions]="{standalone: true}"
                                id="ProductTags" nzShowSearch class="w-100" nzPlaceHolder="Enter Values">
                            </nz-select>
                        </nz-input-group>
                        <p class="error-message" *ngIf="requiredData == 'value'">*Please Enter Accepted Values</p>
                    </nz-form-control>
                </nz-form-item>

                <!-- <nz-form-item *ngIf="specType == 'Description'">
                    <nz-form-label class="large_label" [nzSm]="24" nzRequired>Description
                    </nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <input autocomplete="off" type="text" [(ngModel)]="specDescription"
                                [ngModelOptions]="{standalone: true}" nz-input placeholder="Enter Spec Value Name">
                        </nz-input-group>
                        <p class="error-message" *ngIf="requiredData == 'description'">*Please Enter Description</p>
                    </nz-form-control>
                </nz-form-item> -->

                <nz-form-item>
                    <nz-form-label nzRequired nzFor="ValueFilterShow">Show On Filter
                    </nz-form-label>
                    <nz-form-control>
                        <!-- <nz-input-group> -->
                        <nz-switch id="ValueFilterShow" [(ngModel)]="isShowOnFilter"
                            [ngModelOptions]="{standalone: true}" [nzCheckedChildren]="checkedTemplate"
                            [nzUnCheckedChildren]="unCheckedTemplate"></nz-switch>
                        <ng-template #checkedTemplate><i nz-icon nzType="check"></i></ng-template>
                        <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i></ng-template>
                        <!-- </nz-input-group> -->
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item class="text-center">
                    <nz-form-control>
                        <button (click)="resetForm()" type="button" class="mr-3 cancel-button" nz-button
                            nzType="default">Reset</button>
                        <a><button [nzLoading]="specLoading" (click)="doChanges()" type="button" nz-button
                                class="save-button">{{defaultMode ==
                                'Add' ? 'Create' : 'Update'}}</button></a>
                        <!-- <a *ngIf="defaultMode == 'Edit'"><button (click)="doChanges()" type="button" nz-button
                                class="save-button">Update</button></a> -->
                    </nz-form-control>
                </nz-form-item>

            </form>

        </div>
    </div>
</nz-card>

<!-- ADD SPECIFICATION VALUE FORM DESIGN ENDS  -->

<!-- MEDIA MODEL SECTION STARTS -->

<nz-modal [nzStyle]="{top : '5px'}" [(nzVisible)]="MediaVisible" nzTitle="Specification" (nzOnCancel)="handleCancel()"
    nzOkText="Set Specification Image" [nzOkLoading]="isOkLoading">
    <div class="container-fluid" *nzModalContent>
        <app-media></app-media>
    </div>
</nz-modal>

<!-- MEDIA MODEL SECTION STARTS -->