import { NgModule } from "@angular/core";
import { RouterModule, Routes, PreloadAllModules } from "@angular/router";
import { DashboardComponent } from "./project/dashboard/dashboard.component";
import { AttributesComponent } from "./project/attributes/attributes.component";
import { BrandsComponent } from "./project/brands/brands.component";
import { BannersComponent } from "./project/banners/banners.component";
import { CategoryComponent } from "./project/category/category.component";
import { MediaComponent } from "./project/media/media.component";
import { ProductsComponent } from "./project/products/products.component";
import { SettingComponent } from "./project/setting/setting.component";
import { SpecGroupComponent } from "./project/spec-group/spec-group.component";
import { SpecValueComponent } from "./project/spec-value/spec-value.component";
import { LoginComponent } from "./project/login/login.component";
import { OrderDetailsComponent } from "./project/order-details/order-details.component";
import { ForgotPasswordComponent } from "./project/forgot-password/forgot-password.component";
import { UserManagementComponent } from "./project/user-management/user-management.component";
import { OfferComponent } from "./project/offer/offer.component";
import { AddToOfferPageComponent } from "./project/add-to-offer-page/add-to-offer-page.component";
import { AuthGuardService } from "./services/auth-guard.service";
import { SpecificationsComponent } from "./project/specifications/specifications.component";
import { CouponCodeComponent } from "./project/coupon-code/coupon-code.component";
import { SettingPageComponent } from "./project/setting-page/setting-page.component";
import { BuildPcComponent } from "./project/build-pc/build-pc.component";
import { CustomersComponent } from "./project/customers/customers.component";
import { PaymentsComponent } from "./project/payments/payments.component";
import { PolicyPageComponent } from "./project/policy-page/policy-page.component";
import { TagsComponent } from "./project/tags/tags.component";

const appRoutes: Routes = [
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "attribute",
    component: AttributesComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "brands",
    component: BrandsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "banners",
    component: BannersComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "category",
    component: CategoryComponent,
    canActivate: [AuthGuardService],
  },
  { path: "media", component: MediaComponent, canActivate: [AuthGuardService] },
  {
    path: "products",
    component: ProductsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "store-location",
    component: SettingComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "specification",
    component: SpecificationsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "specGroup",
    component: SpecGroupComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "specValue",
    component: SpecValueComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "recent-orders",
    component: OrderDetailsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "recent-orders/:filter",
    component: OrderDetailsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "user-management",
    component: UserManagementComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "offer-page",
    component: OfferComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "coupon-page",
    component: CouponCodeComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "setting",
    component: SettingPageComponent,
    canActivate: [AuthGuardService],
  },

  {
    path: "add-offer",
    component: AddToOfferPageComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "build-pc-enquiry",
    component: BuildPcComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "customers",
    component: CustomersComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "payments",
    component: PaymentsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "policies",
    component: PolicyPageComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "tags",
    component: TagsComponent,
    canActivate: [AuthGuardService],
  },
  // LOGIN AND SIGNUP PAGES

  { path: "", component: LoginComponent },
  { path: "forgot-password", component: ForgotPasswordComponent },

  { path: "**", redirectTo: "/" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes
      //   {
      //   preloadingStrategy: PreloadAllModules,
      //   anchorScrolling: 'enabled',
      //   scrollPositionRestoration: 'enabled',
      // }
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
