import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { scrollToTop } from "../../shared/dummy";
import axios, { Axios } from "axios";
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from "src/environments/environment";
import { ToastrService } from "ngx-toastr";

interface DataItem {
  id: string;
  name: string;
  brand: any;
  address1: any;
  address2: any;
  state: any;
  city: any;
  pincode: number;
  map: any;
  phone: any;
  landline: any;
  description: any;
  website: any;
  active: any;
}

@Component({
  selector: "app-setting-page",
  templateUrl: "./setting-page.component.html",
  styleUrls: ["./setting-page.component.css"],
})
export class SettingPageComponent implements OnInit {
  selectedCategory: string;
  selectedStatus: string;
  searchInput: any;
  displayData = [];
  brands = [];
  settings: any;

  centerName = "";
  brandList = [];
  address1 = "";
  address2 = "";
  city = "";
  state = "";
  pincode = "";
  mapUrl = "";
  mobNo = "";
  landlineNo = "";
  description = "";
  website = "";
  isActive = "";
  minimumOrderValue = "";
  deliveryCharges = "";
  freeDeliveryChargesOver = "";
  freeDeliveryChargesRange = "";
  upiId = "";

  showTable: boolean = true;
  showForm: boolean = false;

  validateForm: FormGroup;
  requiredData = "";

  categoryList: any = [];
  categoriesLists: any = [];
  locationActive: boolean;

  navbarCategoryList: any = [];

  isCodAvailable: boolean = false;
  isDirectBankTransferAvailable: boolean = false;
  isUpiCodAvailable: boolean = false;
  storeIsActive: boolean = false;
  reviewIsAvailable: boolean = false;

  // new setting page variables

  // accounts and privacy
  mode = "date";
  // email tab data

  emailData = [
    {
      email: "New order",
      contentType: "text/html",
      recipient: "sales@ieyalsolutions.com",
    },
    {
      email: "Cancelled order ",
      contentType: "text/html",
      recipient: "sales@ieyalsolutions.com",
    },
    {
      email: "Failed order ",
      contentType: "text/html",
      recipient: "sales@ieyalsolutions.com",
    },
    {
      email: "Order on-hold  ",
      contentType: "text/html",
      recipient: "Customer",
    },
    {
      email: "Processing order   ",
      contentType: "text/html",
      recipient: "Customer",
    },
    {
      email: "Completed order   ",
      contentType: "text/html",
      recipient: "Customer",
    },
    {
      email: "Refunded order  ",
      contentType: "text/html",
      recipient: "Customer",
    },
    {
      email: "Customer invoice / Order details   ",
      contentType: "text/html",
      recipient: "Customer",
    },
    {
      email: "Customer note   ",
      contentType: "text/html",
      recipient: "Customer",
    },
    {
      email: "Reset password   ",
      contentType: "text/html",
      recipient: "Customer",
    },
    { email: "New account  ", contentType: "text/html", recipient: "Customer" },
  ];

  errorArr: any = [];

  fetchData() {
    var self = this;
    axios
      .get(environment.url + "settings/", {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("admintokenoasisdata"),
        },
      })
      .then((response) => {
        console.log(response.data.data);
        self.settings = response?.data?.data;
        self.minimumOrderValue =
          response?.data?.data?.settings?.delivery?.minimumOrderValue;
        self.deliveryCharges =
          response?.data?.data?.settings?.delivery?.deliveryCharges;
        self.freeDeliveryChargesOver =
          response?.data?.data?.settings?.delivery?.freeDeliveryChargesOver;
        self.freeDeliveryChargesRange =
          response?.data?.data?.settings?.delivery?.freeDeliveryChargesKilometerLessThan;
        self.categoryList =
          response?.data?.data?.settings?.getCategoryOfProductsForHomePage;
        self.locationActive =
          response?.data?.data?.settings?.locationIQ?.isActive;
        self.navbarCategoryList =
          response?.data?.data?.settings?.getCategoriesForNavbar;
        self.isCodAvailable =
          response?.data?.data?.settings?.paymentMethods?.isCodAvailable;
        self.isDirectBankTransferAvailable =
          response?.data?.data?.settings?.paymentMethods?.isDirectBankTransferAvailable;
        self.isUpiCodAvailable =
          response?.data?.data?.settings?.paymentMethods?.isUpiCodAvailable;
        self.storeIsActive = response?.data?.data?.settings?.store?.isActive;
        self.reviewIsAvailable =
          response?.data?.data?.settings?.review?.isEnabled;
        self.spinner.hide();
        //console.log(response.data.status);
      })
      .catch((error) => {
        console.log(error.response);
        self.spinner.hide();
        //alert(error.response.data.message);
      });
  }

  productsList: DataItem[] = [];

  constructor(
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {}

  categoryChange(value: string): void {
    const data = this.productsList;
    // value !== 'All'
    //   ? (this.displayData = data.filter((elm) => elm.name === value))
    this.displayData = data;
  }

  statusChange(value: string): void {
    const data = this.productsList;
    // value !== 'All'
    //   ? (this.displayData = data.filter((elm) => elm.feature === value))
    //   : (this.displayData = data);
    this.displayData = data;
  }

  ngOnInit(): void {
    this.spinner.show();
    scrollToTop();
    this.fetchData();
    this.getAllCategories();
    this.validateForm = this.fb.group({});
  }

  getAllCategories() {
    axios
      .get(environment.url + "categories?isForProductPage=true", {})
      .then((response) => {
        console.log(response.data.data);
        this.categoriesLists = response.data.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  showFormDetails() {
    this.showTable = !this.showTable;
    this.showForm = !this.showForm;
  }

  // FORM RESET SECTION

  resetForm(): void {
    this.validateForm.reset();
  }
  updateSettings() {
    var self = this;
    self.errorArr = [];

    if (!self.minimumOrderValue) {
      self.errorArr.push("MOV");
    } else if (self.errorArr.indexOf("MOV") > -1) {
      self.errorArr.splice(self.errorArr.indexOf("MOV"), 1);
    }

    if (!self.deliveryCharges) {
      self.errorArr.push("DC");
    } else if (self.errorArr.indexOf("DC") > -1) {
      self.errorArr.splice(self.errorArr.indexOf("DC"), 1);
    }

    if (!self.freeDeliveryChargesOver) {
      self.errorArr.push("FDC");
    } else if (self.errorArr.indexOf("FDC") > -1) {
      self.errorArr.splice(self.errorArr.indexOf("FDC"), 1);
    }

    if (self.isUpiCodAvailable) {
      if (!self.upiId) {
        self.errorArr.push("upiId");
      }
    } else if (self.errorArr.indexOf("upiId") > -1) {
      self.errorArr.splice(self.errorArr.indexOf("upiId"), 1);
    }

    if (
      !self.isCodAvailable &&
      !self.isUpiCodAvailable &&
      !self.isDirectBankTransferAvailable
    ) {
      self.toastr.error(
        "",
        "At least select one payment method to continue!..",
        {
          timeOut: 2500,
        }
      );
    } else if (this.errorArr.length == 0) {
      var reqObj = {
        delivery: {
          minimumOrderValue: this.minimumOrderValue,
          deliveryCharges: this.deliveryCharges,
          freeDeliveryChargesOver: this.freeDeliveryChargesOver,
          freeDeliveryChargesKilometerLessThan: this.freeDeliveryChargesRange,
        },
        getCategoryOfProductsForHomePage: this.categoryList,
        getCategoriesForNavbar: this.navbarCategoryList,
        locationIQ: {
          isActive: this.locationActive,
        },
        paymentMethods: {
          isCodAvailable: this.isCodAvailable,
          isDirectBankTransferAvailable: this.isDirectBankTransferAvailable,
          isUpiCodAvailable: this.isUpiCodAvailable,
        },
        store: {
          isActive: this.storeIsActive,
        },
        review: {
          isEnabled: this.reviewIsAvailable,
        },
      };

      self.spinner.show();
      axios
        .post(environment.url + "settings/manage-settings", reqObj, {
          headers: {
            Authorization:
              "Bearer " + localStorage.getItem("admintokenoasisdata"),
          },
        })
        .then((response) => {
          if (response.data.status == "success") {
            console.log(response.data);
            self.toastr.success("", response.data.message, {
              timeOut: 2500,
            });
            self.fetchData();
          }
        })
        .catch((error) => {
          self.spinner.hide();
          console.log(error.response);
          if (error.response.data.data == undefined) {
            self.toastr.error("", error.response.data.message, {
              timeOut: 2500,
            });
          } else {
            self.toastr.error("", error.response.data.data[0].msg, {
              timeOut: 2500,
            });
          }
        });
    }
  }

  inputValueChange(field: string) {
    if (field == "MOV") {
      if (!this.minimumOrderValue) {
        this.errorArr.push("MOV");
      } else if (this.errorArr.indexOf("MOV") > -1) {
        this.errorArr.splice(this.errorArr.indexOf("MOV"), 1);
      }
    }

    if (field == "DC") {
      if (!this.deliveryCharges) {
        this.errorArr.push("DC");
      } else if (this.errorArr.indexOf("DC") > -1) {
        this.errorArr.splice(this.errorArr.indexOf("DC"), 1);
      }
    }

    if (field == "FDC") {
      if (!this.freeDeliveryChargesOver) {
        this.errorArr.push("FDC");
      } else if (this.errorArr.indexOf("FDC") > -1) {
        this.errorArr.splice(this.errorArr.indexOf("FDC"), 1);
      }
    }
  }

  editChanges(value) {
    // alert(value.id);
  }
}
