<!-- <nz-card *ngIf="showTable">
    <div class="row  m-b-30">

        <div class="col-lg-10">
            <div class="d-md-flex">
                <div class="m-b-10 m-r-20">
                    <nz-input-group [nzPrefix]="prefixTemplate">
                        <input type="text" nz-input placeholder="Search Project" [(ngModel)]="searchInput">
                    </nz-input-group>
                    <ng-template #prefixTemplate>
                        <i nz-icon nzType="search" class="opacity-05"></i>
                    </ng-template>
                </div>
                <div class="m-b-10 m-r-20 d-flex align-items-center">
                    <nz-select [(ngModel)]="selectedTable" style="min-width: 220px;" class="w-100" nzPlaceHolder="All Products">
                        <nz-option nzLabel="All Products" nzValue="all_products"></nz-option>
                        <nz-option nzLabel="All Categories" nzValue="all_categories"></nz-option>
                    </nz-select>
                </div>
                <div class="m-b-10 m-r-20 d-flex align-items-center" style="padding-left: 5px;">
                    <label class="mr-3" for="">Offers : </label>
                    <nz-switch [(ngModel)]="ShowOffer" (ngModelChange)="offerToggle()"></nz-switch>
                </div>
            </div>
        </div>


    </div>

    <nz-table [nzFooter]=" totalCount == 0 ? '' : 'Showing ' +  (((pageSize * pageIndex) - pageSize) + 1) + ' to ' +  (pageSize * pageIndex > totalCount ? totalCount : pageSize * pageIndex) +  ' of ' +  totalCount" *ngIf="selectedTable == 'all_products'"
        #productsListTable [nzData]="productsList" [nzScroll]="{ x: '1350px', y: '50vh' }" [nzFrontPagination]="false" [nzTotal]="totalCount" [nzPageSize]="pageSize" [nzPageIndex]="pageIndex" (nzQueryParams)="onPageNumberChange($event)">
        <thead>
            <tr *ngIf="ShowOffer">
                <th *ngFor="let column of offerOrderColumn" [nzWidth]="column.width" [nzSortFn]="column.compare">{{ column.title }}</th>
            </tr>
            <tr *ngIf="!ShowOffer">
                <th *ngFor="let column of orderColumn" [nzWidth]="column.width" [nzSortFn]="column.compare">{{ column.title }}</th>
            </tr>
        </thead>
        <tbody>
            <tr id="offerProductsTable" *ngFor="let item of productsListTable.data;  let i=index">
                <td>
                    <div class="d-flex align-items-center">
                        {{item.skuId}}
                    </div>
                </td>
                <td>{{item.name}}</td>
                <td>
                    <nz-avatar nzShape="square" [nzSize]="50" nzIcon="picture" [nzSrc]="liveUrl + item.image">
                    </nz-avatar>
                </td>
                <td>$ {{item?.regularPrice?.toFixed(2)}}</td>
                <td>$ {{item.salePrice.toFixed(2)}}</td>
                <td *ngIf="ShowOffer">{{moment(item.startDate).format('DD-MM-yyyy h:mm a')}}</td>
                <td *ngIf="ShowOffer">{{moment(item.endDate).format('DD-MM-yyyy h:mm a')}}</td>
                <td>{{item.category}}</td>

                <td class="">
                    <button [nzLoading]="offerLoading" (click)="addOffer(item , 'single-product')" nz-button>
                        {{item.isInOffer ? 'Remove' : 'Add to'}}
                        Offer</button>
                </td>
            </tr>
        </tbody>

        <ngx-spinner *ngIf="!MediaVisible" bdColor="rgb(255 255 255 / 88%)" size="medium" color="#00f226" type="ball-spin-clockwise" [fullScreen]="false"></ngx-spinner>
    </nz-table>

    <nz-table [nzFooter]="categoryTotalCount == 0 ? '' : 'Showing ' +  (((categoryPageSize * categoryPageIndex) - categoryPageSize) + 1) + ' to ' +  (categoryPageSize * categoryPageIndex > categoryTotalCount ? categoryTotalCount : categoryPageSize * categoryPageIndex) +  ' of ' +  categoryTotalCount"
        *ngIf="selectedTable == 'all_categories'" #CategoryListTable [nzScroll]="{ y: '50vh', x : '1200px' }" [nzData]="CategoryList" [nzFrontPagination]="false" [nzTotal]="categoryTotalCount" [nzPageSize]="categoryPageSize" [nzPageIndex]="categoryPageIndex"
        (nzQueryParams)="categoryPageChange($event)">
        <thead>
            <tr *ngIf="ShowOffer">
                <th *ngFor="let column of offerCategoryHeading" [nzWidth]="column.width" [nzSortFn]="column.compare">{{ column.title }}</th>
            </tr>
            <tr *ngIf="!ShowOffer">
                <th *ngFor="let column of CategoryHeading" [nzWidth]="column.width" [nzSortFn]="column.compare">{{ column.title }}</th>
            </tr>
        </thead>
        <tbody>
            <tr id="offerCategoryTable" *ngFor="let item of CategoryListTable.data;  let i=index">
                <td>
                    <div (click)="editCategory(item)" class="category-name">
                        {{item.name}}
                    </div>
                </td>
                <td>
                    <nz-avatar nzShape="square" [nzSize]="50" nzIcon="picture" [nzSrc]="liveUrl + item.image">
                    </nz-avatar>
                </td>
                <td *ngIf="ShowOffer">{{moment(item?.offerData?.startDate)?.format('DD-MM-yyyy h:mm a')}}</td>
                <td *ngIf="ShowOffer">{{moment(item?.offerData?.endDate)?.format('DD-MM-yyyy h:mm a')}}</td>
                <td>{{item.priority}}</td>
                <td *ngFor="let spec of item.spectification">{{spec}}</td>
                <td class="">
                    <button [nzLoading]="offerLoading" (click)="addOffer(item , 'category')" nz-button>
                        {{item?.offerData?.isInOffer ? 'Remove' : 'Add to'}} Offer</button>
                </td>
            </tr>
        </tbody>

        <ngx-spinner *ngIf="!MediaVisible" bdColor="rgb(255 255 255 / 88%)" size="medium" color="#00f226" type="ball-spin-clockwise" [fullScreen]="false"></ngx-spinner>
    </nz-table>


</nz-card> -->

<!-- Old Offer Starts -->
<!-- <nz-card *ngIf="!showTable" class="">
    <div class="row justify-content-center">
        <div class="col-12 mb-3 title-text">
            <div class="">
                <h4>
                    <span (click)="showTableData()" class="back_icon" nz-icon nzType="left" nzTheme="outline">
                    </span> Add Offer
                </h4>
            </div>
        </div>

        <div class="col-lg-12">
            <app-add-to-offer-page (offerUpdated)="updated($event)" [offerDetails]="selectedData">
            </app-add-to-offer-page>
        </div>
    </div>
</nz-card>



<nz-card *ngIf="showTable">
    <div class="row  m-b-30">

        <div class="col-lg-10">
            <div class="d-md-flex">
                <div class="m-b-10 m-r-20">
                    <nz-input-group [nzPrefix]="prefixTemplate">
                        <input type="text" nz-input placeholder="Search Project" [(ngModel)]="searchInput">
                    </nz-input-group>
                    <ng-template #prefixTemplate>
                        <i nz-icon nzType="search" class="opacity-05"></i>
                    </ng-template>
                </div>
                <div class="m-b-10 m-r-20 d-flex align-items-center">
                    <nz-select [(ngModel)]="selectedTable" style="min-width: 220px;" class="w-100"
                        nzPlaceHolder="All Products">
                        <nz-option nzLabel="Products" nzValue="all_products"></nz-option>
                        <nz-option nzLabel="Categories" nzValue="all_categories"></nz-option>
                    </nz-select>
                </div>
            </div>
        </div>

        <div class="col-12">
            <nz-table #offerListTable [nzData]="offerList" [nzScroll]="{ x: '600px', y: '50vh' }">
                <thead>
                    <tr>
                        <th *ngFor="let column of offerHeadings" [nzWidth]="column.width">{{ column.title }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr id="offerProductsTable" *ngFor="let item of offerList;  let i=index">
                        <td>{{item?.offerData?.offerType}}</td>
                        <td>{{item?.name}}</td>
                        <td>{{''}}</td>
                        <td>{{item?.offerData?.lastSalePrice}}</td>
                        <td>{{item?.offerData?.offerPrice}}</td>
                        <td>{{moment(item?.offerData?.startDate)?.format('DD-MM-yyyy h:mm a')}}</td>
                        <td>{{moment(item?.offerData?.endDate).format('DD-MM-yyyy h:mm a')}}</td>
                        <td>{{'Remove'}}</td>
                    </tr>
                </tbody>

                <ngx-spinner *ngIf="!MediaVisible" bdColor="rgb(255 255 255 / 88%)" size="medium" color="#00f226"
                    type="ball-spin-clockwise" [fullScreen]="false"></ngx-spinner>
            </nz-table>
        </div>
    </div>
</nz-card> -->

<!-- Old Offer Ends -->

<!-- New Offer Starts -->

<h3 *ngIf="showTable" class="category-heading-outside">Offers</h3>

<nz-card *ngIf="showTable">
  <div class="row">
    <div class="col-lg-8">
      <div class="d-md-flex">
        <div class="m-b-10 m-r-20">
          <nz-input-group [nzPrefix]="prefixTemplate">
            <input autocomplete="off" type="text" nz-input placeholder="Enter Coupon" [(ngModel)]="searchInput" />
          </nz-input-group>
          <ng-template #prefixTemplate>
            <i nz-icon nzType="search" class="opacity-05"></i>
          </ng-template>
        </div>
      </div>
    </div>
    <div class="col-lg-4 text-right add-button-section">
      <a (click)="showForm()" nz-button class="save-button">
        <i nz-icon nzType="plus-circle" theme="outline"></i>
        <span>Add Offer</span>
      </a>
    </div>
  </div>
  <nz-table #coupenListTable [nzData]="offersList" [nzScroll]="{ y: '50vh', x: '1100px' }"
    (nzQueryParams)="onPageNumberChange('couponTable')">
    <thead>
      <tr>
        <th *ngFor="let column of offerOrderColumn" [nzWidth]="column.width">
          {{ column.title }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr id="couponTable" *ngFor="let item of offersList | filter : searchInput; let i = index">
        <!-- <td>{{ i + 1 }}</td> -->
        <td>
          {{ item.name }}
        </td>
        <td>
          <div class="">
            <span>{{ item.offerType }}</span>
          </div>
        </td>
        <td>
          <div *ngIf="item.percentageDiscount" class="">
            {{ item.percentageDiscount + "%" }}
          </div>
          <div *ngIf="item.priceDiscount" class="">
            {{'$ ' + item.priceDiscount }}
          </div>
        </td>
        <td>
          {{ moment(item.startDate).format("DD-MM-YYYY hh:mm a") }}
        </td>
        <td>
          {{ moment(item.endDate).format("DD-MM-YYYY hh:mm a") }}
        </td>
        <td>
          <!-- <div *ngIf="item.percentageDiscount" class="">{{item.percentageDiscount+'%'}}</div> -->
          <!-- <div *ngIf="item.percentageDiscount" class="">{{item.percentageDiscount+'%'}}</div> -->
        </td>
        <td>
          <span (click)="updateOffer(item)" [ngClass]="{ 'un-published': !item.isActive }"
            class="text-capitalize published">{{ item.isActive ? "Active" : "Inactive" }}</span>
        </td>
      </tr>
    </tbody>
    <ngx-spinner *ngIf="!MediaVisible" bdColor="rgb(255 255 255 / 88%)" size="medium" color="#00f226"
      type="ball-spin-clockwise" [fullScreen]="false"></ngx-spinner>
  </nz-table>
</nz-card>

<!-- New Offer Ends -->

<!-- New Add Offer Starts -->

<nz-card *ngIf="!showTable" class="container">
  <div class="row justify-content-center">
    <div class="col-12 mb-3 title-text">
      <div class="">
        <h4>
          <span class="back_icon" (click)="showForm()" nz-icon nzType="left" nzTheme="outline">
          </span>
          Add New Offer
        </h4>
      </div>
    </div>

    <form [formGroup]="addOfferForm" (ngSubmit)="addOffer()" nz-form>
      <div class="row justify-content-center">
        <div class="col-lg-7 left_column">
          <nz-card class="form_fixed_height">
            <div nz-row [nzGutter]="24">
              <div nz-col [nzXs]="24" [nzSpan]="12">
                <nz-form-item>
                  <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired nzFor="coupon-code">Offer Name
                  </nz-form-label>
                  <nz-form-control nzErrorTip="Please Enter Offer Name!">
                    <nz-input-group>
                      <input autocomplete="off" formControlName="OfferName" type="text" nz-input id="offer-code"
                        placeholder="Enter Offer Name" />
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
              </div>

              <!-- <div class="discount_div" nz-row [nzGutter]="24"> -->
              <div nz-col [nzXs]="24" [nzSpan]="12">
                <nz-form-item>
                  <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired nzFor="coupen-type">Discount Type
                  </nz-form-label>
                  <nz-form-control nzErrorTip="Please Select Discount Type!">
                    <nz-input-group [nzXs]="24" [nzLg]="18">
                      <nz-select formControlName="DiscountType" id="discount-type" nzShowSearch class="w-100"
                        nzPlaceHolder="Enter Discount Type">
                        <nz-option nzValue="price-discount" nzLabel="Price Discount"></nz-option>
                        <nz-option nzValue="percentage-discount" nzLabel="Percentage Discount"></nz-option>
                      </nz-select>
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div class="percentage_div" nz-col [nzXs]="24" [nzSpan]="12">
                <nz-form-item>
                  <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired nzFor="coupen-type">
                    Value
                  </nz-form-label>
                  <nz-form-control nzErrorTip="Enter Offer Value!">
                    <nz-input-group>
                      <input autocomplete="off" formControlName="OfferValue" type="number" nz-input id="offer-code"
                        placeholder="Value" />
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <!-- </div> -->

              <div nz-col [nzXs]="24" [nzSpan]="12">
                <nz-form-item>
                  <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired nzFor="coupen-type">Type
                  </nz-form-label>
                  <nz-form-control nzErrorTip="Please Select Type!">
                    <nz-input-group>
                      <nz-select formControlName="OfferType" id="coupen-type" nzShowSearch class="w-100"
                        nzPlaceHolder="Enter Type">
                        <nz-option nzValue="category" nzLabel="Categories"></nz-option>
                        <nz-option nzValue="single-product" nzLabel="Products"></nz-option>
                      </nz-select>
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div *ngIf="addOfferForm.controls['OfferType'].value === 'category'" nz-col [nzXs]="24" [nzSpan]="12">
                <nz-form-item>
                  <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired nzFor="value">Categories
                  </nz-form-label>
                  <nz-form-control nzErrorTip="Please Enter Categories!">
                    <nz-select formControlName="Categories" [nzMaxTagCount]="3" [nzMaxTagPlaceholder]="tagPlaceHolder"
                      nzMode="multiple" nzPlaceHolder="Please Select Categories">
                      <nz-option *ngFor="let item of Categories; let i = index" [nzLabel]="item.name"
                        [nzValue]="item._id"></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div *ngIf="addOfferForm.controls['OfferType'].value === 'category'" nz-col [nzXs]="24" [nzSpan]="12">
                <nz-form-item>
                  <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired nzFor="value">Category types
                  </nz-form-label>
                  <nz-form-control nzErrorTip="Please Select Category types!">
                    <nz-select formControlName="categoriesType" [nzMaxTagCount]="3"
                      [nzMaxTagPlaceholder]="tagPlaceHolder" nzMode="multiple"
                      nzPlaceHolder="Please select Category types">
                      <nz-option *ngFor="let item of categoriesType" [nzLabel]="item" [nzValue]="item"></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div *ngIf="
                  addOfferForm.controls['OfferType'].value === 'single-product'
                " nz-col [nzXs]="24" [nzSpan]="12">
                <nz-form-item>
                  <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired nzFor="value">Products
                  </nz-form-label>
                  <nz-form-control nzErrorTip="Please Enter Products!">
                    <nz-select formControlName="ProductsName" [nzMaxTagCount]="3" [nzMaxTagPlaceholder]="tagPlaceHolder"
                      nzMode="multiple" nzPlaceHolder="Please select Products">
                      <nz-option *ngFor="let item of productsList" [nzLabel]="
                          item.name.length > 40
                            ? (item.name | slice : 0 : 40) + '...'
                            : item.name
                        " [nzValue]="item._id"></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </nz-card>
        </div>

        <div class="col-lg-5 right_column">
          <nz-card class="form_fixed_height">
            <div nz-row [nzGutter]="24">
              <div nz-col [nzXs]="12" [nzSpan]="12">
                <nz-form-item>
                  <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired nzFor="from-date">Start Date
                  </nz-form-label>
                  <nz-form-control nzErrorTip="Please Select date!">
                    <nz-input-group>
                      <nz-date-picker nzShowTime nzFormat="dd-MM-yyyy h:mm:ss a" formControlName="startDate"
                        class="w-100" [nzRenderExtraFooter]="" nzPlaceHolder="Start Date"></nz-date-picker>
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col [nzXs]="12" [nzSpan]="12">
                <nz-form-item>
                  <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired nzFor="to-date">End Date
                  </nz-form-label>
                  <nz-form-control nzErrorTip="Please Select date!">
                    <nz-input-group>
                      <nz-date-picker nzShowTime nzFormat="dd-MM-yyyy h:mm:ss a" formControlName="endDate" class="w-100"
                        [nzRenderExtraFooter]="" nzPlaceHolder="End Date"></nz-date-picker>
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col [nzXs]="24" [nzSpan]="12">
                <nz-form-item>
                  <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired nzFor="BannerImage">Offer Image
                  </nz-form-label>
                  <nz-form-control nzErrorTip="Please input your username!">
                    <nz-input-group>
                      <button type="button" nz-button class="cancel-button" (click)="showMedia()">
                        <span>Select from media</span>
                      </button>
                      <img *ngFor="let img of bannerImage" style="width: 70px; padding-left: 10px"
                        src="{{ liveUrl + img.image.url }}" />
                    </nz-input-group>
                    <p class="image-size">Image Size: 1300px X 580px</p>
                  </nz-form-control>
                </nz-form-item>
              </div>

              <!-- <div nz-col [nzXs]="24" [nzSpan]="12">
                <nz-form-item>
                  <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired nzFor="max-times">Maximum Allowed
                    Times
                  </nz-form-label>
                  <nz-form-control nzErrorTip="Please Enter Value!">
                    <nz-input-group>
                      <input autocomplete="off" formControlName="maxAllowedTimes" type="number" nz-input id="max-times"
                        placeholder="Enter Maximum Allowed Times" />
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col [nzXs]="24" [nzSpan]="12">
                <nz-form-item>
                  <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired nzFor="max-order">Maximum Order
                    Value</nz-form-label>
                  <nz-form-control nzErrorTip="Please Enter Value!">
                    <nz-input-group>
                      <input autocomplete="off" formControlName="maxOrderValue" type="number" nz-input id="max-order"
                        placeholder="Enter Maximum Order Value" />
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
              </div> -->

              <div nz-col [nzXs]="24" [nzSpan]="12">
                <nz-form-item>
                  <nz-form-label nzRequired nzFor="coupon-active">Active</nz-form-label>
                  <nz-form-control>
                    <!-- <nz-input-group> -->
                    <nz-switch formControlName="offerActive" id="coupon-active" [nzCheckedChildren]="checkedTemplate"
                      [nzUnCheckedChildren]="unCheckedTemplate"></nz-switch>
                    <ng-template #checkedTemplate><i nz-icon nzType="check"></i></ng-template>
                    <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i></ng-template>
                    <!-- </nz-input-group> -->
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div class="new_btn_div" nz-col [nzXs]="24" [nzSpan]="12">
                <nz-form-item class="text-center">
                  <nz-form-control>
                    <button (click)="showTableData()" type="button" class="mr-5" nz-button class="mr-3 cancel-button">
                      Reset
                    </button>
                    <a><button type="Submit" [nzLoading]="isOkLoading" nz-button class="save-button">
                        Create
                      </button></a>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </nz-card>
        </div>
      </div>
    </form>
  </div>
</nz-card>

<nz-modal [nzStyle]="{ top: '10px' }" nzWidth="90%" [(nzVisible)]="MediaVisible" nzTitle="Banner Image" nzCentered
  (nzOnCancel)="handleCancel()" (nzOnOk)="sendMedia()" nzOkText="Set Banner Image">
  <div class="container-fluid" *nzModalContent>
    <app-media></app-media>
  </div>
</nz-modal>

<!-- New Add Offer Ends -->