import { Component, Input, OnInit } from "@angular/core";
import { scrollToTop } from "../../shared/dummy";
import * as moment from "moment";
import axios, { Axios } from "axios";
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from "src/environments/environment";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { ExportExcelService } from "src/app/services/export-excel.service";
import { filter } from "rxjs/operators";
import { NavService } from "src/app/services/nav.service";

interface DataItem {
  name: string;
  date: string;
  amount: number;
  status: string;
}

@Component({
  selector: "app-order-details",
  templateUrl: "./order-details.component.html",
  styleUrls: ["./order-details.component.css"],
})
export class OrderDetailsComponent implements OnInit {
  moment: any = moment;
  allChecked: boolean = false;
  indeterminate: boolean = false;
  displayData = [];
  selectedStatus: string;
  searchInput: string;
  seeOrderDetails = false;
  showForm: boolean = false;
  orderData: any = [];
  trackId: any;
  orderDetailsPage = true;
  scrollHeight = "";
  customerDetails: boolean = false;
  drawerWidth: any;
  sortOption: any;
  orderStatus: any = "";

  orderColumn = [
    // {
    //   title: 'S.NO',
    //   width: '40px',
    // },
    {
      title: "Order Number",
      width: "80px",
      compare: (a: DataItem, b: DataItem) => a.name.localeCompare(b.name),
      // sortOrder: null,
      // SortFn:null
    },
    {
      title: "Customer Name",
      width: "100px",
      compare: (a: DataItem, b: DataItem) => a.name.localeCompare(b.name),
      // sortOrder: null,
      // SortFn:null
    },
    {
      title: "Date & Time",
      width: "100px",
      compare: (a: DataItem, b: DataItem) => a.name.localeCompare(b.name),
      sortOrder: null,
      SortFn: "sortFunc",
    },
    {
      title: "Amount",
      width: "80px",
      compare: (a: DataItem, b: DataItem) => a.amount - b.amount,
      sortOrder: null,
      SortFn: "priceFilter",
    },
    {
      title: "Status",
      width: "80px",
      compare: (a: DataItem, b: DataItem) => a.name.localeCompare(b.name),
    },
    {
      title: "Payment Type",
      width: "100px",
      compare: (a: DataItem, b: DataItem) => a.name.localeCompare(b.name),
    },
    {
      title: "Payment Status",
      width: "80px",
      compare: (a: DataItem, b: DataItem) => a.name.localeCompare(b.name),
    },
    // {
    //     title: 'Action'
    // }
  ];

  OrderDetails = [
    // {
    //   title: 'S.No',
    // },
    {
      title: "Product Name",
      width: "130px",
    },
    // {
    //   title: 'Category',
    //   width: '80px',
    // },
    {
      title: "Price",
      width: "60px",
    },
    {
      title: "Quantity",
      width: "40px",
    },
    {
      title: "Total",
      width: "60px",
    },
  ];

  filterOption = "";

  @Input() ordersList = [];
  subscription: Subscription;

  routerEventSubscription: Subscription;
  codStatus: any = "";

  // pagination
  pageIndex: any = 1;
  totalCount: any;
  pageSize: any;

  userData: any = {};

  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    public ete: ExportExcelService,
    private navService: NavService
  ) {}

  // search() {
  //     const data = this.ordersList
  //     this.displayData = this.tableSvc.search(this.searchInput, data )
  // }

  ngOnInit(): void {
    if (this.router.url.includes("/recent-order")) {
      this.route.params.subscribe((data) => {
        console.log(data["filter"]);
        if (data["filter"] == "today") {
          this.filterOption = "today";
        } else {
          this.filterOption = "all";
        }
      });
      this.orderDetailsPage = true;
      this.scrollHeight = "58vh";
    } else if (this.router.url != "/recent-orders") {
      this.orderDetailsPage = false;
      this.scrollHeight = "400px";
    }

    if (window.innerWidth > 768) {
      this.drawerWidth = "50%";
    } else {
      this.drawerWidth = "95%";
    }

    scrollToTop();
    this.sortOption = "high-to-low";
    // this.pageIndex = 1;

    this.getUserData();
  }

  getUserData() {
    this.navService.getUserDetails();

    this.subscription = this.navService.sharedUserData.subscribe(
      (data: any) => {
        if (data) {
          this.userData = data?.data;
          console.log(this.userData);
          this.fetchData();
        }
      }
    );
  }

  fetchData() {
    var editData;
    this.spinner.show();
    this.subscription = this.route.params.subscribe((data) => {
      if (data["edit"] == "true") {
        editData = data["id"];
        console.log(editData);
        this.showForm = true;
        // this.orderFullDetails = editData;
        // this.orderStatus = editData.status;
        // this.trackId = editData.shipping.tracking.trackingId;
        // if (editData.status == 'order shipped') {
        //   this.getTrackId = true;
        // } else {
        //   this.getTrackId = false;
        // }
      }
    });
    console.log(this.sortOption);

    axios
      .post(
        environment.url +
          "orders/all?filter=" +
          this.filterOption +
          "&orderSort=" +
          this.sortOption +
          "&page=" +
          this.pageIndex,
        { search: this.searchInput },
        {
          headers: {
            Authorization:
              "Bearer " + localStorage.getItem("admintokenoasisdata"),
          },
        }
      )
      .then((response) => {
        // console.log(response.data.data);
        //this.ordersList = response.data.data;
        this.orderData = response.data.data.orders;

        if (this.router.url.includes("/recent-orders")) {
          this.totalCount = response.data?.data?.pagination?.totalCount;
          this.pageSize = response.data?.data?.pagination?.limit;
          console.log(this.pageIndex);
          this.ordersList = response.data.data.orders.map(
            (data: any, index: number) => {
              return {
                id: data?._id,
                orderId: data?.orderId,
                name: data?.userId?.name,
                date: data?.orderedDate,
                amount: data?.orderTotal,
                status: data?.shipping?.tracking?.status,
                address: data?.shipping?.contact?.address,
                paymentMode: data?.paymentType,
                contact: data?.shipping?.contact?.contactPhone,
                products: data?.products,
                orderDate: data?.orderedDate,
                shipping: data?.shipping,
                paymentType: data?.paymentType,
              };
            }
          );

          if (editData) {
            var targetIndex = this.ordersList?.findIndex((e: any) => {
              return e.id == editData;
            });
            this.orderFullDetails = this?.ordersList[targetIndex];
            this.trackId =
              this.orderFullDetails?.shipping?.tracking?.trackingId;

            this.orderStatus = this.orderFullDetails.status;
            this.codStatus =
              this.orderFullDetails?.shipping?.tracking?.paymentStatus;
            this.trackId = this.orderFullDetails.shipping.tracking.trackingId;

            if (this?.orderFullDetails?.status == "order shipped") {
              this.getTrackId = true;
            } else {
              this.getTrackId = false;
            }
            // console.log(this.orderFullDetails);
          }
        }

        this.spinner.hide();
        // console.log(this.orderFullDetails);

        //  console.log(response.data.status);
      })
      .catch((error) => {
        console.log(error.response);
        this.spinner.hide();
      });
  }

  handleCancel(): void {
    this.seeOrderDetails = false;
  }

  orderFullDetails: any = [];

  viewDetails(order: any) {
    console.log(order);
    if (this.router.url != "/recent-orders") {
      this.router.navigate([
        "/recent-orders",
        { edit: true, id: order.id },
        // { edit: true },
      ]);
    }
    // else if (this.router.url.includes('/recent-orders')) {
    this.seeOrderDetails = true;
    this.showForm = true;
    this.orderFullDetails = order;
    console.log(this.orderFullDetails);
    this.orderStatus = order.status;
    this.codStatus = order?.shipping?.tracking?.paymentStatus;
    this.trackId = order.shipping.tracking.trackingId;
    // console.log(order.status.toString());
    if (order.status == "order shipped") {
      this.getTrackId = true;
    } else {
      this.getTrackId = false;
    }
    // }
  }

  showFormDetails() {
    this.fetchData();
    this.router.navigate(["/recent-orders"]);
    this.showForm = false;
    this.getTrackId = false;
    this.orderDetailsPage = true;
  }

  getTrackId = false;

  changeOrderStatus() {
    // console.log(this.orderStatus);
    if (this.orderStatus == "order shipped") {
      this.getTrackId = true;
    } else {
      this.getTrackId = false;
    }
  }

  updateOrderStatus() {
    var updateData: any = {};
    var self = this;
    updateData.orderStatus = self.orderStatus;
    updateData.trackingId = self.trackId;
    updateData.paymentType = self?.orderFullDetails?.paymentMode;
    updateData.transportName = "Franch Courier";

    if (self.orderStatus == "order shipped") {
      updateData.trackingId = self.trackId;
    }

    if (this.orderFullDetails.paymentMode == "cash on delivery") {
      updateData.paymentStatus = self.codStatus;
    }

    var url: string = "";

    if (this.userData.user.role == "deliveryBoy") {
      url = "orders/deliveryBoy";
      updateData.orderId = self.orderFullDetails.id;
    } else {
      url = "orders/update";
      updateData.id = self.orderFullDetails.id;
    }
    // console.log(updateData);
    axios
      .post(environment.url + url, updateData, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("admintokenoasisdata"),
        },
      })
      .then((response) => {
        if (response.data.status == "success") {
          // console.log(response);
          this.toastr.success("", "Order Details Updated!.", {
            timeOut: 2500,
          });
          self.spinner.show();
          self.showForm = false;
          self.fetchData();
        }
      })
      .catch((error) => {
        if (error?.response?.data?.data == undefined) {
          this.toastr.error("", error?.response?.data?.message, {
            timeOut: 2500,
          });
        } else {
          this.toastr.error("", error?.response?.data?.data[0]?.msg, {
            timeOut: 2500,
          });
        }
      });
  }

  updateStatus(event: string, id: string) {
    //   alert(id);
    //   alert(event);
    var reqObj = {
      id: id,
      orderStatus: event,
      paymentStatus: "",
      trackingId: "",
      transportName: "",
    };
    axios
      .post(
        //'http://192.168.1.16:4000/brands/create',
        environment.url + "orders/update",
        reqObj,
        {
          headers: {
            Authorization:
              "Bearer " + localStorage.getItem("admintokenoasisdata"),
          },
        }
      )
      .then((response) => {
        if (response.data.status == "success") {
          console.log(response);
          this.toastr.success("", "Order Details Updated!.", {
            timeOut: 2500,
          });
          this.fetchData();
          //   self.showTable = !self.showTable;
          //   self.showForm = !self.showForm;
          //  localStorage.setItem('admintokenoasisdata',response.data.data);
          //  self.router.navigate(['dashboard'])
        }
      })
      .catch((error) => {
        if (error.response.data.data == undefined) {
          this.toastr.error("", error.response.data.message, {
            timeOut: 2500,
          });
        } else {
          this.toastr.error("", error.response.data.data[0].msg, {
            timeOut: 2500,
          });
        }
      });
  }

  export() {
    var Data: any = [];
    for (let i = 0; i < this.ordersList.length; i++) {
      var temp = [
        i + 1,
        this.ordersList[i].orderId,
        this.ordersList[i].name,
        moment(this.ordersList[i].date).format("DD-MM-YYYY HH:MM:SS"),
        this.ordersList[i].amount,
        this.ordersList[i].status,
      ];
      Data.push(temp);
      temp = [];
    }

    let orderData = [
      {
        title: "Order list",
        data: Data,
        headers: [
          "S.No",
          "Order Number",
          "Customer Name",
          "Date & Time",
          "Amount",
          "Status",
        ],
      },
    ];

    this.ete.exportExcel(orderData, "Order Details");
  }

  onPageNumberChange(event: any, id: string) {
    if (event.pageIndex != this.pageIndex) {
      document.getElementById(id).scrollIntoView();
      this.pageIndex = event.pageIndex;
      this.fetchData();
    }
  }

  viewCutomerDetails(customerData: any) {
    this.customerDetails = true;
    console.log(customerData);

    this.orderFullDetails = customerData;
  }

  closeCutomerDetails() {
    this.customerDetails = false;
  }

  sortFunc(value: any, position: string) {
    console.log(value);

    if (value == "Date & Time") {
      if (position == "up") {
        this.sortOption = "asc";
      } else if (position == "down") {
        this.sortOption = "des";
      }
    } else if (value == "Amount") {
      if (position == "up") {
        this.sortOption = "high-to-low";
      } else if (position == "down") {
        this.sortOption = "low-to-high";
      }
    }
    this.pageIndex = 1;
    this.fetchData();
  }

  searchOrders() {
    this.pageIndex = 1;
    this.fetchData();
  }

  applyFilter() {
    this.pageIndex = 1;
    this.fetchData();
  }
}
