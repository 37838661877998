import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NZ_I18N, en_US } from "ng-zorro-antd/i18n";
import { NzBreadCrumbModule } from "ng-zorro-antd/breadcrumb";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { HighchartsChartModule } from "highcharts-angular";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import {
  registerLocaleData,
  PathLocationStrategy,
  LocationStrategy,
} from "@angular/common";
import en from "@angular/common/locales/en";

import { AppRoutingModule } from "./app-routing.module";
import { TemplateModule } from "./shared/template/template.module";
import { SharedModule } from "./shared/shared.module";

import { AppComponent } from "./app.component";
import { CommonLayoutComponent } from "./layouts/common-layout/common-layout.component";
import { FullLayoutComponent } from "./layouts/full-layout/full-layout.component";

import { NgChartjsModule } from "ng-chartjs";
import { ThemeConstantService } from "./shared/services/theme-constant.service";
import { DashboardComponent } from "./project/dashboard/dashboard.component";
import { AttributesComponent } from "./project/attributes/attributes.component";

import { NgxSpinnerModule } from "ngx-spinner";
import { AngularEditorModule } from "@kolkov/angular-editor";

import { initializeApp } from "firebase/app";
initializeApp(environment.firebase);

import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireMessagingModule } from "@angular/fire/compat/messaging";

// NZ IMPORTS

import { NzButtonModule } from "ng-zorro-antd/button";
import { NzCardModule } from "ng-zorro-antd/card";
import { NzAvatarModule } from "ng-zorro-antd/avatar";
import { NzRateModule } from "ng-zorro-antd/rate";
import { NzBadgeModule } from "ng-zorro-antd/badge";
import { NzProgressModule } from "ng-zorro-antd/progress";
import { NzRadioModule } from "ng-zorro-antd/radio";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzDropDownModule } from "ng-zorro-antd/dropdown";
import { NzTimelineModule } from "ng-zorro-antd/timeline";
import { NzTabsModule } from "ng-zorro-antd/tabs";
import { NzTagModule } from "ng-zorro-antd/tag";
import { NzListModule } from "ng-zorro-antd/list";
import { NzCalendarModule } from "ng-zorro-antd/calendar";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { NzCheckboxModule } from "ng-zorro-antd/checkbox";
import { NzModalModule } from "ng-zorro-antd/modal";
import { NzUploadModule } from "ng-zorro-antd/upload";
import { NzMessageModule } from "ng-zorro-antd/message";
import { ProjectComponent } from "./project/project.component";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { NzPaginationModule } from "ng-zorro-antd/pagination";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzSwitchModule } from "ng-zorro-antd/switch";
import { NzDrawerModule } from "ng-zorro-antd/drawer";
import { NzImageModule } from "ng-zorro-antd/image";

import { NgOtpInputModule } from "ng-otp-input";

import { BrandsComponent } from "./project/brands/brands.component";
import { CategoryComponent } from "./project/category/category.component";
import { MediaComponent } from "./project/media/media.component";
import { ProductsComponent } from "./project/products/products.component";
import { BannersComponent } from "./project/banners/banners.component";
import { SettingComponent } from "./project/setting/setting.component";
import { SpecGroupComponent } from "./project/spec-group/spec-group.component";
import { SpecValueComponent } from "./project/spec-value/spec-value.component";
import { LoginComponent } from "./project/login/login.component";
import { HomeComponent } from "./project/home/home.component";
import { OrderDetailsComponent } from "./project/order-details/order-details.component";
import { ForgotPasswordComponent } from "./project/forgot-password/forgot-password.component";
import { UserManagementComponent } from "./project/user-management/user-management.component";
import { OfferComponent } from "./project/offer/offer.component";
import { AddToOfferPageComponent } from "./project/add-to-offer-page/add-to-offer-page.component";
import { SpecificationsComponent } from "./project/specifications/specifications.component";
import { ToastrModule } from "ngx-toastr";
import { CouponCodeComponent } from "./project/coupon-code/coupon-code.component";
import { SettingPageComponent } from "./project/setting-page/setting-page.component";
import { DndDirective } from "./dnd.directive";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { BuildPcComponent } from "./project/build-pc/build-pc.component";
import { ColorSwitcherComponent } from "./project/color-switcher/color-switcher.component";
import { FroalaEditorModule, FroalaViewModule } from "angular-froala-wysiwyg";
import { CustomersComponent } from "./project/customers/customers.component";
import { PaymentsComponent } from "./project/payments/payments.component";
import { PolicyPageComponent } from "./project/policy-page/policy-page.component";
import { environment } from "src/environments/environment";
import { TagsComponent } from './project/tags/tags.component';

registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent,
    CommonLayoutComponent,
    FullLayoutComponent,
    DashboardComponent,
    AttributesComponent,
    ProjectComponent,
    BrandsComponent,
    CategoryComponent,
    MediaComponent,
    ProductsComponent,
    BannersComponent,
    SettingComponent,
    SpecGroupComponent,
    SpecValueComponent,
    LoginComponent,
    HomeComponent,
    OrderDetailsComponent,
    ForgotPasswordComponent,
    UserManagementComponent,
    OfferComponent,
    AddToOfferPageComponent,
    SpecificationsComponent,
    CouponCodeComponent,
    SettingPageComponent,
    DndDirective,
    BuildPcComponent,
    ColorSwitcherComponent,
    CustomersComponent,
    PaymentsComponent,
    PolicyPageComponent,
    TagsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NzBreadCrumbModule,
    TemplateModule,
    SharedModule,
    NgChartjsModule,
    NzButtonModule,
    NzCardModule,
    NzAvatarModule,
    NzRateModule,
    NzBadgeModule,
    NzProgressModule,
    NzRadioModule,
    NzTableModule,
    NzDropDownModule,
    NzTimelineModule,
    NzTabsModule,
    NzTagModule,
    NzListModule,
    NzCalendarModule,
    NzToolTipModule,
    NzCheckboxModule,
    NzModalModule,
    NzUploadModule,
    NzMessageModule,
    NzFormModule,
    NzSelectModule,
    NzInputModule,
    NzDatePickerModule,
    NzPaginationModule,
    NzSwitchModule,
    NzDrawerModule,
    NzImageModule,
    NgOtpInputModule,
    Ng2SearchPipeModule,
    NgxSpinnerModule,
    ToastrModule.forRoot(),
    ReactiveFormsModule,
    FormsModule,
    HighchartsChartModule,
    InfiniteScrollModule,
    AngularEditorModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
  ],
  providers: [
    {
      provide: NZ_I18N,
      useValue: en_US,
    },
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
    ThemeConstantService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
