<div class="container-fluid">
    <div class="row ">
        <div class="col-12 d-flex justify-content-between mb-3">
            <div class="">
                <h4>Media Library</h4>
            </div>
            <!-- <div class="">
                <a nz-button nzType="primary">
                    <span>Refresh</span>
                </a>
            </div> -->
            <div class="mobile_upload upload-button save-button">
                <label for='input-file'>Upload Files</label>
                <!-- <input id='input-file' type='file' multiple (change)="uploadImage($event  , 'direct')"> -->
                <input id='input-file' type='file' multiple (change)="uploadFile($event)">
            </div>
        </div>
        <div class="col-lg-12 mb-3 d-flex justify-content-start">
            <div class="search-input-section">
                <nz-input-group nzSearch [nzAddOnAfter]="suffixButton">
                    <input (keyup)="searchImage()" type="text" [(ngModel)]="searchInput"
                        [ngModelOptions]="{standalone: true}" nz-input placeholder="Search Images" />
                </nz-input-group>

                <ng-template #suffixButton>
                    <i nz-icon nzType="search" class="opacity-05 search-icon-section"></i>
                </ng-template>
            </div>
        </div>

        <!-- <div *ngFor="let image of gallery; let i=index" class="col-lg-2">
            <nz-card [ngClass]="{'image-card-section' : setBorder}" class="text-center" #local>
                <img (click)="selectedImage(i)" class="img-fluid" [src]="image.src" alt="">
            </nz-card>
        </div> -->
        <div infinite-scroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="10" [scrollWindow]="false"
            (scrolled)="onScroll()" class="col-12 scroll-section" [ngClass]="{'image-size-altering' : !mediaPage}">
            <div class="row justify-content-start">

                <div [ngClass]="{'col-lg-4 media_upload' : !mediaPage}" class="col-lg-12 dnd_column main-card mb-3">
                    <div class="drag_drop_container web_upload" appDnd (files)="filesDropped($event)">
                        <div class="drag_drop_section">
                            <h5 class="mb-1">Drag & Drop to upload</h5>
                            <span>OR</span>
                            <div class="upload-button save-button mt-1">
                                <label for='input-file1'>Select Files</label>
                                <input (change)="uploadFile($event)" multiple class="upload_input" id='input-file1'
                                    type='file'>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngFor="let image of gallery ; let i=index" class="col-lg-2 col-sm-3 main-card mb-3">
                    <div class="text-center image_card" [ngClass]="{'image_media_card' : mediaPage}">
                        <input (click)="newCheckChange($event ,check , image._id)" #check
                            [checked]="MaxSelect.indexOf(image._id) > -1 && !mediaPage" type="checkbox"
                            id="{{image._id}}">
                        <label for="{{image._id}}">
                            <div class="">
                                <img nz-dropdown [nzDropdownMenu]="menu" [nzPlacement]="'topRight'"
                                    [ngClass]="{'show_copy_image' : imageCopied}" class="copy_option"
                                    src="../../../assets/images/logo/copy_icon.png" alt="">
                                <p [ngClass]="{'show_copy_text' : image._id == tempId && imageCopied}"
                                    *ngIf="image._id == tempId && imageCopied || tempId == liveUrl+image?.image?.url && imageCopied"
                                    class="copied_text">Copied!.</p>
                                <nz-dropdown-menu #menu="nzDropdownMenu">
                                    <ul nz-menu>
                                        <li (click)="copyUrl($event , image._id , image._id)" nz-menu-item>copy id</li>
                                        <li (click)="copyUrl($event , image._id ,liveUrl+image?.image?.url)"
                                            nz-menu-item>copy url
                                        </li>
                                    </ul>
                                </nz-dropdown-menu>
                            </div>
                            <img class="img-fluid" [src]="liveUrl+image?.image?.url" />
                            <span class="cover-checkbox">
                                <svg viewBox="0 0 12 10">
                                    <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                </svg>
                            </span>
                            <!-- <div class="product-title-section">
                                <nz-input-group class="product-name mt-3">
                                    <input type="text" [readonly]=true nz-input value={{image.name}}>
                                </nz-input-group>
                            </div> -->
                        </label>
                    </div>
                </div>
            </div>
            <ngx-spinner bdColor="rgb(255 255 255 / 88%)" size="medium" color="#00f226" type="ball-spin-clockwise"
                [fullScreen]="false">
            </ngx-spinner>
        </div>
    </div>

    <!-- LOADER -->


</div>

<!-- <div class="cont-bg"> -->

<!-- <div class="cont-main"> -->
<!-- <div class="cont-checkbox" *ngFor="let image of gallery; let i=index">
    <input type="checkbox" id="{{image.id}}" />
    <label for="{{image.id}}">
          <img [src]="image.src" />
           <span class="cover-checkbox">
             <svg viewBox="0 0 12 10">
               <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
             </svg>
           </span>
       </label>
</div> -->
<!-- </div> -->
<!-- </div> -->