<h3 *ngIf="showTable" class="category-heading-outside">Products</h3>


<nz-card *ngIf="showTable">
    <div class="row justify-content-between m-b-30">
        <div class="col-xl-3 col-lg-5 col-10 col-md-5 col-sm-5 col-12 search_section">
            <div class="w-100">
                <div class="m-r-20">
                    <nz-input-group [nzPrefix]="prefixTemplate">
                        <input (keyup)="searchProducts()" autocomplete="off" type="text" nz-input
                            placeholder="Enter Product Name" [(ngModel)]="searchInputKey">
                    </nz-input-group>
                    <ng-template #prefixTemplate>
                        <i nz-icon nzType="search" class="opacity-05"></i>
                    </ng-template>
                </div>
            </div>
            <div class="filter_for_mobile">
                <span class="more_icon" nzTrigger="click" nz-button nz-dropdown [nzDropdownMenu]="moreOption" nz-icon
                    nzType="more" nzTheme="outline"></span>
            </div>

            <nz-dropdown-menu #moreOption="nzDropdownMenu">
                <ul class="drop_down_menu" nz-menu>
                    <li (click)="openTableFilter()" nz-menu-item>
                        Filter
                    </li>
                    <li *ngIf="filterApplied" (click)="resetFilters()" nz-menu-item>
                        Clear Filter
                    </li>
                    <li nz-dropdown nz-button nzTrigger="click" [nzDropdownMenu]="filterOptions" nz-menu-item>
                        Custom Field
                    </li>
                    <li [nzLoading]="downloadingFile" (click)="downloadProductsAsFile()" nz-menu-item>
                        Download
                    </li>
                </ul>
            </nz-dropdown-menu>
        </div>



        <div *ngIf="checkboxActive" class="col-xl-2 col-lg-6 col-md-5 col-8">
            <!-- <a class="publish-button" nz-button nzType=""><span>Publish Selected</span></a> -->
            <div class="">
                <nz-select nzShowSearch [(ngModel)]="selectedOption" class="w-100" nzPlaceHolder="None">
                    <!-- <nz-option nzValue="Ready for immediate dispatch"
                        nzLabel="Ready for immediate dispatch"></nz-option> -->
                    <nz-option nzValue="In Stock" nzLabel="In Stock"></nz-option>
                    <nz-option nzValue="Out of Stock" nzLabel="Out of Stock"></nz-option>
                    <!-- <nz-option nzValue="Against Order" nzLabel="Against Order"></nz-option>
                    <nz-option nzValue="Pre Order" nzLabel="Pre Order"></nz-option> -->
                </nz-select>
            </div>
        </div>
        <div *ngIf="checkboxActive" class="col-xl-1 col-lg-4 col-md-2 col-4 checkboxColumn">
            <a [nzLoading]="okLoading" (click)="updateStockStatus()" class="publish-button" nz-button
                nzType=""><span>Submit</span></a>
        </div>

        <div class="col-xl-6 col-md-5 col-sm-5 col-2 text-right add-button-section filter_add_section">
            <button (click)="openTableFilter()" nz-button class="save-button filter_for_web">
                Filter
            </button>

            <button *ngIf="filterApplied" (click)="resetFilters()" nz-button class="save-button filter_for_web">
                Clear Filter
            </button>

            <div class="">
                <a nzTrigger="click" nz-button class="save-button" nz-dropdown [nzDropdownMenu]="menu">
                    <i nz-icon nzType="plus-circle" theme="outline"></i>
                    <span>Add Product</span>
                </a>
                <nz-dropdown-menu #menu="nzDropdownMenu">
                    <ul class="drop_down_menu" nz-menu>
                        <li (click)="showFormDetails()" nz-menu-item>
                            <i nz-icon nzType="plus" theme="outline"></i> New
                        </li>
                        <li nz-menu-item>
                            <label for='input-excel'>
                                <span nz-icon nzType="cloud-upload" nzTheme="outline"></span>
                                Bulk Upload
                            </label>
                            <input (change)="uploadExcelFile($event)" class="upload_input" id='input-excel' type='file'>

                        </li>
                    </ul>
                </nz-dropdown-menu>
            </div>

            <div class="filter_for_web">
                <button class="save-button" nz-dropdown nz-button nzTrigger="click" [nzDropdownMenu]="filterOptions">
                    <!-- <i nz-icon nzType="plus-circle" theme="outline"></i> -->
                    <i>
                        <img class="select_list_icon" src="../../../assets/images/logo/select_list.png" alt="">
                    </i>
                </button>
                <nz-dropdown-menu #filterOptions="nzDropdownMenu">
                    <ul nz-menu>
                        <li class="table_filter_options" *ngFor="let order of orderColumn">
                            <label [ngModel]="tableFilterArr.indexOf(order.title) >= 0"
                                (ngModelChange)="tableFilterChange(order.title)" nz-checkbox>{{order.title}}</label>
                        </li>
                    </ul>
                </nz-dropdown-menu>
            </div>

            <div class="filter_for_web">
                <button [nzLoading]="downloadingFile" (click)="downloadProductsAsFile()" class="save-button" nz-button>
                    <!-- <i nz-icon nzType="plus-circle" theme="outline"></i> -->
                    <i nz-icon nzType="download" nzTheme="outline"></i>
                </button>
            </div>
        </div>


    </div>
    <nz-table
        [nzFooter]="totalCount == 0 ? '' : 'Showing ' +  (((pageSize * pageIndex) - pageSize) + 1) + ' to ' +  (pageSize * pageIndex > totalCount ? totalCount : pageSize * pageIndex) +  ' of ' +  totalCount"
        #productsListTable [nzData]="productsList" [nzScroll]="{ x: tableFilterArr.length*130 + 'px', y: '50vh'}"
        [nzFrontPagination]="false" [nzTotal]="totalCount" [nzPageSize]="pageSize" [nzPageIndex]="pageIndex"
        (nzQueryParams)="onPageNumberChange($event)">
        <thead>
            <tr>
                <th nzWidth="40px">
                    <!-- <input class="checkbox-label" type="checkbox" (ngModelChange)="SelectAll()" [(ngModel)]="Checked"
                        class="form-check-input"> -->
                </th>
                <ng-container *ngFor="let column of orderColumn">
                    <th *ngIf="tableFilterArr.indexOf(column.title) >= 0" [nzWidth]="column.width">{{ column.title }}
                    </th>
                </ng-container>
            </tr>
        </thead>
        <tbody>
            <tr id="productTable" *ngFor="let item of productsListTable.data;  let i=index">
                <td>
                    <!-- <label class="checkbox-label" (ngModelChange)="checkSelected(item)" nz-checkbox [(ngModel)]="item.isSelected"></label> -->
                    <input class="checkbox-label" type="checkbox" (ngModelChange)="checkSelected(item)"
                        [(ngModel)]="item.isSelected" class="form-check-input">
                </td>
                <!-- <td>{{i+1}}</td> -->
                <td *ngIf="tableFilterArr.indexOf('Image') >= 0">
                    <!-- <nz-avatar nzShape="square" [nzSize]="50" nzIcon="picture" [nzSrc]="liveUrl+item.image"></nz-avatar> -->
                    <img class="table_image" nz-image [nzSrc]="liveUrl+item.image" alt="">
                </td>
                <td *ngIf="tableFilterArr.indexOf('Name') >= 0">
                    <div nz-tooltip [nzTooltipTitle]="item.name" (click)="editProduct(item)" class="product-name ">
                        {{item.name ? item.name.length > 18 ? (item.name | slice : 0 : 18) + '...' : item.name : '-'}}
                    </div>
                </td>
                <!-- <td *ngIf="tableFilterArr.indexOf('SKU ID') >= 0">
                    <div class="d-flex align-items-center">
                        {{item.Skuld ? item.Skuld : '-'}}
                    </div>
                </td> -->
                <td *ngIf="tableFilterArr.indexOf('Stock') >= 0">
                    <span [ngClass]="{'un-published' : item?.outOfStock}" class="text-capitalize published">
                        {{item?.outOfStock ? 'Out Of Stock' : 'In Stock'}}</span>
                </td>
                <!-- <td *ngIf="tableFilterArr.indexOf('Brand') >= 0">{{item.brand ? item.brand.name : '-'}}</td> -->
                <td *ngIf="tableFilterArr.indexOf('Category') >= 0">{{item.category[0] ? item.category[0].name : '-'}}
                </td>
                <td *ngIf="tableFilterArr.indexOf('Regular Price') >= 0">{{item?.regularPrice ? '$ ' +
                    item?.regularPrice?.toFixed(2) : '-'}}</td>
                <td *ngIf="tableFilterArr.indexOf('Sale Price') >= 0">{{item?.salePrice ? '$ ' +
                    item?.salePrice?.toFixed(2) :
                    '-'}}</td>


                <!-- <td *ngIf="tableFilterArr.indexOf('Best Seller') >= 0">
                    <nz-switch [nzLoading]="productsLoading" [(ngModel)]="item.bestseller"
                        (ngModelChange)="directUpdate(item , $event , 'bestSeller')"></nz-switch>
                </td> -->
                <!-- <td *ngIf="tableFilterArr.indexOf('Featured') >= 0">
                    <nz-switch [nzLoading]="productsLoading" [(ngModel)]="item.featured"
                        (ngModelChange)="directUpdate(item , $event , 'featured')"></nz-switch>
                </td> -->
                <td *ngIf="tableFilterArr.indexOf('Status') >= 0">
                    <!-- <span [ngClass]="{'un-published' : !item.active}" class="text-capitalize published">{{item.active ?
                        'Published' : 'Unpublished'}}</span> -->
                    <nz-switch [nzLoading]="productsLoading" [(ngModel)]="item.active"
                        (ngModelChange)="directUpdate(item , $event, 'active')"></nz-switch>
                </td>
                <!-- <td>
                    <div *ngFor="let data of item.tags" class="tag-name d-flex">
                        <p>{{data}}</p>
                    </div>
                </td> -->

                <!-- <td>{{item.type}}</td> -->
                <!-- <td>{{item.modelNumber}}</td> -->
                <!-- <td class="">
                    <button (click)="addOffer(item , 'single-product')" nz-button>{{item.isInOffer ? 'Remove' : 'Add to'}}
                        Offer</button>
                </td> -->
            </tr>
        </tbody>
        <!-- LOADER -->

        <ngx-spinner *ngIf="productSpinner" bdColor="rgb(255 255 255 / 88%)" size="medium" color="#00f226"
            type="ball-spin-clockwise" [fullScreen]="false"></ngx-spinner>
    </nz-table>
</nz-card>

<!-- (ngModelChange)="search()" Goes to line 7 in top -->

<!-- ADD PRODUCTS FORM DESIGN STARTS  -->

<div *ngIf="showForm" class="main_form_section">
    <div class="row justify-content-center">
        <div class="col-lg-6 col-8 text-left mb-3">
            <div class="">
                <h4>
                    <span class="back_icon" (click)="draftAlert()" nz-icon nzType="left" nzTheme="outline">
                    </span> {{defaultMode == 'Add'? 'Add New': 'Edit'}} Product
                </h4>
            </div>
        </div>
        <div class="col-lg-6 col-4 text-right publish_button_section mb-3">
            <a (click)="saveAsDraft()" nz-button nzType="default" class="">
                <span>Draft</span>
            </a>
            <a [nzLoading]="publishing" (click)="doChanges()" nz-button class="save-button">
                <span>Publish</span>
            </a>
        </div>
        <div class="col-12 form-details">
            <form nz-form>
                <div class="row justify-content-start">
                    <div class="col-12">

                        <div class="row product_data_div">
                            <!-- <div class="col-12  product_data_heading">
                                <h5>General</h5>
                            </div> -->

                            <div class="col-lg-8 left_column">
                                <nz-card>
                                    <div class="" nz-row [nzGutter]="24">
                                        <!-- <div nz-col [nzXs]="24" [nzSpan]="12">
                                    <nz-form-item>
                                        <nz-form-label class="large_label" [nzSm]="24" [nzXs]="24" nzRequired
                                            nzFor="ProductType">Type
                                        </nz-form-label>
                                        <nz-form-control>
                                            <nz-input-group>
                                                <nz-select id="ProductType" [(ngModel)]="productType"
                                                    [ngModelOptions]="{standalone: true}" nzShowSearch class="w-100"
                                                    nzPlaceHolder="simple">
                                                    <nz-option nzValue="simple" nzLabel="simple"></nz-option>
                                                </nz-select>
                                            </nz-input-group>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div> -->
                                        <div nz-col [nzXs]="24" class="" [nzSm]="24" [nzSpan]="12">
                                            <h5>General</h5>
                                        </div>
                                        <div nz-col [nzXs]="24" class="" [nzSm]="24" [nzSpan]="12">
                                            <nz-form-item>
                                                <nz-form-label class="" class="large_label" [nzSm]="24" [nzXs]="24"
                                                    nzRequired nzFor="ProductName">Product Name
                                                </nz-form-label>
                                                <nz-form-control>
                                                    <nz-input-group>
                                                        <input required autocomplete="off" type="text"
                                                            [(ngModel)]="productName"
                                                            [ngModelOptions]="{standalone: true}"
                                                            (ngModelChange)="addSlugName()" nz-input id="ProductName"
                                                            placeholder="Enter Product Name">
                                                    </nz-input-group>
                                                    <p class="error-message" *ngIf="requiredData == 'productName'">
                                                        *Please Enter Product Name</p>
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div>

                                        <div nz-col [nzXs]="24" class="" [nzSm]="24" [nzSpan]="12">
                                            <nz-form-item>
                                                <nz-form-label class="" class="large_label" [nzSm]="24" [nzXs]="24"
                                                    nzRequired nzFor="ProductName">Slug
                                                </nz-form-label>
                                                <nz-form-control>
                                                    <nz-input-group>
                                                        <input required autocomplete="off" type="text"
                                                            [(ngModel)]="slugName" [ngModelOptions]="{standalone: true}"
                                                            nz-input id="ProductName" placeholder="Enter Product Name">
                                                    </nz-input-group>
                                                    <!-- <p class="error-message" *ngIf="requiredData == 'slugName'">
                                                        *Please Enter Product Name</p> -->
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div>

                                        <div nz-col [nzXs]="24" class="large_label products_editor" [nzSm]="24"
                                            [nzSpan]="12">
                                            <nz-form-item>
                                                <!-- <nz-form-label class="large_label" [nzSm]="24" [nzXs]="24" nzRequired
                                                    nzFor="ProductShortDescription "> Short Description</nz-form-label> -->
                                                <nz-form-label class="large_label" [nzSm]="24" [nzXs]="24" nzRequired
                                                    nzFor="ProductShortDescription ">Description</nz-form-label>
                                                <nz-form-control>
                                                    <nz-input-group>
                                                        <!-- <textarea id="ProductShortDescription"
                                                            [(ngModel)]="productShortDescription"
                                                            [ngModelOptions]="{standalone: true}" nz-input
                                                            placeholder="Enter Your Text Here "
                                                            [nzAutosize]="{ minRows: 3, maxRows: 6 } "></textarea> -->
                                                        <angular-editor [(ngModel)]="productShortDescription"
                                                            [ngModelOptions]="{standalone: true}" [config]="config">
                                                        </angular-editor>
                                                    </nz-input-group>
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div>

                                        <!-- <div nz-col [nzXs]="24" class="large_label products_editor" [nzSm]="24"
                                            [nzSpan]="12">
                                            <div class="row">
                                                <div class="col-12">
                                                    <nz-form-label class="large_label" [nzSm]="24" [nzXs]="24"
                                                        nzRequired>
                                                        Technical Specification
                                                    </nz-form-label>
                                                    <angular-editor [(ngModel)]="productLongDescription"
                                                        [ngModelOptions]="{standalone: true}" [config]="config">
                                                    </angular-editor>
                                                </div>
                                            </div>
                                        </div> -->
                                    </div>
                                </nz-card>
                            </div>

                            <div class="col-lg-4 right_column">
                                <nz-card>
                                    <div class="" nz-row [nzGutter]="24">

                                        <div nz-col [nzXs]="24" [nzSpan]="12">
                                            <nz-form-item>
                                                <nz-form-label class="large_label" [nzSm]="24" [nzXs]="24" nzRequired
                                                    nzFor="ProductImage">Image
                                                </nz-form-label>
                                                <nz-form-control nzErrorTip="Please input your username!">
                                                    <nz-input-group>
                                                        <button type="button" nz-button class="cancel-button"
                                                            (click)="showMedia()">
                                                            <span>Select from media</span>
                                                        </button>
                                                        <img style="width: 60px;padding-left: 10px;"
                                                            *ngFor="let category of productImages" nz-image
                                                            [nzSrc]="liveUrl+category?.image?.url">
                                                        <!-- <p *ngFor="let category of productImages">"{{category.image._id}}"</p> -->
                                                        <!-- <p *ngFor="let category of productImage">product</p> -->
                                                    </nz-input-group>
                                                    <p class="error-message" *ngIf="requiredData == 'productImages'">
                                                        *Please Select Product Image
                                                    </p>
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div>

                                        <!-- <div nz-col [nzXs]="24" [nzSpan]="12">
                                            <nz-form-item>
                                                <nz-form-label class="large_label" [nzSm]="24" [nzXs]="24" nzRequired
                                                    nzFor="Brand">
                                                    Brand</nz-form-label>
                                                <nz-form-control>
                                                    <nz-input-group>
                                                        <nz-select #brandSelect id="Brand" [(ngModel)]="selectedBrand"
                                                            [ngModelOptions]="{standalone: true}" nzShowSearch
                                                            class="w-100" (ngModelChange)="brandValueChange()"
                                                            (keyup)="brandFilter(brandSelect)"
                                                            (click)="onBrandFocus(brandSelect)"
                                                            nzPlaceHolder="Select Brand">
                                                            <nz-option
                                                                *ngIf="addBrandsOption && filteredBrands.length == 0"
                                                                nzValue="addBrand"
                                                                [nzLabel]="'click to add new Brand : ' + searchValue">
                                                            </nz-option>
                                                            <nz-option *ngFor="let column of brandsList"
                                                                [nzValue]="column._id" [nzLabel]="column.name">
                                                            </nz-option>
                                                        </nz-select>
                                                    </nz-input-group>
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div> -->

                                        <!-- <div nz-col [nzXs]="24" [nzSpan]="12">
                                            <nz-form-item>
                                                <nz-form-label class="large_label" [nzSm]="24" [nzXs]="24" nzRequired
                                                    nzFor="ProductModelNumber">Model Number
                                                </nz-form-label>
                                                <nz-form-control>
                                                    <nz-input-group>
                                                        <input autocomplete="off" type="text" [(ngModel)]="modelNo"
                                                            [ngModelOptions]="{standalone: true}" nz-input
                                                            id="ProductModelNumber" placeholder="Enter Model Number">
                                                    </nz-input-group>
                                                    <p class="error-message" *ngIf="requiredData == 'modelNo'">*Please
                                                        Enter Model Number
                                                    </p>
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div> -->

                                        <div nz-col [nzXs]="24" class="" [nzSm]="24" [nzSpan]="12">
                                            <nz-form-item>
                                                <nz-form-label class="large_label" [nzSm]="24" [nzXs]="24"
                                                    nzRequired>Tags
                                                </nz-form-label>
                                                <nz-form-control>
                                                    <nz-input-group>
                                                        <nz-select #tagSelect nzMode="multiple"
                                                            [(ngModel)]="productTags"
                                                            [ngModelOptions]="{standalone: true}"
                                                            (ngModelChange)="tagValueChange()" nzShowSearch nzAllowClear
                                                            class="w-100 tags_multi_select" nzPlaceHolder="select tags"
                                                            (keyup)="tagFilter(tagSelect)"
                                                            (click)="onTagFocus(tagSelect)">

                                                            <nz-option *ngIf="addTagOption && filteredTags.length == 0"
                                                                nzValue="addNewTagDirect"
                                                                [nzLabel]="'click to add new Tag : ' + searchTag">
                                                            </nz-option>

                                                            <nz-option *ngFor="let tag of tags" [nzValue]="tag?._id"
                                                                [nzLabel]="tag?.name"></nz-option>
                                                        </nz-select>
                                                    </nz-input-group>
                                                    <p class="error-message" *ngIf="requiredData == 'productTags'">
                                                        *Please Enter Product Tags
                                                    </p>
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div>
                                    </div>
                                </nz-card>

                                <!-- category -->
                                <nz-card>
                                    <div class="" nz-row [nzGutter]="24">
                                        <div nz-col [nzXs]="24" class="" [nzSm]="24" [nzSpan]="12">
                                            <nz-form-item>
                                                <nz-form-label class="large_label" [nzSm]="24" [nzXs]="24" nzRequired
                                                    nzFor="BrandCategory">Select Category
                                                </nz-form-label>
                                                <nz-form-control>
                                                    <nz-input-group>
                                                        <nz-select #categorySelect nzMode="multiple"
                                                            [(ngModel)]="categoryList"
                                                            [ngModelOptions]="{standalone: true}" id="BrandCategory"
                                                            nzShowSearch class="w-100"
                                                            (ngModelChange)="categoryValueChange()"
                                                            (keyup)="categoryFilter(categorySelect)"
                                                            (click)="onCategoryFocus(categorySelect)"
                                                            nzPlaceHolder="select category">
                                                            <nz-option
                                                                *ngIf="addCategoryOption && filteredCategories.length == 0"
                                                                nzValue="addCategory"
                                                                [nzLabel]="'click to add new Category : ' + searchValue">
                                                            </nz-option>
                                                            <nz-option *ngFor="let column of categoriesLists"
                                                                nzValue="{{column._id}}" nzLabel="{{column.name}}">
                                                            </nz-option>
                                                        </nz-select>
                                                    </nz-input-group>
                                                    <p class="error-message" *ngIf="requiredData == 'categoryList'">
                                                        *Please Select Category List</p>
                                                </nz-form-control>
                                            </nz-form-item>

                                            <!-- add new category form -->
                                            <ng-container *ngIf="categoryForm">
                                                <nz-form-item>
                                                    <nz-form-label class="large_label" [nzSm]="24" [nzXs]="24"
                                                        nzRequired nzFor="CategoryName">
                                                        Name
                                                    </nz-form-label>
                                                    <nz-form-control nzErrorTip="Please input your username!">
                                                        <nz-input-group>
                                                            <input autocomplete="off" type="text"
                                                                [(ngModel)]="categoryName"
                                                                [ngModelOptions]="{standalone: true}" nz-input
                                                                id="CategoryName" placeholder="Enter Category Name">
                                                        </nz-input-group>
                                                        <p class="error-message" *ngIf="requiredData == 'name'">
                                                            *Please Enter Category Name
                                                        </p>
                                                    </nz-form-control>
                                                </nz-form-item>

                                                <nz-form-item>
                                                    <nz-form-label class="large_label" [nzSm]="24" [nzXs]="24"
                                                        nzRequired nzFor="BrandWebsite">
                                                        Parent
                                                    </nz-form-label>
                                                    <nz-form-control>
                                                        <nz-input-group>
                                                            <nz-select [(ngModel)]="parentId"
                                                                [ngModelOptions]="{standalone: true}" id="BrandWebsite"
                                                                nzShowSearch class="w-100"
                                                                nzPlaceHolder="Select Parent Category">
                                                                <nz-option *ngFor="let spec of categoriesLists"
                                                                    [nzValue]="spec._id" [nzLabel]="spec.name">
                                                                </nz-option>
                                                            </nz-select>
                                                        </nz-input-group>
                                                    </nz-form-control>
                                                </nz-form-item>

                                                <nz-form-item class="text-center">
                                                    <nz-form-control>
                                                        <!-- <button (click)="resetForm()" type="button" class="mr-3 cancel-button" nz-button
                                                                nzType="default">Reset</button> -->
                                                        <a>
                                                            <button type="button" (click)="addNewCategory()" nz-button
                                                                class="save-button">Add</button>
                                                        </a>
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </ng-container>
                                        </div>

                                        <!-- <div nz-col [nzXs]="24" [nzSpan]="12">
                                            <nz-form-item class="text-center">
                                                <nz-form-control>
                                                    <button (click)="resetForm()" type="reset"
                                                        class="mr-3 cancel-button" nz-button
                                                        nzType="default">Reset</button>
                                                    <a [nzLoading]="okLoading" *ngIf="defaultMode == 'Add'"><button
                                                            (click)="doChanges()" type="button" nz-button
                                                            class="save-button">Create</button></a>
                                                    <a [nzLoading]="okLoading" *ngIf="defaultMode == 'Edit'"><button
                                                            (click)="doChanges()" type="button" nz-button
                                                            class="save-button">Update</button></a>

                                                </nz-form-control>
                                            </nz-form-item>
                                        </div> -->
                                    </div>
                                </nz-card>
                                <!-- </nz-card> -->
                            </div>
                        </div>

                        <div class="row product_data_div">
                            <div class="col-lg-6">
                                <nz-card>
                                    <div class="" nz-row [nzGutter]="24">
                                        <div nz-col [nzXs]="24" [nzSpan]="12">
                                            <h5>Pricing</h5>
                                            <div class="" nz-row [nzGutter]="24">
                                                <div nz-col [nzXs]="24" [nzSpan]="12">
                                                    <nz-form-item>
                                                        <nz-form-label class="large_label" [nzSm]="24" [nzXs]="24"
                                                            nzRequired nzFor="ProductRegularPrice">Regular Price
                                                        </nz-form-label>
                                                        <nz-form-control>
                                                            <nz-input-group>
                                                                <input autocomplete="off" type="number" min="1"
                                                                    [(ngModel)]="regularPrice"
                                                                    [ngModelOptions]="{standalone: true}"
                                                                    (ngModelChange)="regularPriceChange()" nz-input
                                                                    id="ProductRegularPrice"
                                                                    placeholder="Enter Regular Price">
                                                            </nz-input-group>
                                                            <p class="error-message"
                                                                *ngIf="requiredData == 'regularPrice' && notRegularPrice">
                                                                *Please Enter Regular Price
                                                            </p>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </div>

                                                <div nz-col [nzXs]="24" [nzSpan]="12">
                                                    <nz-form-item>
                                                        <nz-form-label class="large_label" [nzSm]="24" [nzXs]="24"
                                                            nzRequired nzFor="ProductSalePrice">Sale Price
                                                        </nz-form-label>
                                                        <nz-form-control>
                                                            <nz-input-group>
                                                                <input autocomplete="off" min="1" type="number"
                                                                    [(ngModel)]="sellPrice"
                                                                    [ngModelOptions]="{standalone: true}"
                                                                    [ngClass]="{'price_alert':priceAlert}"
                                                                    (ngModelChange)="sellPriceChange()" nz-input
                                                                    id="ProductSalePrice"
                                                                    placeholder="Enter Sale Price">
                                                            </nz-input-group>
                                                            <p class="error-message"
                                                                *ngIf="requiredData == 'sellPrice'&& notSellPrice">
                                                                *Please Enter Sale Price
                                                            </p>
                                                            <p class="error-message" *ngIf="priceAlert">
                                                                *Entered price is greater than regular price
                                                            </p>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- <div nz-col [nzXs]="24" [nzSpan]="12">
                                            <h5>Status</h5>
                                            <div class="" nz-row [nzGutter]="24">
                                                <div nz-col [nzXs]="9" [nzSpan]="12">
                                                    <nz-form-item>
                                                        <nz-form-label class="large_label" nzRequired nzFor="ProductBestSeller">
                                                            Best Seller
                                                        </nz-form-label>
                                                        <nz-form-control>
                                                            <nz-switch id="ProductBestSeller" [(ngModel)]="isBestSeller"
                                                                [ngModelOptions]="{standalone: true}"
                                                                [nzCheckedChildren]="checkedTemplate"
                                                                [nzUnCheckedChildren]="unCheckedTemplate"></nz-switch>
                                                            <ng-template #checkedTemplate><i nz-icon nzType="check"></i>
                                                            </ng-template>
                                                            <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i>
                                                            </ng-template>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </div>
        
                                                <div nz-col [nzXs]="9" [nzSpan]="12">
                                                    <nz-form-item>
                                                        <nz-form-label nzRequired nzFor="ProductFeature">Feature
                                                        </nz-form-label>
                                                        <nz-form-control>
                                                         
                                                            <nz-switch id="ProductFeature" [(ngModel)]="isFeatured"
                                                                [ngModelOptions]="{standalone: true}"
                                                                [nzCheckedChildren]="checkedTemplate"
                                                                [nzUnCheckedChildren]="unCheckedTemplate"></nz-switch>
                                                            <ng-template #checkedTemplate><i nz-icon nzType="check"></i>
                                                            </ng-template>
                                                            <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i>
                                                            </ng-template>
                                                            
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </div>
        
                                                <div nz-col [nzXs]="24" [nzSpan]="12">
                                                    <nz-form-item>
                                                        <nz-form-label nzRequired nzFor="ProductActive">Active
                                                        </nz-form-label>
                                                        <nz-form-control>
                                                            <nz-switch id="ProductActive" [(ngModel)]="isActive"
                                                                [ngModelOptions]="{standalone: true}"
                                                                [nzCheckedChildren]="checkedTemplate"
                                                                [nzUnCheckedChildren]="unCheckedTemplate">
                                                            </nz-switch>
                                                            <ng-template #checkedTemplate><i nz-icon nzType="check"></i>
                                                            </ng-template>
                                                            <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i>
                                                            </ng-template>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </div>
                                            </div>
                                        </div> -->
                                    </div>
                                </nz-card>
                            </div>
                        </div>
                    </div>





                    <!-- <div class="col-lg-6">
                        <nz-card>
                            <div class="row product_data_div">
                                <div class="col-12  product_data_heading">
                                    <h5>Inventory</h5>
                                </div>
                                <div class="col-12">
                                    <div nz-col [nzXs]="24" [nzSpan]="12">
                                        <nz-form-item>
                                            <nz-form-label class="large_label" [nzSm]="24" [nzXs]="24" nzRequired
                                                nzFor="ProductSKUID">SKU ID
                                            </nz-form-label>
                                            <nz-form-control>
                                                <nz-input-group>
                                                    <input autocomplete="off" type="text" [(ngModel)]="productSkuID"
                                                        [ngModelOptions]="{standalone: true}" nz-input id="ProductSKUID"
                                                        placeholder="Enter SKU ID">
                                                </nz-input-group>
                                                <p class="error-message" *ngIf="requiredData == 'SkuID'">*Please Enter
                                                    Sku ID</p>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>

                                    <div nz-col [nzXs]="24" [nzSpan]="12">
                                        <nz-form-item>
                                            <nz-form-label class="large_label" nzRequired nzFor="ProductBestSeller">
                                                Manage Stock
                                            </nz-form-label>
                                            <nz-form-control>
                                                <nz-switch id="ProductBestSeller" [(ngModel)]="manageStock"
                                                    [ngModelOptions]="{standalone: true}"
                                                    [nzCheckedChildren]="checkedTemplate"
                                                    [nzUnCheckedChildren]="unCheckedTemplate"></nz-switch>
                                                <ng-template #checkedTemplate><i nz-icon nzType="check"></i>
                                                </ng-template>
                                                <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i>
                                                </ng-template>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>

                                    <div *ngIf="manageStock" nz-col [nzXs]="24" [nzSpan]="12">
                                        <nz-form-item>
                                            <nz-form-label class="large_label" [nzSm]="24" [nzXs]="24" nzRequired
                                                nzFor="ProductQuantity">Quantity
                                            </nz-form-label>
                                            <nz-form-control>
                                                <nz-input-group>
                                                    <input autocomplete="off" type="number" min="1"
                                                        [(ngModel)]="quantity" [ngModelOptions]="{standalone: true}"
                                                        nz-input id="ProductQuantity" placeholder="Enter Quantity">
                                                </nz-input-group>

                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>

                                    <div *ngIf="manageStock" nz-col [nzXs]="24" [nzSpan]="12">
                                        <nz-form-item>
                                            <nz-form-label class="large_label" [nzSm]="24" [nzXs]="24" nzRequired
                                                nzFor="minimumQuantityAlert">Minimum Quantity Alert
                                            </nz-form-label>
                                            <nz-form-control>
                                                <nz-input-group>
                                                    <input autocomplete="off" type="number" min="1"
                                                        [(ngModel)]="minimumQuantityAlert"
                                                        [ngModelOptions]="{standalone: true}" nz-input
                                                        id="minimumQuantityAlert" placeholder="Enter Minimum Quantity">
                                                </nz-input-group>

                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>

                                </div>
                            </div>
                        </nz-card>
                    </div> -->

                    <!-- <div class="col-12">
                        <div nz-col [nzXs]="24" class="" [nzSm]="24" [nzSpan]="12">
                            <nz-card>
                                <div class="row product_data_div">
                                    <div class="col-12  product_data_heading">
                                        <h5>Specification</h5>
                                    </div>

                                    <div class="col-12 product_data_right_column">
                                        <div class="" nz-row [nzGutter]="24">
                                            <div nz-col [nzSm]="7" [nzXs]="24" class="" [nzSpan]="12">
                                                <nz-form-item>
                                                    <nz-form-label class="large_label" [nzXs]="24" nzRequired>
                                                        Spec Group
                                                    </nz-form-label>
                                                    <nz-form-control>
                                                        <nz-input-group>
                                                            <nz-select #specGroup [(ngModel)]="selectedSpecGroup"
                                                                [ngModelOptions]="{standalone: true}"
                                                                (ngModelChange)="specGroupValueChange('group')"
                                                                nzShowSearch class="w-100"
                                                                nzPlaceHolder="Select Specification Group"
                                                                (keyup)="specGroupSearch(specGroup , 'group')"
                                                                (click)="onFocus(specGroup)">
                                                                <nz-option *ngIf="addOption && filteredData.length == 0"
                                                                    nzValue="addGroup"
                                                                    [nzLabel]="'click to add new Group : ' + searchValue">
                                                                </nz-option>
                                                                <nz-option *ngFor="let spec of allSpecGroups"
                                                                    [nzValue]="spec.name" [nzLabel]="spec.name">
                                                                </nz-option>
                                                            </nz-select>
                                                        </nz-input-group>
                                                        <p class="error-message"
                                                            *ngIf="errorField.indexOf('spec group') != -1">
                                                            *Please Select Specification Group</p>
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </div>
                                            <div nz-col [nzSm]="6" [nzXs]="24" class="" [nzSpan]="12">
                                                <nz-form-item>
                                                    <nz-form-label class="large_label" [nzXs]="24" nzRequired>
                                                        Spec Name
                                                    </nz-form-label>
                                                    <nz-form-control>
                                                        <nz-input-group>
                                                            <nz-select #specValue [(ngModel)]="selectedSpecValue"
                                                                [ngModelOptions]="{standalone: true}"
                                                                (ngModelChange)="specGroupValueChange('value')"
                                                                nzShowSearch class="w-100"
                                                                nzPlaceHolder="Select Specification Name"
                                                                (keyup)="specGroupSearch(specValue , 'value')"
                                                                (click)="onFocus(specValue)">
                                                                <nz-option *ngIf="addOption && filteredData.length == 0"
                                                                    nzValue="addValue"
                                                                    [nzLabel]="'click to add new Value : ' + searchValue">
                                                                </nz-option>
                                                                <nz-option
                                                                    *ngFor="let spec of allSpecValues ; let k = index"
                                                                    [nzValue]="spec" [nzLabel]="spec.name">
                                                                </nz-option>
                                                            </nz-select>
                                                        </nz-input-group>
                                                        <p class="error-message"
                                                            *ngIf="errorField.indexOf('spec name') != -1">
                                                            *Please Select Specification Name</p>
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </div>
                                            <div *ngIf="selectedSpecValue?.type == 'Value'" nz-col [nzSm]="6"
                                                [nzXs]="24" class="" [nzSpan]="12">
                                                <nz-form-item>
                                                    <nz-form-label class="large_label" [nzXs]="24" nzRequired>
                                                        Value
                                                    </nz-form-label>
                                                    <nz-form-control>
                                                        <nz-input-group>
                                                            <nz-select #acceptedValue
                                                                [(ngModel)]="selectedAcceptedValues"
                                                                [ngModelOptions]="{standalone: true}"
                                                                (ngModelChange)="specGroupValueChange('accepted value')"
                                                                nzShowSearch class="w-100" nzPlaceHolder="Select Values"
                                                                (keyup)="specGroupSearch(acceptedValue , 'accepted value')"
                                                                (click)="onFocus(acceptedValue)">
                                                                <nz-option *ngIf="addOption && filteredData.length == 0"
                                                                    nzValue="addAcceptedValue"
                                                                    [nzLabel]="'click to add new Value : ' + searchValue">
                                                                </nz-option>
                                                                <nz-option *ngFor="let value of acceptedValues"
                                                                    [nzValue]="value" [nzLabel]="value">
                                                                </nz-option>
                                                            </nz-select>
                                                        </nz-input-group>
                                                        <p class="error-message"
                                                            *ngIf="errorField.indexOf('spec value') != -1">
                                                            *Please Select Values</p>
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </div>

                                            <div *ngIf="selectedSpecValue?.type == 'Description'" nz-col [nzSm]="6"
                                                [nzXs]="24" class="" [nzSpan]="12">
                                                <nz-form-item>
                                                    <nz-form-label class="" class="large_label" [nzSm]="24" [nzXs]="24"
                                                        nzRequired nzFor="specDescription">Description
                                                    </nz-form-label>
                                                    <nz-form-control>
                                                        <nz-input-group>
                                                            <input required autocomplete="off" type="text"
                                                                [(ngModel)]="selectedAcceptedValues"
                                                                [ngModelOptions]="{standalone: true}" nz-input
                                                                id="specDescription" placeholder="Enter Description">
                                                        </nz-input-group>
                                                        <p class="error-message"
                                                            *ngIf="errorField.indexOf('spec value') != -1">
                                                            *Please Enter Description</p>
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </div>

                                            <div nz-col [nzSm]="3" [nzXs]="12" class="add_spec_column" [nzSpan]="12">
                                                <nz-form-item>
                                                    <nz-form-label class="large_label" [nzSm]="24" [nzXs]="6" nzRequired
                                                        nzFor="ProductBestSeller">
                                                        Highlight
                                                    </nz-form-label>
                                                    <nz-form-control [nzSm]="24" [nzXs]="12" class="spec_section">
                                                        <label nz-checkbox [(ngModel)]="specHighlighted"
                                                            [ngModelOptions]="{standalone: true}">
                                                            <span nz-icon nzType="star"
                                                                [nzTheme]="specHighlighted ? 'twotone' : 'outline'"></span>
                                                        </label>
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </div>

                                            <div nz-col [nzSm]="2" [nzXs]="12" class="add_spec_column" [nzSpan]="12">
                                                <span (click)="addSpecification()" nz-icon nzType="plus-circle"
                                                    nzTheme="outline"></span>
                                            </div>

                                            <div *ngIf="selectedSpecsTableData.length > 0 " nz-col [nzXs]="24" class=""
                                                [nzSpan]="24">
                                                <nz-table #specListTable [nzData]="selectedSpecsTableData"
                                                    [nzScroll]="{ x: '800px', y: '50vh'}" [nzFrontPagination]="false"
                                                    [nzTotal]="selectedSpecsTableData.length" [nzPageSize]="10">
                                                    <thead>
                                                        <tr>
                                                            <th *ngFor="let column of specTableHeading"
                                                                [nzWidth]="column.width">
                                                                {{ column.name }}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let item of selectedSpecsTableData;  let s=index">
                                                            <td>
                                                                {{item?.specGroup.name}}
                                                            </td>
                                                            <td>
                                                                {{item?.specName.name}}
                                                            </td>
                                                            <td>
                                                                {{item?.specValue}}
                                                            </td>
                                                            <td>
                                                                {{item.specName.isHighlighted ? 'Yes' : 'No'}}
                                                            </td>
                                                            <td>
                                                                <div class="spec_edit_options">
                                                                    <span (click)="editSpec(item , s)" nz-icon
                                                                        nzType="edit" nzTheme="outline"></span>
                                                                    <span (click)="deleteSpec(item , s)" nz-icon
                                                                        nzType="delete" nzTheme="outline"></span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>

                                                </nz-table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </nz-card>
                        </div>
                    </div> -->

                    <div *ngIf="productType == 'variable'" class="text-center mb-5 mt-5">
                        <h2>Product Attribute</h2>
                        <nz-form-item>
                            <nz-form-label class="large_label" [nzSm]="24" [nzXs]="24" nzRequired nzFor="ProductSelect">
                                Select Attribute
                            </nz-form-label>
                            <nz-form-control>
                                <nz-input-group>
                                    <nz-select nzMode="multiple" [(ngModel)]="selectedAttribute"
                                        [ngModelOptions]="{standalone: true}" id="ProductSelect" nzShowSearch
                                        class="w-100" nzPlaceHolder="None">
                                        <nz-option *ngFor="let column of attributeList" nzValue="{{column}}"
                                            nzLabel="{{column.name}}"></nz-option>
                                        <!-- <nz-option nzValue="Size" nzLabel="Size"></nz-option>
                                    <nz-option nzValue="" nzLabel=""></nz-option> -->
                                    </nz-select>
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>

                        <nz-form-item>
                            <nz-form-label class="large_label" [nzSm]="24" [nzXs]="24" nzRequired nzFor="ProductColor">
                                Color
                            </nz-form-label>
                            <nz-form-control>
                                <nz-input-group>
                                    <nz-select id="ProductColor" nzShowSearch class="w-100" nzPlaceHolder="Red">
                                        <nz-option nzValue="Red" nzLabel="Red"></nz-option>
                                        <nz-option nzValue="Blue" nzLabel="Blue"></nz-option>
                                        <nz-option nzValue="yellow" nzLabel="yellow"></nz-option>
                                    </nz-select>
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>

                        <nz-form-item>
                            <nz-form-label class="large_label" [nzSm]="24" [nzXs]="24" nzRequired nzFor="ProductSize">
                                Size</nz-form-label>
                            <nz-form-control>
                                <nz-input-group>
                                    <nz-select id="ProductSize" nzShowSearch class="w-100" nzPlaceHolder="XL">
                                        <nz-option nzValue="S" nzLabel="S"></nz-option>
                                        <nz-option nzValue="M" nzLabel="M"></nz-option>
                                        <nz-option nzValue="L" nzLabel="L"></nz-option>
                                        <nz-option nzValue="XL" nzLabel="XL"></nz-option>
                                    </nz-select>
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>

                        <nz-form-item>
                            <nz-form-label class="large_label" [nzSm]="24" [nzXs]="24" nzRequired nzFor="ProductSize">
                                Size</nz-form-label>
                            <nz-form-control>
                                <nz-input-group>
                                    <nz-select id="ProductSize" nzShowSearch class="w-100" nzPlaceHolder="XL">
                                        <nz-option nzValue="S" nzLabel="S"></nz-option>
                                        <nz-option nzValue="M" nzLabel="M"></nz-option>
                                        <nz-option nzValue="L" nzLabel="L"></nz-option>
                                        <nz-option nzValue="XL" nzLabel="XL"></nz-option>
                                    </nz-select>
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>

                        <nz-form-item class="text-center">
                            <nz-form-control>
                                <button type="button" nz-button class="save-button">Add Variation</button>
                            </nz-form-control>
                        </nz-form-item>

                        <nz-table [nzData]="VariationData">
                            <thead>
                                <tr>
                                    <th *ngFor="let title of VariationHeading">{{ title.heading }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of VariationData">
                                    <td>{{item.Variation}}</td>
                                    <td>{{item?.RegularPrice?.toFixed(2)}}</td>
                                    <td>{{item.SKU}}</td>
                                    <td>{{item?.SalePrice?.toFixed(2)}}</td>
                                    <td>{{item.Photo}}</td>
                                </tr>
                            </tbody>

                        </nz-table>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- ADD PRODUCTS FORM DESIGN ENDS  -->

<!-- add to offer  -->

<nz-card *ngIf="!showTable && !showForm" class="">
    <div class="row justify-content-center">
        <div class="col-12 mb-3 title-text">
            <div class="">
                <h4>
                    <span (click)="showTableData()" class="back_icon" nz-icon nzType="left" nzTheme="outline">
                    </span> Add Offer
                </h4>
            </div>
        </div>

        <div class="col-lg-12">
            <app-add-to-offer-page (offerUpdated)="showTableData()" [offerDetails]="selectedData">

            </app-add-to-offer-page>
        </div>
    </div>
</nz-card>

<!-- spec value modal -->

<nz-modal [(nzVisible)]="specValueModal" nzCentered nzTitle="Specification Value" (nzOnCancel)="closeSpecValueModal()">
    <ng-container *nzModalContent>
        <form nz-form>
            <nz-form-item>
                <nz-form-label class="large_label" [nzSm]="24" nzRequired nzFor="ValueName">Name</nz-form-label>
                <nz-form-control nzErrorTip="Please input your username!">
                    <nz-input-group>
                        <input autocomplete="off" type="text" [(ngModel)]="specValueName"
                            [ngModelOptions]="{standalone: true}" nz-input id="ValueName"
                            placeholder="Enter Spec Value Name">
                    </nz-input-group>
                    <p class="error-message" *ngIf="requiredData == 'name'">*Please Enter Spec Value Name</p>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label class="large_label" [nzSm]="24" nzRequired nzFor="ValueType">Type</nz-form-label>
                <nz-form-control>
                    <nz-input-group>
                        <nz-select id="ValueType" [(ngModel)]="specType" [ngModelOptions]="{standalone: true}"
                            nzShowSearch class="w-100" nzPlaceHolder="None">
                            <nz-option nzValue="Value" nzLabel="Value"></nz-option>
                            <nz-option nzValue="Description" nzLabel="Description"></nz-option>
                        </nz-select>
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item class="text-center">
                <nz-form-control>
                    <button type="button" class="mr-3 cancel-button" nz-button nzType="default">Cancel</button>
                    <a>
                        <button (click)="addNewSpecValue()" type="button" nz-button class="save-button"> create
                        </button>
                    </a>
                </nz-form-control>
            </nz-form-item>

        </form>
    </ng-container>
    <ng-template class="modal_footer" *nzModalFooter>

    </ng-template>
</nz-modal>

<!-- specification modal -->

<nz-modal [nzStyle]="{ top: '10px' }" nzWidth="70%" [(nzVisible)]="isVisible" nzCentered nzTitle="Specification"
    (nzOnCancel)="handleCancel()" (nzOnOk)="getSelectedSpecs()" [nzOkLoading]="isOkLoading">

    <div class="container-fluid specification-container" *nzModalContent>

        <div class="row d-flex justify-content-start">
            <div class="col-sm-2 group-coloumn">
                <!-- <h2>Specification Group</h2> -->
                <div *ngFor="let values of specResult;let i = index;" class="mb-3"
                    [ngClass]="{'SelectedButton': selectedTab == values.groupName.name}">
                    <button class="specification-select-button" (click)="ShowSpecValues(values,i)" nz-button
                        nzType="default">
                        <span>{{values.groupName.name}}</span>
                    </button>
                </div>
            </div>

            <div class="col-sm-10 spec-form-column">
                <div class="row justify-content-center">
                    <div class="col-sm-12 ">
                        <div class="form-section" style=" display: flex; justify-content: flex-end;">
                            <div class="search-input-section">
                                <nz-input-group nzSearch [nzAddOnAfter]="suffixButton">
                                    <input autocomplete="off" type="text" [(ngModel)]="searchInput"
                                        [ngModelOptions]="{standalone: true}" nz-input
                                        placeholder="Search Specifications" />
                                </nz-input-group>

                                <ng-template #suffixButton>
                                    <i nz-icon nzType="search" class="opacity-05 search-icon-section"></i>
                                </ng-template>
                            </div>
                            <!-- <button (click)="addProductSpec(reqSpecValue)" nz-button class="save-button">
                    <span>Add</span>
                </button> -->
                        </div>
                        <div class="spec-values-section mt-3">
                            <div *ngFor="let value of reqSpecValue | filter:searchInput" class="">
                                <div>
                                    <form nz-form>
                                        <div class="heading-text">
                                            <!-- <h5>{{value.name}}</h5> -->
                                        </div>
                                        <nz-form-item>
                                            <nz-form-label class="large_label" [nzSm]="24" [nzXs]="24" nzRequired>
                                                {{value.name}}
                                            </nz-form-label>
                                            <nz-form-control>
                                                <nz-input-group>
                                                    <nz-select [(ngModel)]="value.value"
                                                        [ngModelOptions]="{standalone: true}" nzShowSearch class="w-100"
                                                        nzPlaceHolder="values">
                                                        <nz-option *ngFor="let specValue of value.acceptedValues"
                                                            nzValue="{{specValue}}" nzLabel="{{specValue}}"></nz-option>

                                                    </nz-select>
                                                </nz-input-group>
                                            </nz-form-control>
                                        </nz-form-item>
                                        <nz-form-item>
                                            <nz-form-label class="large_label" nzRequired nzFor="ProductBestSeller">Is
                                                Highlighted
                                            </nz-form-label>
                                            <nz-form-control>
                                                <!-- <nz-input-group> -->
                                                <nz-switch id="ProductBestSeller" [(ngModel)]="value.isHighlighted"
                                                    [ngModelOptions]="{standalone: true}"
                                                    [nzCheckedChildren]="checkedTemplate"
                                                    [nzUnCheckedChildren]="unCheckedTemplate"></nz-switch>
                                                <ng-template #checkedTemplate><i nz-icon nzType="check"></i>
                                                </ng-template>
                                                <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i>
                                                </ng-template>
                                                <!-- </nz-input-group> -->
                                            </nz-form-control>
                                        </nz-form-item>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</nz-modal>

<!-- media modal -->

<nz-modal [nzStyle]="{ top: '10px' }" nzWidth="90%" [(nzVisible)]="MediaVisible" nzCentered nzTitle="Product Image"
    (nzOnCancel)="handleCancel()" (nzOnOk)="sendMedia()" nzOkText="Set Product Image" [nzOkLoading]="isOkLoading">
    <div class="container-fluid" *nzModalContent>
        <app-media [productID]="productId" [MaxSelect]="reqImageArray" [reqImages]="productImages"></app-media>
    </div>
</nz-modal>

<!-- custom filter drawer -->

<nz-drawer [nzWidth]="'60%'" [nzClosable]="true" [nzVisible]="filterVisible" [nzPlacement]="'right'"
    nzTitle="Product Filter" (nzOnClose)="filterClose()">
    <ng-container *nzDrawerContent>
        <form [formGroup]="applyFilterForm" (ngSubmit)="applyFilterButton()" nz-form>
            <div nz-row [nzGutter]="24">
                <div nz-col [nzXs]="24" [nzSm]="24">
                    <nz-form-item>
                        <nz-form-label class="large_label" nzFor="product_name" [nzSm]="24" [nzXs]="24">
                            Product name
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please Select Product name!">
                            <nz-input-group>
                                <nz-select nzAllowClear formControlName="productName" nzMode="multiple"
                                    class="select_option w-100" id="product_name" nzPlaceHolder="Select Product name">
                                    <nz-option *ngFor="let product of productsForFilter" [nzValue]="product._id"
                                        [nzLabel]="product.name"></nz-option>
                                </nz-select>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                </div>

                <div nz-col [nzXs]="24" [nzSm]="12">
                    <nz-form-item>
                        <nz-form-label class="large_label" nzFor="product_category" [nzSm]="24" [nzXs]="24">
                            Category
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please Select Category!">
                            <nz-input-group>
                                <nz-select nzAllowClear formControlName="category" nzMode="multiple"
                                    class="select_option w-100" id="product_category" nzPlaceHolder="Select Category">
                                    <nz-option *ngFor="let column of categoriesLists" [nzValue]="column._id"
                                        [nzLabel]="column.name">
                                    </nz-option>
                                </nz-select>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                </div>

                <div nz-col [nzXs]="24" [nzSm]="12">
                    <nz-form-item>
                        <nz-form-label class="large_label" nzFor="product_category" [nzSm]="24" [nzXs]="24">
                            Include Children Category
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please Select Category!">
                            <nz-input-group>
                                <nz-switch formControlName="andItsChildren"></nz-switch>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                </div>

                <!-- <div nz-col [nzXs]="24" [nzSm]="12">
                    <nz-form-item>
                        <nz-form-label class="large_label" nzFor="product_brand" [nzSm]="24" [nzXs]="24">
                            Brand
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please Select Brand!">
                            <nz-input-group>
                                <nz-select nzAllowClear formControlName="brand" nzMode="multiple"
                                    class="select_option w-100" id="product_brand" nzPlaceHolder="Select Brand">
                                    <nz-option *ngFor="let column of brandsList" [nzValue]="column._id"
                                        [nzLabel]="column.name">
                                    </nz-option>
                                </nz-select>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                </div> -->

                <div nz-col [nzXs]="24" [nzSm]="12">
                    <nz-form-item>
                        <nz-form-label class="large_label" nzFor="product_stock_status" [nzSm]="24" [nzXs]="24">
                            Stock Status
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please Select Stock Status!">
                            <nz-input-group>
                                <nz-select nzAllowClear formControlName="stockStatus" class="select_option w-100"
                                    id="product_stock_status" nzPlaceHolder="Select Stock Status">
                                    <!-- <nz-option nzValue="Ready for immediate dispatch"
                        nzLabel="Ready for immediate dispatch"></nz-option> -->
                                    <nz-option nzValue="In Stock" nzLabel="In Stock"></nz-option>
                                    <nz-option nzValue="Out of Stock" nzLabel="Out of Stock"></nz-option>
                                    <!-- <nz-option nzValue="Against Order" nzLabel="Against Order"></nz-option>
                    <nz-option nzValue="Pre Order" nzLabel="Pre Order"></nz-option> -->
                                </nz-select>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                </div>

                <div nz-col [nzXs]="24" [nzSm]="12">
                    <nz-form-item>
                        <nz-form-label class="large_label" nzFor="product_status" [nzSm]="24" [nzXs]="24"> Product
                            Status
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please Select Product Status!">
                            <nz-input-group>
                                <nz-select nzAllowClear formControlName="productStatus" class="select_option w-100"
                                    id="product_status" nzPlaceHolder="Select Product Status">
                                    <nz-option nzValue="true" nzLabel="Published"></nz-option>
                                    <nz-option nzValue="false" nzLabel="Unpublished"></nz-option>
                                </nz-select>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                </div>

                <!-- <div nz-col [nzXs]="24" [nzSm]="12">
                    <nz-form-item>
                        <nz-form-label class="large_label" nzFor="product_featured" [nzSm]="24" [nzXs]="24"> Is Featured
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please Select is Featured!">
                            <nz-input-group>
                                <nz-select nzAllowClear formControlName="isFeatured" class="select_option w-100"
                                    id="product_featured" nzPlaceHolder="Select is Featured">
                                    <nz-option nzValue="true" nzLabel="Yes"></nz-option>
                                    <nz-option nzValue="false" nzLabel="No"></nz-option>
                                </nz-select>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                </div> -->

                <div nz-col [nzXs]="24" [nzSm]="12">
                    <nz-form-item>
                        <nz-form-label class="large_label" nzFor="product_best_seller" [nzSm]="24" [nzXs]="24">
                            Tags
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please Select is Best Seller!">
                            <nz-input-group>
                                <nz-select nzShowSearch nzAllowClear formControlName="tags" class="select_option w-100"
                                    nzPlaceHolder="Select Tags">
                                    <nz-option *ngFor="let tag of tags" [nzValue]="tag?._id"
                                        [nzLabel]="tag?.name"></nz-option>
                                </nz-select>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                </div>

                <!-- <div nz-col [nzXs]="24" [nzSm]="12">
                    <nz-form-item>
                        <nz-form-label class="large_label" nzFor="product_best_seller" [nzSm]="24" [nzXs]="24">
                            SKU ID
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please Select is Best Seller!">
                            <nz-input-group>
                                <input autocomplete="off" formControlName="SkuID" nz-input placeholder="Enter SKU ID">
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                </div> -->

                <div nz-col [nzXs]="24" [nzSm]="24">
                    <nz-form-item class="submit_section text-center">
                        <nz-form-control [nzSm]="24" [nzXs]="24">
                            <button (click)="resetFilters()" type="button" class="sighup_button mr-3" nz-button
                                nzType="default">Reset</button>
                            <button type="submit" class="sighup_button" nz-button nzType="primary">Apply</button>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
        </form>
    </ng-container>
</nz-drawer>



<!-- (nzOnOk)="handleOk()" GOES IN LINE 368 -->