import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { NavService } from './nav.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  constructor(private route: Router, private navService: NavService) {}
  userData: any;

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (localStorage.getItem('admintokenoasisdata') === null) {
      this.route.navigate(['/']);
      return false;
    } else {
      // this.test();
      return true;
    }
  }

  test() {
    this.navService.sharedUserData.subscribe((data) => {
      if (data) {
        this.userData = data?.data;
        console.log(this.userData);
        if (this.userData.user.role == 'deliveryBoy') {
          this.route.navigate(['/recent-orders']);
          // return true;
        }
        return true;
      }
    });
  }
}
