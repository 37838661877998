import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { scrollToTop } from "../../shared/dummy";
import axios, { Axios } from "axios";
import { NavService } from "../../services/nav.service";
import { Router } from "@angular/router";
import { NzModalService } from "ng-zorro-antd/modal";
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from "src/environments/environment";
import { ToastrService } from "ngx-toastr";
import * as moment from "moment";
import { ExportExcelService } from "src/app/services/export-excel.service";

interface DataItem {
  id: string;
  name: string;
  image: any;
  banner: any;
  children: any;
  featured: any;
  active: any;
  priority: number;
  specification: any;
  createdBy: any;
  createdAt: any;
  lastUpdateBy: any;
  lastUpdateAt: any;
  imageData: any;
  isSelected: any;
  offerData: any;
}

@Component({
  selector: "app-category",
  templateUrl: "./category.component.html",
  styleUrls: ["./category.component.css"],
})
export class CategoryComponent implements OnInit {
  moment: any = moment;
  selectedCategory: string;
  selectedStatus: string;
  searchInput: any = "";
  displayData = [];
  requiredData = "";

  showTable: boolean = true;
  showForm: boolean = false;

  MediaVisible = false;
  mediaType: any = "";

  isOkLoading = false;
  isEdit = false;

  validateForm: FormGroup;
  categoryName = "";
  //parentId : any[] = [];
  parentId = "";
  categoryImage = [];
  bannerImage = [];
  isFeatured = true;
  isActive = true;
  specNameList: any[] = [];
  specGroupName: any = {};
  defaultMode = "Add";
  categoryId = "";

  categoryLoading: boolean;

  currentUrl = "";
  liveUrl = environment.url;

  pageIndex = 1;
  totalCount: number = 0;
  pageSize: number = 0;

  directMode: boolean;
  offerLoading: boolean;

  // offer and out of stock
  Checked = false;
  checkboxActive = false;
  selectedOption = "Out of Stock";

  selectedData: any = {};

  orderColumn = [
    // {
    //   title: 'S.NO',
    //   width: '50px',
    // },
    {
      title: "Image",
      width: "60px",
      compare: (a: DataItem, b: DataItem) => a.image - b.image,
    },
    {
      title: "Category Name",
      width: "100px",
      compare: (a: DataItem, b: DataItem) => a.name.localeCompare(b.name),
    },
    // {
    //   title: 'Children',
    //   width: '150px',
    //   compare: (a: DataItem, b: DataItem) => a.children - b.children,
    // },
    // {
    //   title: "Featured",
    //   width: "70px",
    //   compare: (a: DataItem, b: DataItem) => a.featured - b.featured,
    // },
    {
      title: "Status",
      width: "80px",
      compare: (a: DataItem, b: DataItem) => a.active - b.active,
    },
    // {
    //   title: 'priority',
    //   width: '60px',
    //   compare: (a: DataItem, b: DataItem) => a.priority - b.priority,
    // },
    // {
    //   title: 'Action',
    //   width: '120px',
    // },
  ];

  productsList: DataItem[] = [];
  categoriesLists: DataItem[] = [];
  specList: any[] = [];
  specValueList: any[] = [];
  // SPECIFICATION TAGS SECTION

  tags = [];
  constructor(
    private fb: FormBuilder,
    public navService: NavService,
    private router: Router,
    private modalService: NzModalService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    public ete: ExportExcelService
  ) {}

  // fetchSpecValues() {
  //   axios
  //     .get(
  //       environment.url + 'specValues/get?isForProductPage=true',
  //       {
  //         headers: {
  //           Authorization:
  //             'Bearer ' + localStorage.getItem('admintokenoasisdata'),
  //         },
  //       }
  //     )
  //     .then((response) => {
  //       console.log('spec values');
  //       console.log(response.data.data);
  //       this.specValueList = response.data.data;
  //     })
  //     .catch((error) => {
  //       console.log(error.response);
  //     });
  // }

  // fetchSpecifications() {
  //   axios
  //     .get(
  //       environment.url + 'specGroup/get?isForProductPage=true',
  //       {
  //         headers: {
  //           Authorization:
  //             'Bearer ' + localStorage.getItem('admintokenoasisdata'),
  //         },
  //       }
  //     )
  //     .then((response) => {
  //       console.log('spec values');
  //       console.log(response.data.data);
  //       this.specList = response.data.data;
  //       this.fetchSpecValues();
  //     })
  //     .catch((error) => {
  //       console.log(error.response);
  //     });
  // }

  fetchAllCategories() {
    axios
      .get(environment.url + "categories?isForProductPage=true", {})
      .then((response) => {
        this.categoriesLists = response.data.data;
        console.log(this.categoriesLists);

        // console.log("Categories data"+JSON.stringify(this.categoriesLists));
      })
      .catch((error) => {
        console.log(error);
        //alert(error.response.data.message);
      });
  }

  fetchData() {
    axios
      .get(
        environment.url +
          "categories?page=" +
          this.pageIndex +
          "&search=" +
          this.searchInput,
        {}
      )
      .then((response) => {
        console.log(response.data.data);
        var initialResult = response.data.data;
        this.totalCount = response?.data?.pagination?.totalCount;
        this.pageSize = response?.data?.pagination?.limit;
        this.productsList = initialResult.map((data: any, index: number) => {
          return {
            id: data?._id,
            name: data?.name,
            image: data?.image?.image?.url,
            banner: data?.bannerImage,
            children: data?.children,
            featured: data?.featured,
            active: data?.isActive,
            priority: data?.priority,
            specification: data?.specifications,
            createdBy: data?.createdBy,
            createdAt: data?.createdAt,
            lastUpdateBy: data?.lastUpdatedBy,
            lastUpdateAt: data?.lastUpdatedAt,
            imageData: data?.image,
            isSelected: false,
            offerData: data?.offerData,
          };
        });
        console.log(response);
        // this.fetchSpecifications();   // specification section removed for add it from products page
        this.spinner.hide();
        //console.log(response.data.status);
        this.fetchAllCategories();
      })
      .catch((error) => {
        this.spinner.hide();
        console.log(error);
      });
  }

  categoryChange(value: string): void {
    const data = this.productsList;
    this.displayData = data;
  }

  statusChange(value: string): void {
    const data = this.productsList;
    this.displayData = data;
  }

  ngOnInit(): void {
    this.spinner.show();
    scrollToTop();
    this.fetchData();
    this.currentUrl = this.router.url;
    this.validateForm = this.fb.group({});

    if (window.innerWidth > 992) {
      this.showForm = true;
    }
  }

  addspecificantion(a: any, b: any) {
    console.log(this.specGroupName);
    console.log(this.specNameList);
    var name;
    for (var i = 0; i < this.specList.length; i++) {
      if (this.specList[i]._id == this.specGroupName) {
        name = this.specList[i].name;
      }
    }
    var reqObj = {
      name: name,
      groupName: this.specGroupName,
      specValues: this.specNameList,
    };
    this.specNameList = [];
    var count = 0;
    for (var i = 0; i < this.tags.length; i++) {
      if (this.tags[i].groupName == reqObj.groupName) {
        this.tags[i].specValues = reqObj.specValues;
        count++;
      }
    }
    if (count == 0) this.tags.push(reqObj);
    console.log(reqObj);
    var reqSpecArray = this.tags;
    console.log("spec");
    console.log(reqSpecArray);
  }

  editSpec(tag) {
    console.log(tag);
    this.specGroupName = tag.groupName;
    this.specNameList = tag.specValues;
  }

  showFormDetails() {
    this.requiredData = "";
    this.clearFields();
    this.isEdit = false;
    this.defaultMode = "Add";
    this.tags = [];

    if (window.innerWidth < 992) {
      this.showTable = !this.showTable;
      this.showForm = !this.showForm;
    }
  }

  // MODEL SECTION
  showMedia(type: string) {
    this.spinner.hide();
    this.mediaType = type;
    this.MediaVisible = true;
  }

  handleCancel(): void {
    this.MediaVisible = false;
  }

  // FORM RESET SECTION

  resetForm(): void {
    // this.validateForm.reset();
    this.modalService.confirm({
      nzTitle: "Confirm",
      nzContent: "Sure You Want to Reset ?",
      nzOkText: "Yes",
      nzCancelText: "No",
      nzOnOk: () => {
        // if (window.innerWidth > 992) {
        this.requiredData = "";
        this.clearFields();
        this.isEdit = false;
        // } else {
        // this.showFormDetails();
      },
      // },
    });
  }
  sendMedia() {
    this.navService.formRefreshAnnouncedSource.subscribe((item) => {
      if (this.mediaType == "category") {
        this.categoryImage = item;
      } else if (this.mediaType == "banner") {
        this.bannerImage = item;
      }

      //alert(this.categoryImage[0].image.url);
    });
    this.handleCancel();
  }

  clearFields() {
    this.parentId = "";
    this.categoryName = "";
    this.categoryImage = [];
    this.bannerImage = [];
    this.specGroupName = "";
    this.specNameList = [];
    this.isFeatured = true;
    this.isActive = true;
    this.tags = [];
  }
  doChanges() {
    console.log(this.parentId);
    var self = this;
    if (self.categoryName == "") {
      // alert('Enter Category Name');
      self.requiredData = "name";
    } else if (self.categoryImage.length == 0) {
      self.toastr.error("Select Image for Category", "", {
        timeOut: 2500,
      });

      
    }
    //  else if (self.bannerImage.length == 0 && !this.parentId) {
    //   self.toastr.error("Select Image for Category Banner", "", {
    //     timeOut: 2500,
    //   });
    // }
    
    else {
      //alert('success');
      var reqSpecArray = self.tags;
      for (var i = 0; i < reqSpecArray.length; i++) {
        delete reqSpecArray[i].name;
      }
      if (self.defaultMode == "Add") {
        self.categoryLoading = true;
        var categoryData = {
          name: self.categoryName,
          isMainParent: self.parentId.length > 0 ? false : true,
          imageId: self.categoryImage[self.categoryImage.length - 1]._id,
          bannerImageId: self.bannerImage[self.bannerImage.length - 1]?._id,
          parentId: self.parentId.length > 0 ? self.parentId : "",
          //   specList: [{
          //     "groupName": self.specGroupName,
          //     "specValues": self.specNameList
          // }],
          // specList: reqSpecArray,
          featured: self.isFeatured,
          isActive: self.isActive,
        };
        console.log(categoryData);
        axios
          .post(environment.url + "categories/create", categoryData, {
            headers: {
              Authorization:
                "Bearer " + localStorage.getItem("admintokenoasisdata"),
            },
          })
          .then((response) => {
            if (response.data.status == "success") {
              console.log(response.data.data);
              self.toastr.success("Category Added", "", {
                timeOut: 2500,
              });

              self.categoryLoading = false;
              self.clearFields();
              self.requiredData = "";
              if (!this.directMode) {
                self.fetchData();
                if (window.innerWidth < 992) {
                  self.showTable = !self.showTable;
                  self.showForm = !self.showForm;
                }
              }
              this.directMode = false;
            }
          })
          .catch((error) => {
            if (this.directMode) {
              self.fetchData();
            }
            self.categoryLoading = false;
            if (error.response.data.data == undefined) {
              self.toastr.error("", error.response.data.message, {
                timeOut: 2500,
              });
            } else {
              self.toastr.error("", error.response.data.data[0].msg, {
                timeOut: 2500,
              });
            }
          });
      } else if (self.defaultMode == "Edit") {
        self.categoryLoading = true;
        var reqObj = {
          id: self.categoryId,
          name: self.categoryName,
          isMainParent: self?.parentId?.length > 0 ? false : true,
          imageId: self?.categoryImage[self.categoryImage.length - 1]?._id,
          bannerImageId: self?.bannerImage[self.bannerImage.length - 1]?._id,
          parentId: self.parentId,
          //   specList: [{
          //     "groupName": self.specGroupName,
          //     "specValues": self.specNameList
          // }],
          // specList: reqSpecArray,
          featured: self.isFeatured,
          isActive: self.isActive,
        };
        console.log(categoryData);
        axios
          .put(environment.url + "categories/update", reqObj, {
            headers: {
              Authorization:
                "Bearer " + localStorage.getItem("admintokenoasisdata"),
            },
          })
          .then((response) => {
            if (response.data.status == "success") {
              console.log(response);
              self.toastr.success("Category Updated", "", {
                timeOut: 2500,
              });

              self.categoryLoading = false;
              self.clearFields();
              self.requiredData = "";
              if (!this.directMode) {
                if (window.innerWidth < 992) {
                  self.showTable = !self.showTable;
                  self.showForm = !self.showForm;
                }
                self.fetchData();
              }
              this.directMode = false;
            }
          })
          .catch((error) => {
            if (this.directMode) {
              self.fetchData();
            }
            self.categoryLoading = false;
            if (error.response.data.data == undefined) {
              self.toastr.error("", error.response.data.message, {
                timeOut: 2500,
              });
            } else {
              self.toastr.error("", error.response.data.data[0].msg, {
                timeOut: 2500,
              });
            }
          });
      }
    }
  }

  getParentId(id: string) {
    var self = this;
    var reqObj = {
      categoryId: id,
    };
    axios
      .post(environment.url + "categories/for-update", reqObj, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("admintokenoasisdata"),
        },
      })
      .then((response) => {
        if (response.data.status == "success") {
          console.log(response);
          self.parentId = response?.data?.data?.parentId;
          if (this.directMode == true) {
            this.doChanges();
          }
        }
      })
      .catch((error) => {
        if (error.response.data.data == undefined) {
          self.toastr.error("", error.response.data.message, {
            timeOut: 2500,
          });
        } else {
          self.toastr.error("", error.response.data.data[0].msg, {
            timeOut: 2500,
          });
        }
      });
  }

  editCategory(category: any) {
    console.log(category);
    this.isEdit = true;
    this.directMode = false;
    console.log(category);
    this.getParentId(category.id);
    this.categoryId = category?.id;
    this.categoryName = category?.name;
    this.categoryImage = [category?.imageData];
    this.bannerImage = [category?.banner];
    this.isFeatured = category?.featured;
    this.isActive = category?.active;
    // this.tags = category.specification;
    this.specNameList = [];
    for (var i = 0; i < category?.specification?.length; i++) {
      this.specGroupName = category?.specification[i]?.groupName?._id;
      //this.specNameList = category.specification[i].specValues

      for (var j = 0; j < category?.specification[i].specValues.length; j++) {
        this.specNameList.push(category.specification[i]?.specValues[j]?._id);
      }
      this.addspecificantion(this.specGroupName, this.specNameList);
    }
    console.log("spec name list is");
    console.log(this.specNameList);

    console.log(category.specification);
    this.defaultMode = "Edit";
    // if (window.innerWidth < 992) {
    if (window.innerWidth < 992) {
      this.showTable = !this.showTable;
      this.showForm = !this.showForm;
    }
    // }
  }

  onClose(tags: any) {
    this.tags.splice(
      this.tags.findIndex((e) => e.groupName == tags.groupName, 1)
    );
  }

  onPageNumberChange(id: any, event: any) {
    if (event.pageIndex != this.pageIndex) {
    this.spinner.show();
    this.pageIndex = event.pageIndex;
    this.fetchData();
    document.getElementById(id).scrollIntoView();
    window.scroll(0, 0);
    }
  }

  searchCategory() {
    this.pageIndex = 1;
    this.fetchData();
  }

  // update from table

  directUpdate(selected: any, event: any) {
    console.log(selected);
    // console.log(event);
    this.categoryLoading = true;

    this.categoryId = selected.id;
    this.categoryName = selected.name;
    this.categoryImage = [selected.imageData];
    this.bannerImage = [selected.banner];
    this.isFeatured = event;
    this.isActive = selected.active;
    // this.tags = selected.specification;
    this.specNameList = [];
    // for (var i = 0; i < selected.specification.length; i++) {
    //   this.specGroupName = selected.specification[i].groupName._id;
    //   //this.specNameList = selected.specification[i].specValues

    //   for (var j = 0; j < selected.specification[i].specValues.length; j++) {
    //     this.specNameList.push(selected.specification[i].specValues[j]._id);
    //   }
    //   this.addspecificantion(this.specGroupName, this.specNameList);
    // }
    this.defaultMode = "Edit";
    this.directMode = true;
    this.getParentId(selected.id);

    // this.doChanges();
  }

  // offer and out of stock section
  checkedList: any = [];

  SelectAll() {
    for (let i = 0; i < this.productsList.length; i++) {
      if (this.Checked == false) {
        this.productsList[i].isSelected = true;
        this.checkedList.push(this.productsList[i].id);
      } else if (this.Checked == true) {
        this.productsList[i].isSelected = false;
        this.checkedList = [];
      }
    }
    console.log(this.checkedList);

    if (this.checkedList.length >= 1) {
      this.checkboxActive = true;
    } else if (this.checkedList.length == 0) {
      this.checkboxActive = false;
    }
  }

  checkSelected(item: any) {
    item.isSelected = !item.isSelected;
    // console.log(item.isSelected);
    if (item.isSelected == true) {
      this.checkedList.push(item.id);
      // console.log(this.checkedList);
    } else if (item.isSelected == false) {
      this.checkedList.splice(this.checkedList.indexOf(item.id), 1);
      // console.log(this.checkedList);
    }
    console.log(this.checkedList);

    if (this.checkedList.length >= 1) {
      this.checkboxActive = true;
    } else if (this.checkedList.length == 0) {
      this.checkboxActive = false;
    }
  }

  updateStockStatus() {
    var outOfStockStatus;
    this.categoryLoading = true;
    if (this.selectedOption == "In Stock") {
      outOfStockStatus = false;
    } else if (this.selectedOption == "Out of Stock") {
      outOfStockStatus = true;
    }
    axios
      .post(
        environment.url + "categories/update-stock-status",
        {
          categoryIds: this.checkedList,
          outOfStock: outOfStockStatus,
        },
        {
          headers: {
            Authorization:
              "Bearer " + localStorage.getItem("admintokenoasisdata"),
          },
        }
      )
      .then((response) => {
        // console.log(response);
        if (response.data.status == "success") {
          this.categoryLoading = false;
          this.toastr.success(response.data.message, "", {
            timeOut: 2500,
          });
          this.checkboxActive = false;
          this.fetchData();
        }
      })
      .catch((error) => {
        this.categoryLoading = false;
        console.log(error);
        if (error.response.data.data == undefined) {
          this.toastr.error("", error.response.data.message, {
            timeOut: 2500,
          });
        } else {
          this.toastr.error("", error.response.data.data[0].msg, {
            timeOut: 2500,
          });
        }
      });
  }

  // offer section

  addOffer(offerData: any, type: any) {
    if (offerData?.offerData?.isInOffer) {
      this.removeOffer(offerData);
    } else {
      this.selectedData = {
        id: offerData.id,
        offerType: type,
        action: offerData.isInOffer ? "update" : "create",
      };
      console.log(offerData);
      this.showTable = false;
    }
  }

  removeOffer(offerData: any) {
    this.offerLoading = true;
    var offerObj: any = {
      categoryId: offerData.id,
      type: "category",
    };

    axios
      .post(environment.url + "offers/remove-offer", offerObj, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("admintokenoasisdata"),
        },
      })
      .then((response: any) => {
        console.log(response);
        this.offerLoading = false;
        this.fetchData();
        this.toastr.success(response.data.message, "", {
          timeOut: 2500,
        });
      })
      .catch((error: any) => {
        this.offerLoading = false;
        this.toastr.error(error.response.message, "", {
          timeOut: 2500,
        });
        console.log(error);
      });
  }

  showTableData() {
    this.showTable = true;
    this.fetchData();
  }

  downloadingFile: boolean = false;

  downloadCategoriesAsFile() {
    this.downloadingFile = true;
    axios({
      url: environment.url + "categories/exportAsExcel", //your url
      method: "POST",
      responseType: "blob", // important
      headers: {
        Authorization: "Bearer " + localStorage.getItem("admintokenoasisdata"),
      },
    })
      .then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        let date = moment(new Date()).format("DD-MM-YYYY");
        link.setAttribute("download", "categories_dated_" + date + ".csv"); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        this.downloadingFile = false;
      })
      .catch((error) => {
        console.log(error.response);
        this.downloadingFile = false;
        this.toastr.error("Please Try Again!", "", {
          timeOut: 2500,
        });
      });
  }

  // category bulk upload

  uploadExcelFile(ev: any) {
    this.spinner.show();
    console.log(ev.target.files);
    var formData = new FormData();
    formData.append("file", ev.target.files[0]);
    axios
      .post(environment.url + "categories/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization:
            "Bearer " + localStorage.getItem("admintokenoasisdata"),
        },
      })
      .then((response) => {
        console.log(response.data);
        this.toastr.success("category excel updated!..", "", {
          timeOut: 3500,
        });
        // if (response.data.message == 'media uploaded') {
        this.fetchData();
        this.downloadExcel(response.data.data);
        this.spinner.hide();
        // }
      })
      .catch((error) => {
        console.log(error);
        this.toastr.error(error.response.data.message, "", {
          timeOut: 3500,
        });
        this.spinner.hide();
      });

    // ev.target.value = '';
  }

  downloadExcel(data: any) {
    var categoryData: any = [];
    var brandData: any = [];
    var duplicateData: any = [];

    let orderData = [];
    if (data?.categories?.length > 0) {
      for (let i = 0; i < data?.categories?.length; i++) {
        var temp = [
          i + 1,
          data?.categories[i][0]?.indexNo,
          data?.categories[i][0]?.categoryName,
        ];
        categoryData.push(temp);
        temp = [];
      }
      orderData.push({
        title: ["Mismatch Categories"],
        data: categoryData,
        headers: ["S.No", "Line Number", "Category Name"],
      });
    }

    if (data?.brands?.length > 0) {
      for (let i = 0; i < data?.brands?.length; i++) {
        var temp = [
          i + 1,
          data?.brands[i][0]?.indexNo,
          data?.brands[i][0]?.brandName,
        ];
        brandData.push(temp);
        temp = [];
      }
      orderData.push({
        title: ["Mismatch Brands"],
        data: brandData,
        headers: ["S.No", "Line Number", "Brand Name"],
      });
    }

    if (data?.duplicateModels?.length > 0) {
      for (let i = 0; i < data?.duplicateModels?.length; i++) {
        var temp = [i + 1, data?.duplicateModels[i]];
        duplicateData.push(temp);
        temp = [];
      }

      orderData.push({
        title: ["Duplicate Models"],
        data: duplicateData,
        headers: ["S.No", "Model Number"],
      });
    }

    if (orderData.length > 0) {
      this.ete.exportExcel(orderData, "Error_Report");
    }
  }
}
