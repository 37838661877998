import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Observable } from "rxjs";
import { distinctUntilChanged, filter, map, startWith } from "rxjs/operators";
import { IBreadcrumb } from "../../shared/interfaces/breadcrumb.type";
import { ThemeConstantService } from "../../shared/services/theme-constant.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  breadcrumbs$: Observable<IBreadcrumb[]>;
  contentHeaderDisplay: string;
  isFolded: boolean;
  isSideNavDark: boolean;
  isExpand: boolean;
  selectedHeaderColor: string;

  hideNavbarSidnav = true;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private themeService: ThemeConstantService
  ) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // console.log(event.url);

        if (event.url == "/" || event.url == "/forgot-password") {
          this.hideNavbarSidnav = false;
        } else if (
          event.url == "/dashboard" ||
          event.url == "/attribute" ||
          event.url == "/brands" ||
          event.url == "/banners" ||
          event.url == "/category" ||
          event.url == "/media" ||
          event.url == "/customers" ||
          event.url.includes("/products") ||
          event.url == "/store-location" ||
          event.url == "/specification" ||
          event.url == "/specGroup" ||
          event.url == "/specValue" ||
          event.url.includes("/recent-orders") ||
          event.url == "/user-management" ||
          event.url == "/offer-page" ||
          event.url == "/coupon-page" ||
          event.url == "/setting" ||
          event.url == "/add-offer" ||
          event.url == "/build-pc-enquiry" ||
          event.url.includes("/recent-orders/") ||
          event.url == "/payments" ||
          event.url == "/policies" ||
          event.url == "/tags"
        ) {
          this.hideNavbarSidnav = true;
        } else {
          this.hideNavbarSidnav = false;
        }
      }
    });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
            } else if (
              child.snapshot.data &&
              child.snapshot.data["headerDisplay"]
            ) {
              return child.snapshot.data["headerDisplay"];
            } else {
              return null;
            }
          }
          return null;
        })
      )
      .subscribe((data: any) => {
        this.contentHeaderDisplay = data;
      });
  }

  ngOnInit() {
    this.breadcrumbs$ = this.router.events.pipe(
      startWith(new NavigationEnd(0, "/", "/")),
      filter((event) => event instanceof NavigationEnd),
      distinctUntilChanged(),
      map((data) => this.buildBreadCrumb(this.activatedRoute.root))
    );
    this.themeService.isMenuFoldedChanges.subscribe(
      (isFolded) => (this.isFolded = isFolded)
    );
    this.themeService.isSideNavDarkChanges.subscribe(
      (isDark) => (this.isSideNavDark = isDark)
    );
    this.themeService.selectedHeaderColor.subscribe(
      (color) => (this.selectedHeaderColor = color)
    );
    this.themeService.isExpandChanges.subscribe(
      (isExpand) => (this.isExpand = isExpand)
    );
  }

  private buildBreadCrumb(
    route: ActivatedRoute,
    url: string = "",
    breadcrumbs: IBreadcrumb[] = []
  ): IBreadcrumb[] {
    let label = "",
      path = "/",
      display = null;

    if (route.routeConfig) {
      if (route.routeConfig.data) {
        label = route.routeConfig.data["title"];
        path += route.routeConfig.path;
      }
    } else {
      label = "Dashboard";
      path += "dashboard";
    }

    const nextUrl = path && path !== "/dashboard" ? `${url}${path}` : url;
    const breadcrumb = <IBreadcrumb>{
      label: label,
      url: nextUrl,
    };

    const newBreadcrumbs = label
      ? [...breadcrumbs, breadcrumb]
      : [...breadcrumbs];
    if (route.firstChild) {
      return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }
    return newBreadcrumbs;
  }
}
