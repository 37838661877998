import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { scrollToTop } from '../../shared/dummy';
import axios, { Axios } from 'axios';
import { NavService } from '../../services/nav.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { ExportExcelService } from 'src/app/services/export-excel.service';

interface DataItem {
  id: string;
  name: string;
  logo: any;
  website: string;
  isActive: boolean;
  isPopular: boolean;
  Description: string;
  imageData: any;
}

@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.css'],
})
export class BrandsComponent implements OnInit {
  moment: any = moment;
  selectedCategory: string;
  selectedStatus: string;
  searchInput: any = '';
  displayData = [];
  liveUrl = environment.url;
  requiredData = '';

  showTable: boolean = true;
  showForm: boolean = false;

  MediaVisible = false;
  isOkLoading = false;

  validateForm: FormGroup;
  brandId = '';
  brandName = '';
  brandImages = [];
  isPopular = false;
  isActive = false;
  website = '';
  description = '';
  isEdit = false;
  reqImageArray = [];

  pageIndex = 1;
  totalCount: number = 0;
  pageSize: number = 0;

  brandsLoading: boolean;

  orderColumn = [
    // {
    //   title: 'S.NO',
    //   width: '50px',
    // },
    {
      title: 'Brand Name',
      width: '80px',
      compare: (a: DataItem, b: DataItem) => a.name.localeCompare(b.name),
    },
    {
      title: 'Logo',
      width: '50px',
      compare: (a: DataItem, b: DataItem) => a.logo - b.logo,
    },
    {
      title: 'Website',
      width: '80px',
      compare: (a: DataItem, b: DataItem) => a.website.localeCompare(b.website),
    },
    // {
    //   title: 'Action',
    // },
  ];

  productsList: DataItem[] = [];

  // search(): void {
  //     const data = this.productsList
  //     this.displayData = this.tableSvc.search(this.searchInput, data )
  // }
  constructor(
    private fb: FormBuilder,
    public navService: NavService,
    private modalService: NzModalService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    public ete: ExportExcelService
  ) {}
  fetchData() {
    // axios.get(environment.url+'brands', {
    // })
    axios
      .get(
        environment.url +
          'brands?page=' +
          this.pageIndex +
          '&search=' +
          this.searchInput,
        {}
      )
      .then((response) => {
        console.log(response.data);
        var initialResult = response.data.data;
        this.totalCount = response?.data?.pagination?.totalCount;
        this.pageSize = response?.data?.pagination?.limit;
        this.productsList = initialResult.map((data: any, index: number) => {
          return {
            id: data?._id,
            name: data?.name,
            logo: data?.image?.image?.url,
            website: data?.website,
            isActive: data?.isActive,
            isPopular: data?.isPopular,
            Description: data?.description,
            imageData: data?.image,
          };
        });
        this.spinner.hide();

        //console.log(response.data.status);
      })
      .catch((error) => {
        console.log(error);
        this.spinner.hide();

        //alert(error.response.data.message);
      });
  }

  categoryChange(value: string): void {
    const data = this.productsList;
    // value !== 'All'
    //   ? (this.displayData = data.filter((elm) => elm.name === value))
    this.displayData = data;
  }

  statusChange(value: string): void {
    const data = this.productsList;
    // value !== 'All'
    //   ? (this.displayData = data.filter((elm) => elm.feature === value))
    //   : (this.displayData = data);
    this.displayData = data;
  }

  ngOnInit(): void {
    this.spinner.show();
    scrollToTop();
    this.fetchData();
    this.navService.parentToChild('brand');

    this.validateForm = this.fb.group({});

    if (window.innerWidth > 992) {
      this.showForm = true;
    }
  }

  onImageSelected(selectedImage: any) {
    this.brandImages = selectedImage;
    alert('hello khjkhkj ' + this.brandImages);
  }

  doChanges() {
    var self = this;
    if (self.brandName == '') {
      // alert('Enter Brand Name');
      self.requiredData = 'name';
    } else if (self.brandImages.length == 0) {
      self.requiredData = '';
      self.toastr.error('Please Select Brand Image', '', {
        timeOut: 2500,
      });
    } else {
      if (self.isEdit == false) {
        var brandData = {
          name: self.brandName,
          image: self.brandImages[self.brandImages.length - 1]._id,
          description: self.description,
          website: self.website,
          isPopular: self.isPopular,
          isActive: self.isActive,
        };
        self.brandsLoading = true;
        axios
          .post(
            environment.url + 'brands/create',
            //environment.url+'brands/create',
            brandData,
            {
              headers: {
                Authorization:
                  'Bearer ' + localStorage.getItem('admintokenoasisdata'),
              },
            }
          )
          .then((response) => {
            if (response.data.status == 'success') {
              console.log(response.data.data);
              self.toastr.success('Brand Added', '', {
                timeOut: 2500,
              });
              self.requiredData = '';
              self.fetchData();
              self.clearFields();
              self.brandsLoading = false;
              if (window.innerWidth < 992) {
                self.showTable = !self.showTable;
                self.showForm = !self.showForm;
              }
              //  localStorage.setItem('admintokenoasisdata',response.data.data);
              //  self.router.navigate(['dashboard'])
            }
          })
          .catch((error) => {
            self.brandsLoading = false;
            if (error.response.data.data == undefined) {
              self.toastr.error('', error.response.data.message, {
                timeOut: 2500,
              });
            } else {
              self.toastr.error('', error.response.data.data[0].msg, {
                timeOut: 2500,
              });
            }
          });
      } else if (self.isEdit == true) {
        var reqObj = {
          id: self.brandId,
          name: self.brandName,
          image: self.brandImages[self.brandImages.length - 1]._id,
          description: self.description,
          website: self.website,
          isPopular: self.isPopular,
          isActive: self.isActive,
        };
        self.brandsLoading = true;
        axios
          .put(
            environment.url + 'brands/update',
            //environment.url+'brands/create',
            reqObj,
            {
              headers: {
                Authorization:
                  'Bearer ' + localStorage.getItem('admintokenoasisdata'),
              },
            }
          )
          .then((response) => {
            if (response.data.status == 'success') {
              console.log(response.data.data);
              self.toastr.success('Brand Updated!', '', {
                timeOut: 2500,
              });
              self.fetchData();
              self.clearFields();
              self.requiredData = '';
              self.brandsLoading = false;
              if (window.innerWidth < 992) {
                self.showTable = !self.showTable;
                self.showForm = !self.showForm;
              }
              //  localStorage.setItem('admintokenoasisdata',response.data.data);
              //  self.router.navigate(['dashboard'])
            }
          })
          .catch((error) => {
            self.brandsLoading = false;
            if (error.response.data.data == undefined) {
              self.toastr.error('', error.response.data.message, {
                timeOut: 2500,
              });
            } else {
              self.toastr.error('', error.response.data.data[0].msg, {
                timeOut: 2500,
              });
            }
          });
      }
    }
    // self.showTable = !self.showTable;
    // self.showForm = !self.showForm;
  }

  showFormDetails() {
    this.requiredData = '';
    this.isEdit = false;
    this.clearFields();
    this.showTable = !this.showTable;
    this.showForm = !this.showForm;
  }

  // MODEL SECTION
  showMedia() {
    this.MediaVisible = true;
    this.brandImages = [];
  }
  handleCancel(): void {
    this.MediaVisible = false;
  }

  // FORM RESET SECTION

  resetForm(): void {
    // this.validateForm.reset();
    this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Sure You Want to Reset ?',
      nzOkText: 'Yes',
      nzCancelText: 'No',
      nzOnOk: () => {
        // if (window.innerWidth > 992) {
        this.clearFields();
        this.isEdit = false;
        // } else {
        //   this.showFormDetails();
        // }
      },
    });
  }

  sendMedia() {
    this.reqImageArray = [];
    this.navService.formRefreshAnnouncedSource.subscribe((item) => {
      this.brandImages = item;
      for (var i = 0; i < this.brandImages.length; i++) {
        this.reqImageArray.push(this.brandImages[i]._id);
      }
    });
    this.handleCancel();
  }
  editBrand(brand) {
    if (window.innerWidth < 992) {
      this.showTable = !this.showTable;
      this.showForm = !this.showForm;
    }
    this.clearFields();
    console.log(brand);
    this.brandId = brand?.id;
    this.brandName = brand?.name;
    this.brandImages = [brand?.imageData];
    this.isPopular = brand?.isPopular;
    this.isActive = brand?.isActive;
    this.website = brand?.website;
    this.description = brand?.Description;
    this.isEdit = true;
  }

  clearFields() {
    this.brandName = '';
    this.brandImages = [];
    this.isPopular = false;
    this.isActive = false;
    this.website = '';
    this.description = '';
  }

  onPageNumberChange(id: any, event: any) {
    if (event.pageIndex != this.pageIndex) {
    this.spinner.show();
    this.pageIndex = event.pageIndex;
    this.fetchData();
    document.getElementById(id).scrollIntoView();
    window.scroll(0, 0);
    }
  }

  searchBrands() {
    this.pageIndex = 1;
    this.fetchData();
  }

  downloadingFile: boolean = false;

  downloadBrandsAsFile() {
    this.downloadingFile = true;
    axios({
      url: environment.url + 'brands/exportAsExcel', //your url
      method: 'POST',
      responseType: 'blob', // important
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('admintokenoasisdata'),
      },
    })
      .then((response) => {
        console.log(response);
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        let date = moment(new Date()).format('DD-MM-YYYY');
        link.setAttribute('download', 'brands_dated_' + date + '.csv'); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        this.downloadingFile = false;
      })
      .catch((error) => {
        this.downloadingFile = false;
        this.toastr.error('Please Try Again!', '', {
          timeOut: 2500,
        });
      });
  }

  // brands bulk upload

  uploadExcelFile(ev: any) {
    this.spinner.show();
    console.log(ev.target.files);
    var formData = new FormData();
    formData.append('file', ev.target.files[0]);
    axios
      .post(environment.url + 'brands/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization:
            'Bearer ' + localStorage.getItem('admintokenoasisdata'),
        },
      })
      .then((response) => {
        console.log(response.data);
        this.toastr.success('brands excel updated!..', '', {
          timeOut: 3500,
        });
        // if (response.data.message == 'media uploaded') {
        this.fetchData();
        this.downloadExcel(response.data.data);
        this.spinner.hide();
        // }
      })
      .catch((error) => {
        console.log(error);
        this.toastr.error(error.response.data.message, '', {
          timeOut: 3500,
        });
        this.spinner.hide();
      });

    // ev.target.value = '';
  }

  downloadExcel(data: any) {
    var categoryData: any = [];
    var brandData: any = [];
    var duplicateData: any = [];

    let orderData = [];
    if (data?.categories?.length > 0) {
      for (let i = 0; i < data?.categories?.length; i++) {
        var temp = [
          i + 1,
          data?.categories[i][0]?.indexNo,
          data?.categories[i][0]?.categoryName,
        ];
        categoryData.push(temp);
        temp = [];
      }
      orderData.push({
        title: ['Mismatch Categories'],
        data: categoryData,
        headers: ['S.No', 'Line Number', 'Category Name'],
      });
    }

    if (data?.brands?.length > 0) {
      for (let i = 0; i < data?.brands?.length; i++) {
        var temp = [
          i + 1,
          data?.brands[i][0]?.indexNo,
          data?.brands[i][0]?.brandName,
        ];
        brandData.push(temp);
        temp = [];
      }
      orderData.push({
        title: ['Mismatch Brands'],
        data: brandData,
        headers: ['S.No', 'Line Number', 'Brand Name'],
      });
    }

    if (data?.duplicateModels?.length > 0) {
      for (let i = 0; i < data?.duplicateModels?.length; i++) {
        var temp = [i + 1, data?.duplicateModels[i]];
        duplicateData.push(temp);
        temp = [];
      }

      orderData.push({
        title: ['Duplicate Models'],
        data: duplicateData,
        headers: ['S.No', 'Model Number'],
      });
    }

    if (orderData.length > 0) {
      this.ete.exportExcel(orderData, 'Error_Report');
    }
  }
}
