<h3 *ngIf="showTable" class="category-heading-outside outside_heading">Banners</h3>






<div class="row">
    <!-- form column -->
    <div class="col-lg-5">
        <nz-card class="fixed_height" *ngIf="showForm">
            <div class="row justify-content-center">
                <div class="col-12 form-details">
                    <div class="row justify-content-center">
                        <div class="col-12 title-text">
                            <div class="mb-3">
                                <h4>
                                    <span class="back_icon" (click)="showFormDetails()" nz-icon nzType="left"
                                        nzTheme="outline">
                                    </span>
                                    {{isEdit? 'Edit': 'Add New'}} Banner
                                </h4>
                            </div>
                        </div>

                        <div class="col-lg-12 form_main_div">
                            <form nz-form>

                                <nz-form-item>
                                    <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired
                                        nzFor="BannerType">Type
                                    </nz-form-label>
                                    <nz-form-control>
                                        <nz-input-group>
                                            <nz-select id="BannerType" nzShowSearch class="w-100"
                                                [(ngModel)]="bannerType" [ngModelOptions]="{standalone: true}"
                                                (ngModelChange)="getBannerType($event)"
                                                nzPlaceHolder="Select Banner Type">
                                                <nz-option nzValue="product" nzLabel="Product"></nz-option>
                                                <nz-option nzValue="category" nzLabel="Category"></nz-option>
                                                <!-- <nz-option nzValue="brand" nzLabel="Brand"></nz-option>
                                                <nz-option nzValue="login-banner" nzLabel="Login Banner"></nz-option> -->

                                            </nz-select>
                                        </nz-input-group>
                                    </nz-form-control>
                                </nz-form-item>
                                <nz-form-item *ngIf="selectionType == 'category'">
                                    <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired
                                        nzFor="BannerType">Categories
                                    </nz-form-label>
                                    <nz-form-control>
                                        <nz-input-group>
                                            <nz-select id="cateforyType" nzShowSearch class="w-100"
                                                [(ngModel)]="categoryId" [ngModelOptions]="{standalone: true}"
                                                nzPlaceHolder="Select Category">
                                                <nz-option *ngFor="let category of categoryList"
                                                    nzValue="{{category._id}}" nzLabel="{{category.name}}"></nz-option>
                                                <!-- <nz-option nzValue="category" nzLabel="Category"></nz-option>
                                        <nz-option nzValue="Brand" nzLabel="Brand"></nz-option> -->

                                            </nz-select>
                                        </nz-input-group>
                                    </nz-form-control>
                                </nz-form-item>
                                <nz-form-item *ngIf="selectionType == 'product'">
                                    <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired
                                        nzFor="BannerType">Products
                                    </nz-form-label>
                                    <nz-form-control>
                                        <nz-input-group>
                                            <nz-select id="producttype" nzShowSearch class="w-100"
                                                [(ngModel)]="productId" [ngModelOptions]="{standalone: true}"
                                                nzPlaceHolder="Select Product">
                                                <nz-option *ngFor="let product of productList" nzValue="{{product._id}}"
                                                    nzLabel="{{product.name}}"></nz-option>
                                                <!-- <nz-option nzValue="category" nzLabel="Category"></nz-option>
                                        <nz-option nzValue="Brand" nzLabel="Brand"></nz-option> -->

                                            </nz-select>
                                        </nz-input-group>
                                    </nz-form-control>
                                </nz-form-item>
                                <nz-form-item *ngIf="selectionType == 'brand'">
                                    <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired
                                        nzFor="BannerType">Brands
                                    </nz-form-label>
                                    <nz-form-control>
                                        <nz-input-group>
                                            <nz-select id="brand" nzShowSearch class="w-100" [(ngModel)]="brandId"
                                                [ngModelOptions]="{standalone: true}" nzPlaceHolder="Select Brand">
                                                <nz-option *ngFor="let brand of brandList" nzValue="{{brand._id}}"
                                                    nzLabel="{{brand.name}}"></nz-option>
                                                <!-- <nz-option nzValue="category" nzLabel="Category"></nz-option>
                                        <nz-option nzValue="Brand" nzLabel="Brand"></nz-option> -->

                                            </nz-select>
                                        </nz-input-group>
                                    </nz-form-control>
                                </nz-form-item>
                                <!-- <nz-form-item>
                            <nz-form-label [nzLg]="8"  [nzSm]="6" nzRequired nzFor="BannerLink">Link</nz-form-label>
                            <nz-form-control>
                                <nz-input-group>
                                    <input type="text" nz-input id="BannerLink" placeholder="Enter Link">
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item> -->

                                <!-- <nz-form-item *ngIf="bannerType != 'login-banner'">
                                    <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired
                                        nzFor="BannerLocation">Location
                                    </nz-form-label>
                                    <nz-form-control>
                                        <nz-input-group>
                                            <nz-select id="BannerLocation" [(ngModel)]="location"
                                                [ngModelOptions]="{standalone: true}"
                                                (ngModelChange)="onLocationChange($event)" nzShowSearch class="w-100"
                                                nzPlaceHolder="Hero Section">
                                                <nz-option nzValue="offer-banner" nzLabel="Offer Banner"></nz-option>
                                                <nz-option nzValue="hero-section" nzLabel="Hero Section"></nz-option>
                                                <nz-option nzValue="sub-banner" nzLabel="Sub Banner"></nz-option>
                                                <nz-option nzValue="mobile-sub-banner-large"
                                                    nzLabel="Mobile Sub Banner Large">
                                                </nz-option>
                                                <nz-option nzValue="mobile-sub-banner-small"
                                                    nzLabel="Mobile Sub Banner Small">
                                                </nz-option>
                                            </nz-select>
                                        </nz-input-group>
                                    </nz-form-control>
                                </nz-form-item> -->

                                <nz-form-item>
                                    <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired
                                        nzFor="BannerImage">Banner Image
                                    </nz-form-label>
                                    <nz-form-control nzErrorTip="Please input your username!">
                                        <nz-input-group>
                                            <button type="button" nz-button class="cancel-button" (click)="showMedia()">
                                                <span>Select from media</span>
                                            </button>
                                            <img *ngFor="let img of bannerImage" style="width: 70px;padding-left: 10px;"
                                                nz-image [nzSrc]="liveUrl+img?.image?.url">
                                        </nz-input-group>
                                        <p *ngIf="imageSize !== ''" class="image-size">Image Size: {{imageSize}}</p>
                                    </nz-form-control>
                                </nz-form-item>

                                <nz-form-item>
                                    <nz-form-label nzRequired nzFor="attributeActive">Active
                                    </nz-form-label>
                                    <nz-form-control>
                                        <!-- <nz-input-group> -->
                                        <nz-switch id="attributeActive" [(ngModel)]="isActive"
                                            [ngModelOptions]="{standalone: true}" [nzCheckedChildren]="checkedTemplate"
                                            [nzUnCheckedChildren]="unCheckedTemplate"></nz-switch>
                                        <ng-template #checkedTemplate><i nz-icon nzType="check"></i></ng-template>
                                        <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i></ng-template>
                                        <!-- </nz-input-group> -->
                                    </nz-form-control>
                                </nz-form-item>

                                <nz-form-item class="text-center">
                                    <nz-form-control>
                                        <button (click)="resetForm()" type="button" class="mr-5" nz-button
                                            class="mr-3 cancel-button">Reset</button>
                                        <a><button [nzLoading]="bannerLoading" type="button" (click)="doChanges()"
                                                nz-button class="save-button">{{isEdit ?
                                                'Update' : 'Create'}}</button></a>
                                    </nz-form-control>
                                </nz-form-item>

                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </nz-card>
    </div>
    <div class="col-lg-7">
        <nz-card class="fixed_height" *ngIf="showTable">
            <div class="row">
                <div class="col-lg-4 text-left web-heading">
                    <h4>All Banners</h4>
                </div>
                <div class="col-lg-8 table_search">
                    <div class="d-md-flex">
                        <div class="m-b-10">
                            <nz-input-group [nzPrefix]="prefixTemplate">
                                <input autocomplete="off" type="text" nz-input placeholder="Enter Banner Name"
                                    [(ngModel)]="searchInput">
                            </nz-input-group>
                            <ng-template #prefixTemplate>
                                <i nz-icon nzType="search" class="opacity-05"></i>
                            </ng-template>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 text-right add-button-section">
                    <a (click)="showFormDetails()" nz-button class="save-button">
                        <i nz-icon nzType="plus-circle" theme="outline"></i>
                        <span>Add Banners</span>
                    </a>
                </div>
            </div>
            <nz-table #productsListTable [nzData]="productsList" [nzScroll]="{ y: '58vh', x : '500px'}"
                (nzQueryParams)="onPageNumberChange('bannerTable')">
                <thead>
                    <tr>
                        <th *ngFor="let column of orderColumn" [nzWidth]="column.width">{{ column.title }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr id="bannerTable"
                        *ngFor="let item of productsListTable.data | filter : searchInput;  let i=index">
                        <!-- <td>{{i+1}}</td> -->
                        <td>
                            <div (click)="editBanner(item)" class="d-flex align-items-center banner-name">
                                {{item.type}}
                            </div>
                        </td>
                        <!-- <td>
                            {{item.typeId}}
                        </td> -->
                        <td>
                            <!-- <nz-avatar nzShape="square" [nzSize]="50" nzIcon="picture" [nzSrc]="liveUrl+item.banner">
                            </nz-avatar> -->
                            <img class="table_image" nz-image [nzSrc]="liveUrl+item.banner" alt="">
                        </td>
                        <td>
                            <span [ngClass]="{'un-published' : !item.active}"
                                class="text-capitalize published">{{item.active ?
                                'Published' : 'Unpublished'}}</span>
                        </td>
                        <td>{{item.location ? item.location : '-'}}</td>
                    </tr>
                </tbody>
                <!-- LOADER -->

                <ngx-spinner *ngIf="!MediaVisible" bdColor="rgb(255 255 255 / 88%)" size="medium" color="#00f226"
                    type="ball-spin-clockwise" [fullScreen]="false"></ngx-spinner>
            </nz-table>
        </nz-card>
    </div>
</div>

<nz-modal [nzStyle]="{ top: '10px' }" nzWidth="90%" [(nzVisible)]="MediaVisible" nzTitle="Banner Image" nzCentered
    (nzOnCancel)="handleCancel()" (nzOnOk)="sendMedia()" nzOkText="Set Banner Image" [nzOkLoading]="isOkLoading">
    <div class="container-fluid" *nzModalContent>
        <app-media></app-media>
    </div>
</nz-modal>

<!-- MEDIA MODEL SECTION STARTS -->