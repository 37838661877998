<h3 class="category-heading-outside ">Build PC Enquiries</h3>

<nz-card>
    <nz-table #enquiryListTable [nzData]="enquiryList" [nzScroll]="{ y: '50vh', x : '800px' }">
        <thead>
            <tr>
                <th *ngFor="let column of orderColumn" [nzWidth]="column.width">{{ column.title }}</th>
            </tr>
        </thead>
        <tbody>
            <tr id="categoryTable" *ngFor="let item of enquiryListTable.data ;  let i=index">
                <!-- <td>{{i+1}}</td> -->
                <td>{{item.name}}</td>
                <td>{{item.phoneNo}}</td>
                <td>{{item.email}}</td>
                <td>$ {{item.budget}}</td>
                <td><span [innerHTML]="item.systemRequirements"></span></td>
            </tr>
        </tbody>
        <!-- LOADER -->

        <ngx-spinner bdColor="rgb(255 255 255 / 88%)" size="medium" color="#00f226" type="ball-spin-clockwise"
            [fullScreen]="false">
        </ngx-spinner>
    </nz-table>
</nz-card>