<h3 *ngIf="showTable" class="category-heading-outside">Service Center</h3>


<nz-card *ngIf="showTable">
    <div class="row">
        <div class="col-lg-8">
            <div class="d-md-flex">
                <div class="m-b-10 m-r-20">
                    <nz-input-group [nzPrefix]="prefixTemplate">
                        <input autocomplete="off" type="text" nz-input placeholder="Enter Service Center Name"
                            [(ngModel)]="searchInput">
                    </nz-input-group>
                    <ng-template #prefixTemplate>
                        <i nz-icon nzType="search" class="opacity-05"></i>
                    </ng-template>
                </div>
                <!-- <div class="m-b-10 m-r-20 d-flex align-items-center">
                    <nz-select style="min-width: 220px;" class="w-100" nzPlaceHolder="Category" [(ngModel)]="selectedCategory" (ngModelChange)="categoryChange($event)">
                        <nz-option nzLabel="All" nzValue="All"></nz-option>
                        <nz-option nzLabel="Home Decoration" nzValue="Home Decoration"></nz-option>
                        <nz-option nzLabel="Eletronic" nzValue="Eletronic"></nz-option>
                        <nz-option nzLabel="Jewellery" nzValue="Jewellery"></nz-option>
                    </nz-select>
                </div>
                <div class="m-b-10 m-r-20 d-flex align-items-center">
                    <nz-select style="min-width: 220px;" class="w-100" nzPlaceHolder="Status" [(ngModel)]="selectedStatus" (ngModelChange)="statusChange($event)">
                        <nz-option nzLabel="All" nzValue="All"></nz-option>
                        <nz-option nzLabel="In Stock" nzValue="in stock"></nz-option>
                        <nz-option nzLabel="Out Of Stock" nzValue="out of stock"></nz-option>
                    </nz-select>
                </div> -->
            </div>
        </div>
        <div class="col-lg-4 text-right add-button-section">
            <a (click)="showFormDetails()" nz-button class="save-button">
                <i nz-icon nzType="plus-circle" theme="outline"></i>
                <span>Add Services</span>
                <!-- <span *ngFor="defaultMode == 'Edit'">Edit Services</span> -->
            </a>
        </div>
    </div>
    <nz-table #productsListTable [nzData]="productsList" [nzScroll]="{ y: '50vh' , x: '2200px'}"
        (nzQueryParams)="onPageNumberChange('serviceCenterTable')">
        <thead>
            <tr>
                <th *ngFor="let column of orderColumn" [nzWidth]="column.width">{{ column.title }}</th>
            </tr>
        </thead>
        <tbody>
            <tr id="serviceCenterTable" *ngFor="let item of productsListTable.data|filter: searchInput;  let i=index">
                <!-- <td>{{i+1}}</td> -->
                <td>
                    <div (click)="editChanges(item)" class="d-flex align-items-center service-center-name">
                        {{item.name}}
                    </div>
                </td>
                <td>
                    <div *ngFor="let data of item?.brand" class="">
                        {{data?.name + ','}}
                    </div>

                </td>
                <td>{{item?.address1}}</td>
                <td>{{item?.address2}}</td>
                <td>{{item?.state}}</td>
                <td>{{item?.city}}</td>
                <td>{{item?.pincode}}</td>
                <td>{{item?.map}}</td>
                <td>{{item?.phone}}</td>
                <td>{{item?.landline}}</td>
                <td>{{item?.description}}</td>
                <td>{{item?.website}}</td>

                <td>
                    <!-- <span [ngClass]="{'un-published' : !item.active}" class="text-capitalize published">{{item.active ?
                        'Published' : 'Unpublished'}}</span> -->
                    <nz-switch [nzLoading]="okLoading" [(ngModel)]="item.active"
                        (ngModelChange)="directUpdate(item , $event)"></nz-switch>
                </td>
            </tr>
        </tbody>
        <!-- LOADER -->

        <ngx-spinner bdColor="rgb(255 255 255 / 88%)" size="medium" color="#00f226" type="ball-spin-clockwise"
            [fullScreen]="false">
        </ngx-spinner>
    </nz-table>
</nz-card>

<!-- (ngModelChange)="search()" Goes to line 7 -->
<!-- text-md-right Goes to line 70 -->

<!-- ADD SERVICE FORM DESIGN STARTS  -->

<nz-card *ngIf="showForm" class="">
    <div class="row justify-content-center">
        <div class="col-12 mb-3 title-text">
            <div class="">
                <h4>
                    <span class="back_icon" (click)="showFormDetails()" nz-icon nzType="left" nzTheme="outline">
                    </span> {{defaultMode == 'Add' ? 'Add New' : 'Edit'}} Service Center
                </h4>
            </div>
        </div>

        <div class="col-lg-12">
            <form nz-form>
                <div class="row justify-content-center">
                    <div class="col-lg-7 left_column">
                        <nz-card class="form_fixed_height">
                            <div nz-row [nzGutter]="24">
                                <div nz-col [nzXs]="24" [nzSpan]="12">
                                    <nz-form-item>
                                        <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired
                                            nzFor="ServiceName">Name
                                        </nz-form-label>
                                        <nz-form-control>
                                            <nz-input-group>
                                                <input autocomplete="off" type="text" [(ngModel)]="centerName"
                                                    [ngModelOptions]="{standalone: true}" nz-input id="ServiceName "
                                                    placeholder="Enter Name">
                                            </nz-input-group>
                                            <p *ngIf="requiredData == 'centerName'" class="error-message">!Please Enter
                                                Service Center Name</p>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>

                                <div nz-col [nzXs]="24" [nzSpan]="12">
                                    <nz-form-item>
                                        <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired
                                            nzFor="attributeValue">Brands
                                        </nz-form-label>
                                        <nz-form-control>
                                            <nz-input-group>
                                                <nz-select [(ngModel)]="brandList" [ngModelOptions]="{standalone: true}"
                                                    nzMode="tags" id="" nzShowSearch class="w-100"
                                                    nzPlaceHolder="Enter Brands">
                                                    <nz-option *ngFor="let column of brands" [nzValue]="column._id"
                                                        [nzLabel]="column.name">
                                                    </nz-option>
                                                </nz-select>
                                            </nz-input-group>
                                            <p *ngIf="requiredData == 'brandList'" class="error-message">!Please Enter
                                                Brands Name
                                            </p>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>

                                <div nz-col [nzXs]="24" [nzSpan]="12">
                                    <nz-form-item>
                                        <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired
                                            nzFor="ServiceLandline">
                                            Description
                                        </nz-form-label>
                                        <nz-form-control>
                                            <nz-input-group>
                                                <textarea id="ServiceLandline" [(ngModel)]="description"
                                                    [ngModelOptions]="{standalone: true}" nz-input
                                                    placeholder="Enter Your Text Here"
                                                    [nzAutosize]="{ minRows: 3, maxRows: 6 }"></textarea>
                                            </nz-input-group>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>

                                <div nz-col [nzXs]="24" [nzSpan]="12">
                                    <nz-form-item>
                                        <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired
                                            nzFor="ServiceWebsite">Website
                                        </nz-form-label>
                                        <nz-form-control>
                                            <nz-input-group>
                                                <input autocomplete="off" type="text" [(ngModel)]="website"
                                                    [ngModelOptions]="{standalone: true}" nz-input id="ServiceWebsite "
                                                    placeholder="Enter Website url ">
                                            </nz-input-group>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>

                                <div nz-col [nzXs]="24" [nzSpan]="12">
                                    <nz-form-item>
                                        <nz-form-label nzRequired nzFor="ServiceActive">Active
                                        </nz-form-label>
                                        <nz-form-control>
                                            <!-- <nz-input-group> -->
                                            <nz-switch id="ServiceActive" [(ngModel)]="isActive"
                                                [ngModelOptions]="{standalone: true}"
                                                [nzCheckedChildren]="checkedTemplate"
                                                [nzUnCheckedChildren]="unCheckedTemplate">
                                            </nz-switch>
                                            <ng-template #checkedTemplate><i nz-icon nzType="check"></i>
                                            </ng-template>
                                            <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i>
                                            </ng-template>
                                            <!-- </nz-input-group> -->
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </div>
                        </nz-card>
                    </div>

                    <div class="col-lg-5 right_column">
                        <nz-card class="form_fixed_height">
                            <div nz-row [nzGutter]="24">
                                <div nz-col [nzXs]="12" [nzSpan]="12">
                                    <nz-form-item>
                                        <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired
                                            nzFor="ServiceAddress1 ">Address Line 1
                                        </nz-form-label>
                                        <nz-form-control>
                                            <nz-input-group>
                                                <input autocomplete="off" type="text" [(ngModel)]="address1"
                                                    [ngModelOptions]="{standalone: true}" nz-input id="ServiceAddress1 "
                                                    placeholder="Enter address Line 1 ">
                                            </nz-input-group>
                                            <p *ngIf="requiredData == 'address1'" class="error-message">!Please Enter
                                                Valid Address
                                            </p>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>

                                <div nz-col [nzXs]="12" [nzSpan]="12">
                                    <nz-form-item>
                                        <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired
                                            nzFor="ServiceAddress2 ">Address Line 2
                                        </nz-form-label>
                                        <nz-form-control>
                                            <nz-input-group>
                                                <input autocomplete="off" type="text" [(ngModel)]="address2"
                                                    [ngModelOptions]="{standalone: true}" nz-input id="ServiceAddress2 "
                                                    placeholder="Enter address Line 2 ">
                                            </nz-input-group>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>

                                <div nz-col [nzXs]="12" [nzSpan]="12">
                                    <nz-form-item>
                                        <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired
                                            nzFor="ServiceCity">City
                                        </nz-form-label>
                                        <nz-form-control>
                                            <nz-input-group>
                                                <input autocomplete="off" type="text" [(ngModel)]="city"
                                                    [ngModelOptions]="{standalone: true}" nz-input id="ServiceCity "
                                                    placeholder="Enter City">
                                            </nz-input-group>
                                            <p *ngIf="requiredData == 'city'" class="error-message">!Please Enter City
                                                Name
                                            </p>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>

                                <div nz-col [nzXs]="12" [nzSpan]="12">
                                    <nz-form-item>
                                        <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired
                                            nzFor="ServiceState">State
                                        </nz-form-label>
                                        <nz-form-control>
                                            <nz-input-group>
                                                <input autocomplete="off" type="text" [(ngModel)]="state"
                                                    [ngModelOptions]="{standalone: true}" nz-input id="ServiceState "
                                                    placeholder="Enter State ">
                                            </nz-input-group>
                                            <p *ngIf="requiredData == 'state'" class="error-message">!Please Enter State
                                                Name
                                            </p>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>

                                <div nz-col [nzXs]="12" [nzSpan]="12">
                                    <nz-form-item>
                                        <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired
                                            nzFor="ServicePincode">Pincode
                                        </nz-form-label>
                                        <nz-form-control>
                                            <nz-input-group>
                                                <input autocomplete="off" type="number" [(ngModel)]="pincode"
                                                    [ngModelOptions]="{standalone: true}" nz-input id="ServicePincode "
                                                    placeholder="Enter Pincode" maxlength="6"
                                                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                                            </nz-input-group>
                                            <p *ngIf="requiredData == 'pincode'" class="error-message">!Please Enter
                                                Pincode
                                            </p>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>

                                <div nz-col [nzXs]="12" [nzSpan]="12">
                                    <nz-form-item>
                                        <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired
                                            nzFor="ServiceLocation">Location
                                        </nz-form-label>
                                        <nz-form-control>
                                            <nz-input-group>
                                                <input autocomplete="off" type="text" [(ngModel)]="mapUrl"
                                                    [ngModelOptions]="{standalone: true}" nz-input id="ServiceLocation "
                                                    placeholder="Enter Location Url ">
                                            </nz-input-group>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>

                                <div nz-col [nzXs]="12" [nzSpan]="12">
                                    <nz-form-item>
                                        <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired
                                            nzFor="ServiceMobile">Mobile Number
                                        </nz-form-label>
                                        <nz-form-control>
                                            <nz-input-group>
                                                <input autocomplete="off" type="number" [(ngModel)]="mobNo"
                                                    [ngModelOptions]="{standalone: true}" nz-input id="ServiceMobile "
                                                    placeholder="Enter Mobile Number" maxlength="10"
                                                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                                            </nz-input-group>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>

                                <div nz-col [nzXs]="12" [nzSpan]="12">
                                    <nz-form-item>
                                        <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired
                                            nzFor="ServiceLandline">Landline Number
                                        </nz-form-label>
                                        <nz-form-control>
                                            <nz-input-group>
                                                <input autocomplete="off" type="number" [(ngModel)]="landlineNo"
                                                    [ngModelOptions]="{standalone: true}" nz-input id="ServiceLandline "
                                                    placeholder="Enter Landline Number">
                                            </nz-input-group>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>

                                <div nz-col [nzXs]="24" [nzSpan]="12">
                                    <nz-form-item class="text-center">
                                        <nz-form-control>
                                            <button (click)="resetForm()" type="button" class="mr-3 cancel-button"
                                                nz-button nzType="default">Reset</button>
                                            <a>
                                                <button [nzLoading]="okLoading" (click)="doChanges()" type="button"
                                                    nz-button class="save-button">{{defaultMode == 'Add' ? 'Create' :
                                                    'Update'}}
                                                </button>
                                            </a>
                                            <!-- <a *ngIf="defaultMode == 'Edit'"><button (click)="doChanges()" type="button"
                                                    nz-button class="save-button">Save Changes</button></a> -->
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </div>
                        </nz-card>
                    </div>
                </div>
            </form>
        </div>
    </div>
</nz-card>

<!-- ADD SERVICE FORM DESIGN ENDS  -->