<h3 *ngIf="showTable" class="category-heading-outside">Customers</h3>

<nz-card *ngIf="showTable">
    <div class="row">
        <div class="col-lg-8">
            <div class="d-md-flex">
                <div class="m-b-10">
                    <nz-input-group [nzPrefix]="prefixTemplate">
                        <input (keyup)="searchCustomers()" autocomplete="off" type="text" nz-input
                            placeholder="Enter Customer Name" [(ngModel)]="searchInput" />
                    </nz-input-group>
                    <ng-template #prefixTemplate>
                        <i nz-icon nzType="search" class="opacity-05"></i>
                    </ng-template>
                </div>
            </div>
        </div>
        <!-- <div class="col-lg-4 text-right add-button-section">
            <a (click)="showForm()" nz-button class="save-button">
                <i nz-icon nzType="plus-circle" theme="outline"></i>
                <span>Add Customer</span>
            </a>
        </div> -->
    </div>
    <nz-table
        [nzFooter]="totalCount == 0 ? '' : 'Showing ' +  (((pageSize * pageIndex) - pageSize) + 1) + ' to ' +  (pageSize * pageIndex > totalCount ? totalCount : pageSize * pageIndex) +  ' of ' +  totalCount"
        #customerListTable [nzData]="customerList" [nzScroll]="{ y: '50vh' , x : '900px' }" [nzFrontPagination]="false"
        [nzTotal]="totalCount" [nzPageSize]="pageSize" [nzPageIndex]="pageIndex"
        (nzQueryParams)="onPageNumberChange('customersTable' , $event)">
        <thead>
            <tr>
                <th *ngFor="let column of orderColumn" [nzWidth]="column.width">
                    {{ column.title }}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr id="customersTable" *ngFor="let item of customerList  ; let i = index ">
                <!-- <td>{{i+1}}</td> -->
                <td>{{item?.name}}</td>
                <td>{{item?.phoneNo}}</td>
                <td>{{item?.email}}</td>
                <td>{{item?.contact ? item?.contact[0]?.address?.addressLine1 + ', ' +
                    item?.contact[0]?.address?.addressLine2
                    + ', '
                    +
                    item?.contact[0]?.address?.townOrCity + ', ' + item?.contact[0]?.address?.country + ', ' +
                    item?.contact[0]?.address?.pincodeOrZipcode : '-'}}
                </td>
                <td>
                    <div (click)="showOrderDetails(item)" class="" [ngClass]="{'order_count' : item?.orderCount} ">
                        {{item?.orderCount ? item?.orderCount : 0}}
                    </div>
                </td>
                <td>{{item?.orderTotal ? '$ ' + item?.orderTotal : '-'}}</td>
            </tr>

        </tbody>
        <ngx-spinner *ngIf="tableSpinner" bdColor="rgb(255 255 255 / 88%)" size="medium" color="#00f226"
            type="ball-spin-clockwise" [fullScreen]="false"></ngx-spinner>
    </nz-table>
</nz-card>



<nz-drawer [nzWidth]="drawerWidth" [nzClosable]="true" [nzVisible]="productPreview" [nzPlacement]="'right'"
    nzTitle="Order Details" (nzOnClose)="previewClose()">
    <ng-container *nzDrawerContent>
        <div class="order_details_container">
            <div *ngFor="let order of customerOrders" class="order_details_div mb-3">
                <div class="row mb-3">
                    <div class="col-5">
                        <img class="product_image" [src]="liveUrl + order?.products[0]?.images[0]?.image?.url" alt="">
                    </div>
                    <div class="col-7">
                        <h5>{{order?.products[0]?.name}}</h5>
                        <p><span>Order Id: </span>{{ order?.orderId}}</p>
                        <p><span>Order Date: </span>{{ moment(order?.orderedDate).format('DD-MM-YYYY hh:mm a')}}</p>
                        <p><span>Order Status: </span>{{ order?.shipping?.tracking?.status}}</p>
                        <p><span>Payment Type: </span>{{ order?.paymentType}}</p>
                        <p><span>Order Total: </span>{{'Rs.' + order?.orderTotal}}</p>
                    </div>
                </div>
            </div>
        </div>

        <ngx-spinner *ngIf="drawerSpinner" bdColor="rgb(255 255 255 / 88%)" size="medium" color="#00f226"
            type="ball-spin-clockwise" [fullScreen]="false"></ngx-spinner>
    </ng-container>
</nz-drawer>