import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import axios, { Axios } from 'axios';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-policy-page',
  templateUrl: './policy-page.component.html',
  styleUrls: ['./policy-page.component.css'],
})
export class PolicyPageComponent implements OnInit {
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter description here',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: '',
    toolbarHiddenButtons: [['list', '']],
    sanitize: false,
    fonts: [
      { class: 'Inter-Bold', name: 'Inter Bold' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
    ],
  };
  showTable: boolean = true;
  showForm: boolean = false;
  isEdit = false;
  directMode: boolean = false;

  orderColumn: any = [
    {
      title: 'Name',
      width: '80px',
    },
    {
      title: 'Description',
      width: '150px',
    },
    {
      title: 'Status',
      width: '40px',
    },
  ];

  searchInput: any = '';

  allPolicies: any = [];

  addPolicyForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private modalService: NzModalService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    if (window.innerWidth > 992) {
      this.showForm = true;
    }

    this.addPolicyForm = this.fb.group({
      policyName: [null, [Validators.required]],
      description: [null, [Validators.required]],
      isActive: [true],
    });

    this.fetchData();
  }

  fetchData() {
    this.spinner.show();
    this.directMode = false;
    this.isEdit = false;
    axios
      .get(environment.url + 'policy/get', {
        headers: {
          Authorization:
            'Bearer ' + localStorage.getItem('admintokenoasisdata'),
        },
      })
      .then((response) => {
        this.spinner.hide();
        console.log(response);
        this.allPolicies = response.data.data;
      })
      .catch((error) => {
        this.spinner.hide();
        console.log(error);
      });
  }

  showFormDetails() {
    this.directMode = false;
    this.addPolicyForm.reset();
    this.addPolicyForm.get('isActive').setValue(true);
    this.isEdit = false;
    this.showTable = !this.showTable;
    this.showForm = !this.showForm;
  }

  directUpdate(selected: any, event: any) {
    this.addingPolicy = true;
    this.directMode = true;
    this.isEdit = true;
    this.addPolicyForm.get('policyName').setValue(selected.name);
    this.addPolicyForm.get('description').setValue(selected.description);
    this.addPolicyForm.get('isActive').setValue(event);
    this.addUpdatePolicy();
  }

  addingPolicy: any;

  addUpdatePolicy() {
    if (this.addPolicyForm.valid) {
      this.addingPolicy = true;
      console.log(this.addPolicyForm.value);

      var url = '';
      var reqObj: any = {
        description: this.addPolicyForm.value.description,
        isActive: this.addPolicyForm.value.isActive,
      };

      if (this.isEdit) {
        url = 'policy/update';
        reqObj.policyName = this.addPolicyForm.value.policyName;
      } else {
        url = 'policy/create';
        reqObj.name = this.addPolicyForm.value.policyName;
      }

      axios
        .post(environment.url + url, reqObj, {
          headers: {
            Authorization:
              'Bearer ' + localStorage.getItem('admintokenoasisdata'),
          },
        })
        .then((response) => {
          // console.log(response);
          if (response.data.status == 'success') {
            this.addingPolicy = false;
            this.addPolicyForm.reset();
            this.addPolicyForm.get('isActive').setValue(true);
            this.toastr.success(response.data.message, '', {
              timeOut: 2500,
            });
            if (!this.directMode) {
              this.fetchData();
            }
          }
        })
        .catch((error) => {
          this.addingPolicy = false;
          console.log(error);
        });
    } else {
      Object.values(this.addPolicyForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  editPolicy(selected: any) {
    this.directMode = false;
    console.log(selected);
    if (window.innerWidth < 992) {
      this.showTable = !this.showTable;
      this.showForm = !this.showForm;
    }
    this.isEdit = true;
    this.addPolicyForm.get('policyName').setValue(selected.name);
    this.addPolicyForm.get('description').setValue(selected.description);
    this.addPolicyForm.get('isActive').setValue(selected.isActive);
  }

  resetForm(): void {
    // this.validateForm.reset();
    this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Sure You Want to Reset ?',
      nzOkText: 'Yes',
      nzCancelText: 'No',
      nzOnOk: () => {
        this.addPolicyForm.reset();
        this.addPolicyForm.get('isActive').setValue(true);
        if (window.innerWidth > 992) {
          this.isEdit = false;
        } else {
          this.showFormDetails();
        }
      },
    });
  }
  detailsModal: any = false;
  singleDescription: any = '';

  showDetailModal(selected: any) {
    this.singleDescription = selected;
    this.detailsModal = true;
  }

  handleCancel() {
    this.singleDescription = '';
    this.detailsModal = false;
  }
}
