import { Component, OnInit } from "@angular/core";
import axios, { Axios } from "axios";
import { environment } from "src/environments/environment";
import * as moment from "moment";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { NavService } from "src/app/services/nav.service";
import { NzModalService } from "ng-zorro-antd/modal";

@Component({
  selector: "app-coupon-code",
  templateUrl: "./coupon-code.component.html",
  styleUrls: ["./coupon-code.component.css"],
})
export class CouponCodeComponent implements OnInit {
  addCoupenForm: FormGroup;
  liveUrl = environment.url;
  moment: any = moment;
  showTable: boolean = true;
  coupenList: any = [];
  searchInput: any;
  MediaVisible = false;
  isOkLoading = false;
  bannerImage: any;
  createCouponObj: any = {};

  orderColumn = [
    // {
    //   title: 'S.No',
    //   width: '40px',
    // },
    {
      title: "Coupon Code",
      width: "100px",
    },

    {
      title: "Image",
      width: "60px",
    },
    {
      title: "Value",
      width: "50px",
    },
    {
      title: "Type",
      width: "80px",
    },
    {
      title: "Start Date",
      width: "125px",
    },
    {
      title: "End Date",
      width: "125px",
    },
    {
      title: "Status",
      width: "80px",
    },
    {
      title: "Minimum Order Value",
      width: "120px",
    },
    {
      title: "Maximum Allowed Time",
      width: "130px",
    },
  ];

  constructor(
    private navService: NavService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private modalService: NzModalService
  ) {}

  ngOnInit(): void {
    this.addCoupenForm = this.fb.group({
      couponCode: [null, [Validators.required]],
      coupenType: ["price-discount", [Validators.required]],
      value: [null, [Validators.required]],
      fromDate: [null, [Validators.required]],
      toDate: [null, [Validators.required]],
      maxAllowedTimes: [null],
      maxOrderValue: [null],
      coupenActive: [true],
    });

    this.fetchData();
  }

  fetchData() {
    this.spinner.show();
    axios
      .get(environment.url + "coupon/", {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("admintokenoasisdata"),
        },
      })
      .then((response) => {
        console.log(response.data.data.coupons);
        this.coupenList = response.data.data.coupons;
        this.spinner.hide();
      })
      .catch((err) => {
        console.log(err.response);
        this.spinner.hide();
      });
  }

  showMedia() {
    this.MediaVisible = true;
  }
  handleCancel(): void {
    this.MediaVisible = false;
  }

  sendMedia() {
    this.navService.formRefreshAnnouncedSource.subscribe((item) => {
      this.bannerImage = item;
      console.log(this.bannerImage);
    });
    this.handleCancel();
  }

  showForm() {
    this.addCoupenForm.reset();
    this.bannerImage = [];
    this.isOkLoading = false;
    this.addCoupenForm.get("coupenActive").setValue(true);
    this.showTable = !this.showTable;
  }

  showTableData() {
    this.modalService.confirm({
      nzTitle: "Confirm",
      nzContent: "Sure You Want to Reset ?",
      nzOkText: "Yes",
      nzCancelText: "No",
      nzOnOk: () => this.addCoupenForm.reset(),
    });
  }

  addCoupon() {
    if (this.bannerImage == undefined || this.bannerImage == "") {
      this.toastr.error("Please Select Banner Image", "", {
        timeOut: 2500,
      });
    }
    console.log(this.bannerImage);
    if (
      this.addCoupenForm.valid &&
      this.bannerImage !== undefined &&
      this.bannerImage !== ""
    ) {
      this.isOkLoading = true;
      this.createCouponObj = {
        name: this.addCoupenForm.value.couponCode,
        startDate: moment(this.addCoupenForm.value.fromDate).format(),
        endDate: moment(this.addCoupenForm.value.toDate).format(),
        type: this.addCoupenForm.value.coupenType,
        value: this.addCoupenForm.value.value,
        bannerImageId: this.bannerImage[0]._id,
        isActive: this.addCoupenForm.value.coupenActive,
      };

      if (this.addCoupenForm.value.maxOrderValue == null) {
        this.createCouponObj.hasMaxOrderAmountValueEnabled = false;
      } else {
        this.createCouponObj.hasMaxOrderAmountValueEnabled = true;
        this.createCouponObj.hasMaxOrderAmountValue =
          this.addCoupenForm.value.maxOrderValue;
      }

      if (this.addCoupenForm.value.maxAllowedTimes == null) {
        this.createCouponObj.hasMaximumAllowedTimes = false;
      } else {
        this.createCouponObj.hasMaximumAllowedTimes = true;
        this.createCouponObj.maximumAllowedTimes =
          this.addCoupenForm.value.maxAllowedTimes;
      }

      console.log(this.createCouponObj);

      axios
        .post(environment.url + "coupon/create", this.createCouponObj, {
          headers: {
            Authorization:
              "Bearer " + localStorage.getItem("admintokenoasisdata"),
          },
        })
        .then((response) => {
          console.log(response.data);
          // if (response.data.status == 'success') {
          this.isOkLoading = false;
          this.toastr.success(response.data.message, "", {
            timeOut: 2500,
          });
          this.fetchData();
          this.showTable = !this.showTable;
          // }
        })
        .catch((err) => {
          console.log(err.response);
          if (err.response.data.data == undefined) {
            this.toastr.error("", err.response.data.message, {
              timeOut: 2500,
            });
          } else {
            this.toastr.error("", err.response.data.data[0].msg, {
              timeOut: 2500,
            });
          }
          this.isOkLoading = false;
        });
    } else {
      Object.values(this.addCoupenForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  updateCoupen(coupenData: any) {
    var updateData = {
      couponId: coupenData._id,
      isActive: !coupenData.isActive,
    };
    // console.log(updateData);
    axios
      .post(environment.url + "coupon/update", updateData, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("admintokenoasisdata"),
        },
      })
      .then((response) => {
        // console.log(response.data);
        if (response.data.status == "success") {
          this.toastr.success("Coupon Status Updated!", "", {
            timeOut: 2500,
          });
          this.fetchData();
        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response.data.data == undefined) {
          this.toastr.error("", error.response.data.message, {
            timeOut: 2500,
          });
        } else {
          this.toastr.error(
            error.response?.data?.data[0]?.msg +
              " at " +
              error.response?.data?.data[0]?.param,
            "",
            {
              timeOut: 2500,
            }
          );
        }
      });
  }

  onPageNumberChange(event) {
    document.getElementById(event).scrollIntoView();
    window.scroll(0, 0);
  }
}
