import { Component, ElementRef, HostListener } from "@angular/core";
import { ROUTES } from "./side-nav-routes.config";
import { ThemeConstantService } from "../../services/theme-constant.service";
import { NavService } from "src/app/services/nav.service";
import { NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs";

@Component({
  selector: "app-sidenav",
  templateUrl: "./side-nav.component.html",
})
export class SideNavComponent {
  public menuItems: any[];
  isFolded: boolean;
  isSideNavDark: boolean;
  isExpand: boolean;

  addProduct: boolean;
  userData: any;

  subscription!: Subscription;

  constructor(
    private themeService: ThemeConstantService,
    private elementRef: ElementRef,
    private navService: NavService,
    private router: Router
  ) {
    // if (this?.userData?.user?.role == 'deliveryBoy') {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // console.log(event.url);
        if (event.url != "recent-orders") {
          if (
            localStorage.getItem(
              "kgahjegfygwfhjvbajsndfgjhjagjkhfteygfhygakjhfkghjfgasdkayetyfjhgd"
            ) == "deliveryBoy"
          ) {
            this.router.navigate(["/recent-orders"]);
          }
        }
      }
    });
    // }
  }

  ngOnInit(): void {
    this.menuItems = ROUTES.filter((menuItem) => menuItem);
    this.themeService.isMenuFoldedChanges.subscribe(
      (isFolded) => (this.isFolded = isFolded)
    );
    this.themeService.isExpandChanges.subscribe(
      (isExpand) => (this.isExpand = isExpand)
    );
    this.themeService.isSideNavDarkChanges.subscribe(
      (isDark) => (this.isSideNavDark = isDark)
    );

    // this.navService.productStatus.subscribe((status) => {
    //   // console.log(status);
    //   this.addProduct = status;
    // });

    this.getUserData();
  }

  getUserData() {
    // this.navService.getUserDetails();

    this.subscription = this.navService.sharedUserData.subscribe((data) => {
      if (data) {
        // console.log(data.data);
        this.userData = data?.data;
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  @HostListener("document:mousedown", ["$event"])
  onGlobalClick(event: any): void {
    if (
      !this.elementRef.nativeElement.contains(event.target) &&
      window.innerWidth < 992
    ) {
      this.isFolded = false;
      this.isExpand = false;
      this.themeService.toggleExpand(this.isExpand);
      this.themeService.toggleFold(this.isFolded);
    }
  }

  closeMobileMenu(url): void {
    this.navService.checkProductStatus(url);
    if (window.innerWidth < 992) {
      this.isFolded = false;
      this.isExpand = !this.isExpand;
      this.themeService.toggleExpand(this.isExpand);
      this.themeService.toggleFold(this.isFolded);
    }
  }
}
