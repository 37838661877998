import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import axios, { Axios } from "axios";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-payments",
  templateUrl: "./payments.component.html",
  styleUrls: ["./payments.component.css"],
})
export class PaymentsComponent implements OnInit {
  paymentsList: any = [];

  orderColumn = [
    {
      title: "Order Id",
      width: "60px",
    },
    {
      title: "Customer Name",
      width: "80px",
    },
    {
      title: "Phone Number",
      width: "80px",
    },
    {
      title: "Order Amount",
      width: "70px",
    },
    {
      title: "Payment Mode",
      width: "90px",
    },
    {
      title: "Payment Status",
      width: "80px",
    },
    {
      title: "Ref. Number",
      width: "80px",
    },
  ];

  selectedPaymentStatus: any = "all";
  selectedOrderStatus: any = "all";
  selectedPaymentMode: any = "all";

  paymentStatus: any = [
    { value: "all", label: "All" },
    { value: "unpaid", label: "Unpaid" },
    { value: "paid", label: "Paid" },
  ];
  orderStatus: any = [
    { value: "all", label: "All" },
    { value: "Booked", label: "Booked" },
    { value: "In Transit", label: "In Transit" },
    { value: "Out for Delivery", label: "Out for Delivery" },
    { value: "Delivered", label: "Delivered" },
    { value: "Un Delivered", label: "Un Delivered" },
    { value: "Return to Origin", label: "Return to Origin" },
    { value: "return", label: "return" },
    { value: "cancel", label: "cancel" },
  ];
  paymentMode: any = [
    { value: "all", label: "All" },
    { value: "netsPayment", label: "NETS payment" },
    { value: "UPI payment", label: "UPI payment" },
    { value: "cash on delivery", label: "Cash On delivery" },
  ];

  // pagination
  pageIndex: any = 1;
  totalCount: any;
  pageSize: any;

  searchInput: any = "";

  constructor(private spinner: NgxSpinnerService) {}

  ngOnInit(): void {
    this.fetchData();
  }

  fetchData() {
    this.spinner.show();
    var filterOption = {
      paymentStatus: this.selectedPaymentStatus,
      orderStatus: this.selectedOrderStatus,
      paymentType: this.selectedPaymentMode,
      search: this.searchInput,
    };

    axios
      .post(
        environment.url + "orders/paymentDetails?page=" + this.pageIndex,
        filterOption,
        {
          headers: {
            Authorization:
              "Bearer " + localStorage.getItem("admintokenoasisdata"),
          },
        }
      )
      .then((response) => {
        console.log(response);
        this.paymentsList = response.data.data.orderPaymentDetails;
        this.totalCount = response.data?.data?.pagination?.totalCount;
        this.pageSize = response?.data?.data?.pagination?.limit;
        document.getElementById("paymentsTable").scrollIntoView();
        window.scroll(0, 0);
        this.spinner.hide();
      })
      .catch((error) => {
        console.log(error.response);
        this.spinner.hide();
      });
  }

  searchPayments() {
    this.pageIndex = 1;
    this.fetchData();
  }

  applyFilter() {
    this.pageIndex = 1;
    this.fetchData();
  }

  onPageNumberChange(event: any) {
    // this.spinner.show();
    if (event.pageIndex != this.pageIndex) {
      this.pageIndex = event.pageIndex;
      this.fetchData();
    }
  }
}
