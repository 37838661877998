<h3 *ngIf="showTable" class="category-heading-outside outside_heading">Category</h3>


<div class="row">
    <!-- form column -->
    <div class="col-lg-5">
        <nz-card *ngIf="showForm" class="fixed_height">
            <div class="row justify-content-center">
                <div class="col-12 mb-3 title-text">
                    <div class="">
                        <h4>
                            <span class="back_icon" (click)="showFormDetails()" nz-icon nzType="left" nzTheme="outline">
                            </span> {{isEdit? 'Edit': 'Add New'}} Category
                        </h4>
                    </div>

                    <!-- <div *ngIf="!isEdit" class="">
                        <label for='input-category-excel'>
                            <a class="save-button" nz-button>
                                <span class="bulk_upload_icon" nz-icon nzType="cloud-upload" nzTheme="outline"></span>
                                <span class="bulk_upload_btn">Bulk Upload</span>
                            </a>
                        </label>

                        <input (change)="uploadExcelFile($event)" class="upload_input" id='input-category-excel'
                            type='file'>
                    </div> -->
                </div>

                <div class="col-lg-12 form_main_div">
                    <form nz-form>
                        <!-- <div class="row justify-content-center">
                            <div class="col-lg-7 left_column"> -->
                        <!-- <nz-card class="form_fixed_height"> -->
                        <div nz-row [nzGutter]="24">
                            <div nz-col [nzXs]="24" [nzSpan]="12">
                                <nz-form-item>
                                    <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired
                                        nzFor="CategoryName">
                                        Name
                                    </nz-form-label>
                                    <nz-form-control nzErrorTip="Please input your username!">
                                        <nz-input-group>
                                            <input autocomplete="off" type="text" [(ngModel)]="categoryName"
                                                [ngModelOptions]="{standalone: true}" nz-input id="CategoryName"
                                                placeholder="Enter Category Name">
                                        </nz-input-group>
                                        <p class="error-message" *ngIf="requiredData == 'name'">*Please Enter Category
                                            Name
                                        </p>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>

                            <!-- <div nz-col [nzXs]="24" [nzSpan]="12">
                                <nz-form-item>
                                    <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired
                                        nzFor="BrandWebsite">
                                        Parents
                                    </nz-form-label>
                                    <nz-form-control>
                                        <nz-input-group>
                                            <nz-select [(ngModel)]="parentId" [ngModelOptions]="{standalone: true}"
                                                id="BrandWebsite" nzShowSearch nzAllowClear class="w-100"
                                                nzPlaceHolder="Select Parent Category">
                                                <nz-option *ngFor="let spec of categoriesLists;let i=index"
                                                    [nzValue]="spec._id" [nzLabel]="spec.name">
                                                </nz-option>
                                            </nz-select>
                                        </nz-input-group>
                                    </nz-form-control>
                                </nz-form-item>
                            </div> -->

                            <div nz-col [nzXs]="24" [nzSpan]="12">
                                <nz-form-item>
                                    <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired
                                        nzFor="CategoryImage">Category Image
                                    </nz-form-label>
                                    <nz-form-control nzErrorTip="Please input your username!">
                                        <nz-input-group>
                                            <button nz-button type="button" class="cancel-button"
                                                (click)="showMedia('category')">
                                                <span>Select from media</span>
                                            </button>
                                            <img style="width: 60px;padding-left: 10px;"
                                                *ngFor="let category of categoryImage" nz-image
                                                [nzSrc]="liveUrl + category?.image?.url">
                                        </nz-input-group>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>

                            <!-- <div nz-col [nzXs]="24" [nzSpan]="12">
                                <nz-form-item>
                                    <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired
                                        nzFor="CategoryImage">Banner Image
                                    </nz-form-label>
                                    <nz-form-control nzErrorTip="Please input your username!">
                                        <nz-input-group>
                                            <button nz-button type="button" class="cancel-button"
                                                (click)="showMedia('banner')">
                                                <span>Select from media</span>
                                            </button>
                                            <img style="width: 60px;padding-left: 10px;"
                                                *ngFor="let banner of bannerImage" nz-image
                                                [nzSrc]="liveUrl + banner?.image?.url">
                                        </nz-input-group>
                                    </nz-form-control>
                                </nz-form-item>
                            </div> -->

                            <!-- <div nz-col [nzXs]="12" [nzSpan]="12">
                                <nz-form-item>
                                    <nz-form-label nzRequired nzFor="CategoryFeature">
                                        Featured
                                    </nz-form-label>
                                    <nz-form-control>

                                        <nz-switch id="CategoryFeature" [(ngModel)]="isFeatured"
                                            [ngModelOptions]="{standalone: true}" [nzCheckedChildren]="checkedTemplate"
                                            [nzUnCheckedChildren]="unCheckedTemplate"></nz-switch>
                                        <ng-template #checkedTemplate><i nz-icon nzType="check"></i>
                                        </ng-template>
                                        <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i>
                                        </ng-template>

                                    </nz-form-control>
                                </nz-form-item>
                            </div> -->

                            <div nz-col [nzXs]="12" [nzSpan]="12">
                                <nz-form-item>
                                    <nz-form-label nzRequired nzFor="CategoryActive">
                                        Active
                                    </nz-form-label>
                                    <nz-form-control>
                                        <!-- <nz-input-group> -->
                                        <nz-switch id="CategoryActive" [(ngModel)]="isActive"
                                            [ngModelOptions]="{standalone: true}" [nzCheckedChildren]="checkedTemplate"
                                            [nzUnCheckedChildren]="unCheckedTemplate">
                                        </nz-switch>
                                        <ng-template #checkedTemplate><i nz-icon nzType="check"></i>
                                        </ng-template>
                                        <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i>
                                        </ng-template>
                                        <!-- </nz-input-group> -->
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>

                        <nz-form-item class="text-center">
                            <nz-form-control>
                                <button (click)="resetForm()" type="button" class="mr-3 cancel-button" nz-button
                                    nzType="default">Reset</button>
                                <a>
                                    <button [nzLoading]="categoryLoading" type="button" (click)="doChanges()" nz-button
                                        class="save-button">{{defaultMode ==
                                        'Add' ? 'Create' :
                                        'Update'}}</button>
                                </a>
                            </nz-form-control>
                        </nz-form-item>
                        <!-- </nz-card> -->
                        <!-- </div> -->


                        <!-- <div class="col-lg-5 right_column">
                            <nz-card class="form_fixed_height">
                                <nz-form-item>
                                    <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired
                                        nzFor="SpecificationGroup">
                                        Choose Specification Group</nz-form-label>
                                    <nz-form-control>
                                        <nz-input-group>
                                            <nz-select id="SpecificationGroup" [(ngModel)]="specGroupName"
                                                [ngModelOptions]="{standalone: true}" nzShowSearch class="w-100"
                                                nzPlaceHolder="None" #selectedGroup>
                                                <nz-option *ngFor="let spec of specList;let i=index"
                                                    nzValue="{{spec._id}}" nzLabel="{{spec.name}}"></nz-option>
                                            </nz-select>
                                        </nz-input-group>
                                        <p class="error-message" *ngIf="requiredData == 'groupName'">*Please Select
                                            Group
                                            Name
                                        </p>
                                    </nz-form-control>
                                </nz-form-item>

                                <nz-form-item>
                                    <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired
                                        nzFor="SpecificationValue">
                                        Choose Specification Value</nz-form-label>
                                    <nz-form-control>
                                        <nz-input-group>
                                            <nz-select nzMode="multiple" [(ngModel)]="specNameList"
                                                [ngModelOptions]="{standalone: true}" id="SpecificationValue"
                                                nzShowSearch class="w-100" nzPlaceHolder="None" #selectedValue>
                                                <nz-option *ngFor="let spec of specValueList;let i=index"
                                                    nzValue="{{spec._id}}" nzLabel="{{spec.name}}"></nz-option>
                                            </nz-select>

                                        </nz-input-group>
                                    </nz-form-control>
                                </nz-form-item>

                                <nz-form-item class="text-center">
                                    <nz-form-control>
                                        <a>
                                            <button type="button"
                                                (click)="addspecificantion(specGroupName , specNameList)" nz-button
                                                class="cancel-button">Add Specification</button>
                                        </a>
                                    </nz-form-control>
                                </nz-form-item>

                                <nz-form-item class="mb-5">
                                    <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired
                                        nzFor="SpecificationValue">
                                        Selected Specification</nz-form-label>
                                    <nz-form-control>
                                        <nz-input-group>
                                            <nz-tag [nzColor]="'#87d068'" *ngFor="let tag of tags" nzMode="closeable"
                                                (click)="editSpec(tag)" (nzOnClose)="onClose(tag)">
                                                {{tag.name}}</nz-tag>
                                        </nz-input-group>
                                    </nz-form-control>
                                </nz-form-item>

                                <nz-form-item class="text-center">
                                    <nz-form-control>
                                        <button (click)="resetForm()" type="button" class="mr-3 cancel-button" nz-button
                                            nzType="default">Reset</button>
                                        <a>
                                            <button [nzLoading]="categoryLoading" type="button" (click)="doChanges()"
                                                nz-button class="save-button">{{defaultMode ==
                                                'Add' ? 'Create' :
                                                'Update'}}</button>
                                        </a>
                                    </nz-form-control>
                                </nz-form-item>
                            </nz-card>
                        </div> -->
                        <!-- </div> -->
                    </form>

                    <!-- <form nz-form>
                <div class="Specification-section">
                    <hr>
                    <div class="text-center mb-5 mt-5">
                        <h4>Specification</h4>
                    </div>
                    <hr>
                </div>
            </form> -->
                </div>
            </div>
        </nz-card>
    </div>

    <!-- table column -->
    <div class="col-lg-7">
        <nz-card *ngIf="showTable" class="fixed_height">
            <div class="row justify-content-between">
                <div class="col-lg-4 text-left web-heading">
                    <h4>All Category</h4>
                </div>
                <div class="col-lg-8 table_search search_space">
                    <div class="d-md-flex">
                        <div class="m-b-10">
                            <nz-input-group [nzPrefix]="prefixTemplate">
                                <input (keyup)="searchCategory()" autocomplete="off" type="text" nz-input
                                    placeholder="Enter Category Name" [(ngModel)]="searchInput">
                            </nz-input-group>
                            <ng-template #prefixTemplate>
                                <i nz-icon nzType="search" class="opacity-05"></i>
                            </ng-template>
                        </div>
                    </div>

                    <!-- <div class="m-b-10">
                        <button [nzLoading]="downloadingFile" (click)="downloadCategoriesAsFile()" class="save-button"
                            nz-button>
                            <i nz-icon nzType="download" nzTheme="outline"></i>
                        </button>
                    </div> -->
                </div>

                <div *ngIf="checkboxActive" class="col-lg-6 col-8 m-b-10">
                    <div class="">
                        <nz-select nzShowSearch [(ngModel)]="selectedOption" class="w-100" nzPlaceHolder="None">
                            <!-- <nz-option nzValue="Ready for immediate dispatch"
                                nzLabel="Ready for immediate dispatch"></nz-option> -->
                            <nz-option nzValue="In Stock" nzLabel="In Stock"></nz-option>
                            <nz-option nzValue="Out of Stock" nzLabel="Out of Stock"></nz-option>
                            <!-- <nz-option nzValue="Against Order" nzLabel="Against Order"></nz-option> -->
                            <!-- <nz-option nzValue="Pre Order" nzLabel="Pre Order"></nz-option> -->
                        </nz-select>
                    </div>
                </div>
                <div *ngIf="checkboxActive" class="col-lg-6 col-4 checkboxColumn">
                    <a [nzLoading]="categoryLoading" (click)="updateStockStatus()" class="publish-button" nz-button
                        nzType=""><span>Submit</span></a>
                </div>

                <div class="col-xl-4 col-md-5 col-sm-5 col-12 text-right add-button-section">
                    <a (click)="showFormDetails()" nz-button class="save-button">
                        <i nz-icon nzType="plus-circle" theme="outline"></i>
                        <span>Add Category</span>
                    </a>
                </div>
            </div>
            <nz-table
                [nzFooter]="totalCount == 0 ? '' : 'Showing ' +  (((pageSize * pageIndex) - pageSize) + 1) + ' to ' +  (pageSize * pageIndex > totalCount ? totalCount : pageSize * pageIndex) +  ' of ' +  totalCount"
                #productsListTable [nzData]="productsList" [nzScroll]="{ y: '58vh', x : '500px' }"
                [nzFrontPagination]="false" [nzTotal]="totalCount" [nzPageSize]="pageSize" [nzPageIndex]="pageIndex"
                (nzQueryParams)="onPageNumberChange('categoryTable' , $event)">
                <thead>
                    <tr>
                        <th nzWidth="30px">
                            <!-- <input class="checkbox-label" type="checkbox" (ngModelChange)="SelectAll()" [(ngModel)]="Checked"
                        class="form-check-input"> -->
                        </th>
                        <th *ngFor="let column of orderColumn" [nzWidth]="column.width">{{ column.title }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr id="categoryTable" *ngFor="let item of productsListTable.data ;  let i=index">
                        <td>
                            <!-- <label class="checkbox-label" (ngModelChange)="checkSelected(item)" nz-checkbox [(ngModel)]="item.isSelected"></label> -->
                            <input class="checkbox-label" type="checkbox" (ngModelChange)="checkSelected(item)"
                                [(ngModel)]="item.isSelected" class="form-check-input">
                        </td>

                        <!-- <td>{{i+1}}</td> -->
                        <td>
                            <!-- <nz-avatar nzShape="square" [nzSize]="50" nzIcon="picture" > -->
                            <img class="table_image" nz-image [nzSrc]="liveUrl + item.image" alt="">
                            <!-- </nz-avatar> -->
                        </td>
                        <td>
                            <div (click)="editCategory(item)" class="category-name">
                                {{item.name}}
                            </div>
                        </td>
                        <!-- <td>
                    <div *ngFor="let data of item.children" class="children-name d-flex">
                        <p>{{data}}</p>
                    </div>
                </td> -->
                        <!-- <td>
                            <span class="text-capitalize">
                                <nz-switch [nzLoading]="categoryLoading" [(ngModel)]="item.featured"
                                    (ngModelChange)="directUpdate(item , $event)"></nz-switch>
                            
                            </span>
                        </td> -->
                        <td>
                            <!-- <span [ngClass]="{'un-published' : !item.active}" class="text-capitalize published">{{item.active ?
                        'Published' : 'Unpublished'}}</span> -->
                            <nz-switch [nzLoading]="categoryLoading" [(ngModel)]="item.active"
                                (ngModelChange)="directUpdate(item , $event)"></nz-switch>
                        </td>
                        <!-- <td>{{item?.priority ? item?.priority : '-'}}</td> -->
                        <!-- <td *ngFor="let spec of item.spectification">{{spec}}</td> -->
                        <!-- <td class="">
                    <button [nzLoading]="offerLoading" (click)="addOffer(item , 'category')" nz-button>
                        {{item?.offerData?.isInOffer ? 'Remove' : 'Add to'}} Offer</button>
                </td> -->
                    </tr>
                </tbody>
                <!-- LOADER -->

                <ngx-spinner *ngIf="!MediaVisible" bdColor="rgb(255 255 255 / 88%)" size="medium" color="#00f226"
                    type="ball-spin-clockwise" [fullScreen]="false"></ngx-spinner>
            </nz-table>
        </nz-card>
    </div>
</div>

<!-- offer section -->

<nz-card *ngIf="!showTable && !showForm" class="">
    <div class="row justify-content-center">
        <div class="col-12 mb-3 title-text">
            <div class="">
                <h4>
                    <span (click)="showTableData()" class="back_icon" nz-icon nzType="left" nzTheme="outline">
                    </span> Add Offer
                </h4>
            </div>
        </div>

        <div class="col-lg-12">
            <app-add-to-offer-page (offerUpdated)="showTableData()" [offerDetails]="selectedData">

            </app-add-to-offer-page>
        </div>
    </div>
</nz-card>

<!-- MEDIA MODEL SECTION STARTS -->

<nz-modal [nzStyle]="{ top: '10px' }" [(nzVisible)]="MediaVisible" nzCentered nzWidth="90%" nzTitle="Category Image"
    (nzOnCancel)="handleCancel()" (nzOnOk)="sendMedia()" nzOkText="Set Category Image" [nzOkLoading]="isOkLoading">
    <div class="container-fluid" *nzModalContent>
        <app-media></app-media>
    </div>
</nz-modal>

<!-- MEDIA MODEL SECTION STARTS -->



<!-- [nzSortFn]="column.compare" -->