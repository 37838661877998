import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { scrollToTop } from '../../shared/dummy';
import axios, { Axios } from 'axios';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';

interface DataItem {
  id: any;
  name: string;
  acceptedValues: any;
  Type: any;
  icon: any;
  showOnFilter: any;
  createdBy: any;
  createdAt: any;
  lastUpdateBy: any;
  lastUpdateAt: any;
}

@Component({
  selector: 'app-spec-value',
  templateUrl: './spec-value.component.html',
  styleUrls: ['./spec-value.component.css'],
})
export class SpecValueComponent implements OnInit {
  selectedCategory: string;
  selectedStatus: string;
  searchInput: any = '';
  displayData = [];

  showTable: boolean = true;
  showForm: boolean = false;

  MediaVisible = false;
  isOkLoading = false;

  specValueName = '';
  specType = 'Value';
  acceptedValues = [];
  isShowOnFilter = false;
  defaultMode = 'Add';
  specDescription = '';
  requiredData = '';

  validateForm: FormGroup;
  specLoading: boolean;

  pageIndex = 1;
  totalCount: number = 0;
  pageSize: number = 0;

  directMode: boolean;
  specSpinner = false;

  orderColumn = [
    // {
    //   title: 'S.NO',
    //   width: '30px',
    // },
    {
      title: 'Value Name',
      width: '60px',
      compare: (a: DataItem, b: DataItem) => a.name.localeCompare(b.name),
    },
    {
      title: 'Type',
      width: '50px',
      compare: (a: DataItem, b: DataItem) => a.Type - b.Type,
    },
    {
      title: 'showOnFilter',
      width: '60px',
      compare: (a: DataItem, b: DataItem) => a.showOnFilter - b.showOnFilter,
    },
  ];
  fetchData() {
    this.specSpinner = true;
    axios
      .get(
        environment.url +
          'specValues/get?page=' +
          this.pageIndex +
          '&search=' +
          this.searchInput,
        //environment.url+'specValues/get',
        {
          headers: {
            Authorization:
              'Bearer ' + localStorage.getItem('admintokenoasisdata'),
          },
        }
      )
      .then((response) => {
        console.log(response);
        this.totalCount = response?.data?.pagination?.totalCount;
        this.pageSize = response?.data?.pagination?.limit;
        this.productsList = response.data.data.map(
          (data: any, index: number) => {
            return {
              id: data._id,
              name: data.name,
              acceptedValues: data.acceptedValues,
              type: data.type,
              icon: 'assets/images/others/thumb-11.jpg',
              showOnFilter: data.isShowOnFilter,
              createdBy: data.createdBy,
              createdAt: data.createdAt,
              lastUpdateBy: data.lastUpdatedBy,
              lastUpdateAt: data.lastUpdatedAt,
            };
          }
        );
        this.specSpinner = false;
        this.spinner.hide();

        //console.log(response.data.status);
      })
      .catch((error) => {
        console.log(error);
        this.specSpinner = false;
        this.spinner.hide();
        //alert(error.response.data.message);
      });
  }
  productsList: DataItem[] = [];
  specId = '';

  // constructor(private tableSvc : TableService) {
  //     this.displayData = this.productsList
  // }

  // search(): void {
  //     const data = this.productsList
  //     this.displayData = this.tableSvc.search(this.searchInput, data )
  // }

  constructor(
    private fb: FormBuilder,
    private modalService: NzModalService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {}

  categoryChange(value: string): void {
    const data = this.productsList;
    // value !== 'All'
    //   ? (this.displayData = data.filter((elm) => elm.name === value))
    this.displayData = data;
  }

  statusChange(value: string): void {
    const data = this.productsList;
    // value !== 'All'
    //   ? (this.displayData = data.filter((elm) => elm.feature === value))
    //   : (this.displayData = data);
    this.displayData = data;
  }

  ngOnInit(): void {
    this.spinner.show();
    scrollToTop();
    this.fetchData();
    this.validateForm = this.fb.group({});
  }

  showFormDetails() {
    this.requiredData = '';
    this.defaultMode = 'Add';
    this.directMode = false;
    this.clearFields();
    this.showTable = !this.showTable;
    this.showForm = !this.showForm;
  }

  // MODEL SECTION
  showMedia() {
    this.MediaVisible = true;
  }
  handleCancel(): void {
    this.MediaVisible = false;
  }

  resetForm(): void {
    // this.validateForm.reset();
    this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Sure You Want to Reset ?',
      nzOkText: 'Yes',
      nzCancelText: 'No',
      nzOnOk: () => this.clearFields(),
    });
  }

  doChanges() {
    var self = this;
    if (self.specValueName == '') {
      // alert('Enter Spec Value Name');
      self.requiredData = 'name';
    } else if (self.acceptedValues.length == 0 && self.specType == 'Value') {
      // alert('Select Accepted Values');
      self.requiredData = 'value';
    } else {
      if (self.defaultMode == 'Add') {
        var specValueDetails: any = {
          name: self.specValueName,
          type: self.specType,
          isShowOnFilter: self.isShowOnFilter,
        };

        if (self.specType == 'Value') {
          specValueDetails.acceptedValues = self.acceptedValues;
        }
        self.specLoading = true;
        axios
          .post(
            environment.url + 'specValues/create',
            //environment.url+'specValues/create',
            specValueDetails,
            {
              headers: {
                Authorization:
                  'Bearer ' + localStorage.getItem('admintokenoasisdata'),
              },
            }
          )
          .then((response) => {
            if (response.data.status == 'success') {
              console.log(response.data.data);
              self.toastr.success('Specification Value Added!', '', {
                timeOut: 2500,
              });
              self.requiredData = '';
              if (!self.directMode) {
                self.fetchData();
                self.showTable = !self.showTable;
                self.showForm = !self.showForm;
              }
              self.directMode = false;

              self.specValueName = '';
              self.specType = 'Value';
              self.acceptedValues = [];
              self.isShowOnFilter = false;
              self.specLoading = false;
              //  localStorage.setItem('admintokenoasisdata',response.data.data);
              //  self.router.navigate(['dashboard'])
            }
          })
          .catch((error) => {
            self.specLoading = false;
            if (self.directMode) {
              self.fetchData();
            }
            if (error.response.data.data == undefined) {
              self.toastr.error('', error.response.data.message, {
                timeOut: 2500,
              });
            } else {
              self.toastr.error('', error.response.data.data[0].msg, {
                timeOut: 2500,
              });
            }
          });
      } else if (self.defaultMode == 'Edit') {
        var reqObj: any = {
          id: self.specId,
          name: self.specValueName,
          type: self.specType,
          isShowOnFilter: self.isShowOnFilter,
        };

        if (self.specType == 'Value') {
          reqObj.acceptedValues = self.acceptedValues;
        }
        self.specLoading = true;
        axios
          .post(
            environment.url + 'specValues/update',
            //environment.url+'specValues/update',
            reqObj,
            {
              headers: {
                Authorization:
                  'Bearer ' + localStorage.getItem('admintokenoasisdata'),
              },
            }
          )
          .then((response) => {
            self.specLoading = false;
            if (response.data.status == 'success') {
              console.log(response.data.data);
              self.toastr.success('Specification Value Updated!', '', {
                timeOut: 2500,
              });
              self.requiredData = '';
              if (!self.directMode) {
                self.fetchData();
                self.showTable = !self.showTable;
                self.showForm = !self.showForm;
              }
              self.directMode = false;

              self.specValueName = '';
              self.specType = 'Value';
              self.acceptedValues = [];
              self.isShowOnFilter = false;
              //  localStorage.setItem('admintokenoasisdata',response.data.data);
              //  self.router.navigate(['dashboard'])
            }
          })
          .catch((error) => {
            self.specLoading = false;
            if (self.directMode) {
              self.fetchData();
            }
            if (error.response.data.data == undefined) {
              self.toastr.error('', error.response.data.message, {
                timeOut: 2500,
              });
            } else {
              self.toastr.error('', error.response.data.data[0].msg, {
                timeOut: 2500,
              });
            }
          });
      }
    }
  }
  editSpecValues(spec) {
    var self = this;
    self.clearFields();
    self.directMode = false;
    self.specId = spec.id;
    self.acceptedValues = spec.acceptedValues;
    self.specType = spec.type;
    self.specValueName = spec.name;
    self.isShowOnFilter = spec.showOnFilter;
    self.showTable = !self.showTable;
    self.showForm = !self.showForm;
    self.defaultMode = 'Edit';
  }
  clearFields() {
    this.acceptedValues = [];
    this.specType = 'Value';
    this.specValueName = '';
    this.isShowOnFilter = false;
  }

  directUpdate(selected: any, event: any) {
    var self = this;
    self.clearFields();
    self.specId = selected.id;
    self.acceptedValues = selected.acceptedValues;
    self.specType = selected.type;
    self.specValueName = selected.name;
    self.isShowOnFilter = selected.showOnFilter;
    self.defaultMode = 'Edit';

    self.directMode = true;
    self.doChanges();
  }

  onPageNumberChange(id: any, event: any) {
    if (event.pageIndex != this.pageIndex) {
    this.pageIndex = event.pageIndex;
    this.fetchData();
    document.getElementById(id).scrollIntoView();
    window.scroll(0, 0);
    }
  }

  searchSpecValue() {
    this.pageIndex = 1;
    this.fetchData();
  }
}
