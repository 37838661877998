<h3 *ngIf="showTable" class="category-heading-outside outside_heading">Brands</h3>


<div class="row">
    <!-- form column -->
    <div class="col-lg-5">
        <nz-card class="fixed_height" *ngIf="showForm">
            <div class="row justify-content-center">
                <div class="col-12 form-details">
                    <div class="row justify-content-center">
                        <div class="col-12 mb-3 title-text">
                            <div class="">
                                <h4>
                                    <span class="back_icon" (click)="showFormDetails()" nz-icon nzType="left"
                                        nzTheme="outline">
                                    </span>
                                    {{isEdit? 'Edit': 'Add New'}} Brand
                                </h4>
                            </div>

                            <div *ngIf="!isEdit" class="">
                                <label for='input-category-excel'>
                                    <!-- <button class="save-button" nz-button> -->
                                    <a class="save-button" nz-button>
                                        <span class="bulk_upload_icon" nz-icon nzType="cloud-upload"
                                            nzTheme="outline"></span>
                                        <span class="bulk_upload_btn">Bulk Upload</span>
                                    </a>
                                    <!-- </button> -->
                                </label>

                                <input (change)="uploadExcelFile($event)" class="upload_input" id='input-category-excel'
                                    type='file'>
                            </div>
                        </div>

                        <div class="col-lg-12 form_main_div">
                            <form nz-form>
                                <nz-form-item>
                                    <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired
                                        nzFor="BrandName">Name
                                    </nz-form-label>
                                    <nz-form-control nzErrorTip="Please input your username!">
                                        <nz-input-group>
                                            <input autocomplete="off" type="text" [(ngModel)]="brandName"
                                                [ngModelOptions]="{standalone: true}" nz-input id="BrandName"
                                                placeholder="Enter Brand Name">
                                        </nz-input-group>
                                        <p class="error-message" *ngIf="requiredData == 'name'">*Please Enter Brand Name
                                        </p>
                                    </nz-form-control>
                                </nz-form-item>

                                <nz-form-item>
                                    <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired
                                        nzFor="BrandLogo">Logo
                                    </nz-form-label>
                                    <nz-form-control nzErrorTip="Please input your username!">
                                        <nz-input-group>
                                            <button type="button" nz-button class="cancel-button" (click)="showMedia()">
                                                <span>Select from media</span>
                                            </button>
                                            <img style="width: 60px;padding-left: 10px;"
                                                *ngFor="let category of brandImages"
                                                [src]="liveUrl + category?.image?.url">
                                        </nz-input-group>
                                    </nz-form-control>
                                </nz-form-item>

                                <nz-form-item>
                                    <nz-form-label nzRequired nzFor="BrandPopular">
                                        Popular
                                    </nz-form-label>
                                    <nz-form-control>
                                        <!-- <nz-input-group> -->
                                        <nz-switch id="BrandPopular" [(ngModel)]="isPopular"
                                            [ngModelOptions]="{standalone: true}" [nzCheckedChildren]="checkedTemplate"
                                            [nzUnCheckedChildren]="unCheckedTemplate"></nz-switch>
                                        <ng-template #checkedTemplate><i nz-icon nzType="check"></i></ng-template>
                                        <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i></ng-template>
                                        <!-- </nz-input-group> -->
                                    </nz-form-control>
                                </nz-form-item>

                                <nz-form-item>
                                    <nz-form-label nzRequired nzFor="BrandActive">
                                        Active
                                    </nz-form-label>
                                    <nz-form-control>
                                        <!-- <nz-input-group> -->
                                        <nz-switch id="BrandActive" [(ngModel)]="isActive"
                                            [ngModelOptions]="{standalone: true}" [nzCheckedChildren]="checkedTemplate"
                                            [nzUnCheckedChildren]="unCheckedTemplate"></nz-switch>
                                        <ng-template #checkedTemplate><i nz-icon nzType="check"></i></ng-template>
                                        <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i></ng-template>
                                        <!-- </nz-input-group> -->
                                    </nz-form-control>
                                </nz-form-item>

                                <nz-form-item>
                                    <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzFor="BrandWebsite">
                                        Website</nz-form-label>
                                    <nz-form-control>
                                        <nz-input-group>
                                            <input autocomplete="off" type="text" [(ngModel)]="website"
                                                [ngModelOptions]="{standalone: true}" nz-input id="BrandWebsite"
                                                placeholder="Website Name">
                                        </nz-input-group>
                                    </nz-form-control>
                                </nz-form-item>

                                <nz-form-item>
                                    <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzFor="BrandDescription">
                                        Description
                                    </nz-form-label>
                                    <nz-form-control>
                                        <nz-input-group>
                                            <textarea autocomplete="off" id="BrandDescription" [(ngModel)]="description"
                                                [ngModelOptions]="{standalone: true}" nz-input
                                                placeholder="Enter Your Text Here"
                                                [nzAutosize]="{ minRows: 3, maxRows: 6 }"></textarea>
                                        </nz-input-group>
                                    </nz-form-control>
                                </nz-form-item>

                                <nz-form-item class="text-center">
                                    <nz-form-control>
                                        <button (click)="resetForm()" type="button" class="mr-3 cancel-button" nz-button
                                            nzType="default">Reset</button>
                                        <a>
                                            <button [nzLoading]="brandsLoading" (click)="doChanges()" type="button"
                                                nz-button class="save-button">{{isEdit == false ? 'Create' : 'Update'}}
                                            </button>
                                        </a>
                                        <!-- <a *ngIf="isEdit == true"><button (click)="doChanges()" type="button" nz-button
                                                class="save-button">Update</button></a> -->
                                    </nz-form-control>
                                </nz-form-item>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </nz-card>
    </div>
    <!-- table column -->
    <div class="col-lg-7">
        <nz-card class="fixed_height" *ngIf="showTable">
            <div class="row">
                <div class="col-lg-4 text-left web-heading">
                    <h4>All Brands</h4>
                </div>
                <div class="col-lg-8 table_search search_space">
                    <div class="d-md-flex">
                        <div class="m-b-10">
                            <nz-input-group [nzPrefix]="prefixTemplate">
                                <input (keyup)="searchBrands()" autocomplete="off" type="text" nz-input
                                    placeholder="Enter Brand Name" [(ngModel)]="searchInput">
                            </nz-input-group>
                            <ng-template #prefixTemplate>
                                <i nz-icon nzType="search" class="opacity-05"></i>
                            </ng-template>
                        </div>
                    </div>

                    <div class="m-b-10">
                        <button [nzLoading]="downloadingFile" (click)="downloadBrandsAsFile()" class="save-button"
                            nz-button>
                            <!-- <i nz-icon nzType="plus-circle" theme="outline"></i> -->
                            <i nz-icon nzType="download" nzTheme="outline"></i>
                        </button>
                    </div>
                </div>
                <div class="col-lg-4 text-right add-button-section">
                    <a (click)="showFormDetails()" nz-button class="save-button">
                        <i nz-icon nzType="plus-circle" theme="outline"></i>
                        <span>Add Brands</span>
                    </a>
                </div>
            </div>
            <nz-table #productsListTable
                [nzFooter]="totalCount == 0 ? '' : 'Showing ' +  (((pageSize * pageIndex) - pageSize) + 1) + ' to ' +  (pageSize * pageIndex > totalCount ? totalCount : pageSize * pageIndex) +  ' of ' +  totalCount"
                [nzData]="productsList" [nzScroll]="{ y: '58vh' , x:'500px'}" [nzFrontPagination]="false"
                [nzTotal]="totalCount" [nzPageSize]="pageSize" [nzPageIndex]="pageIndex"
                (nzQueryParams)="onPageNumberChange('brandsTable' , $event)">
                <thead>
                    <tr>
                        <th *ngFor="let column of orderColumn" [nzWidth]="column.width">{{ column.title }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr id="brandsTable" *ngFor="let item of productsListTable.data ;  let i=index">
                        <!-- <td>{{i+1}}</td> -->
                        <td>
                            <div (click)="editBrand(item)" class="d-flex align-items-center brand-name">
                                {{item.name}}
                            </div>
                        </td>
                        <td>
                            <nz-avatar nzShape="square" [nzSize]="50" nzIcon="picture" [nzSrc]="liveUrl+item.logo">
                            </nz-avatar>
                        </td>

                        <td>
                            <div class="website_link">
                                <a target="_blank" [href]="item.website">{{item.website}}</a>
                            </div>

                        </td>
                    </tr>
                </tbody>
                <!-- LOADER -->

                <ngx-spinner *ngIf="!MediaVisible" bdColor="rgb(255 255 255 / 88%)" size="medium" color="#00f226"
                    type="ball-spin-clockwise" [fullScreen]="false">
                </ngx-spinner>
            </nz-table>
        </nz-card>
    </div>
</div>


<!-- MEDIA MODEL SECTION STARTS -->

<nz-modal [nzStyle]="{ top: '10px' }" nzWidth="90%" [(nzVisible)]="MediaVisible" nzCentered nzTitle="Brand Image"
    (nzOnCancel)="handleCancel()" (nzOnOk)="sendMedia()" nzOkText="Set Brand Image" [nzOkLoading]="isOkLoading">
    <div class="container-fluid" *nzModalContent>
        <app-media (imageSelected)="onImageSelected($event)"></app-media>
    </div>
</nz-modal>

<!-- MEDIA MODEL SECTION STARTS -->