<!-- <nz-card> -->
<div class="container">
    <form [formGroup]="addOfferForm" (ngSubmit)="addOffer()" nz-form>
        <div class="row">
            <div class="col-lg-7">
                <div nz-row [nzGutter]="24">

                    <div nz-col [nzXs]="24" [nzSpan]="24">
                        <nz-form-item>
                            <nz-form-label class="large_label" [nzLg]="24" [nzSm]="24" nzRequired>Discount Type
                            </nz-form-label>
                            <nz-form-control nzErrorTip="Please Select Discount Type!">
                                <nz-input-group>
                                    <nz-select formControlName="discountType" class="w-100"
                                        nzPlaceHolder="Select Discount Type">
                                        <nz-option nzValue="percentage-discount" nzLabel="By Percentage"></nz-option>
                                    <nz-option nzValue="price-discount" nzLabel="By Price"></nz-option>
                                    </nz-select>
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div nz-col [nzXs]="24" [nzSpan]="24">
                        <nz-form-item *ngIf="addOfferForm.controls['discountType'].value === 'percentage-discount'">
                            <nz-form-label class="large_label" [nzLg]="24" [nzSm]="24" nzRequired nzFor="ProductName">
                                Discount
                                Percentage
                            </nz-form-label>
                            <nz-form-control nzErrorTip="Please Enter Percentage!">
                                <nz-input-group>
                                    <input formControlName="percentage" autocomplete="off" type="number" nz-input
                                        placeholder="Enter Product Name">
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div nz-col [nzXs]="24" [nzSpan]="24">
                        <nz-form-item *ngIf="addOfferForm.controls['discountType'].value === 'price-discount'">
                            <nz-form-label class="large_label" [nzLg]="24" [nzSm]="24" nzRequired nzFor="ProductName">
                                Discount
                                Price
                            </nz-form-label>
                            <nz-form-control nzErrorTip="Please Enter Price!">
                                <nz-input-group>
                                    <input formControlName="price" autocomplete="off" type="number" nz-input
                                        placeholder="Enter Product Name">
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div nz-col [nzXs]="24" [nzSpan]="24">
                        <nz-form-item>
                            <nz-form-label class="large_label" [nzLg]="24" [nzSm]="24" nzRequired nzFor="ProductImage">
                                Banner
                                Image
                            </nz-form-label>
                            <nz-form-control>
                                <nz-input-group>
                                    <button type="button" nz-button nzType="primary" (click)="showMedia()">
                                        <span>Upload</span>
                                    </button>
                                    <img *ngIf="bannerImage" style="width: 70px;padding-left: 10px;" class="img-fluid" [src]="liveUrl + bannerImage[0].image.url" alt="">
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div nz-col [nzXs]="12" [nzSpan]="24">
                        <nz-form-item>
                            <nz-form-label class="large_label" [nzLg]="24" [nzSm]="24" nzRequired nzFor="from-date">
                                Start Date
                            </nz-form-label>
                            <nz-form-control nzErrorTip="Please Select date!">
                                <nz-input-group>
                                    <nz-date-picker nzShowTime nzFormat="dd-MM-yyyy h:mm:ss a"
                                        formControlName="fromDate" class="w-100" [nzRenderExtraFooter]=""
                                        nzPlaceHolder="Start Date"></nz-date-picker>
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div nz-col [nzXs]="12" [nzSpan]="24">
                        <nz-form-item>
                            <nz-form-label class="large_label" [nzLg]="24" [nzSm]="24" nzRequired nzFor="from-date">
                                End Date
                            </nz-form-label>
                            <nz-form-control nzErrorTip="Please Select date!">
                                <nz-input-group>
                                    <nz-date-picker nzShowTime nzFormat="dd-MM-yyyy h:mm:ss a" formControlName="toDate"
                                        class="w-100" [nzRenderExtraFooter]="" nzPlaceHolder="End Date">
                                    </nz-date-picker>
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div nz-col [nzXs]="24" [nzSpan]="24">
                        <nz-form-item class="text-center">
                            <nz-form-control>
                                <button type="button" class="mr-5" nz-button nzType="default">Reset</button>
                                <a><button type="submit" nz-button nzType="primary">Add</button></a>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<!-- </nz-card> -->



<!-- MEDIA CONTENT -->

<nz-modal [nzStyle]="{ top: '10px' }" nzWidth="90%" [(nzVisible)]="MediaVisible" nzTitle="Banner Image" nzCentered
    (nzOnCancel)="handleCancel()" (nzOnOk)="sendMedia()" nzOkText="Set Banner Image" [nzOkLoading]="isOkLoading">
    <div class="container-fluid" *nzModalContent>
        <app-media></app-media>
    </div>
</nz-modal>