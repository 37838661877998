<!-- <nz-card> -->
<h3 class="category-heading-outside">Specifications</h3>
<div class="row">
    <div class="col-xl-5">
        <app-spec-group></app-spec-group>
    </div>
    <div class="col-xl-7">
        <app-spec-value></app-spec-value>
    </div>
</div>
<!-- </nz-card> -->