import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { environment } from "../environments/environment";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { ToastrService } from "ngx-toastr";
// import { AngularFireMessaging } from "@angular/fire/compat/messaging";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
  message: any;

  constructor(
    private toastr: ToastrService // private afMessaging: AngularFireMessaging
  ) {
    // this.afMessaging.onBackgroundMessage((payload) => {
    //   console.log("Received background message: ", payload);
    //   // handle the background message here
    // });
  }

  ngOnInit(): void {
    // console.log(navigator.onLine);
    this.requestPermission();
    this.listen();

    console.log("serviceWorker" in navigator);
  }

  // ngAfterViewInit() {
  //   console.log("after view init");
  //   navigator.serviceWorker.addEventListener("message", (event) => {
  //     console.log("Received message in app component: ", event.data);
  //   });
  // }

  // Push Notification

  requestPermission() {
    const messaging = getMessaging();
    getToken(messaging, { vapidKey: environment.firebase.vapidKey })
      .then((currentToken) => {
        if (currentToken) {
          console.log("Hurraaa!!! we got the token.....");
          console.log(currentToken);
          localStorage.setItem(
            "sgdfgdgqirbanmsdfgfjhsdfgkkfdhstafydiuscdagsyuker",
            currentToken
          );
        } else {
          console.log(
            "No registration token available. Request permission to generate one."
          );
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
      });
  }

  listen() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      console.log("Message received. ", payload);
      this.playSound();
      this.toastr.info(
        payload?.notification?.body,
        payload?.notification?.title,
        {
          timeOut: 2500,
        }
      );
      this.message = payload;
    });
  }

  playSound() {
    const audio = new Audio("../assets/audio/push_notification_alert.mp3");
    audio.play();
  }
}
