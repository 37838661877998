import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class ExportExcelService {
  constructor() {}

  exportExcel(excelData: any, filename: any) {
    //Create a workbook with a worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Orders');

    for (let s = 0; s < excelData.length; s++) {
      const title = excelData[s].title;
      const header = excelData[s].headers;
      const data = excelData[s].data;

      //Blank Row
      worksheet.addRow([]);
      let heading = worksheet.addRow(title);
      heading.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '0de503' },
        bgColor: { argb: '' },
      };
      heading.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12,
      };
      worksheet.addRow([]);

      //Adding Header Row
      let headerRow = worksheet.addRow(header);
      headerRow.eachCell((cell) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '4167B8' },
          bgColor: { argb: '' },
        };
        cell.font = {
          bold: true,
          color: { argb: 'FFFFFF' },
          size: 12,
        };
      });

      // Adding Data
      data.forEach((d) => {
        let row = worksheet.addRow(d);
      });

      //Blank Row
      worksheet.addRow([]);
    }
    //Title, Header & Data

    //Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      fs.saveAs(blob, filename + '.xlsx');
    });
  }
}
