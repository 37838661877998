<div class="header">
    <div class="logo logo-dark">
        <a routerLink="/dashboard">
            <img src="../../../../assets/images/logo/Shamimah_logo.svg" alt="Logo">
            <img class="logo-fold" src="../../../../assets/images/logo/Shamimah_logo.svg" alt="Logo">
        </a>
    </div>
    <!-- <div class="logo logo-white">
        <a href="">
            <img src="https://res.cloudinary.com/oasis-it-store/image/upload/v1648107220/ttt/oasis_qgul9s.png" alt="Logo">
            <img class="logo-fold" src="https://res.cloudinary.com/oasis-it-store/image/upload/v1648107220/ttt/oasis_qgul9s.png" alt="Logo">
        </a>
    </div> -->
    <div class="nav-wrap">
        <ul class="nav-left">
            <li class="desktop-toggle">
                <a (click)="toggleFold()">
                    <i nz-icon [nzType]="isFolded? 'menu-unfold':'menu-fold'" theme="outline"></i>
                </a>
            </li>
            <li class="mobile-toggle">
                <a (click)="toggleExpand()">
                    <i nz-icon [nzType]="isExpand? 'menu-fold': 'menu-unfold'" theme="outline"></i>
                </a>
            </li>
            <li class="mobile_logo" routerLink="/dashboard">
                <img src="../../../../assets/images/logo/Shamimah_logo.svg" alt="Logo">
            </li>
        </ul>
        <ul class="nav-right">
            <li>
                <div class="user-section">
                    <div class="mr-3">
                        <!-- <p (click)="changeColor()" class="color_picker"></p> -->
                        <!-- <div nz-popover [nzPopoverContent]="contentTemplate" nzPopoverTrigger="click" nzPopoverPlacement="bottomLeft">
                            <img src="../../../../assets/AdminPanel/Logo/user.svg" alt="">
                        </div> -->
                        <a nz-dropdown nzTrigger="hover" [nzDropdownMenu]="menu" [nzPlacement]="'bottomRight'">
                            <img src="../../../../assets/images/logo/user.svg" alt="">
                        </a>

                    </div>
                    <div class="profile-detail">
                        <h3>{{userData?.user?.name}}</h3>
                        <p>{{userData?.user?.role}}</p>
                    </div>
                </div>
            </li>
        </ul>

    </div>
</div>

<nz-dropdown-menu #menu="nzDropdownMenu">
    <ul class="nz-dropdown-oprion" nz-menu>
        <li (click)="Logout()" nz-menu-item> <i class="mr-3" nz-icon nzType="logout" nzTheme="outline"></i> logout</li>
        <!-- <a href=""> <i nz-icon nzType="logout" nzTheme="outline"></i> Log Out</a> -->
    </ul>
</nz-dropdown-menu>