<nz-card *ngIf="!showForm" [ngClass]="{'dashboard_orders' : !orderDetailsPage} ">
    <div *ngIf="orderDetailsPage" class="row">
        <div class="col-lg-8">
            <div class="row justify-content-start">
                <div class="col-sm-6">
                    <div class="">
                        <div class="m-b-10">
                            <nz-input-group [nzPrefix]="prefixTemplate">
                                <input autocomplete="off" type="text" nz-input placeholder="Enter order number "
                                    [(ngModel)]="searchInput" (keyup)="searchOrders()">
                            </nz-input-group>
                            <ng-template #prefixTemplate>
                                <i nz-icon nzType="search" class="opacity-05"></i>
                            </ng-template>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4 mb-3">
                    <nz-select [(ngModel)]="filterOption" (ngModelChange)="applyFilter()" class="w-100">
                        <nz-option nzValue="all" nzLabel="All Orders"></nz-option>
                        <nz-option nzValue="today" nzLabel="Today"></nz-option>
                    </nz-select>

                </div>
            </div>
        </div>

        <!-- <div class="col-lg-4 text-right add-button-section">
            <a (click)="export()" nz-button nzType="primary">
                <i nz-icon nzType="file-excel" theme="outline"></i>
                <span>Export</span>
            </a>
        </div> -->
    </div>

    <div *ngIf="!orderDetailsPage" class="d-flex justify-content-between align-items-center mb-3">
        <h5>Recent Orders</h5>
    </div>

    <nz-table
        [nzFooter]="!orderDetailsPage ? '' : (totalCount == 0 ? '' : 'Showing ' +  (((pageSize * pageIndex) - pageSize) + 1) + ' to ' +  (pageSize * pageIndex > totalCount ? totalCount : pageSize * pageIndex) +  ' of ' +  totalCount)"
        #ordersListTable [nzShowPagination]="orderDetailsPage" [nzData]="ordersList" [nzFrontPagination]="false"
        [nzTotal]="totalCount" [nzPageSize]="pageSize" [nzPageIndex]="pageIndex"
        [nzScroll]="{ x: '1150px', y: scrollHeight }" (nzQueryParams)="onPageNumberChange($event , 'orderTable')">
        <thead>
            <tr>
                <th class="th_div" *ngFor="let column of orderColumn" [nzWidth]="column.width">
                    <span class="table_heading_span">
                        {{column.title }}
                        <div *ngIf="column.title =='Date & Time' || column.title =='Amount'" class="up_down_arrow_div">
                            <span (click)="sortFunc(column.title,'up')"><img class="up"
                                    src="../../../assets/images/avatars/icons8-up-96.png" alt=""></span>
                            <span (click)="sortFunc(column.title,'down')"><img class="down"
                                    src="../../../assets/images/avatars/icons8-down-96.png" alt=""></span>
                        </div>
                    </span>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr id="orderTable" *ngFor="let item of ordersListTable.data;  let i=index">
                <!-- <td>{{i+1}}</td> -->
                <td>
                    <div class="d-flex align-items-center">
                        <!-- <nz-avatar [nzSize]="30" nzIcon="user" [nzSrc]="item.avatar"></nz-avatar> -->
                        <h6 (click)="viewDetails(item)" class="m-l-10 m-b-0 order-id">{{item.orderId}}</h6>
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center">
                        <!-- <nz-avatar [nzSize]="30" nzIcon="user" [nzSrc]="item.avatar"></nz-avatar> -->
                        <h6 (click)="viewCutomerDetails(item)" class="m-l-10 m-b-0 customer_name_link">{{item.name}}
                        </h6>
                    </div>
                </td>
                <td>{{moment(item.date).format('DD-MM-YYYY hh:mm A')}}</td>
                <td>$ {{item?.amount?.toFixed(2)}}</td>
                <td>
                    <span class="text-capitalize">{{item.status}}</span>
                </td>
                <td>
                    <span>{{item.paymentType}}</span>
                </td>
                <td>
                    <div [ngClass]="{'un-published' : item?.shipping?.tracking?.paymentStatus == 'unpaid'}"
                        class="published ">
                        {{item?.shipping?.tracking?.paymentStatus}}
                    </div>
                </td>
                <!-- <td class="">
                    <a class="m-r-5" nz-button nzType="default" nzShape="circle" nz-tooltip nzTooltipTitle="Edit">
                        <i nz-icon nzType="edit" theme="outline"></i>
                    </a>
                   
                </td> -->
            </tr>
        </tbody>
        <ngx-spinner bdColor="rgb(255 255 255 / 88%)" size="medium" color="#00f226" type="ball-spin-clockwise"
            [fullScreen]="false">
        </ngx-spinner>
    </nz-table>
</nz-card>

<div *ngIf="showForm" class="">
    <!-- <div class="row">
        <div class="col-12">
            <div class="close-icon ">
                <i (click)="showFormDetails()" nz-icon nzType="close" nzTheme="outline" title="close"></i>
            </div>
            <div class="mt-3 order-heading">
                <h3>Order Data</h3>
            </div>
            <div class="row">
                <div class="col-4 order-data-title">
                    <h4>Order Number</h4>
                    <p>{{orderFullDetails.orderId}}</p>

                </div>
                <div class="col-4 order-data-title">
                    <h4>Order Date</h4>
                    <p>{{moment(orderFullDetails.orderDate).format('DD-MM-YYYY')}}</p>
                </div>
                <div class="col-4 order-data-title mb-3 address-section">
                    <h4>Address</h4>
                    <p>{{orderFullDetails.address.addressLine1}}, </p>
                    <p>{{orderFullDetails.address.addressLine2}}, </p>
                    <p>{{orderFullDetails.address.townOrCity}}, </p>
                    <p>{{orderFullDetails.address.state}}, </p>
                    <p>{{orderFullDetails.address.country}}, </p>
                    <p>{{orderFullDetails.address.pincodeOrZipcode}}.</p>
                </div>
                <div class="col-4 order-data-title">
                    <h4>Customer Name</h4>
                    <p style="text-transform: capitalize ;">{{orderFullDetails.name}} </p>
                </div>
                <div class="col-4 order-data-title">
                    <h4>Payment Method</h4>
                    <p>{{orderFullDetails.paymentMode}}</p>
                </div>
                <div class="col-4 order-data-title">
                    <h4>Contact Number</h4>
                    <p>{{orderFullDetails.contact}}</p>
                </div>
            </div>
        </div>
        <div class="col-12 mt-5">
            <div class="order-heading">
                <h3>Order Items</h3>
            </div>
            <nz-table #orderFullDetailsTable [nzShowPagination]="false" [nzData]="orderFullDetails.products"
                [nzScroll]="{ y: '300px' , x : '500px' }">
                <thead>
                    <tr>
                        <th *ngFor="let column of OrderDetails" [nzWidth]="column.width">{{ column.title }}</th>
                    </tr>
                </thead>
                <tbody>

                    <tr *ngFor="let item of orderFullDetailsTable.data ;  let i=index">

                        <td>
                            <div class="d-flex align-items-center">
                                <h6 class="m-l-10 m-b-0">{{item.item.name}}</h6>
                            </div>
                        </td>

                        <td>
                            <div class="d-flex align-items-center">
                                <h6 class="m-l-10 m-b-0">$ {{item?.orderedPrice?.toFixed(2)}}
                                </h6>
                            </div>
                        </td>

                        <td>
                            <div class="d-flex align-items-center">
                                <h6 class="m-l-10 m-b-0">{{item?.quantity}}</h6>
                            </div>
                        </td>

                        <td>
                            <div class="d-flex align-items-center">
                                <h6 class="m-l-10 m-b-0">$ {{item?.orderedPrice?.toFixed(2)}}</h6>
                            </div>
                        </td>

                    </tr>
                </tbody>
            </nz-table>
        </div>

        <div class="col-12 mt-3 text-right">
            <div class="total-price">
                <h4>Order Total Price</h4>
                <p>$ {{orderFullDetails.amount}}</p>
            </div>
        </div>

        <div class="col-12 mt-5">
            <div class="row">
                <div class="col-6">
                    <nz-form-item>
                        <nz-form-label [nzLg]="8" [nzSm]="6" nzRequired nzFor="order">Order Status</nz-form-label>
                        <nz-form-control>
                            <nz-input-group>
                                <nz-select id="order" [(ngModel)]="orderStatus" (ngModelChange)="changeOrderStatus()"
                                    nzShowSearch class="w-100" nzPlaceHolder="Order Placed">
                                    <nz-option nzValue="order placed" nzLabel="Order Placed"></nz-option>
                                    <nz-option nzValue="order shipped" nzLabel="Order Shipped"></nz-option>
                                    <nz-option nzValue="delivered" nzLabel="Delivered"></nz-option>
                                </nz-select>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item *ngIf="getTrackId">
                        <nz-form-label [nzLg]="8" [nzSm]="6" nzRequired nzFor="track-id">Track Id</nz-form-label>
                        <nz-form-control>
                            <nz-input-group>
                                <input autocomplete="off" type="text" [(ngModel)]="trackId" nz-input id="track-id"
                                    placeholder="Enter Track Id">
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <div class="text-right">
                        <button (click)="updateOrderStatus()" nz-button nzType="primary">
                            <span>Update</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <div class="tm_container">
        <div class="tm_invoice_wrap">
            <div class="tm_invoice tm_style1" id="tm_download_section">
                <div class="tm_invoice_in">
                    <div class="tm_invoice_head tm_align_center tm_mb20">
                        <div class="tm_invoice_left logo_section">
                            <span class="left_arrow_icon" (click)="showFormDetails()" nz-icon nzType="left"
                                nzTheme="outline"></span>
                            <div class="tm_logo"><img src="../../../assets/images/logo/Shamimah_logo.svg" alt="Logo">
                            </div>
                        </div>
                        <div class="tm_invoice_right tm_text_right">
                            <div class="tm_primary_color tm_f40">Order Details</div>
                        </div>
                    </div>

                    <div class="tm_invoice_info tm_mb20">
                        <div class="tm_invoice_seperator tm_gray_bg"></div>
                        <div class="tm_invoice_info_list">
                            <p class="tm_invoice_number tm_m0">Order Number: <b
                                    class="tm_primary_color">{{orderFullDetails?.orderId}}</b></p>
                            <p class="tm_invoice_date tm_m0">Order Date: <b
                                    class="tm_primary_color">{{moment(orderFullDetails?.orderDate).format('DD-MM-YYYY')}}</b>
                            </p>
                        </div>
                    </div>

                    <div class="tm_invoice_head tm_mb10">

                        <div class="tm_invoice_left">
                            <p class="tm_mb2"><b class="tm_primary_color">Customer Name:</b></p>
                            <p> {{orderFullDetails?.name}} </p>
                            <p class="tm_mb2"><b class="tm_primary_color">Contact Number:</b></p>
                            <p>{{orderFullDetails?.contact}}</p>
                        </div>

                        <div class="tm_invoice_right tm_text_right">
                            <p class="tm_mb2"><b class="tm_primary_color">Address:</b></p>
                            <p class="delivery_address">{{orderFullDetails?.shipping?.contact?.address?.blockNumber}},
                            </p>
                            <p class="delivery_address">{{orderFullDetails?.shipping?.contact?.address?.buildingName}}
                            </p>
                            <p class="delivery_address">{{orderFullDetails?.shipping?.contact?.address?.streetName}}</p>
                            <p class="delivery_address">{{orderFullDetails?.shipping?.contact?.address?.city}}</p>
                            <!-- <br> {{orderFullDetails?.shipping?.contact?.address?.unitNumber}}. -->

                        </div>
                    </div>

                    <div class=" tm_style1">
                        <nz-table #orderFullDetailsTable [nzShowPagination]="false" [nzData]="orderFullDetails.products"
                            [nzScroll]="{ y: '300px' , x : '500px' }">
                            <thead>
                                <tr>
                                    <th *ngFor="let column of OrderDetails" [nzWidth]="column.width">{{ column.title }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr *ngFor="let item of orderFullDetailsTable.data ;  let i=index">

                                    <td>
                                        <h6 class="">{{item?.item?.name}}</h6>
                                    </td>

                                    <td>
                                        <h6 class="">$ {{item?.orderedPrice?.toFixed(2)}}</h6>
                                    </td>

                                    <td>
                                        <h6 class="">{{item?.quantity}}</h6>
                                    </td>

                                    <td>
                                        <h6 class="">$ {{item?.orderedPrice?.toFixed(2)}}</h6>
                                    </td>

                                </tr>
                            </tbody>
                        </nz-table>
                        <div class="tm_invoice_footer mt-2">
                            <div class="tm_left_footer p-0">
                                <p class="tm_mb2"><b class="tm_primary_color">Payment info:</b></p>
                                <p class="t_upper">{{orderFullDetails?.paymentMode}}</p>
                                <p class="tm_mb2"><b class="tm_primary_color">Order Status:</b></p>
                                <p class="t_upper">{{orderFullDetails?.status}}</p>
                            </div>
                            <div class="tm_right_footer">
                                <table>
                                    <tbody>
                                        <!-- <tr class="tm_gray_bg tm_border_top tm_border_left tm_border_right">
                                            <td class="tm_width_3 tm_primary_color tm_border_none tm_bold">Subtoal</td>
                                            <td
                                                class="tm_width_3 tm_primary_color tm_text_right tm_border_none tm_bold">
                                                $1650</td>
                                        </tr>
                                        <tr class="tm_gray_bg tm_border_left tm_border_right">
                                            <td class="tm_width_3 tm_primary_color tm_border_none tm_pt0">Discount <span
                                                    class="tm_ternary_color">(10%)</span></td>
                                            <td class="tm_width_3 tm_text_right tm_border_none tm_pt0 tm_danger_color">
                                                -$165</td>
                                        </tr>
                                        <tr class="tm_gray_bg tm_border_left tm_border_right">
                                            <td class="tm_width_3 tm_primary_color tm_border_none tm_pt0">Tax <span
                                                    class="tm_ternary_color">(5%)</span></td>
                                            <td class="tm_width_3 tm_primary_color tm_text_right tm_border_none tm_pt0">
                                                +$82</td>
                                        </tr> -->
                                        <tr class="tm_gray_bg">
                                            <td class="tm_width_3 tm_border_top_0 tm_bold tm_f16 tm_primary_color">
                                                Grand Total
                                            </td>
                                            <td
                                                class="tm_width_3 tm_border_top_0 tm_bold tm_f16 tm_primary_color tm_text_right">
                                                $ {{orderFullDetails?.amount}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <hr class="mt-2 mb-3">

                        <div class="order_form_row" nz-row [nzGutter]="24">
                            <div nz-col [nzXs]="24" class="mb-3" [nzSm]="7">
                                <nz-form-item>
                                    <nz-form-label class="large_label" [nzXs]="24" nzRequired nzFor="order">Order Status
                                    </nz-form-label>
                                    <nz-form-control>
                                        <nz-input-group>
                                            <nz-select
                                                [nzDisabled]="orderStatus == 'failed' || orderStatus == 'Processing'"
                                                id="order" [(ngModel)]="orderStatus"
                                                (ngModelChange)="changeOrderStatus()" class="w-100"
                                                nzPlaceHolder="Order Status">
                                                <!-- <nz-option nzValue="order placed" nzLabel="Order Received"></nz-option> -->
                                                <nz-option [nzHide]="userData.user.role == 'deliveryBoy'"
                                                    nzValue="Order confirmed" nzLabel="Order Confirmed"></nz-option>
                                                <nz-option [nzHide]="userData.user.role == 'deliveryBoy'"
                                                    nzValue="Ready for dispatch"
                                                    nzLabel="Ready for Dispatch"></nz-option>
                                                <nz-option nzValue="Out for Delivery"
                                                    nzLabel="Out For Delivery"></nz-option>
                                                <nz-option nzValue="Delivered" nzLabel="Delivered"></nz-option>
                                                <nz-option [nzHide]="userData.user.role == 'deliveryBoy'"
                                                    nzValue="Order cancelled" nzLabel="Order Cancelled"></nz-option>
                                                <nz-option [nzHide]="true" nzValue="failed"
                                                    nzLabel="Failed"></nz-option>
                                                <nz-option [nzHide]="true" nzValue="order placed"
                                                    nzLabel="Order Placed"></nz-option>
                                            </nz-select>
                                        </nz-input-group>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>

                            <div *ngIf="orderFullDetails.paymentMode == 'cash on delivery'" nz-col [nzXs]="24"
                                class="mb-3" [nzSm]="7">
                                <nz-form-item>
                                    <nz-form-label class="large_label" [nzXs]="24" nzRequired nzFor="order">
                                        Payment Status
                                    </nz-form-label>
                                    <nz-form-control>
                                        <nz-input-group>
                                            <nz-select id="order" [(ngModel)]="codStatus"
                                                (ngModelChange)="changeOrderStatus()" class="w-100"
                                                nzPlaceHolder="select payment status">
                                                <nz-option nzValue="paid" nzLabel="Paid"></nz-option>
                                                <nz-option nzValue="unpaid" nzLabel="Unpaid"></nz-option>
                                            </nz-select>
                                        </nz-input-group>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>

                            <div nz-col [nzXs]="24" class="mb-3" [nzSm]="7">
                                <nz-form-item>
                                    <nz-form-label class="large_label" [nzXs]="24" nzRequired nzFor="track-id">Track Id
                                    </nz-form-label>
                                    <nz-form-control>
                                        <nz-input-group>
                                            <input [disabled]="orderStatus == 'failed' || orderStatus == 'Processing'"
                                                autocomplete="off" type="text" [(ngModel)]="trackId" nz-input
                                                id="track-id" placeholder="Enter Track Id">
                                        </nz-input-group>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>

                            <div nz-col [nzXs]="24" class="" [nzSm]="3">
                                <div class="text-right mb-3">
                                    <button [disabled]="orderStatus == 'failed' || orderStatus == 'Processing'"
                                        (click)="updateOrderStatus()" nz-button nzType="primary">
                                        <span>Update</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <hr class="tm_mb20">
                    <div class="tm_text_center">
                        <p class="tm_mb5"><b class="tm_primary_color">Terms & Conditions:</b></p>
                        <p class="tm_m0">Your use of the Website shall be deemed to constitute your understanding and
                            approval of, and agreement <br class="tm_hide_print">to be bound by, the Privacy Policy and
                            you consent to the collection.</p>
                    </div> -->
                </div>
            </div>
            <!-- <div class="tm_invoice_btns tm_hide_print">
                <a href="javascript:window.print()" class="tm_invoice_btn tm_color1">
                    <span class="tm_btn_icon">
                        <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512">
                            <path
                                d="M384 368h24a40.12 40.12 0 0040-40V168a40.12 40.12 0 00-40-40H104a40.12 40.12 0 00-40 40v160a40.12 40.12 0 0040 40h24"
                                fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32" />
                            <rect x="128" y="240" width="256" height="208" rx="24.32" ry="24.32" fill="none"
                                stroke="currentColor" stroke-linejoin="round" stroke-width="32" />
                            <path d="M384 128v-24a40.12 40.12 0 00-40-40H168a40.12 40.12 0 00-40 40v24" fill="none"
                                stroke="currentColor" stroke-linejoin="round" stroke-width="32" />
                            <circle cx="392" cy="184" r="24" fill='currentColor' />
                        </svg>
                    </span>
                    <span class="tm_btn_text">Print</span>
                </a>
                <button id="tm_download_btn" class="tm_invoice_btn tm_color2">
                    <span class="tm_btn_icon">
                        <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512">
                            <path
                                d="M320 336h76c55 0 100-21.21 100-75.6s-53-73.47-96-75.6C391.11 99.74 329 48 256 48c-69 0-113.44 45.79-128 91.2-60 5.7-112 35.88-112 98.4S70 336 136 336h56M192 400.1l64 63.9 64-63.9M256 224v224.03"
                                fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="32" />
                        </svg>
                    </span>
                    <span class="tm_btn_text">Download</span>
                </button>
            </div> -->
        </div>
    </div>
</div>





<!-- (ngModelChange)="search()" GOES TO LINE 7 -->
<!-- [nzSortFn]="column.compare" -->
<!-- [disabled]="orderFullDetails?.shipping?.tracking?.trackingId" -->



<nz-drawer [nzWidth]="drawerWidth" [nzClosable]="true" [nzVisible]="customerDetails" [nzPlacement]="'right'"
    nzTitle="Customer Details" (nzOnClose)="closeCutomerDetails()">
    <ng-container *nzDrawerContent>
        <div class="order_details_container">
            <div class="order_details_div mb-3">
                <div class="row cd_row mb-3">
                    <div class="">
                        <img class="product_image" src="../../../assets/images/logo/man-g5126ed6c5_1280.png" alt="">
                    </div>
                    <div class="n_cd_div">
                        <!-- <h5>{{orderFullDetails?.name}}</h5> -->
                        <p><span>Name: </span>{{orderFullDetails?.name}}</p>
                        <p><span>Contact No: </span>{{ orderFullDetails?.contact}}</p>
                        <!-- <p><span>Order Date: </span>{{ moment(order?.orderedDate).format('DD-MM-YYYY hh:mm a')}}</p> -->

                        <p><span>Block Number: </span>{{ orderFullDetails?.shipping?.contact?.address?.blockNumber}}
                        </p>
                        <p><span>Unit Number:</span>{{ orderFullDetails?.address?.unitNumber}}</p>
                        <p><span>Building Name:</span>{{ orderFullDetails?.shipping?.contact?.address?.buildingName}}
                        </p>
                        <p><span>Street Name:</span>{{ orderFullDetails?.shipping?.contact?.address?.streetName}}</p>
                        <p><span>Postal Code:</span>{{ orderFullDetails?.shipping?.contact?.address?.pincodeOrZipcode}}
                        </p>


                        <!-- <p><span>Order Total: </span>{{'Rs.' + order?.orderTotal}}</p> -->
                    </div>
                </div>
            </div>
        </div>

        <ngx-spinner *ngIf="drawerSpinner" bdColor="rgb(255 255 255 / 88%)" size="medium" color="#00f226"
            type="ball-spin-clockwise" [fullScreen]="false"></ngx-spinner>
    </ng-container>
</nz-drawer>