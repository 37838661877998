<h3 class="category-heading-outside outside_heading">Attributes</h3>




<div class="row">
    <!-- form column -->
    <div class="col-lg-5">
        <nz-card class="fixed_height" *ngIf="showForm">
            <div class="row justify-content-center">
                <div class="col-12 form-details">
                    <div class="row justify-content-center">
                        <div class="col-12 title-text">
                            <div class="mb-3">
                                <h4>
                                    <span class="back_icon" (click)="showTableDesign()" nz-icon nzType="left"
                                        nzTheme="outline">
                                    </span>
                                    {{isEdit? 'Edit': 'Add New'}} Attribute
                                </h4>
                            </div>
                        </div>

                        <div class="col-lg-12 form_main_div">
                            <form nz-form>
                                <nz-form-item>
                                    <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired
                                        nzFor="attributeName">Name
                                    </nz-form-label>
                                    <nz-form-control nzErrorTip="Please input your username!">
                                        <nz-input-group>
                                            <input autocomplete="off" type="text" [(ngModel)]="name"
                                                [ngModelOptions]="{standalone: true}" nz-input id="attributeName"
                                                placeholder="Enter Attribute Name">
                                        </nz-input-group>
                                        <p class="error-message" *ngIf="requiredData == 'name'">*Please Enter Attribute
                                            Name</p>
                                    </nz-form-control>
                                </nz-form-item>

                                <nz-form-item>
                                    <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired
                                        nzFor="attributeValue">Accepted
                                        Values
                                    </nz-form-label>
                                    <nz-form-control>
                                        <nz-input-group>
                                            <nz-select [(ngModel)]="attValues" [ngModelOptions]="{standalone: true}"
                                                nzMode="tags" id="" nzShowSearch class="w-100"
                                                nzPlaceHolder="Enter Values">
                                            </nz-select>
                                        </nz-input-group>
                                        <p class="error-message" *ngIf="requiredData == 'value'">*Please Enter Attribute
                                            Values
                                        </p>
                                    </nz-form-control>
                                </nz-form-item>

                                <div nz-row [nzGutter]="24">
                                    <div nz-col [nzXs]="12" [nzSpan]="12">
                                        <nz-form-item>
                                            <nz-form-label nzRequired nzFor="attributeFeature">
                                                Feature
                                            </nz-form-label>
                                            <nz-form-control>
                                                <!-- <nz-input-group> -->
                                                <nz-switch [(ngModel)]="isFeatured"
                                                    [ngModelOptions]="{standalone: true}" id="attributeFeature"
                                                    [nzCheckedChildren]="checkedTemplate"
                                                    [nzUnCheckedChildren]="unCheckedTemplate"></nz-switch>
                                                <ng-template #checkedTemplate><i nz-icon nzType="check"></i>
                                                </ng-template>
                                                <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i>
                                                </ng-template>
                                                <!-- </nz-input-group> -->
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>

                                    <div nz-col [nzXs]="12" [nzSpan]="12">
                                        <nz-form-item>
                                            <nz-form-label  nzRequired nzFor="attributeActive">
                                                Active
                                            </nz-form-label>
                                            <nz-form-control>
                                                <!-- <nz-input-group> -->
                                                <nz-switch [(ngModel)]="isActive" [ngModelOptions]="{standalone: true}"
                                                    id="attributeActive" [nzCheckedChildren]="checkedTemplate"
                                                    [nzUnCheckedChildren]="unCheckedTemplate"></nz-switch>
                                                <ng-template #checkedTemplate><i nz-icon nzType="check"></i>
                                                </ng-template>
                                                <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i>
                                                </ng-template>
                                                <!-- </nz-input-group> -->
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                </div>

                                <nz-form-item class="text-center">
                                    <nz-form-control>
                                        <button (click)="resetForm()" type="button" class="mr-3 cancel-button"
                                            nz-button>Reset</button>
                                        <a><button [nzLoading]="attrLoading" class="save-button"
                                                (click)="showFormDetails()" type="button" nz-button>{{isEdit? 'Update':
                                                'Create'}}</button></a>
                                        <!-- <a *ngIf="isEdit"><button (click)="showFormDetails()" nz-button nzType="primary">Edit Attribute</button></a> -->
                                    </nz-form-control>
                                </nz-form-item>

                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </nz-card>
    </div>
    <!-- table column -->
    <div class="col-lg-7">
        <nz-card class="fixed_height" *ngIf="showTable">
            <div class="row">
                <div class="col-lg-4 text-left web-heading">
                    <h4>All Attributes</h4>
                </div>
                <div class="col-lg-8 table_search">
                    <div class="d-md-flex">
                        <div class="m-b-10">
                            <nz-input-group [nzPrefix]="prefixTemplate">
                                <input autocomplete="off" type="text" nz-input placeholder="Enter Attribute Name"
                                    [(ngModel)]="searchInput">
                            </nz-input-group>
                            <ng-template #prefixTemplate>
                                <i nz-icon nzType="search" class="opacity-05"></i>
                            </ng-template>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 text-right add-button-section">
                    <a (click)="showTableDesign()" nz-button class="save-button">
                        <i nz-icon nzType="plus-circle" theme="outline"></i>
                        <span>Add Attribute</span>
                    </a>
                </div>
            </div>
            <nz-table #productsListTable [nzData]="productsList" [nzScroll]="{ y: '58vh' , x : '550px' }"
                (nzQueryParams)="onPageNumberChange('attributeTable')">
                <thead>
                    <tr>
                        <th *ngFor="let column of orderColumn" [nzWidth]="column.width">{{ column.title }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr id="attributeTable"
                        *ngFor="let item of productsListTable.data | filter : searchInput;  let i=index">
                        <!-- <td>{{i+1}}</td> -->
                        <td>
                            <div (click)="editAttribute(item)" class="d-flex align-items-center attribute-name">
                                {{item.name}}
                            </div>
                        </td>

                        <td>{{item.values}}</td>

                        <td>
                            <span class="text-capitalize">{{item.isFeature ? 'Yes' : 'No'}}</span>
                        </td>
                        <td>
                            <span [ngClass]="{'un-published' : !item.isActive}"
                                class="text-capitalize published">{{item.isActive ? 'Published' : 'Unpublished'}}</span>
                        </td>
                        <!-- <td class="">
                            <a (click)="editAttribute(item)" class="m-r-5" nz-button nzType="default" nzShape="circle" nz-tooltip nzTooltipTitle="Edit">
                                <i nz-icon nzType="edit" theme="outline"></i>
                            </a>
                        </td> -->
                    </tr>
                    <!-- LOADER -->

                    <ngx-spinner bdColor="rgb(255 255 255 / 88%)" size="medium" color="#00f226"
                        type="ball-spin-clockwise" [fullScreen]="false">
                    </ngx-spinner>
                </tbody>
            </nz-table>
        </nz-card>
    </div>
</div>

<!-- ADD ATTRIBUTE FORM DESIGN ENDS  -->