import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import axios, { Axios } from 'axios';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { NavService } from 'src/app/services/nav.service';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.css'],
})
export class OfferComponent implements OnInit {
  addOfferForm: FormGroup;
  showTable = true;
  liveUrl = environment.url;
  moment: any = moment;
  createOfferObj: any = {};

  MediaVisible = false;
  isOkLoading = false;

  offerUpdateAction = '';
  offerLoading: boolean;
  isProducts = false;
  isCategory = false;
  bannerImage: any;
  offersList: any = [];

  currentUrl = '';

  selectedType = '';
  selectedTable = 'all_products';
  ShowOffer = true;

  dateFormat = 'dd-MM-yyyy';

  pageSize: number = 0;
  pageIndex = 1;
  totalCount: number = 0;
  searchInput = '';

  categoryPageSize: number = 0;
  categoryPageIndex = 1;
  categoryTotalCount: number = 0;

  selectedData: any = {};

  // Category type Multiple select
  Categories: any[] = [];
  // listOfCategories: string[] = [];

  // categoriestype

  categoriesType = ['All', 'Featured Products', 'Best Seller'];
  test = ['All', 'Featured Products', 'Best Seller'];

  // categoriesType: any[] = [];

  // ProductsType

  productsList: any[] = [];

  // TABLE HEADING

  orderColumn: any = [
    // {
    //   title: 'S.NO',
    //   width: '40px',
    // },
    {
      title: 'SKU ID',
      width: '60px',
    },
    {
      title: 'Name',
      width: '80px',
    },
    {
      title: 'Image',
      width: '50px',
    },
    {
      title: 'Regular Price',
      width: '70px',
    },
    {
      title: 'Offer Price',
      width: '70px',
    },
    {
      title: 'Category',
      width: '60px',
    },
    {
      title: 'Action',
      width: '80px',
    },
  ];

  offerOrderColumn = [
    // {
    //   title: 'S.NO',
    //   width: '40px',
    // },
    {
      title: 'Offer Name',
      width: '80px',
    },
    {
      title: 'Offer Type',
      width: '90px',
    },
    {
      title: 'Offer value',
      width: '70px',
    },
    {
      title: 'Start Date',
      width: '90px',
    },
    {
      title: 'End Date',
      width: '90px',
    },
    {
      title: 'Category & Products',
      width: '100px',
    },
    {
      title: 'Status',
      width: '80px',
    },
  ];

  CategoryHeading = [
    // {
    //   title: 'S.NO',
    //   width: '50px',
    // },
    {
      title: 'Category Name',
      width: '100px',
    },
    {
      title: 'Image',
      width: '60px',
    },
    // {
    //   title: 'Children',
    //   width: '200px',
    // },
    // {
    //   title: 'priority',
    //   width: '60px',
    // },
    {
      title: 'Action',
      width: '120px',
    },
  ];

  offerCategoryHeading = [
    // {
    //   title: 'S.NO',
    //   width: '50px',
    // },
    {
      title: 'Category Name',
      width: '100px',
    },
    {
      title: 'Image',
      width: '60px',
    },
    {
      title: 'Children',
      width: '200px',
    },
    {
      title: 'Start Date',
      width: '120px',
    },
    {
      title: 'End Date',
      width: '120px',
    },
    {
      title: 'priority',
      width: '60px',
    },
    {
      title: 'Action',
      width: '120px',
    },
  ];

  CategoryList: any = [];

  // fetchData() {
  //   this.spinner.show();
  //   axios
  //     .get(environment.url + 'products/offers', {})
  //     .then((response) => {
  //       console.log(response.data.data);
  //       var initialResult = response.data.data;
  //       this.offerUpdateAction = 'update';
  //       this.productsList = response.data.data.products;
  //       console.log(this.productsList);
  //       this.spinner.hide();
  //       //console.log(response.data.status);
  //     })
  //     .catch((error) => {
  //       console.log(error.message);
  //       this.spinner.hide();
  //     });
  // }

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private navService: NavService
  ) {}

  ngOnInit(): void {
    this.fetchData();
    this.fetchAllCategories();
    this.fetchProducts();

    // const category: string[] = [];
    // for (let i = 0; i < 3; i++) {
    //   category.push(this.Categories[i]);
    // }
    // this.listOfCategories = category;

    // const categoryType: string[] = [];
    // for (let i = 0; i < 3; i++) {
    //   categoryType.push(this.testtype[i]);
    // }
    // this.categoriesType = categoryType;

    // const productNew: string[] = [];
    // for (let i = 0; i < 4; i++) {
    //   productNew.push(this.productstestName[i]);
    // }
    // this.Products = productNew;

    this.addOfferForm = this.fb.group({
      OfferName: [null, [Validators.required]],
      DiscountType: [null, [Validators.required]],
      OfferType: [null, [Validators.required]],
      OfferValue: [null, [Validators.required]],
      Categories: [null, [Validators.required]],
      categoriesType: [null, [Validators.required]],
      ProductsName: [null, [Validators.required]],
      startDate: [null, [Validators.required]],
      endDate: [null, [Validators.required]],
      offerActive: [true],
    });
    console.log(this.addOfferForm.value);

    this.currentUrl = this.router.url;
    // this.fetchData();
    // this.getAllProducts();
    // this.getAllCategories();

    // new offer design

    this.getOfferProducts();
  }

  fetchData() {
    this.spinner.show();
    axios
      .post(
        environment.url + 'offers/get-all',
        {},
        {
          headers: {
            Authorization:
              'Bearer ' + localStorage.getItem('admintokenoasisdata'),
          },
        }
      )
      .then((response) => {
        console.log(response);
        this.offersList = response.data.data.offers;
        this.spinner.hide();
      })
      .catch((err) => {
        console.log(err.response);
        this.spinner.hide();
      });
  }

  getAllCategories() {
    var offer = '';
    if (!this.ShowOffer) {
      offer = '';
    } else if (this.ShowOffer) {
      offer = '&isOfferCategories=true';
    }

    axios
      //.get(environment.url+'categories', {})
      .get(
        environment.url +
          'categories?page=' +
          this.categoryPageIndex +
          '&search=' +
          this.searchInput +
          offer,
        {}
      )
      .then((response) => {
        this.categoryTotalCount = response?.data?.pagination?.totalCount;
        this.categoryPageSize = response?.data?.pagination?.limit;
        this.CategoryList = response.data.data;
        console.log(response);
        this.spinner.hide();
        //console.log(response.data.status);
      })
      .catch((error) => {
        this.spinner.hide();
        console.log(error);
      });
  }

  showTableData() {
    this.showTable = true;
  }

  fetchAllCategories() {
    axios
      .get(environment.url + 'categories?isForProductPage=true', {})
      .then((response) => {
        this.Categories = response.data.data;
        console.log(this.Categories);
        // console.log("Categories data"+JSON.stringify(this.categoriesLists));
      })
      .catch((error) => {
        console.log(error);
        //alert(error.response.data.message);
      });
  }

  addOffer() {
    // this.addOfferForm.get('OfferType').valueChanges.subscribe((val) => {
    //   // alert('hi');
    //   this.addOfferForm.get('ProductsName').setValue('');
    //   this.addOfferForm.get('Categories').setValue('');
    //   this.addOfferForm.get('categoriesType').setValue('');
    // });
    if (this.bannerImage == undefined || this.bannerImage == '') {
      this.toastr.error('Please Select Banner Image', '', {
        timeOut: 2500,
      });
    }
    // console.log(this.bannerImage);

    if (this.addOfferForm.value.OfferType == 'category') {
      this.createOfferObj.categoryIds = this.addOfferForm.value.Categories;
      this.addOfferForm.get('ProductsName').setValue(this.productsList[0]._id);
      this.createOfferObj.whichCategory =
        this.addOfferForm.value.categoriesType;
    } else if (this.addOfferForm.value.OfferType == 'single-product') {
      this.createOfferObj.productIds = this.addOfferForm.value.ProductsName;
      this.addOfferForm.get('Categories').setValue(this.Categories[0]._id);
      this.addOfferForm.get('categoriesType').setValue(this.categoriesType[0]);
    }

    if (
      this.addOfferForm.valid &&
      this.bannerImage !== undefined &&
      this.bannerImage !== ''
    ) {
      // console.log(this.addOfferForm);
      this.isOkLoading = true;

      this.createOfferObj.offerName = this.addOfferForm.value.OfferName;
      this.createOfferObj.discountType = this.addOfferForm.value.DiscountType;
      this.createOfferObj.startDate = moment(
        this.addOfferForm.value.startDate
      ).format();
      this.createOfferObj.endDate = moment(
        this.addOfferForm.value.endDate
      ).format();
      this.createOfferObj.offerType = this.addOfferForm.value.OfferType;
      //  categoryIds:this.addOfferForm.value.Categories,

      // value: this.addOfferForm.value.value,
      // productIds:this.addOfferForm.value.ProductsName,
      this.createOfferObj.bannerImageId = this.bannerImage[0]._id;
      this.createOfferObj.isActive = this.addOfferForm.value.offerActive;

      if (this.addOfferForm.value.DiscountType == null) {
        this.createOfferObj.priceDiscount = false;
      } else if (this.addOfferForm.value.DiscountType == 'price-discount') {
        this.createOfferObj.priceDiscount = true;
        this.createOfferObj.priceDiscount = this.addOfferForm.value.OfferValue;
      }

      if (this.addOfferForm.value.DiscountType == null) {
        this.createOfferObj.percentageDiscount = false;
      } else if (
        this.addOfferForm.value.DiscountType == 'percentage-discount'
      ) {
        this.createOfferObj.percentageDiscount = true;
        this.createOfferObj.percentageDiscount =
          this.addOfferForm.value.OfferValue;
      }

      console.log(this.createOfferObj);

      axios
        .post(environment.url + 'offers/create-offer', this.createOfferObj, {
          headers: {
            Authorization:
              'Bearer ' + localStorage.getItem('admintokenoasisdata'),
          },
        })
        .then((response) => {
          this.addOfferForm.reset();
          console.log(response?.data);
          // if (response.data.status == 'success') {
          this.isOkLoading = false;
          this.toastr.success(response?.data?.message, '', {
            timeOut: 2500,
          });
          this.fetchData();
          this.showTable = !this.showTable;
          // }
        })
        .catch((err) => {
          console.log(err?.response);
          if (err?.response?.data?.data == undefined) {
            this.toastr.error('', err?.response?.data?.message, {
              timeOut: 2500,
            });
          } else {
            this.toastr.error('', err?.response?.data?.data[0]?.msg, {
              timeOut: 2500,
            });
          }
          this.isOkLoading = false;
        });
    } else {
      Object.values(this.addOfferForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }

    //   if (type == 'single-product') {
    //     if (!offerData.isInOffer) {
    //       this.selectedData = {
    //         id: offerData._id,
    //         offerType: type,
    //         action: offerData.isInOffer ? 'update' : 'create',
    //       };
    //       console.log(offerData);
    //       this.showTable = false;
    //     } else if (offerData.isInOffer) {
    //       this.removeOffer(offerData, type);
    //     }
    //   } else if (type == 'category') {
    //     if (!offerData?.offerData?.isInOffer) {
    //       this.selectedData = {
    //         id: offerData._id,
    //         offerType: type,
    //         action: offerData?.offerData?.isInOffer ? 'update' : 'create',
    //       };
    //       console.log(offerData);
    //       this.showTable = false;
    //     } else if (offerData?.offerData?.isInOffer) {
    //       this.removeOffer(offerData, type);
    //     }
    //   }
    // }

    // removeOffer(offerData: any, type: any) {
    //   this.offerLoading = true;
    //   var offerObj: any = {};

    //   if (type == 'single-product') {
    //     offerObj.productId = offerData._id;
    //     offerObj.type = 'product';
    //   } else if (type == 'category') {
    //     offerObj.categoryId = offerData._id;
    //     offerObj.type = 'category';
    //   }

    //   axios
    //     .post(environment.url + 'offers/remove-offer', offerObj, {
    //       headers: {
    //         Authorization:
    //           'Bearer ' + localStorage.getItem('admintokenoasisdata'),
    //       },
    //     })
    //     .then((response: any) => {
    //       console.log(response);
    //       this.offerLoading = false;
    //       // this.fetchData();
    //       this.offerToggle();
    //       this.toastr.success(response.data.message, '', {
    //         timeOut: 2500,
    //       });
    //     })
    //     .catch((error: any) => {
    //       this.offerLoading = false;
    //       this.toastr.error(error.response.message, '', {
    //         timeOut: 2500,
    //       });
    //       console.log(error);
    //     });
  }

  handleCancel(): void {
    this.MediaVisible = false;
  }

  sendMedia() {
    this.navService.formRefreshAnnouncedSource.subscribe((item) => {
      this.bannerImage = item;
      console.log(this.bannerImage);
    });
    this.handleCancel();
  }

  showMedia() {
    this.MediaVisible = true;
  }

  fetchProducts() {
    axios
      .get(environment.url + 'products?isForProductPage=true', {
        headers: {
          Authorization:
            'Bearer ' + localStorage.getItem('admintokenoasisdata'),
        },
      })
      .then((response) => {
        //console.log(response.data.data.products[0].name);
        this.productsList = response.data.data;
        console.log(this.productsList);

        // this.count = response.data.data.count;

        console.log(response);
      })
      .catch((error) => {
        console.log(error);
        //alert(error.response.data.message);
      });
  }

  getAllProducts() {
    this.spinner.show();

    var offer = '';
    if (!this.ShowOffer) {
      offer = '';
    } else if (this.ShowOffer) {
      offer = '&isOfferProducts=true';
    }

    axios
      .get(
        environment.url +
          'products?page=' +
          this.pageIndex +
          '&search=' +
          this.searchInput +
          offer,
        {}
      )
      .then((response) => {
        this.offerUpdateAction = 'create';
        console.log(response.data.data);
        var initialResult = response?.data?.data;
        this.totalCount = response?.data?.data?.pagination?.totalCount;
        this.pageSize = response?.data?.data?.pagination?.limit;
        // this.productsLists = response?.data?.data?.products;
        // console.log(this.productsList);

        this.spinner.hide();
      })
      .catch((error) => {
        this.spinner.hide();
        console.log(error);
      });
  }

  offerToggle() {
    console.log('show offer : ' + this.ShowOffer);
    if (this.selectedTable == 'all_products') {
      this.pageIndex = 1;
      this.getAllProducts();
    } else if (this.selectedTable == 'all_categories') {
      this.categoryPageIndex = 1;
      this.getAllCategories();
    }
  }

  onPageNumberChange(event: any) {
    if (event.pageIndex != this.pageIndex) {
    this.spinner.show();
    this.pageIndex = event.pageIndex;
    this.getAllProducts();
    document.getElementById('offerProductsTable').scrollIntoView();
    window.scroll(0, 0);
    }
  }

  categoryPageChange(event: any) {
    console.log(event);
    this.spinner.show();
    this.categoryPageIndex = event.pageIndex;
    this.getAllCategories();
    document.getElementById('offerCategoryTable').scrollIntoView();
    window.scroll(0, 0);
  }

  updated(event: any) {
    this.showTable = true;
    this.fetchData();
  }

  // NEW OFFER DESIGN
  offerHeadings: any = [
    {
      title: 'Offer Name',
      width: '60px',
    },
    {
      title: 'Offer Type',
      width: '80px',
    },
    {
      title: 'Type',
      width: '60px',
    },
    {
      title: 'Sale Price',
      width: '50px',
    },
    {
      title: 'Offer Price',
      width: '50px',
    },
    {
      title: 'Start Date',
      width: '70px',
    },
    {
      title: 'End Date',
      width: '70px',
    },
    {
      title: 'Status',
      width: '90px',
    },
  ];

  offerList: any = [];

  getOfferProducts() {
    axios
      .get(
        environment.url +
          'products?page=' +
          this.pageIndex +
          '&search=' +
          this.searchInput +
          '&isOfferProducts=true',
        {}
      )
      .then((response) => {
        this.offerList = response?.data?.data?.products;
        console.log(this.offerList);
        this.spinner.hide();
      })
      .catch((error) => {
        this.spinner.hide();
        console.log(error);
      });
  }

  showForm() {
    this.addOfferForm.reset();
    this.addOfferForm.get('offerActive').setValue(true);
    this.addOfferForm.get('categoriesType').valueChanges.subscribe((val) => {
      if (val.indexOf('All') >= 0) {
        // this.addOfferForm.get('categoriesType').setValue(this.categoriesType);
        this.categoriesType = ['All'];
      } else {
        this.categoriesType = this.test;
      }
      console.log(val);
    });
    this.showTable = !this.showTable;
  }

  // getOfferCategories() {}

  updateOffer(offerData: any) {
    var updateData = {
      offerId: offerData._id,
      isActive: !offerData.isActive,
    };
    console.log(updateData);
    axios
      .post(environment.url + 'offers/update-active-status', updateData, {
        headers: {
          Authorization:
            'Bearer ' + localStorage.getItem('admintokenoasisdata'),
        },
      })
      .then((response) => {
        console.log(response?.data);
        if (response.data.status == 'success') {
          this.toastr.success('Offer Status Updated!', '', {
            timeOut: 2500,
          });
          this.fetchData();
        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response.data.data == undefined) {
          this.toastr.error('', error.response.data.message, {
            timeOut: 2500,
          });
        } else {
          this.toastr.error(
            error.response?.data?.data[0]?.msg +
              ' at ' +
              error.response?.data?.data[0]?.param,
            '',
            {
              timeOut: 2500,
            }
          );
        }
      });
  }
}
