<h3 *ngIf="showTable" class="category-heading-outside outside_heading">Tags</h3>


<div class="row">
    <!-- form column -->
    <div class="col-lg-5">
        <nz-card *ngIf="showForm" class="fixed_height">
            <div class="row justify-content-center">
                <div class="col-12 mb-3 title-text">
                    <div class="">
                        <h4>
                            <span class="back_icon" (click)="showFormDetails()" nz-icon nzType="left" nzTheme="outline">
                            </span> {{isEdit? 'Edit': 'Add New'}} Tag
                        </h4>
                    </div>
                </div>

                <div class="col-lg-12 form_main_div">
                    <form nz-form [formGroup]="tagsForm" (ngSubmit)="addUpdateTag()">
                        <div nz-row [nzGutter]="24">
                            <div nz-col [nzXs]="24" [nzSpan]="12">
                                <nz-form-item>
                                    <nz-form-label class="large_label" [nzLg]="24" [nzSm]="6" nzRequired>
                                        Tag Name
                                    </nz-form-label>
                                    <nz-form-control nzErrorTip="Please Enter Tag Name!">
                                        <nz-input-group>
                                            <input formControlName="tagName" autocomplete="off" type="text" nz-input
                                                placeholder="Enter Tag Name">
                                        </nz-input-group>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>

                            <div nz-col [nzXs]="12" [nzSpan]="12">
                                <nz-form-item>
                                    <nz-form-label nzRequired>
                                        Active
                                    </nz-form-label>
                                    <nz-form-control>
                                        <nz-switch formControlName="isActive" [nzCheckedChildren]="checkedTemplate"
                                            [nzUnCheckedChildren]="unCheckedTemplate">
                                        </nz-switch>
                                        <ng-template #checkedTemplate><i nz-icon nzType="check"></i>
                                        </ng-template>
                                        <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i>
                                        </ng-template>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>

                        <div nz-col [nzXs]="24" [nzSpan]="12">
                            <nz-form-item class="text-center">
                                <nz-form-control>
                                    <button (click)="resetForm()" type="button" class="mr-3 cancel-button" nz-button
                                        nzType="default">Reset</button>
                                    <a>
                                        <button [nzLoading]="addingTag" type="submit" nz-button class="save-button">
                                            {{!isEdit ? 'Create' : 'Update'}}
                                        </button>
                                    </a>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                    </form>
                </div>
            </div>
        </nz-card>
    </div>

    <!-- table column -->
    <div class="col-lg-7">
        <nz-card *ngIf="showTable" class="fixed_height">
            <div class="row justify-content-between">
                <div class="col-lg-4 text-left web-heading">
                    <h4>All Tags</h4>
                </div>
                <div class="col-lg-8 table_search search_space">
                    <div class="d-md-flex">
                        <div class="m-b-10">
                            <nz-input-group [nzPrefix]="prefixTemplate">
                                <input (keyup)="searchTags()" autocomplete="off" type="text" nz-input
                                    placeholder="Enter Tag Name" [(ngModel)]="searchInput">
                            </nz-input-group>
                            <ng-template #prefixTemplate>
                                <i nz-icon nzType="search" class="opacity-05"></i>
                            </ng-template>
                        </div>
                    </div>
                </div>

                <div class="col-xl-4 col-md-5 col-sm-5 col-12 text-right add-button-section">
                    <a (click)="showFormDetails()" nz-button class="save-button">
                        <i nz-icon nzType="plus-circle" theme="outline"></i>
                        <span>Add Tags</span>
                    </a>
                </div>
            </div>
            <nz-table
                [nzFooter]="totalCount == 0 ? '' : 'Showing ' +  (((pageSize * pageIndex) - pageSize) + 1) + ' to ' +  (pageSize * pageIndex > totalCount ? totalCount : pageSize * pageIndex) +  ' of ' +  totalCount"
                #tagsTable [nzData]="allTags" [nzScroll]="{ y: '58vh', x : '500px' }" [nzFrontPagination]="false"
                [nzTotal]="totalCount" [nzPageSize]="pageSize" [nzPageIndex]="pageIndex"
                (nzQueryParams)="onPageNumberChange('tagsTable' , $event)">
                <thead>
                    <tr>

                        <th *ngFor="let column of orderColumn" [nzWidth]="column.width">{{ column.title }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr id="tagsTable" *ngFor="let item of tagsTable.data ;  let i=index">
                        <td>
                            <div (click)="editTag(item)" class="category-name">
                                {{item.name}}
                            </div>
                        </td>

                        <td>
                            <nz-switch [nzLoading]="categoryLoading" [(ngModel)]="item.isActive"
                                (ngModelChange)="directUpdate(item , $event)"></nz-switch>
                        </td>

                    </tr>
                </tbody>

                <!-- LOADER -->

                <ngx-spinner bdColor="rgb(255 255 255 / 88%)" size="medium" color="#00f226" type="ball-spin-clockwise"
                    [fullScreen]="false"></ngx-spinner>
            </nz-table>
        </nz-card>
    </div>
</div>